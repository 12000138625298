import { Box, Button, Dialog, DialogContent, Grid, Stack, Typography } from '@mui/material';
import { addThousandSeparator } from 'src/constants/SportSEparator';
import { getPendingPaymentsById, Permission, useGetPermissions } from 'src/api/CalendarBooking';
import React, { useContext, useEffect, useState } from 'react';
import { MakePaymentOfPendingPalyer } from 'src/api/Customer';
import { useFieldArray, useForm } from 'react-hook-form';
import PendingPlayersList from './PendingPlayersList';
import MainContext from 'src/context/MainContext';
import PaymentsRow from './Calendar/PaymentsRow';
import { getAllCoupon } from 'src/api/Coupon';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ProductPending from './ProductPending';
import MainProvider from 'src/context/MainProvider';
import { BsInfoCircleFill } from 'react-icons/bs';
import { activeColor } from 'src/constants/color';

const common = {
    display:"flex",
    alignItems:'center',
    gap:"10px",
}

const button={
  fontFamily:'var(--font-regular)',
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
  padding: "7px 20px",
  borderRadius: "7px",
}

const style={
    border:"1px solid #EEEFEE",
    borderRadius:"7px",
    height:"37px"
}

const transformPaymentsData = (data:any,location_id:number,pendingPlayers:any) => {
  // Extract payments and create products array
  const { payments } = data;


  const products = payments.map((payment:any) => {
    const customer_id = Number(pendingPlayers?.filter((item:any)=>item.id===payment.player_id)[0]?.customer?.id);
    const products = payment.products?.map((item:any)=>{
      const {price,showCoupon,product_name,...newitem} = item;
      return newitem
    });
    return payment.products?.length>0 ? {customer_id,products,location_id}:null
  }).filter(Boolean);

  // Create the transformed payments without the products array
  const transformedPayments = payments.map((payment:any) => ({
    player_id: payment.player_id,
    splitPayments: payment.splitPayments.map(({ method_type, price, wallet_id,method_id,membership_id,discount,coupon_id }:any) => ({
      method_type,
      price,
      discount,
      coupon_id:coupon_id?coupon_id:undefined,
      wallet_id:wallet_id||undefined,
      method_id:method_id||undefined,
      membership_id:membership_id||undefined
    })),
  }));

  // Return the new structure
  return {
    payments: transformedPayments,
    products,
  };
};


type splitPayments={
    method_type: string;
    discount: number;
    price: string;
    coupon_id?: number;
    showCoupon: boolean;
    payment_id?:number;
    wallet_id?:number;
    method_id?:number;
    membership_id?:number;
}

type innerProduct={
  product_id: number,
  wallet_id: number,
  method_id:number,
  discount:number,
  coupon_id:number,
  product_name:string,
  price:number
}

type products={
    customer_id: number,
    location_id: number,
    products:innerProduct[]
}

type Payment = {
  player_id:number,
  splitPayments:splitPayments[],
  products:products[]
  };
  
  type FormData = {
    payments: Payment[];
  };
const PaymentSettings = ({ update,open,fromwh,updatePAB, handleClose,PendingPlayers,RefundingPlayers,serviceID ,selectedLocationID}:{selectedLocationID:number, serviceID:number,fromwh:any,updatePAB:any,RefundingPlayers:any[],PendingPlayers:any[],open:boolean, handleClose:any,update:any}) => {
    const { control, register, handleSubmit, setValue,unregister , getValues,reset,watch } = useForm<FormData>();
    const [showWarning,setShowWarning] =useState<boolean>(false);
    const [isSendNotification,setIsSendNotification] =useState<boolean>(false);
    const {t,i18n} = useTranslation();
    const currentLanguage = i18n.language;
     const StaffPermission = useGetPermissions();
    const {Notification,setNotification,setIsPermissionOn} = useContext(MainContext);
    const [isOpen,setIsopen] = useState(false);
    const {SelectedLocation,event} = useContext(MainContext);
    const [pendingPlayers,setPendingPlayers] =useState<any[]>([]);
    const currentFields = watch('payments');
    const [pendingPayments,setPendingPayments] = useState<any[]>([]);
    const [AllCoupons,setAllCoupons]= useState<any[]>([]);
    const [ProductCoupons,setProductCoupons]= useState<any[]>([]);
    const [isClickable,setIsClickable] = useState(false);
    const [Refunds,setRefunds] = useState<any[]>([]);
    let PendingPAyments:[]

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'payments'
    });



    const transformData = (backendData:any) => {
        const transformedData :any= [];
        const playerMap :any= {};
    
        backendData.forEach((item:any) => {
          const { id: payment_id, player_id, price } = item;
          if (!playerMap[player_id]) {
            playerMap[player_id] = {
              player_id,
              splitPayments: [],
              products:[]
            };
            transformedData.push(playerMap[player_id]);
          }
          playerMap[player_id].splitPayments.push({
            method_type: 'Pending',
            discount: 0,
            price,
            showCoupon: false,
            payment_id,
          });
          // playerMap[player_id].products.push({
          //   discount: 0,
          //   showCoupon: false,
          // });
        });
    
        return transformedData;
      };

      useEffect(() => {
     if(isClickable){
      setTimeout(() => {
        setIsClickable(false)
       }, 2000);
     }
      }, [isClickable]);

      
    const onSubmit = async(data: FormData) => {
      if(!StaffPermission.edit_permissions){
        setIsPermissionOn(true);
        return;
      } 


      setIsClickable(true);
      const result  = transformPaymentsData(data,selectedLocationID,pendingPlayers)
      const hasMultipleProducts = result.products?.some((item:any) => item?.products?.length > 0);

      if(Refunds?.length>0 && !showWarning){
        setShowWarning(true)
        return;
      }

     try {
        const response = await MakePaymentOfPendingPalyer(serviceID,isSendNotification,{payments:result.payments,products:hasMultipleProducts?result.products:undefined,refunds:Refunds?.length>0?Refunds:undefined});
        handleClose();
        setShowWarning(false);
        setIsSendNotification(false);
        setRefunds([]);
        update();
        if(fromwh=="payments" || fromwh==="bookings"){
          updatePAB();
        }
        setPendingPlayers([]);
        setNotification({isShow:true,message:response,subMessage:'',type:'success'});
     } catch (error:any) {
      setNotification({isShow:true,message:error,subMessage:'',type:'error'});
     }
    };   
    
      useEffect(() => {
        if(serviceID){
            getPendingPay();
        }
        getAllCoupons();
      }, [serviceID,open]);

      useEffect(() => {
        const filtered = pendingPayments?.filter((item:any)=>{
            return PendingPlayers?.some((player:any)=>player?.id === item?.player_id)
        })
       const transfromed = transformData(filtered||[]);
       reset({payments:transfromed});
       const AllplayerID = pendingPayments?.map((item:any)=>item?.player_id);
       const updatedata = PendingPlayers?.filter((pen:any)=>{
        return AllplayerID?.some((item:any)=>item===pen?.id);
       })

       setPendingPlayers(updatedata)
      
      }, [pendingPayments,PendingPlayers]);


      const getPendingPay = async()=>{
          try {
            const response = await getPendingPaymentsById(serviceID);
            setPendingPayments(response);
          } catch (error) {
            setPendingPayments([]);
          }

      }

      const getAllCoupons = async()=>{
       try {
        const response = await getAllCoupon();
        const filtered = response?.filter((item:any)=>
        {
            return item?.locations?.some((loc:any)=>loc?.id===selectedLocationID)
        })?.filter((item:any)=>{
          if(event?.service?.service_type==="Booking"){
           return item?.allowBookings 
        }else if(event?.service?.service_type==="Lesson"){
          return item?.allowLessons
        }else if(event?.service?.service_type==="Event"){
          return item?.allowEvents
        }
      })

        const filteredCoupon = response?.filter((item:any)=>item?.allowProducts)
       
        setProductCoupons(filteredCoupon);
        setAllCoupons(filtered);
       } catch (error) {
        setAllCoupons([]);
       }
      }


const isShowCoupon =  currentFields?.some((item:any)=>{
  return item?.splitPayments?.some((spli:any)=>spli?.showCoupon)
});

const isShowCouponProduct =  currentFields?.some((item:any)=>{
  return item?.products?.some((spli:any)=>spli?.showCoupon)
});

const allcurent = currentFields?.flatMap((item:any)=> item?.splitPayments)||[]
const allproduct = currentFields?.flatMap((item:any)=> item?.products)||[]
const PendingPRice = allcurent?.reduce((acc:any,item:any)=> acc+Number(item?.price||0),0) + allproduct?.reduce((acc:any,item:any)=> acc+Number(item?.price||0),0);
const PendingDiscount = allcurent?.reduce((acc:any,item:any)=> acc+Number(item?.discount),0) + allproduct?.reduce((acc:any,item:any)=> acc+Number(item?.discount||0),0);
const TotalPending = PendingPRice-PendingDiscount||0;

const RefundPrice = Refunds?.reduce((acc:any,item:any)=> acc+Number(item?.refund_price),0);
const TotalPaidAmount = RefundingPlayers?.reduce((acc:any,item:any)=>acc+item.paid_price,0)

  
    return (
        <Dialog
          sx={{
            ".MuiDialog-paper": {
            minWidth: {xs:"auto", sm:isShowCouponProduct?'1050px':isShowCoupon?"840px":RefundingPlayers?.length>0 && PendingPlayers?.length===0?"660px":'800px'},
            },
          }}
          onClose={handleClose}
          open={open}
          >
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{">p:not(:first-child)":{fontFamily:"var(--font-regular) !important", color:"#22356DB2"}}}>
                  <Typography sx={{fontFamily:"var(--font-semibold) !important", color:"#22356D"}}>{t("locationmanager.Payment Settings")}</Typography>
                  <Typography>{moment(event?.date).locale(currentLanguage).format('dddd, DD MMMM, yyyy')} | {moment(event?.start_time).format('HH:mm')} - {moment(event?.end_time).format('HH:mm')}</Typography>
                  <Typography>{SelectedLocation?.location_name} | {event?.courts?.map((item:any)=><span>{item?.court_name} </span>)}</Typography>
                </Box>
                {PendingPlayers?.length>0 &&
                <Box sx={{marginBlock:"1rem"}}>
                  <Typography sx={{fontFamily:"var(--font-semibold)"}}>{t('title.Payments')}</Typography>
                  <Grid container rowGap={5} width={'100%'}>
                      
                  <Grid xs={12} md={9}>
                  {
                    currentFields?.map((item:any,index:any)=>
                      <Box key={index} sx={{...common,width:"100%",flexDirection:{xs:"column",md:'column'},alignItems:"flex-start !important", marginTop:'10px'}}>
                  <Box sx={{width:"100%"}}>
                    {
                      pendingPlayers?.filter((pl:any)=>pl?.id===item?.player_id)?.map((pla:any)=>
                      <Box key={index} >
                        <Box sx={{...common,width:"100%", flexDirection:{xs:"column", md:"row"}, alignItems:{xs:"baseline", md:"center"},">img":{display:{xs:"none !important", md:"block !important"}}}}>
                          <img src={pla?.customer?.profile_url ? pla?.customer?.profile_url:"/images/Reserved.svg"} alt="player" style={{width:"40px", height:"40px", borderRadius:"50%"}} />
                        <Box sx={{display:'flex',width:"100%",flexDirection:"column", justifyContent:{xs:"space-between", md:"unset"}}}>
                          <Typography sx={{fontFamily:"var(--font-regular)"}}>{pla?.customer?.first_name} {pla?.customer?.last_name}</Typography>
                          <Box sx={{...common,gap:"5px", marginBottom:'5px',flexDirection:{xs:"row", md:"row"}, flexWrap:'wrap',alignItems:{xs:"baseline"}}}>
                            {
                              pla?.customer?.customerMemberships?.map((cust:any)=>{
                               return cust?.membership?.membership_name && <span key={cust?.id} style={{background:"#889AB626", border:"1px solid #889AB638", borderRadius:"4px", fontSize:"0.8rem",padding:'2px 6px', color:"#22356D"}}>{ cust?.membership?.membership_name}</span>})
                            }
                          </Box>
                      </Box>
                    </Box>
                  </Box>)
                  }
                  </Box> 
                  <Box sx={{display:'flex',flexDirection:'column',marginTop:'5px', width:{xs:"100%", md:"unset"}}}>
                    {
                    item?.splitPayments?.map((splidata:any,splitIndex:number)=>
                        <PendingPlayersList selectedLocation={SelectedLocation} unregister={unregister} key={splitIndex} setValue={setValue} AllCoupons={AllCoupons} player_id={item?.player_id} selectedLocationID={selectedLocationID} PendingPlayers={pendingPlayers} serviceID={serviceID} currentFields={currentFields} index={index} register={register} reset={reset} splidata={splidata} splitIndex={splitIndex}  />
                      )
                    }
                  </Box>
                  <Box sx={{display:'flex',flexDirection:'column',marginTop:'5px', width:{xs:"100%", md:"unset"},gap:'5px'}}>
                  {
                      item?.products?.map((productData:any,splitIndex:number)=>
                        <ProductPending SelectedLocation={SelectedLocation} setIsopen={setIsopen} unregister={unregister} key={splitIndex} setValue={setValue} AllCoupons={ProductCoupons} player_id={item?.player_id} selectedLocationID={selectedLocationID} PendingPlayers={pendingPlayers} serviceID={serviceID} currentFields={currentFields} index={index} register={register} reset={reset} splidata={productData} splitIndex={splitIndex}  />
                      )
                    }
                  </Box>
                </Box>                      
                    )
                  }
                  <Box
                  sx={{
                    display: "flex",
                    alignItems: {xs:"baseline", sm:"flex-end"},
                    flexDirection: "column",
                    width:'100%',
                    marginLeft:isShowCouponProduct?{xs:"0", md:'250px'}:{xs:"0", md:'180px'}
                  }}
                >
                  <Box sx={{display:"flex", flexDirection:'row', alignItems:"center", gap:"8px", justifyContent:"space-between", width:{xs:"100%", sm:'auto'}}}>
                    <p
                      style={{
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-medium)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        marginRight: "10px",
                      }}
                    >
                      {t('common.Price')}
                    </p>

                    <Cleave
                      readOnly
                      value={PendingPRice||0}
                      style={{
                        width: "100px",
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-regular)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        padding: "5px 8px",
                        textAlign:"right"
                      }}
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: "thousand",
                        delimiter:SelectedLocation?.currency==="IDR"?'\u2024':','
                      }}
                    />
                  </Box>
                  <Box sx={{display:"flex", flexDirection:'row', alignItems:"center", gap:"8px", width:{xs:"100%", sm:'auto'},justifyContent:"space-between"}}>
                    <p
                      style={{
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-medium)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        marginRight: "10px",
                      }}
                    >
                      {t('common.Discount')}
                    </p>

                    <Cleave
                      readOnly
                      value={PendingDiscount||0}
                      style={{
                        width: "100px",
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-regular)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        padding: "5px 8px",
                        textAlign:"right"
                      }}
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: "thousand",
                        delimiter:SelectedLocation?.currency==="IDR"?'\u2024':','
                      }}
                    />
                  </Box>
                  <Box sx={{display:"flex", flexDirection:'row', alignItems:"center", gap:"8px", width:{xs:"100%", sm:'auto'},justifyContent:"space-between"}}>
                    <p
                      style={{
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-medium)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        marginRight: "10px",
                      }}
                    >
                     {t('common.Total')}
                    </p>
                    <Cleave
                      value= {TotalPending}
                      style={{
                        width: "100px",
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-regular)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        padding: "5px 8px",
                        textAlign:'right'
                      }}
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: "thousand",
                        delimiter:SelectedLocation?.currency==="IDR"?'\u2024':','
                      }}
                    />
                  </Box>
                </Box>
                  </Grid>
                  </Grid>
                </Box>}
                {RefundingPlayers?.length>0 && <Box  sx={{marginBlock:"1rem"}}>
                    <Typography sx={{fontFamily:"var(--font-semibold)"}}>Refunds</Typography>
                    {
                      RefundingPlayers?.map((item:any,index:number)=><PaymentsRow key={index} type="Refunds" setPayments={setRefunds} item={item} selectedLocationID={selectedLocationID}/>)
                    }
                </Box>}
                {RefundingPlayers?.length>0 &&  <Box
                  sx={{
                    display: "flex",
                    alignItems: {xs:"baseline", sm:"flex-end"},
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{display:"flex", flexDirection:'row', alignItems:"center", gap:"8px", justifyContent:"space-between", width:{xs:"100%", sm:'auto'}}}>
                    <p
                      style={{
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-medium)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        marginRight: "10px",
                      }}
                    >
                {t('common.Amount')} {t('common.Paid')}
                    </p>
                    <Cleave
                      value= {TotalPaidAmount || 0}
                      style={{
                        width: "100px",
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-regular)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        padding: "5px 8px",
                        textAlign:'right'
                      }}
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: "thousand",
                       delimiter:SelectedLocation?.currency==="IDR"?'\u2024':','
                      }}
                    />
                  </Box>
                  <Box sx={{display:"flex", flexDirection:'row', alignItems:"center", gap:"8px", width:{xs:"100%", sm:'auto'},justifyContent:"space-between",}}>
                    <p
                      style={{
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-medium)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        marginRight: "10px",
                      }}
                    >
                     {t('common.Total')} {t('common.Refund')}
                    </p>
                    <Cleave
                      value= {RefundPrice || 0}
                      style={{
                        width: "100px",
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-regular)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        padding: "5px 8px",
                        textAlign:'right'
                      }}
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: "thousand",
                       delimiter:SelectedLocation?.currency==="IDR"?'\u2024':','
                      }}
                    />
                  </Box>
                </Box>}
                <Grid marginTop={isOpen?'100px':'40px'} container justifyContent={"space-between"} alignItems={"center"}>
                    <Button
                      onClick={handleClose}
                      style={
                      {
                              textTransform: "unset",
                              fontFamily: "var(--font-semibold)",
                              width: "104px",
                              height: "37px",
                              color: "#22356D",
                              backgroundColor: "white",
                              fontSize: "16px",
                              padding: "10px 30px",
                              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
                            }
                      }
                    >
                      {t('common.Cancel')}
                    </Button>
                    <Button
                      type='submit'
                      style={isClickable?{
                        textTransform: "unset",
                        fontFamily: "var(--font-semibold)",
                        color: "white",
                        backgroundColor: "#22356D",
                        lineHeight:'14px',
                        width: "92px",
                        height: "37px",
                        fontSize: "16px",
                        pointerEvents:'none'
                      }:{
                        textTransform: "unset",
                        fontFamily: "var(--font-semibold)",
                        color: "white",
                        backgroundColor: "#22356D",
                        lineHeight:'14px',
                        width: "92px",
                        height: "37px",
                        fontSize: "16px",
                        pointerEvents:'auto'
                      }}
                    >
                    {t('common.Save')}
                    </Button>
                </Grid>
                </form>

                <Dialog open={showWarning} onClose={()=>setShowWarning(false)}>
                  <DialogContent>
                  <Box>
              <Typography
                sx={{
                  fontFamily: "var(--font-medium)",
                  color: "#22356D",
                  marginRight: "5rem",
                }}
              >
              {t('common.Are you sure you want to refund in this payment method')}
              </Typography>
             <Box sx={{display:'flex',flexDirection:'row',gap:'10px'}}>
              <label style={{margin:'0px',top:'-8px'}} className="container">
                <input
                  checked={isSendNotification}
                  onChange={()=>setIsSendNotification(!isSendNotification)}
                  type="checkbox"
                />
                <span className="mark"></span>
              </label>
                <Typography sx={{  fontFamily: "var(--font-regular)",
                  color: "#22356D",}}>{t('common.Send emails notification to customers')}.</Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                marginTop={2}
              >
                <button
                  style={{ ...button, color: activeColor }}
                  onClick={() => setShowWarning(false)}
                >
                  {t('customers.No_undo')}
                </button>
                <button
                  style={{ ...button, color: "white", background: activeColor }}
                  onClick={()=>onSubmit({payments:currentFields})}
                >
                  {t('customers.Yes, Cancel')}
                </button>
              </Box>
            </Box>
                  </DialogContent>
                </Dialog>
            </DialogContent>
        </Dialog>
    )
}

export default PaymentSettings;