import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import MobileDrawer from "../features/sidebar/MobileSidebar";
import Sidebar from "../features/sidebar/Sidebar";
import NotificationToast from "src/Notification/NotificationToast";
import MainContext from "src/context/MainContext";
import axios from "axios";


const MainPage: React.FC<any> = () => {
  const {Notification,setNotification} = useContext(MainContext);


  // useEffect(() => {
  //   async function GETSTATUS(){
  //     const apiKey = 'vYYoIFwzr2rXqdyaDoHhdozWVhK4l_iKSbv5UB2N'; // Replace with your actual API key
  //     const monitorId = '26wD3LaO';
  //     try {
  //     const response = await axios.get("https://9258-49-43-0-124.ngrok-free.app/",{
  //       // "headers": {
  //       //   "Accept": "application/json",
  //       //   "Authorization": `Bearer ${apiKey}`
  //       // }
  //     })
  //     const DAta = response?.data?.result
  //     console.log(response,'responsnennennee');    
  //       // Check if the API is down
  //       if (DAta.status === 'OFFLINE') {
  //        setNotification({isShow:true,type:'error',message:'Server Is down',subMessage:''})
  //       }
  //     } catch (error) {
  //       console.error('Error fetching API status:', error);
  //     }
  //   }

  //  setInterval(GETSTATUS, 30000);
    
  // }, [])
  

  useEffect(() => {
    // Apply styles to body
    document.body.style.background =
      "#889AB626"; 

    // Clean up the styles when the component unmounts
    return () => {
      document.body.style.background = "";
    };
  }, []);

  useEffect(() => {
    const handleOffline = () => {
      setNotification({isShow:true,message:"Network connection issue. Please check your internet connection or try again in a few minutes",subMessage:'',type:'error'})
    };

    window.addEventListener('offline', handleOffline);

    // Check initial connection status
    if (!navigator.onLine) {
      handleOffline();
    }

    return () => {
      window.removeEventListener('offline', handleOffline);
    };
  }, []);



  return (
    <Box
    sx={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}>
      <Box sx={{display:{xs:'none', md:"block"}}}>
        <Sidebar />
      </Box>
      <Box sx={{display:{xs:'block', md:"none"}}}>
        <MobileDrawer/>
      </Box>
      <Box component='main' sx={{ flexGrow: 1, p: 1, width:"93%" }}>
        <Outlet />
      
      </Box>
     <NotificationToast/>
    </Box>
  );
};

export default MainPage;
