import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { activeColor } from "src/constants/color";
import { addThousandSeparator } from "src/constants/SportSEparator";
import SearchTeammates, { SearchAddedTeammates } from "./SearchTeammates";
import StatusWarning from "./StatusWarning";
import theme from "src/theme";
import { IoEyeOutline } from "react-icons/io5";
import PaymentPopup from "src/features/Payments/PaymentPopup";
import WaitingList from "./WaitingList";
import { FaCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { getClubId } from "src/api/CalendarBooking";
import { apiCaller } from "src/api/ApiCaller";
import MainContext from "src/context/MainContext";
import { GrAdd } from "react-icons/gr";

const common = {display:"flex", alignItems:{xs:'none',sm:'center'}, gap:{xs:'3px',sm:'10px'},flexDirection:{xs:'column',sm:'row'},
    "& p":{
        fontFamily:"var(--font-regular) !important",
        color:"#22356D"
    },
    "& img":{
       display:{xs:'none',sm:'block'}
    }
};

const style = {
  fontFamily: "var(--font-semibold)",
  background: "white",
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
  padding: "7px 20px",
  borderRadius: "7px",
};


const TeamMatesPer = ({
  sendNotification,
  SetSendNotification,
  waitPlayers,
  item,
  events,
  waitingUpdate,
  add,
  update,
  AllPlayers,
  serviceLists,
  customerList,
  updateCustList,
  setSelectedCheckBox,
  selectEvent,
  index,
  selectedCheckbox,
  ALLCUSTOMER,
  setSelectedCustomerId,
  handleEditInfo,
  setTeammatesData,
  SelectedLocation,
  teamMates,
}: {
  item: any;
  waitingUpdate:any;
  waitPlayers:any;
  index: number;
  setTeammatesData: any;
  selectedCheckbox: any;
  handleEditInfo: any;
  selectEvent: any;
  events: any;
  customerList: any;
  updateCustList: any;
  setSelectedCustomerId: any;
  update: any;
  SelectedLocation: any;
  add: any;
  ALLCUSTOMER: any[];
  setSelectedCheckBox: any;
  AllPlayers: any;
  sendNotification: any;
  SetSendNotification: any;
  teamMates:any;
  serviceLists: any;
}) => {
  const {t} = useTranslation();
  const {setNotification}= useContext(MainContext);
  const [selectedPoisition,setSelectedPoisition] =useState<number>();
  const [showOption, setShowOption] = useState<boolean>(false);
  const [isOpen,setIsOpen] = useState<boolean>(false);
  const [selectedCust,setSelectedCust] =useState<number>();
  const [showTeamWaiting,setShowTeamWaiting] = useState<boolean>(false);
  const [isPayOpen, setISPayOpen] = useState<boolean>(false);
  const [showOption2, setShowOption2] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [PlayerId, setPlayerId] = useState<number>(0);
  const [SelectedPlayer, setSelectedPlayer] = useState<any>();
  const [clickedPlayer, setClickedPlayer] = useState<any>();
  const [showWarning, setshowWarning] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchlist, setSearchList] = useState<any[]>([]);
  const [addedPlayers, setAddedPlayers] = useState<any[]>([]);
  const [selectedPosition,setSelectedPosition] = useState<any[]>([]);

  const SPORT_NAME = Object.keys(events)?.length>0 ? events?.courts[0].sport?.sport_name:''
  const GEtLevelOfPlayer = (player:any)=>{
       const Level = player?.customer?.sportsLevel;
       const FilteredLevel = Level?.filter((item:any)=>item?.sport_name===SPORT_NAME)[0]?.level ||'0.0';
       return FilteredLevel;
  }

  const handleShowAndCancel = (event: any) => {
    if (name === "show") {
      handleEditInfo(event, clickedPlayer, "show");
      handleCloseModel();
      setTimeout(() => {
        update();
      }, 1000);
    } else if (name === "cancel") {
      handleEditInfo(event, clickedPlayer, "cancel");
      handleCloseModel();
      setTimeout(() => {
        update();
      }, 1000);
    }
  };

  const handleMuseHover = (e: any, number: number) => {
    e.preventDefault();
    if (number === 1) setShowOption(true);
    if (number === 2) setShowOption2(true);
  };

  const handleMouseExit = (e: any, number: number) => {
    e.preventDefault();
    if (number === 1) setShowOption(false);
    if (number === 2) setShowOption2(false);
  };

  const handleCloseModel = () => {
    setshowWarning(false);
    SetSendNotification(false);
  };

  const handleshowWarning = (event: any, name: any, item: any) => {
    setName(name);
    setClickedPlayer(item);
    setshowWarning(true);
  };



  const movePlayers = async (id:any,position:any) =>{
    try {
        let club_id = getClubId();
      let res: any = await apiCaller.post(`${club_id}/players/move-player/${events?.service_id}/${id}`,{position});   
      setIsOpen(false);
      setShowTeamWaiting(false);
      serviceLists();
      update();
      waitingUpdate();
      setNotification({isShow:true,message:res.data?.message,subMessage:'',type:'success'})
    } catch (error:any) {
      const err = error.response?.data?.message;
      setNotification({isShow:true,message:err,subMessage:'',type:'error'})

      console.log(err);
    }
  }


  useEffect(() => {
    const updatedData = ALLCUSTOMER?.map((item: any) => {
      const isAvail: boolean = AllPlayers?.filter((item:any)=>!item?.is_show && !item?.is_canceled)?.some(
        (exist: any) => exist?.customer?.id === item.id
      );
      const PlayerID: number = AllPlayers?.filter((item:any)=>!item?.is_show && !item?.is_canceled)?.filter(
        (exist: any) => exist?.customer?.id === item.id
      )[0]?.id;

      return { ...item, Exist: isAvail, playerId: PlayerID ? PlayerID : null };
    });
    const updatedPlayers = updatedData?.filter((item: any) => item?.Exist);

    setSearchList(updatedData);
    setAddedPlayers(updatedPlayers);
  }, [ALLCUSTOMER, AllPlayers]);

  const handleChildClick = (child: any) => {
    setSelectedCheckBox((prev: any) => {
      if (prev.some((item: any) => item?.id === child?.id)) {
        return prev?.filter((item: any) => item?.id !== child?.id);
      } else {
        const player = AllPlayers?.find((item: any) => item?.id === child?.id);
        return [...prev, player];
      }
    });
  };

  const handlepopup = async (data: any) => {
    setSelectedPlayer(data);
    setISPayOpen(true);
    setPlayerId(data?.id);
  };

  
const handleSlot =(idx:number,position:number)=>{
  const slot = ((idx+1)*2+(position===1?-1:0))
  setSelectedPoisition(slot);
}

  const hanldePositionsclick = (data:any)=>{
    setSelectedPosition(data);
    setShowTeamWaiting(true);
  }

  const getPositionPlayers = ()=>{
    return waitPlayers?.filter((item:any)=>item.status==="pending" || item.status==="approved")?.filter((item:any)=>((item?.position===((index+1)*2)-1))|| (item?.position===((index+1)*2)))||[]
  }

  return (
    <Box>
      <Box
        sx={{
          borderRadius: "7px",
          width: "26px",
          height: "26px",
          textAlign: "center",
          padding: "0px 10px",
          boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {index + 1}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          paddingInline: "10px",
          gap:{xs:'5px',sm:''}
        }}
      >
        {/* first teammate */}
        {!!item.customer && (
          <Box
            sx={{ height: { sm: "auto", md: "40px" } }}
            position={"relative"}
            onMouseOver={(e) => handleMuseHover(e, 1)}
            onMouseOut={(e) => handleMouseExit(e, 1)}
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            color={activeColor}
            marginBottom={"5px"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={1}
              sx={{ ">img": { display: { xs: "none", sm: "block" } } }}
            >
              <img
                onClick={() => {
                  const passdata = {
                    ...item?.customer,
                    id: item?.customer?.id,
                  };
                  setSelectedCustomerId(passdata);
                  setTeammatesData(true);
                }}
                src={
                  item?.customer?.profile_url
                    ? item?.customer?.profile_url
                    : "/images/Reserved.svg"
                }
                alt="user"
                style={{ width: "35px", height: "35px", borderRadius: "50%" }}
              />
              <Box
                sx={{ fontFamily: "var(--font-regular)" }}
                lineHeight={"20px"}
              >
                <Box
                  fontSize={"16px"}
                  sx={{
                    ">br": {
                      display: { xs: "block", sm: "none" },
                      position: "relative",
                    },
                  }}
                >
                  <Typography sx={{...common}}>
                    <SearchAddedTeammates
                      update={update}
                      data={item}
                      customerList={addedPlayers}
                    /> 
                
                    <Typography sx={{display:{xs:'none',sm:'flex'},fontFamily:"var(--font-regular)", fontSize:'14px',opacity:'0.7',alignItems:'center'}}> <FaCircle style={{width:'3px',height:'3px',margin:'0px 3px'}} /> {' '}Level {GEtLevelOfPlayer(item)}</Typography>
                  </Typography>
                  {/* {item?.customer?.first_name} {item?.customer?.last_name}  {item.reserved && <span 
              style={{
                background:"#22356DB2", 
                color:'white', 
                padding:"2px 8px", 
                borderRadius:'4px',
                fontSize:isMobile ? "0.8rem" :"1rem"
                }}>Reserved Slot</span>} &nbsp;  */}
                  {showOption && (
                    <Box
                      display={"flex"}
                      gap={1.5}
                      marginRight={1}
                      position={"absolute"}
                      sx={{
                        left: { xs: "0%", sm: item.is_show ? "10%" : "10%" },
                        bottom: { xs: "-1%", sm: "unset" },
                      }}
                    >
                      <Paper
                        sx={{
                          padding: "0px 6px",
                          color: "#22356DB2",
                          cursor: "pointer",
                          zIndex: "99",
                        }}
                        onClick={(event: any) =>
                          handleshowWarning(event, "show", item)
                        }
                      >
                        {item.is_show ? "Undo" : "No Show"}
                      </Paper>
                      <Paper
                        sx={{
                          padding: "0px 6px",
                          color: "#FF1313",
                          cursor: "pointer",
                          zIndex: "99",
                        }}
                        onClick={(event: any) =>
                          handleshowWarning(event, "cancel", item)
                        }
                      >
                        {item.is_canceled ? "Undo" : "Cancel"}
                      </Paper>
                    </Box>
                  )}
                  {/* <br /> */}
                  {item.is_show && (
                    <span
                      style={{
                        background: "#FFFDC5",
                        color: "#838383D9",
                        padding: "2px 8px",
                        borderRadius: "4px",
                        fontSize: isMobile ? "0.8rem" : "1rem",
                      }}
                    >
                      No Show
                    </span>
                  )}
                  {item.is_canceled && (
                    <span
                      style={{
                        background: "#FFE8E8",
                        color: "#FF1313D9",
                        padding: "2px 8px",
                        borderRadius: "4px",
                        fontSize: isMobile ? "0.8rem" : "1rem",
                      }}
                    >
                      Cancelled
                    </span>
                  )}
                </Box>
                <Box
                  sx={{
                    display: { xs: "block", sm: "none" },
                    width: "61px",
                    height: "25px",
                    borderRadius: "5px",
                    border: "1px solid #DDDDDD",
                    padding: "1px 8px",
                    background: "white",
                    mt:'5px'
                  }}
                  onClick={(event: any) => handleMuseHover(event, 1)}
                >
                  Action
                </Box>
              </Box>
            </Box>

            <Box display={"flex"} alignItems={"center"} gap={1}>
              {item?.status === "Paid" && (
                <IoEyeOutline
                  cursor={"pointer"}
                  onClick={() => handlepopup(item)}
                  fontSize={12}
                />
              )}
              <p
                style={{
                  color:
                    item?.status !== "Pending payment" ? "#5B8777" : "#FF1313",
                }}
              >
                <span style={{ color: "#22356DB2" }}>
                  {item?.refunded_status ? `Refunded | ` : ""}
                  {item?.status === "Paid" ? `${item?.payment_method} | ` : ""}
                </span>
                {SelectedLocation?.currency}{" "}
                {item?.pending_price === 0 && !item?.refunded_status
                  ? addThousandSeparator(
                      item?.paid_price,
                      SelectedLocation.currency
                    )
                  : item?.pending_price > 0
                  ? addThousandSeparator(
                      item?.pending_price,
                      SelectedLocation.currency
                    )
                  : item?.refunded_status
                  ? `- ${addThousandSeparator(
                      item?.refund_price,
                      SelectedLocation.currency
                    )}`
                  : ""}
              </p>
              <label className="container">
                <input
                  onClick={() =>
                    !item.refunded_status && handleChildClick(item)
                  }
                  checked={selectedCheckbox?.some(
                    (plar: any) => plar?.id === item?.id
                  )}
                  type="checkbox"
                />
                <span className="mark"></span>
              </label>
            </Box>
          </Box>
        )}
        {/* first player to add */}
        {item.customer === null && (
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            color={activeColor}
            marginBottom={"5px"}
          >
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <SearchTeammates
                update={update}
                index={index}
                updateList={updateCustList}
                customerList={searchlist}
                text={"first"}
                customerId={item.id}
                addingTeam={add}
              />
            </Box>

            <Box display={"flex"} alignItems={"center"} gap={1}>
              {/* <p style={{color:"red"}}>{SelectedLocation?.currency} {addThousandSeparator(Object.keys(selectEvent||{}).length>0 ?selectEvent?.price:events?.service?.price,SelectedLocation?.currency)}</p> */}
              <label className="container">
                <input disabled={true} type="checkbox" />
                <span className="mark"></span>
              </label>
            </Box>
          </Box>
        )}
        {/* second teammate if added */}
        {item.pairedData?.length !== 0 && (
          <Box
            position={"relative"}
            onMouseOver={(e) => handleMuseHover(e, 2)}
            onMouseOut={(e) => handleMouseExit(e, 2)}
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            color={activeColor}
            marginBottom={"5px"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={1}
              sx={{
                ">img": { display: { xs: "none", sm: "block" } },
                height: { sm: "auto", md: "40px" },
              }}
            >
              <img
                onClick={() => {
                  const passdata = {
                    ...item.pairedData[0]?.customer,
                    id: item.pairedData[0]?.customer?.id,
                  };
                  setSelectedCustomerId(passdata);
                  setTeammatesData(true);
                }}
                src={
                  item.pairedData[0].customer?.profile_url
                    ? item.pairedData[0].customer?.profile_url
                    : "/images/Reserved.svg"
                }
                alt="user"
                style={{ width: "35px", height: "35px", borderRadius: "50%" }}
              />
              <Box
                sx={{ fontFamily: "var(--font-regular)" }}
                lineHeight={"20px"}
              >
                <Box
                  fontSize={"16px"}
                  sx={{
                    ">br": { display: { xs: "block", sm: "none" } },
                    position: "relative",
                  }}
                >
                  <Typography sx={{...common}}>
                    <SearchAddedTeammates
                      update={update}
                      data={item?.pairedData[0]}
                      customerList={addedPlayers}
                    />
                    
                    <Typography sx={{fontFamily:"var(--font-regular)",display:{xs:'none',sm:'flex'}, fontSize:'14px',opacity:'0.7',marginRight:'20px',flexDirection:'row',alignItems:'center'}}> <FaCircle style={{width:'3px',height:'3px',margin:'0px 3px'}} /> {' '} Level {GEtLevelOfPlayer(item?.pairedData[0])}</Typography>
                    {item.customer === null && events?.service?.event_type==="Double" && <Typography onClick={()=>hanldePositionsclick(getPositionPlayers())} sx={{fontFamily:'var(--font-regular)',fontsize:'13px',padding:'4px 8px',width:'max-content', background:index%2===0?'white':'#f3f5f8',border:'1px solid #f3f5f8',borderRadius:'7px'}}>Applicants [{getPositionPlayers()?.length}]</Typography>}
                  </Typography>
                  {/* {item?.pairedData[0]?.customer?.first_name} {item?.pairedData[0]?.customer?.last_name} {item.pairedData[0].reserved && <span 
              style={{
                background:"#22356DB2", 
                color:'white', 
                padding:"2px 8px", 
                borderRadius:'4px',
                fontSize:isMobile ? "0.8rem" :"1rem"
                }}>Reserved Slot</span>} &nbsp;  */}
                  {showOption2 && (
                    <Box
                      display={"flex"}
                      gap={1.5}
                      marginRight={1}
                      position={"absolute"}
                      sx={{
                        left: {
                          xs: "0%",
                          sm: item.pairedData[0].cancel ? "10%" : "10%",
                        },
                        bottom: { xs: "-1%", sm: "unset" },
                        width: "180px",
                      }}
                    >
                      <Paper
                        sx={{
                          padding: "0px 6px",
                          color: "#22356DB2",
                          cursor: "pointer",
                        }}
                        onClick={(event: any) =>
                          handleshowWarning(event, "show", item?.pairedData[0])
                        }
                      >
                        {item.pairedData[0].is_show ? "Undo" : "No Show"}
                      </Paper>
                      <Paper
                        sx={{
                          padding: "0px 6px",
                          color: "#FF1313",
                          cursor: "pointer",
                        }}
                        onClick={(event: any) =>
                          handleshowWarning(
                            event,
                            "cancel",
                            item?.pairedData[0]
                          )
                        }
                      >
                        {item.pairedData[0].is_canceled ? "Undo" : "Cancel"}
                      </Paper>
                    </Box>
                  )}
                  {/* <br /> */}
                  {item?.pairedData[0]?.is_show && (
                    <span
                      style={{
                        background: "#FFFDC5",
                        color: "#838383D9",
                        padding: "2px 8px",
                        borderRadius: "4px",
                        fontSize: isMobile ? "0.8rem" : "1rem",
                      }}
                    >
                      No Show
                    </span>
                  )}
                  {item?.pairedData[0]?.is_canceled && (
                    <span
                      style={{
                        background: "#FFE8E8",
                        color: "#FF1313D9",
                        padding: "2px 8px",
                        borderRadius: "4px",
                        fontSize: isMobile ? "0.8rem" : "1rem",
                      }}
                    >
                      Cancelled
                    </span>
                  )}
                </Box>
                <Box
                  sx={{
                    display: { xs: "block", sm: "none" },
                    width: "61px",
                    height: "25px",
                    borderRadius: "5px",
                    border: "1px solid #DDDDDD",
                    padding: "1px 8px",
                    background: "white",
                    mt:'5px'
                  }}
                  onClick={(event: any) => handleMuseHover(event, 2)}
                >
                  Action
                </Box>
              </Box>
            </Box>

            <Box display={"flex"} alignItems={"center"} gap={1}>
              {item?.pairedData[0]?.status === "Paid" && (
                <IoEyeOutline
                  cursor={"pointer"}
                  onClick={() => handlepopup(item?.pairedData[0])}
                  fontSize={12}
                />
              )}
              <p
                style={{
                  color:
                    item.pairedData[0]?.status !== "Pending payment"
                      ? "#5B8777"
                      : "#FF1313",
                }}
              >
                {" "}
                <span style={{ color: "#22356DB2" }}>
                  {item.pairedData[0]?.refunded_status ? `Refunded | ` : ""}
                  {item?.pairedData[0]?.status === "Paid"
                    ? `${item?.pairedData[0]?.payment_method} | `
                    : ""}
                </span>
                {SelectedLocation?.currency}{" "}
                {item?.pairedData[0]?.pending_price === 0 &&
                !item?.pairedData[0]?.refunded_status
                  ? addThousandSeparator(
                      item?.pairedData[0]?.paid_price,
                      SelectedLocation.currency
                    )
                  : item?.pairedData[0]?.pending_price > 0
                  ? addThousandSeparator(
                      item?.pairedData[0]?.pending_price,
                      SelectedLocation.currency
                    )
                  : item?.pairedData[0]?.refunded_status
                  ? `- ${addThousandSeparator(
                      item?.pairedData[0]?.refund_price,
                      SelectedLocation.currency
                    )}`
                  : ""}
                {/* {
          item?.pairedData[0]?.pending_price===0 && !item?.pairedData[0]?.refunded_status ? (item?.pairedData[0]?.paid_price): item?.pairedData[0]?.pending_price>0 ? (item?.pairedData[0]?.pending_price) : item?.pairedData[0]?.refunded_status ? (`- ${item?.pairedData[0]?.refund_price}`):''
        } */}
              </p>
              <label className="container">
                <input
                  onClick={() =>
                    !item.pairedData[0].refunded_status &&
                    handleChildClick(item?.pairedData[0])
                  }
                  checked={selectedCheckbox?.some(
                    (plar: any) => plar?.id === item?.pairedData[0]?.id
                  )}
                  type="checkbox"
                />
                <span className="mark"></span>
              </label>
            </Box>
          </Box>
        )}

        {/* second teammate to add */}
        {item.pairedData.length == 0 && (
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            color={activeColor}
            marginBottom={"5px"}
          >
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <SearchTeammates
                update={update}
                index={index}
                updateList={update}
                customerList={searchlist}
                text={"second"}
                customerId={item.id}
                addingTeam={add}
              />
             {!!item.customer && events?.service?.event_type==="Double" && <Typography onClick={()=>hanldePositionsclick(getPositionPlayers())} sx={{fontFamily:'var(--font-regular)',fontsize:'13px',padding:'4px 8px', background:index%2===0?'white':'#f3f5f8',border:'1px solid #f3f5f8',borderRadius:'7px',width:'max-content',}}>Applicants [{getPositionPlayers()?.length}]</Typography>}
            </Box>

            <Box display={"flex"} alignItems={"center"} gap={1}>
              {/* <p style={{color:"red"}}>{SelectedLocation?.currency} {addThousandSeparator(Object.keys(selectEvent||{}).length>0 ?selectEvent?.price:events?.service?.price,SelectedLocation?.currency)}</p> */}
              <label className="container">
                <input disabled={true} type="checkbox" />
                <span className="mark"></span>
              </label>
            </Box>
          </Box>
        )}
      </Box>

      <PaymentPopup
        selectedLocation={SelectedLocation}
        index={0}
        bookingData={""}
        open={isPayOpen}
        player={SelectedPlayer}
        setOpen={setISPayOpen}
        playerId={PlayerId}
      />

      <Dialog onClose={() => handleCloseModel} open={showWarning}>
        <DialogContent>
          <StatusWarning
            sendNotification={sendNotification}
            SetSendNotification={SetSendNotification}
            handleShowAndCancel={handleShowAndCancel}
            name={name}
            handleCloseModel={handleCloseModel}
            userData={clickedPlayer}
          />
        </DialogContent>
      </Dialog>

      <Dialog onClose={() => setShowTeamWaiting(false)} open={showTeamWaiting}>
        <DialogContent sx={{minWidth:{sm:'500px',xs:'auto'}}}>

          <Typography sx={{fontFamily:'var(--font-medium)',fontSize:'16px',marginBottom:'10px'}}>Players Waiting for team Approval</Typography>
       {
        selectedPosition?.length>0 ?
         <Box
         maxHeight={selectedPosition.length > 3 ? "288px" : "auto"}
         id="table-person"
       >
         <Box className="waitingList">
           {selectedPosition.map((user: any, index: number) => (
             <Box
               key={user.id}
               display={"flex"}
               gap={1}
               sx={{ "&:hover": { background: "#889AB64D !important" } }}
               alignItems={"center"}
               padding={"8px 10px"}
             >
               <Box>
                 <Box
                   style={{
                     borderRadius: "7px",
                     width: "26px",
                     height: "26px",
                     textAlign: "center",
                     padding: "0px 10px",
                     boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
                     fontFamily:'var(--font-regular)'
                   }}
                 >
                   {index + 1}
                 </Box>
               </Box>
               <Box style={{ width: "-webkit-fill-available" }}>
                 <Box
                   display={"flex"}
                   justifyContent={"space-between"}
                   width={"100%"}
                   color={activeColor}
                   sx={{ ">img": { display: { xs: "none", sm: "block" } },gap:'5px' }}
                 >
                   <Box sx={{...common}}>
                     <img
                       src={
                         user?.customer?.profile_url
                           ? user?.customer?.profile_url
                           : "/images/Reserved.svg"
                       }
                       alt="user"
                       width="45px"
                       height="45px"
                       style={{ borderRadius: "50%" }}
                     />
                     <Box
                       sx={{
                         fontFamily: "var(--font-regular)",
                       }}
                     >
                       <Typography>
                         {user?.customer?.first_name}{" "}
                         {user?.customer?.last_name}
                       </Typography>
                       <Typography sx={{fontFamily:"var(--font-regular)", fontSize:'12px',opacity:'0.7',display:{xs:'none',sm:'block'}}}>Level {GEtLevelOfPlayer(user)}</Typography>
                     </Box>
                   </Box>
                   <Box display={"flex"} alignItems={"center"} gap={1}>
                     <button
                       style={{
                         background: "white",
                         padding: "0px 15px",
                         border: "1px solid #EEEFEE",
                         borderRadius: "5px",
                         fontFamily:'var(--font-regular)',
                         fontSize:'14px',
                         boxShadow:
                           "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                       }}
                       onClick={() => {setIsOpen(true);setSelectedCust(user?.id)}}
                     >
                       {t('common.Move to Players')}
                     </button>
                   </Box>
                 </Box>
               </Box>
             </Box>
           ))}
         </Box>
       </Box>:
      <Box height={"91px"} id="table-person">
      <Box
        sx={{
          height: "77px",
          background: "#889AB61A",
          color: "#B4C1D5",
          padding: "8px 0px 8px 10px",
        }}
      >
        {" "}
        No Players
      </Box>
    </Box>}
        </DialogContent>
      </Dialog>
      <Dialog hideBackdrop open={isOpen} onClose={()=>{setIsOpen(false);setSelectedPoisition(undefined)}}>
        <DialogContent>
       <Typography sx={{fontFamily:'var(--font-regular)'}}>{t('common.Choose the players position')}</Typography>
       <Box sx={{mt:'20px',pr:'10px'}}>
        {
            teamMates?.map((player:any,index:number)=><Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:3,marginY:'20px'}}>
              <Box sx={{ borderRadius: "7px", width: "26px", height: "26px", textAlign: "center", padding: '0px 10px', boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',color:'#22356D',fontFamily:'var(--font-regular)' }}>{index+1}</Box>
         <Box sx={{display:'grid',gridTemplateColumns:{xs:'repeat(1, 1fr)',sm:'repeat(2, 1fr)'},gap:{xs:2,sm:10},width:'100%'}} >
             {!!player?.customer ?   <Box sx={{...common, marginBottom:"5px"}}>
             <img src={player.customer?.profile_url ? player.customer?.profile_url : "/images/Reserved.svg" } style={{width:"33px", height:"33px", borderRadius:"50%"}} />
             <Box sx={{display:'flex',flexDirection:'column'}}>
                 <Typography sx={{fontFamily:'var(--font-medium)',fontSize:'14px'}}>{player?.reserved?`Reserved by ${player.customer?.first_name} ${player.customer?.last_name}`:`${player.customer?.first_name} ${player.customer?.last_name}` }</Typography>
                 <Typography sx={{fontFamily:"var(--font-regular)", fontSize:'12px',opacity:'0.7',display:{xs:'none',sm:'block'}}}>Level {GEtLevelOfPlayer(player)}</Typography>
                 </Box>
             </Box>:<Box onClick={()=>handleSlot(index,1)} sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:3,cursor:'pointer'}} >
            <Box sx={{  width: '35px',
        height: '35px',
        borderRadius: '50%',
        border: '2px dashed #22356D',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: selectedPoisition===(index+1)*2+(-1)?'#EAF956':'',}}>
              <GrAdd style={{color:"#22356D"}} />
            </Box>
            <Typography sx={{fontFamily:'var(--font-medium)',fontSize:'12px'}}>Available</Typography>
          </Box>}

             {!!player?.pairedData[0]?.customer ?   <Box sx={{...common, marginBottom:"5px"}}>
             <img src={player?.pairedData[0].customer?.profile_url ? player?.pairedData[0].customer?.profile_url : "/images/Reserved.svg" } style={{width:"33px", height:"33px", borderRadius:"50%"}} />
             <Box sx={{display:'flex',flexDirection:'column'}}>
                 <Typography sx={{fontFamily:'var(--font-medium)',fontSize:'14px'}}>{player?.pairedData[0]?.reserved?`Reserved by ${player?.pairedData[0].customer?.first_name} ${player?.pairedData[0].customer?.last_name}`:`${player?.pairedData[0].customer?.first_name} ${player?.pairedData[0].customer?.last_name}` }</Typography>
                 <Typography sx={{fontFamily:"var(--font-regular)", fontSize:'12px',opacity:'0.7'}}>Level {GEtLevelOfPlayer(player?.pairedData[0])}</Typography>
                 </Box>
             </Box>:<Box onClick={()=>handleSlot(index,2)} sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:3,cursor:'pointer'}} >
            <Box sx={{  width: '35px',
        height: '35px',
        borderRadius: '50%',
        border: '2px dashed #22356D',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: selectedPoisition===((index+1)*2)?'#EAF956':'',}}>
              <GrAdd style={{color:"#22356D"}} />
            </Box>
            <Typography sx={{fontFamily:'var(--font-medium)',fontSize:'12px'}}>Available</Typography>
          </Box>}
          </Box>
            </Box>)
        }
        </Box>
    
       <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  marginTop={4}
                >
                  <button
                    style={{ ...style, color: activeColor }}
                    onClick={() => setIsOpen(false)}
                  >
                      {t('common.Cancel')}
                  </button>
                  <button
                    onClick={()=>movePlayers(selectedCust,selectedPoisition||1)}
                    style={{
                      ...style,
                      color: "white",
                      background: activeColor,
                    }}
                  >
                      {t('common.Save')}
                  </button>
                </Box>
        </DialogContent>
    </Dialog>
    </Box>
  );
};

export default TeamMatesPer;
