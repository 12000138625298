import { Box, Button, FormControl, MenuItem, Select, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useGetClubWalletOfCustomer } from "src/api/Customer";
import { useGetAllPaymentMethodsByCust } from "src/api/Voucher";
import { addThousandSeparator } from "src/constants/SportSEparator";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import MainContext from "src/context/MainContext";
import Cleave from "cleave.js/react";
import { useTranslation } from "react-i18next";
import CheckboxTree from "src/features/POS/CustomCategorySelector";
import { useGetAllProducts } from "src/api/POS";

const common = {
  display: "flex",
  alignItems: "center",
  gap: "10px",
};

const style = {
  border: "1px solid #EEEFEE",
  borderRadius: "7px",
  height: "37px",
  color:'#22356D'
};

interface walletTypes {
  currency: string;
  balance: number;
  id: number;
}

const ProductPending = ({
  currentFields,
  AllCoupons,
  player_id,
  selectedLocationID,
  reset,
  splitIndex,
  serviceID,
  index,
  register,
  splidata,
  PendingPlayers,
  setValue,
  unregister,
  setIsopen,
  SelectedLocation
}: {
  register: any;
  index: number;
  currentFields: any;
  reset: any;
  splitIndex: number;
  splidata: any;
  AllCoupons: any;
  serviceID: number;
  player_id: number;
  PendingPlayers: any;
  selectedLocationID: number;
  setValue: any;
  unregister:any;
  setIsopen:any;
  SelectedLocation:any;
}) => {
  const {
    data,
    isSuccess,
    isError,
    setCustomer_id,
    refetch: RefetchClubWallet,
  } = useGetClubWalletOfCustomer();
  const {
    data: PaymentMethodsData,
    isSuccess: PaymentMethodSuccess,
    isError: PaymentMethodError,
    setCustomer_id: SetPCustomer_id,
    setLocation_id,
  } = useGetAllPaymentMethodsByCust();
  const {t} = useTranslation();
  const [open,setOpen] = useState(false);
  const {data:allproduct,isSuccess:productsuccess,isError:productError,refetch} = useGetAllProducts();
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [AllProducts,setAllProducts] = useState<any[]>([]);
  const [PaymentMethods, setPaymentMethods] = useState<any>([]);
  const [SelectedVoucher, setSelectedVoucher] = useState<any>();
  const [selectedCoupon, setselectedCoupon] = useState<any>();
  const { Notification, setNotification } = useContext(MainContext);


  

  useEffect(() => {
    if(productsuccess){
        setAllProducts(allproduct)
    }else{
        setAllProducts([]);
    }
  }, [allproduct,productsuccess,productError])

  const result = AllProducts?.filter((item:any)=>item?.locations?.some((loc:any)=>loc?.id===selectedLocationID))?.filter((item:any)=>item?.status==="Active")?.reduce((acc:any, product:any) => {
    const { category } = product;
    const categoryObj = acc.find((obj:any) => obj.title === category.category_name);

    const productDetails = {
        id: product.id,
        product_name: product.product_name,
        category_id: product.category_id,
        club_id: product.club_id,
        price: product.price,
        status: product.status,
    };

    if (categoryObj) {
        categoryObj.arr.push(productDetails);
    } else {
        acc.push({
            title: category?.category_name,
            sequence: category?.sequence,
            arr: [productDetails],
        });
    }

    return acc;
}, []);

result.sort((a:any, b:any) => a.sequence - b.sequence);

const formattedResult = result.map(({ title, arr }:{ title:any, arr:any }) => ({ title, arr }));  

  useEffect(() => {
    const filetered = PendingPlayers?.filter(
      (item: any) => item?.id === player_id
    );
    setCustomer_id(filetered[0]?.customer?.id);
    SetPCustomer_id(filetered[0]?.customer?.id);
    setLocation_id(selectedLocationID);
  }, [player_id, selectedLocationID, PendingPlayers]);

  useEffect(() => {
    if (isSuccess && PaymentMethodSuccess) {
      const updated = data?.map((item: walletTypes) => {
        const method_name = `Wallet ${addThousandSeparator(
          item.balance,
          item.currency
        )} ${item.currency}`;
        return { ...item, method_name };
      });
      setPaymentMethods([...updated, ...PaymentMethodsData?.paymentMethods]);
    } else if (PaymentMethodSuccess) {
      setPaymentMethods(PaymentMethodsData?.paymentMethods);
    } else if (isSuccess) {
      const updated = data?.map((item: walletTypes) => {
        const method_name = `Wallet ${addThousandSeparator(
          item.balance,
          item.currency
        )} ${item.currency}`;
        return { ...item, method_name };
      });
      setPaymentMethods(updated);
    } else {
      setPaymentMethods([]);
    }
  }, [
    data,
    isSuccess,
    isError,
    PaymentMethodsData,
    PaymentMethodSuccess,
    PaymentMethodError,
  ]);

  const addVoucher = (index: number, splitIndex: number) => {
    const updatedPayments = [...currentFields];
    updatedPayments[index].products[splitIndex].showCoupon = true;
    // updatedPayments[index].products[splitIndex].coupon_id = ;
    reset({ payments: updatedPayments });
  };

  const removeVoucher = (index: number, splitIndex: number) => {
    const updatedPayments = [...currentFields];
    updatedPayments[index].products[splitIndex].showCoupon = false;
    updatedPayments[index].products[splitIndex].discount = 0;
    unregister(`payments.${index}.products.${splitIndex}.coupon_id`);
    setselectedCoupon('');
    reset({ payments: updatedPayments });
  };

  const addSplitPayment = (index: number) => {
    const updatedPayments = [...currentFields];
    updatedPayments[index].products.push({
      discount: 0,
      showCoupon: false,
    });
    reset({ payments: updatedPayments });
  };

  const handleRemoveSplit = (index: number, splitIndex: number) => {
    const updatedPayments = [...currentFields];
    updatedPayments[index].products.splice(splitIndex, 1);
    reset({ payments: updatedPayments });
  };

  const handleSelectPayment = (data: any) => {
    if (data?.hasOwnProperty("balance")) {
      if(data?.currency !== SelectedLocation?.currency){
        setSelectedVoucher('');
        setNotification({
          isShow: true,
          message: "Customer wallet currency is different then product",
          subMessage: "",
          type: "error",
        });
      }else{
        if (
          data?.balance < (currentFields[index]?.products[splitIndex]?.price-currentFields[index]?.products[splitIndex]?.discount)
        ) {
          setNotification({
            isShow: true,
            message: "Customer doesn’t have sufficient wallet credits",
            subMessage: "",
            type: "error",
          });
        }else{
          setValue(
            `payments.${index}.products.${splitIndex}.wallet_id`,
            data?.id
          );
          unregister(`payments.${index}.products.${splitIndex}.method_id`)
          setSelectedVoucher(data);
        }
      }
      
    } else if (data?.hasOwnProperty("method_name")) {
      setSelectedVoucher(data);
      unregister(`payments.${index}.products.${splitIndex}.wallet_id`)
      setValue(
        `payments.${index}.products.${splitIndex}.method_id`,
        data?.id
      );
    }
  };

  const handleSelectCoupon = (data: any) => {
    if (data?.type === "Amount") {
      if(Number(data?.discount)>Number(splidata.price)){
        setValue(
          `payments.${index}.products.${splitIndex}.discount`,
          splidata.price
        );
      }else{
        setValue(
          `payments.${index}.products.${splitIndex}.discount`,
          data?.discount
        );
      }
    } else {
      const price = currentFields[index].products[splitIndex].price;
      const discount = (price * data?.discount) / 100;
      setValue(
        `payments.${index}.products.${splitIndex}.discount`,
        discount
      );
    }
    setselectedCoupon(data);
    setValue(
      `payments.${index}.products.${splitIndex}.coupon_id`,
      data?.id
    );
  };

  const handleSelectProduct = (data:any)=>{
    const price = data.price.replace(".00",'');
    setValue(`payments.${index}.products.${splitIndex}.price`,price);
    setValue(`payments.${index}.products.${splitIndex}.product_name`,data.product_name);
    setValue(`payments.${index}.products.${splitIndex}.product_id`,data.id);
    setSelectedItems(data);
  }

  const isShowCouponProduct =  currentFields?.some((item:any)=>{
    return item?.products?.some((spli:any)=>spli?.showCoupon)
  });

  const handleDiscountChange = (value:number)=>{
    if(Number(value)>Number(splidata?.price)){
     setNotification({
       isShow: true,
       message: "Discount must be less than or equal to booking",
       subMessage: "",
       type: "error",
     });
     setValue(
       `payments.${index}.products.${splitIndex}.discount`,
       0
     )
    }else{
     setValue(
       `payments.${index}.products.${splitIndex}.discount`,
       value
     )
    }
   }
    

  return (
        <Box sx={{ position:'relative',marginLeft:{xs:'0px',sm:isShowCouponProduct && !splidata?.showCoupon?'200px' :'8px',marginTop:'10px'}}}>
          <Box sx={{display:{xs:'none',sm:'block'},position:'absolute',right:'-20px',top:'12px'}} onClick={() => handleRemoveSplit(index, splitIndex)}>
              <RxCross2 />
            </Box>
          <Box
            sx={{
              position: "relative",
              ">p": {
                fontFamily: "var(--font-regular)",
                fontSize: "0.8rem",
                color: "#22356DB2", 
              },
            }}
          >
            
            <Button
              sx={{ position: "absolute", left: {xs:"0.2rem", md:"23.2rem"}, top: {xs:"8.2rem", md:"-2rem"},fontFamily:"var(--font-regular) !important", color:"#22356DB2",
              textTransform:'capitalize' }}
              onClick={() => {splidata?.showCoupon ? removeVoucher(index,splitIndex):addVoucher(index, splitIndex)}}
            >
              {splidata?.showCoupon ? t("common.Cancel Coupon"):t("common.Add Coupon +")}
            </Button>
           
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              gap: "10px",
              marginBottom: "10px",
              ">div>p": { fontFamily: "var(--font-regular) !important" },
            }}
          >
            <FormControl sx={{width:{xs:"100%", md:"unset"}}}>
              <Select
                IconComponent={KeyboardArrowDownIcon}
                placeholder="Customer type"
                value={!!SelectedVoucher ? SelectedVoucher : t("customers.Payment Method")}
                id="customdropdown"
                renderValue={(value) => {
                  if (value?.method_name) {
                    return value?.method_name;
                  }
                  return <em>{t("customers.Payment Method")}</em>;
                }}
                sx={{
                  width: {xs:"100%", md:"180px"},
                  height: "35px",
                  border: "1px solid #EEEEFE",
                  borderRadius: "7px",
                  fontSize: "14px",
                  opacity: "1",
                  fontFamily: "var(--font-regular)",
                  color: "#22356D",
                }}
                MenuProps={{ sx: dopdowncustomcss }}
              >
                {PaymentMethods?.map((location: any) => (
                  <MenuItem
                    onClick={(e: any) => handleSelectPayment(location)}
                    key={location.id}
                    value={location.id}
                  >
                    {location?.method_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box >
                   <CheckboxTree setisOpen={setIsopen} splitIndex={splitIndex} inputValue={currentFields[index].products[splitIndex].product_name} selectedItem={selectedItems} setSelectedItem={handleSelectProduct} data={formattedResult}/>
          </Box>
            {splidata.showCoupon && (
              <Box sx={{ ...common, position: "relative", justifyContent:{xs:"space-between", md:"unset"}, width:{xs:"100%", md:"unset"}, marginTop:{xs:"20px", md:"0px"}, ">div>div":{width:{xs:"100%", md:"180px !important"}} }}>
                <Typography>{t('title.Coupon')}</Typography>
                <FormControl sx={{width:{xs:"100%", md:"unset"}}}>
                  <Select
                    IconComponent={KeyboardArrowDownIcon}
                    placeholder="Customer type"
                    value={!!selectedCoupon ? selectedCoupon : "Select Coupon"}
                    id="customdropdown"
                    renderValue={(value) => {
                      if (value?.name) {
                        return value?.name;
                      }
                      return <em>{"Select Coupon"}</em>;
                    }}
                    sx={{
                      width: {xs:"100%", md:"180px"},
                      height: "35px",
                      border: "1px solid #EEEEFE",
                      borderRadius: "7px",
                      fontSize: "14px",
                      opacity: "1",
                      fontFamily: "var(--font-regular)",
                      color: "#22356D",
                    }}
                    MenuProps={{ sx: dopdowncustomcss }}
                  >
                    {AllCoupons?.map((location: any) => (
                      <MenuItem
                        onClick={(e: any) => handleSelectCoupon(location)}
                        key={location.id}
                        value={location.id}
                      >
                        {location?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <input type="text" placeholder='coupon' {...register(`payments.${index}.products.${splitIndex}.coupon_id`)} style={{...style, width:"155px"}} /> */}
              </Box>
            )}
            <Box sx={{...common, marginTop:{xs:"20px", md:"0px"}, justifyContent:{xs:"space-between", sm:"unset"}, width:{xs:"100%", md:"unset"}}}>
              <Typography>{t("common.Discount")}</Typography>
              <Cleave
                readOnly={currentFields[index].products[splitIndex].showCoupon}
                value={splidata?.discount}
                placeholder={t("common.Discount")}
                onChange={(e:any)=>handleDiscountChange(e.target.rawValue)}
                style={{ ...style, width: "110px" }}
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  delimiter: "\u2024",
                }}
              />
              
            </Box>
            <Box sx={{...common, width:{xs:"100%", md:"unset"}, justifyContent:{xs:"space-between", sm:"unset"}}}>
              <Typography>{t('common.Price')}</Typography>
              <Cleave
              readOnly
               value={splidata?.price}
                placeholder="price"
                onChange={(e:any)=>
                  setValue(
                  `payments.${index}.products.${splitIndex}.price`,
                  e.target.rawValue
                )}
                style={{ ...style, width: "110px" }}
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  delimiter: "\u2024",
                }}
              />
            </Box>
             <Box sx={{display:{xs:'block',sm:'none'}}} onClick={() => handleRemoveSplit(index, splitIndex)}>
              <RxCross2 />
            </Box>
          </Box>
          {/* ))} */}
        </Box>           
  );
};

export default ProductPending;
