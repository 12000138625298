import React, { useContext, useEffect, useState } from "react";
import { activeColor } from "src/constants/color";
import Box from "@mui/material/Box";
import AddPlayer from "./AddPlayer";
import "../tableStyling.css";
import MainContext from "src/context/MainContext";
import { getClubId, useGetAllCustomerList } from "src/api/CalendarBooking";
import { apiCaller } from "src/api/ApiCaller";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const style = {
  fontFamily: "var(--font-semibold)",
  background: "white",
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
  padding: "7px 20px",
  borderRadius: "7px",
};

const WaitingList = ({
  selectAll,
  handleMasterCheckboxChange,
  userData,
  PlayNotRefetch,
  updateList,
  waitingList,
  selectedOpenRegular,
  handleCheckboxChange,
  filterCustomerList
}: {
  selectAll: any;
  handleMasterCheckboxChange: any;
  userData: any;
  updateList: any;
  PlayNotRefetch: any;
  waitingList: any;
  selectedOpenRegular: string;
  handleCheckboxChange: any;
  filterCustomerList:any;
}) => {
  const {t} = useTranslation();
  const [showWarning,setShowWarning] =useState({isOpen:false,id:''});
  const context = useContext(MainContext);
  const event = context?.event;
  const setNotification = context?.setNotification;
  const [data, setData] = useState(userData);
  const [addPlayer, setAddPlayer] = useState<string>("");
  const [showList, setShowList] = useState<boolean>(false);



  const SPORT_NAME = Object.keys(event)?.length>0 ?event?.courts[0].sport?.sport_name:'';
  const GEtLevelOfPlayer = (player:any)=>{
       const Level = player?.customer?.sportsLevel;
       const FilteredLevel = Level?.filter((item:any)=>item?.sport_name===SPORT_NAME)[0]?.level ||'0.0';
       return FilteredLevel;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;
    setAddPlayer(val);
    setShowList(true);
    let arr = filterCustomerList
      ?.map((cust: any) => {
        const { first_name, last_name, ...newcust } = cust;
        const full_name = `${first_name} ${last_name}`;
        return { full_name, ...cust };
      })
      .filter((item:any) => {
        let name = item?.full_name?.toLowerCase();
        return name?.includes(val?.toLowerCase());
      });
    setData(arr);
  };

  const selectedUser = async (item: any) => {
    setShowList(false);
    setAddPlayer(item.full_name);
    let data = {
      customer_id: item.id,
      positin: 2,
    };
    try {
      let club_id = getClubId();
      let res: any = await apiCaller.post(
        `${club_id}/players/waiting-lists/${event?.service_id}`,data
      );
      setTimeout(() => {
        waitingList();
      }, 1000);
      setAddPlayer("");
    } catch (error: any) {
      let err = error.response?.data?.message;
      console.log(err);
    }
  };

  
  const movePlayers = async () => {
    try {
      let club_id = getClubId();
      let res: any = await apiCaller.post(`${club_id}/players/move-player/${event?.service_id}/${showWarning?.id}`);
      waitingList();
      PlayNotRefetch();
      setNotification({isShow:true,message:res.data?.message,subMessage:'',type:'success'})
    } catch (error: any) {
      const err = error.response?.data?.message;
      setNotification({isShow:true,message:err,subMessage:'',type:'error'})
      console.log(err);
    }
  };

  useEffect(() => {
    setData(filterCustomerList);
  }, [filterCustomerList])
  

  useEffect(() => {
    document.addEventListener("click", () => {
      setShowList(false);
    });
  }, []);


  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ pointerEvents: event?.is_cancelled ? "none" : "auto" }}
        position={"relative"}
        zIndex={99}
        alignItems={"center"}
      >
        <AddPlayer
          maxHeight={"200px"}
          addPlayer={addPlayer}
          handleChange={handleChange}
          showList={showList}
          setShowList={setShowList}
          data={data}
          isPlayer={true}
          selectedUser={selectedUser}
        />
      </Box>
      {/* ----- player list ----- */}
      {userData.length > 0 ? (
        <Box
          maxHeight={userData.length > 3 ? "288px" : "auto"}
          id="table-person"
        >
          <Box className="waitingList">
            {userData.map((user: any, index: number) => (
              <Box
                key={user.id}
                display={"flex"}
                gap={1}
                sx={{ "&:hover": { background: "#889AB64D !important" } }}
                alignItems={"center"}
                padding={"8px 10px"}
              >
                <Box>
                  <Box
                    style={{
                      borderRadius: "7px",
                      width: "26px",
                      height: "26px",
                      textAlign: "center",
                      padding: "0px 10px",
                      boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
                      fontFamily:'var(--font-regular)'
                    }}
                  >
                    {index + 1}
                  </Box>
                </Box>
                <Box style={{ width: "-webkit-fill-available" }}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    width={"100%"}
                    color={activeColor}
                    sx={{ ">img": { display: { xs: "none", sm: "block" } } }}
                  >
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                      <img
                        src={
                          user?.customer?.profile_url
                            ? user?.customer?.profile_url
                            : "/images/Reserved.svg"
                        }
                        alt="user"
                        width="45px"
                        height="45px"
                        style={{ borderRadius: "50%" }}
                      />
                      <Box
                        sx={{
                          fontFamily: "var(--font-regular)",
                          ">p": { display: { xs: "none", sm: "block" } },
                        }}
                      >
                        <p>
                          {user?.customer?.first_name}{" "}
                          {user?.customer?.last_name}
                        </p>
                        <Typography sx={{fontFamily:"var(--font-regular)", fontSize:'12px',opacity:'0.7'}}>Level {GEtLevelOfPlayer(user)}</Typography>
                      </Box>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                      <button
                        style={{
                          background: "white",
                          padding: "0px 15px",
                          border: "1px solid #EEEFEE",
                          borderRadius: "5px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                            fontFamily:'var(--font-regular)'
                        }}
                        onClick={() => setShowWarning({id:user?.id,isOpen:true})}
                      >
                        {t('common.Move to Players')}
                      </button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box height={"91px"} id="table-person">
          <Box
            sx={{
              height: "77px",
              background: "#889AB61A",
              color: "#B4C1D5",
              padding: "8px 0px 8px 10px",
            }}
          >
            {" "}
            No Players
          </Box>
        </Box>
      )}
      <Dialog onClose={() => setShowWarning({isOpen:false,id:''})} open={showWarning.isOpen}>
            <DialogContent>
                <Box padding={2}>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-medium)",
                        color: "#22356D",
                        marginRight: "5rem",
                      }}
                    >
                     {t('common.Are you sure you want to')} {t('common.move this player to player list ?')}
                    </Typography>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      marginTop={2}
                    >
                      <button
                        style={{ ...style, color: activeColor }}
                        onClick={() => setShowWarning({isOpen:false,id:''})}
                      >
                        {t('customers.No_undo')}
                      </button>
                      <button
                      onClick={movePlayers}
                        style={{
                          ...style,
                          color: "white",
                          background: activeColor,
                        }}
                      >
                         {t('common.Yes')}
                      </button>
                    </Box>
                  </Box>
                </Box>
                        </DialogContent>
          </Dialog>
    </>
  );
};

export default WaitingList;
