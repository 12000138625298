import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import DialogContent from "@mui/material/DialogContent";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useCallback, useEffect, useState } from "react";
import { Condtions } from "src/constants/variables";
import { FaChevronDown } from "react-icons/fa6";
import LevelRangePicker from "./LevelRangeSelector";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const EventLevelRestrictions = ({
  index,
  preData,
  setDiscountConditions,
}: {
  index: number;
  preData: any;
  setDiscountConditions: any;
}) => {
  const {t} = useTranslation();
  
  const [showlimit, setshowLimit] = useState(false);
  const [ openDropdown, setDropdown ] = useState(false);

  const handleClose = () => {
    setshowLimit(false);
  };

  const borderInput = {
    width: "150px",
    padding: "5px 8px",
    fontFamily:"var(--font-regular)",
    fontSize: "14px",
    border: "1px solid #EEEFEE",
    borderRadius: "7px",
    color: "#22356D",
  };
 

  return (
    <Box >
      <p
        style={borderInput}
        className="flex flex-row justify-between items-center"
        onClick={() => setshowLimit(true)}
      >
        {preData?.minimum_range !== undefined &&
        preData?.maximum_range !== null &&
        preData?.minimum_range !== null &&
        preData?.maximum_range !== undefined
          ? preData?.minimum_range===0 && preData?.maximum_range===0 ? "Off" :`${preData?.minimum_range} - ${preData?.maximum_range}`
          : t("common.Select range")}{" "}
        <FaChevronDown opacity={0.4} fontSize={"10px"} />
      </p>
      <Dialog
        sx={{
          ".MuiDialog-paper": {
            maxWidth: "900px",
            height: openDropdown ? "300px" : "120px",
            minHeight: "120px",
          },
        }}
        onClose={handleClose}
        open={showlimit}
      >
        <DialogContent style={{ marginTop: 0, padding: "0px" }}>
          <LevelRangePicker preTime={preData} getTime={setDiscountConditions} setDropdown={setDropdown} setshowLimit={setshowLimit} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EventLevelRestrictions;
