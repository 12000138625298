import { Box, InputBase, Typography } from "@mui/material";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { LuSendHorizonal } from "react-icons/lu";
import { io, Socket } from "socket.io-client";
import { getClubId } from "src/api/CalendarBooking";
import MainContext from "src/context/MainContext";
import { useSocket } from "src/context/SocketProvider";
import Singlechat from "./Singlechat";
import { useForm } from "react-hook-form";

interface admin {
  id: number;
  full_name: string;
  role_id: number;
}
interface chats {
  _id: string;
  message: string;
  created_at: string;
  customer_id: number;
  admin_id: number;
}
interface customer {
  first_name: string;
  id: number;
  last_name: string;
}
interface user {
  id: number;
  is_cancelled: boolean;
  customer: customer;
}
interface chat {
  admin: admin[];
  chats: chats[];
  users: user[];
}

interface messageType {
  message: string;
}

interface ServerToClientEvents {
  chatMessage: (message: string) => void;
  join: (data: any) => void;
}

interface ClientToServerEvents {
  chatMessage: (message: messageType) => void;
}
interface formInput {
  message: string;
}
const ChatApp = () => {
  const { event, setChatURL, chatURL } = useContext(MainContext);
  const { watch, register, setValue } = useForm<formInput>();
  const { message } = watch();
  const [Chat, setChat] = useState<chat | any>();
  const [socket, setSocket] = useState<Socket<
    ServerToClientEvents,
    ClientToServerEvents
  > | null>(null);
  let club_id = getClubId();

  const token: string = localStorage.getItem("accessToken") || "";
  useEffect(() => {
    // initializing Socket.io
    const newSocket: any = io(chatURL, {
      extraHeaders: {
        Authorization: token,
        accessType: "admin",
      },
    });
    // console.log(newSocket)
    setSocket(newSocket);

    newSocket.on("connect", () => {
      console.log("Connected to WebSocket");
    });

    newSocket.on("disconnect", () => {
      console.log("Disconnected from WebSocket");
    });

    newSocket.on("join", (message: any) => {
      setChat(message);
    });

    newSocket.on("chatMessage", (message: any) => {
      setChat((prev: chat) => {
        const { chats, ...newprev } = prev;
        const updatedChat = [...chats, message.message];
        return { chats: updatedChat, ...newprev };
      });
    });

    newSocket.on("connect_error", (error: any) => {
      console.error("Socket.IO connection error:", error.message);
    });

    return () => {
      newSocket.disconnect();
    };
  }, [token, chatURL]);

  useEffect(() => {
    setChatURL(
      `${process.env.REACT_APP_CHAT_URL}/${club_id}/apps/chat/${event?.id}`
    );
    //    }
  }, [event]);

  const handleKeyPress = async (event: any) => {
    if (event.key === "Enter" || event.key === undefined) {
      if (socket !== null && message) {
        socket.emit("chatMessage", watch());
        setValue("message", "");
      }
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          boxShadow: "4px 4px 4px 0px #0000000D inset",
          border: "0.5px solid #EEEFEE",
          borderRadius: "7px",
          width: "100%",
          maxHeight: "300px",
          height: "300px",
          padding: "13px",
          marginTop: "0px !important",
        }}
        id="table-person"
      >
        <Box sx={{ paddingBottom: "40px" }}>
          {Chat?.chats?.length > 0 ? (
            Chat?.chats?.map((item: any) => (
              <Singlechat
                admins={Chat.admin}
                users={Chat.users}
                key={item?._id}
                item={item}
              />
            ))
          ) : (
            <Typography sx={{ fontFamily: "var(--font-regular)" }}>
              No chats found
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{ position: "absolute", bottom: "0px", width: "95%", left: "10px" }}
      >
        <Box
          sx={{
            border: "1px solid #EEEFEE",
            borderRadius: "7px",
            marginTop: "10px",
            background: "white",
          }}
          position={"relative"}
        >
          <InputBase
            {...register("message")}
            sx={{
              flex: 1,
              width: { xs: "90%", sm: "95%" },
              pl: "10px",
              color: "#22356D",
              fontFamily: "var(--font-regular)",
            }}
            placeholder="Send Message"
            type="text"
            onKeyDown={handleKeyPress}
          />
          <LuSendHorizonal
            onClick={handleKeyPress}
            style={{
              position: "absolute",
              right: "10px",
              top: "7px",
              cursor: "pointer",
              color: "#22356D",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChatApp;
