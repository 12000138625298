import { useState, useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import { apiCaller } from "src/api/ApiCaller";
import { getClubId } from "src/api/CalendarBooking";
import debounce from 'lodash.debounce';

export const useGetAllCustomers = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [FirstName,setFirstName] =useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [Memberships,setMemberships] = useState<any[]>([]);
  const [debouncedSearch, setDebouncedSearch] = useState<string>(search);
  const [debouncedEmail, setDebouncedEmail] = useState<string>(email);
  const [debouncedName, setDebouncedName] = useState<string>(email);


  const queryString = Memberships.map(membership => `membership=${membership?.id}`).join('&');


  const club_id = getClubId();
  let staticURL = debouncedEmail ? `&email=${debouncedEmail}` : debouncedSearch ? `&search=${debouncedSearch}` :debouncedName?`&firstName=${debouncedName}`: Memberships?.length>0?`&${queryString}`:'';

  // Debouncing the search input
  const debouncedSetSearch = useCallback(
    debounce((value: string) => setDebouncedSearch(value), 500), // 500ms delay
    []
  );

  // Debouncing the email input
  const debouncedSetEmail = useCallback(
    debounce((value: string) => setDebouncedEmail(value), 500), // 500ms delay
    []
  );

  const debouncedSetName = useCallback(
    debounce((value: string) => setDebouncedName(value), 500), // 500ms delay
    []
  );

  // Effect to update debounced search term
  useEffect(() => {
    debouncedSetSearch(search);
  }, [search, debouncedSetSearch]);

  // Effect to update debounced email term
  useEffect(() => {
    debouncedSetEmail(email);
  }, [email, debouncedSetEmail]);

  useEffect(() => {
    debouncedSetName(FirstName)
  }, [FirstName])
  

  const query = useQuery(
    ["Allcustomers", page, debouncedSearch, debouncedEmail,Memberships,debouncedName],
    async () => {
      const response = await apiCaller.get(`${club_id}/customers?page=${page}${staticURL}`);
      return response.data.data;
    },
    {
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );

  return { ...query, page, setPage, setSearch, setEmail,setMemberships,setFirstName };
};
