import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";

const SelectBox = ({
  text,
  value,
  setValue,
  arr,
}: {
  text?: string;
  value: any;
  setValue: any;
  arr: any;
}) => {
  const [show, setShow] = useState(false);

  const handleShow = (event: any) => {
    event?.stopPropagation();
    setShow((prev) => !prev);
  };

  const handleClick = (event: any, item: any) => {
    event?.stopPropagation();
    setValue(item);
    setShow(false);
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setShow(false);
    });
  }, []);

  return (
    <Box position={"relative"}>
      <Box onClick={(event) => handleShow(event)}>
        <input
          type="text"
          placeholder={text ? text : "1"}
          style={{
            width: "100%",
            height: "33px",
            color: "#22356D",
            border: "1px solid #EEEEFE",
            borderRadius: "7px",
            fontFamily: "var(--font-regular)",
          }}
          value={value}
          readOnly
        />
        <BsChevronDown
          style={{
            position: "absolute",
            top: "9.5px",
            right: "8.5px",
            fontSize: "0.8rem",
          }}
        />
      </Box>
      {show && (
        <Box
          position={"absolute"}
          top={"27.5px"}
          left={0}
          zIndex={9}
          width={"-webkit-fill-available"}
          sx={{ fontFamily: "var(--font-regular)", background: "white" }}
        >
          <Box
            id="table-person"
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px !important",
              marginTop: "0 !important",
              height: "108px",
              color: "#22356D",
              ">div:hover": { background: "#22356D", color: "white" },
            }}
          >
            {arr.map((item: any) => (
              <Box
                key={item}
                onClick={(event) => handleClick(event, item)}
                sx={{
                  padding: "6px 0px 6px 12px",
                  cursor: "pointer",
                  background: value == item ? "#22356D" : "transparent",
                  color: value == item ? "white" : "#22356D",
                }}
              >
                <Box></Box>
                <p>{item}</p>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SelectBox;

export const SelectBoxPayment = ({
  text,
  value,
  setValue,
  arr,
}: {
  text?: string;
  value: any;
  setValue: any;
  arr: any;
}) => {
  const [show, setShow] = useState(false);

  const handleShow = (event: any) => {
    event?.stopPropagation();
    setShow((prev) => !prev);
  };

  const handleClick = (event: any, item: any) => {
    event?.stopPropagation();
    setValue(item);
    setShow(false);
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setShow(false);
    });
  }, []);

  const selectedValue = value
    ? arr.filter((item: { name: string; key: string }) => item.key === value)[0]
        ?.name
    : "";

  return (
    <Box position={"relative"}>
      <Box onClick={(event) => handleShow(event)}>
        <input
          type="text"
          placeholder={text ? text : "1"}
          style={{
            width: "100%",
            height: "33px",
            color: "#22356D",
            border: "1px solid #EEEEFE",
            borderRadius: "7px",
            fontFamily: "var(--font-regular)",
          }}
          value={selectedValue}
          readOnly
        />
        <BsChevronDown
          style={{
            position: "absolute",
            top: "9.5px",
            right: "8.5px",
            fontSize: "0.8rem",
          }}
        />
      </Box>
      {show && (
        <Box
          position={"absolute"}
          top={"27.5px"}
          left={0}
          zIndex={9}
          width={"-webkit-fill-available"}
          sx={{ fontFamily: "var(--font-regular)", background: "white" }}
        >
          <Box
            id="table-person"
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px !important",
              marginTop: "0 !important",
              height: "108px",
              color: "#22356D",
              ">div:hover": { background: "#22356D", color: "white" },
            }}
          >
            {arr.map((item: any) => (
              <Box
                key={item.key}
                onClick={(event) => handleClick(event, item.key)}
                sx={{
                  padding: "6px 0px 6px 12px",
                  cursor: "pointer",
                  background: value == item ? "#22356D" : "transparent",
                  color: value == item ? "white" : "#22356D",
                }}
              >
                <Box>{item.name}</Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const SelectRegionBox = ({
  text,
  value,
  setValue,
  arr,
}: {
  text?: string;
  value: any;
  setValue: any;
  arr: any;
}) => {
  const [show, setShow] = useState(false);

  const handleShow = (event: any) => {
    event?.stopPropagation();
    setShow((prev) => !prev);
  };

  const handleClick = (event: any, item: any) => {
    event?.stopPropagation();
    setValue(item);
    setShow(false);
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setShow(false);
    });
  }, []);

  return (
    <Box position={"relative"}>
      <Box onClick={(event) => handleShow(event)}>
        <input
          type="text"
          placeholder={text ? text : "1"}
          style={{
            width: "100%",
            height: "33px",
            color: "#22356D",
            border: "1px solid #EEEEFE",
            borderRadius: "7px",
            fontFamily: "var(--font-regular)",
          }}
          value={value}
          readOnly
        />
        <BsChevronDown
          style={{
            position: "absolute",
            top: "9.5px",
            right: "8.5px",
            fontSize: "0.8rem",
          }}
        />
      </Box>
      {show && (
        <Box
          position={"absolute"}
          top={"27.5px"}
          left={0}
          zIndex={9}
          width={"-webkit-fill-available"}
          sx={{ fontFamily: "var(--font-regular)", background: "white" }}
        >
          <Box
            id="table-person"
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px !important",
              marginTop: "0 !important",
              height: "108px",
              color: "#22356D",
              ">div:hover": { background: "#22356D", color: "white" },
            }}
          >
            {arr.map((item: any) => (
              <Box
                key={item}
                onClick={(event) => handleClick(event, item)}
                sx={{
                  padding: "6px 0px 6px 12px",
                  cursor: "pointer",
                  background:
                    value == item?.region_name ? "#22356D" : "transparent",
                  color: value == item?.region_name ? "white" : "#22356D",
                }}
              >
                <Box></Box>
                <p>{item?.region_name}</p>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};
