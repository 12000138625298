import CustomerInfo from "../../components/CustomerInfo";
import {
  Box,
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateMoveWaiting, updateWaiting } from "src/api/CalendarBooking";
import { activeColor } from "src/constants/color";
import MainContext from "src/context/MainContext";
import theme from "src/theme";
const style = {
  fontFamily: "var(--font-semibold)",
  background: "white",
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
  padding: "7px 20px",
  borderRadius: "7px",
};

const WatitingApproveSingle = ({
  player,
  index,
  refetch,
}: {
  refetch: any;
  player: any;
  index: number;
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [IsOpen, setIsOpen] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState({ isOpen: false, type: "" });
  const { t } = useTranslation();
  const [showOption, setShowOption] = useState<boolean>(false);
  const { event, setNotification } = useContext(MainContext);

  useEffect(() => {
    if (isMobile) {
      document.addEventListener("click", () => {
        setShowOption(false);
      });
    }
  }, []);

  const handleClick = async () => {
    if (showWarning?.type === "move") {
      handleClickMove();
    }
    try {
      const response = await updateWaiting(
        player?.id,
        event?.service_id,
        showWarning?.type
      );
      refetch();
      const message = response.data.message;
      setShowWarning({ type: "", isOpen: false });
      setNotification({
        message: message,
        isShow: true,
        subMessage: "",
        type: "success",
      });
    } catch (error: any) {
      const err = error.response?.data?.message;
      setNotification({
        message: err,
        isShow: true,
        subMessage: "",
        type: "error",
      });
    }
  };

  const handleClickMove = async () => {
    try {
      const response = await updateMoveWaiting(player?.id, event?.service_id);
      refetch();
      const message = response.data.message;
      setShowWarning({ type: "", isOpen: false });
      setNotification({
        message: message,
        isShow: true,
        subMessage: "",
        type: "success",
      });
    } catch (error: any) {
      const err = error.response?.data?.message;
      setNotification({
        message: err,
        isShow: true,
        subMessage: "",
        type: "error",
      });
    }
  };

  const SPORT_NAME = Object.keys(event)?.length>0 ?event?.courts[0].sport?.sport_name:'';;
  const GEtLevelOfPlayer = (id: number) => {
    const Level = player?.customer?.sportsLevel;
    const FilteredLevel =
      Level?.filter((item: any) => item?.sport_name === SPORT_NAME)[0]?.level ||
      "0.0";
    return FilteredLevel;
  };

  return (
    <Box
      onMouseOver={() => (isMobile ? "" : setShowOption(true))}
      onMouseOut={() => (isMobile ? "" : setShowOption(false))}
      key={player.id}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
        position: "relative",
        "&:hover": { background: "#889AB64D !important" },
        padding: "0 5px",
      }}
    >
      <Box
        sx={{
          borderRadius: "7px",
          width: "26px",
          height: "26px",
          textAlign: "center",
          padding: "0px 10px",
          boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
          fontSize: { xs: "0.8rem", sm: "1rem" },
          color: activeColor,
        }}
      >
        {index + 1}
      </Box>
      <Box padding={"8px 10px"}>
        <Box width={"100%"} color={activeColor}>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={1}
            sx={{
              ">img": {
                display: { xs: "none", sm: "block" },
                ">p:nth-child(1)": { display: { xs: "none", sm: "block" } },
              },
            }}
          >
            <img
              onClick={() => setIsOpen(true)}
              style={{ borderRadius: "50%" }}
              src={
                player.customer?.profile_url
                  ? player?.customer?.profile_url
                  : "/images/Reserved.svg"
              }
              alt="player"
              width="40px"
              height="40px"
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  fontFamily: "var(--font-regular)",
                  fontSize: { xs: "0.8rem", sm: "1rem" },
                }}
              >
                {player?.customer?.first_name} {player?.customer?.last_name}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "var(--font-regular)",
                  fontSize: "14px",
                  opacity: "0.7",
                }}
              >
                Level {GEtLevelOfPlayer(player.id)}
              </Typography>
            </Box>
            {player.status === "approved" && (
              <Typography
                sx={{
                  color: "#5B8777",
                  fontFamily: "var(--font-regular)",
                  background: "#5B877726",
                  padding: "2px 8px",
                  borderRadius: "7px",
                }}
              >
                {t("common.Approved")}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      {isMobile && (
        <Box
          sx={{
            height: "23px",
            borderRadius: "5px",
            border: "1px solid #DDDDDD",
            padding: "1px 8px",
            background: "white",
            fontFamily: "var(--font-regular)",
            fontSize: "14px",
            color:'#22356DB2'
          }}
          onClick={(event: any) => {
            event.stopPropagation();
            setShowOption(true);
          }}
        >
          Action
        </Box>
      )}
      {showOption && (
        <Box
          display={"flex"}
          gap={1.5}
          marginRight={1}
          position={"absolute"}
          sx={{
            right: { xs: "0%", sm: "2%" },
            bottom: { xs: "4%", sm: "unset" },
          }}
        >
          <Paper
            onClick={() => setShowWarning({ isOpen: true, type: "move" })}
            sx={{
              padding: "0px 6px",
              color: "#22356DB2",
              cursor: "pointer",
              fontSize: { xs: "14px" },
            }}
          >
            {t("common.Move to players")}
          </Paper>
          <Paper
            onClick={() => setShowWarning({ type: "approved", isOpen: true })}
            sx={{
              padding: "0px 6px",
              color: "#22356DB2",
              cursor: "pointer",
              fontSize: { xs: "14px" },
            }}
          >
            {t("common.Approve")}
          </Paper>
          <Paper
            onClick={() => setShowWarning({ type: "remove", isOpen: true })}
            sx={{
              padding: "0px 6px",
              color: "#22356DB2",
              cursor: "pointer",
              fontSize: { xs: "14px" },
            }}
          >
            {t("common.Remove")}
          </Paper>
        </Box>
      )}

      <Dialog
        onClose={() => setShowWarning({ isOpen: false, type: "" })}
        open={showWarning.isOpen}
      >
        <DialogContent>
          <Box padding={2}>
            <Box>
              <Typography
                sx={{
                  fontFamily: "var(--font-medium)",
                  color: "#22356D",
                  marginRight: "5rem",
                }}
              >
                {t("common.Are you sure you want to")}{" "}
                {showWarning.type === "approved"
                  ? t("common.approve this player ?")
                  : showWarning.type === "remove"
                  ? t("common.Remove this player ?")
                  : t("common.move this player to player list ?")}
              </Typography>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                marginTop={2}
              >
                <button
                  style={{ ...style, color: activeColor }}
                  onClick={() => setShowWarning({ isOpen: false, type: "" })}
                >
                  {t("customers.No_undo")}
                </button>
                <button
                  onClick={handleClick}
                  style={{
                    ...style,
                    color: "white",
                    background: activeColor,
                  }}
                >
                  {t("common.Yes")}
                </button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <CustomerInfo
        hidebackdrop={false}
        GetAllCustomer={(data: any) => console.log(data)}
        customerdata={player?.customer}
        customfield={[]}
        fromCustomer={false}
        show={IsOpen}
        setShow={setIsOpen}
      />
    </Box>
  );
};

export default WatitingApproveSingle;
