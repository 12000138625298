import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import { event } from 'jquery';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown } from 'react-icons/io';
import { IoIosArrowUp } from "react-icons/io";

function filterProductsBySearch(data:any, searchTerm:any) {
  if (!searchTerm) return data; // If searchTerm is empty, return the original data

  return data.map((category:any) => {
      const filteredProducts = category.arr.filter((product:any) =>
          product.product_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return { ...category, arr: filteredProducts };
  }).filter((category:any) => category.arr.length > 0); // Remove categories with no matching products
}

// CheckboxTree Component
const CheckboxTree = ({ data ,setSelectedItem,selectedItem,inputValue,splitIndex,setisOpen}:{data:any,setSelectedItem:any,selectedItem:any,inputValue:string,splitIndex:number,setisOpen:any}) => {
    const {t} = useTranslation();
    const [open,setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState<any[]>([]);

    useEffect(() => {
     setFilteredData(data)
    }, [data])
    

    const handleSearch = (e:any) => {
        const value = e.target.value;
        setSearchTerm(value);
        setFilteredData(filterProductsBySearch(data, value));
    };
  

    const handleSelect = (event:any,category:any, item:any) => {
        event.stopPropagation();
        setOpen(false);
          setSelectedItem(item);
          setisOpen(false)
        
    };

    const handleShow = (event: any) => {
        event.stopPropagation();
        setOpen(true);
        setisOpen(true)
      };

      useEffect(() => {
        window.addEventListener("click", (event:any) => {
          event.stopPropagation();
          setOpen(false);
          setisOpen(false)
        });
      }, []);

    return (
      <Box>
          <input
          onChange={handleSearch}
          value={!inputValue?searchTerm:inputValue}
          placeholder={t('POS.Select Products')} 
            onClick={handleShow}
              style={{
                width:"180px",
                height: "35px",
                border: "1px solid #EEEEFE",
                borderRadius: "7px",
                fontSize: "14px",
                opacity: "1",
                fontFamily: "var(--font-regular)",
                color: "#22356D",
              }}/>
              {/* {t('POS.Add products')} <span>+</span> */}
    
      <Box width={"180px"} position={"relative"}>
         
     {open && <Box id="table-person" sx={{backgroundColor:'#889AB61A',borderRadius:'7px',display:'flex',flexDirection:'column',alignItems:'center',marginTop: "0px !important",width:'170px',padding:'5px',maxHeight:'200px',overflowY:'auto',zIndex:'55'}} position={"absolute"}>
            {filteredData?.length>0 ?filteredData.map((category:any) => (
                <CheckboxTreeItem
                    key={category.title}
                    category={category}
                    selectedItems={selectedItem}
                    handleSelect={handleSelect}
                />
            )):<Typography sx={{fontFamily: "var(--font-regular)",fontSize:'14px'}}>No Products Found</Typography>}
        </Box>}
        </Box>
        </Box>
    );
};

// CheckboxTreeItem Component
const CheckboxTreeItem = ({ category, selectedItems, handleSelect }:{ category:any, selectedItems:any, handleSelect:any }) => {
    const [open,setOpen] = useState(false);

    const handleShow = (event: any) => {
      event.stopPropagation();
      setOpen((prev:any) => !prev);
    };
    
    return (
        <Box width={"-webkit-fill-available"} sx={{ background: "white",}}>
            <Box sx={{height:'30px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',background:'#889AB61A',padding:'5px'}} onClick={handleShow}><Typography sx={{fontFamily:'var(--font-medium)',color:'#22356D',}}>{category.title}</Typography>{!open ?<IoIosArrowDown fontSize={"16px"} opacity={0.7} /> : <IoIosArrowUp fontSize={"16px"} opacity={0.7} />}</Box>
            {open && category.arr.map((item:any) => (
                <Box sx={{
                    background: selectedItems?.id===item.id ? "#22356DB2" : "#889AB61A",
                    color: selectedItems?.id===item.id ? "white" : "#22356D",
                    padding: "10px 10px 10px 20px",
                    cursor: "pointer",
                    "&:hover": {
                      background: "#22356DB2",
                      color: "#FFFFFF",
                    },
                    fontFamily:'var(--font-regular)'
                  }}
                  display={"flex"}
                  justifyContent={"space-between"}
                  gap={"20px"}
                  onClick={(event) => handleSelect(event,category.title, item)} key={item.id}>
                  {item.product_name}
                <label className="container">
                <input style={{border:'none !important'}}
                  type="checkbox"
                  readOnly
                  onChange={() => handleSelect(category.title, item)}
                  checked={selectedItems?.id===item.id}
                />
                <span className="mark" style={{ left: "-9px" }}></span>
              </label>
                </Box>
            ))}
        </Box>
    );
};

export default CheckboxTree;
