import { Box, Dialog, DialogContent, Paper, Typography, useMediaQuery } from '@mui/material'
import React, { useContext, useState } from 'react'
import SearchTeammates from './SearchTeammates';
import theme from 'src/theme';
import { useTranslation } from 'react-i18next';
import { GrAdd } from 'react-icons/gr';
import { activeColor } from 'src/constants/color';
import MainContext from 'src/context/MainContext';
const common = {display:"flex", alignItems:{xs:'none',sm:'center'}, gap:{xs:'3px',sm:'10px'},flexDirection:{xs:'column',sm:'row'},
    "& p":{
        fontFamily:"var(--font-regular) !important",
        color:"#22356D"
    },
    "& img":{
       display:{xs:'none',sm:'block'}
    }
};

const style = {
    fontFamily: "var(--font-semibold)",
    background: "white",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
    padding: "7px 20px",
    borderRadius: "7px",
  };


const SinglePlayer= ({item,idx,refetch,checkTeammate,customerList,movePlayers,teamMates,allWaiting}:any)=>{
    const [isOpen,setIsOpen] =useState<boolean>(false);
    const {event}= useContext(MainContext);
    const [firstHover,setFirstHover] = useState<boolean>(false);
    const [secondHover,setSecondHover] = useState<boolean>(false);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [selectedPosition,setSelectedPoisition] =useState<number>();
    const [waitingPosition,setWaitingPosition] = useState<any[]>([]);
    const [showTeamWaiting,setShowTeamWaiting] =useState<boolean>(false);
    const {t} = useTranslation();


    const getPositionPlayers = ()=>{
      return allWaiting?.filter((item:any)=>item.status==="waiting")?.filter((item:any)=>((item?.position===((idx+1)*2)-1))|| (item?.position===((idx+1)*2)))||[]
    }
  

  const SPORT_NAME = Object.keys(event)?.length>0 ?event?.courts[0].sport?.sport_name:'';
  const GEtLevelOfPlayer = (player:any)=>{
       const Level = player?.customer?.sportsLevel;
       const FilteredLevel = Level?.filter((item:any)=>item?.sport_name===SPORT_NAME)[0]?.level ||'0.0';
       return FilteredLevel;
  }

const handleSlot =(idx:number,position:number)=>{
    const slot = ((idx+1)*2+(position===1?-1:0))
    setSelectedPoisition(slot);
}

const hanldePositionsclick = (data:any)=>{
  setWaitingPosition(data);
  setShowTeamWaiting(true);
}




    return <>
    <Box sx={{display:'flex',flexDirection:'column',gap:1,width:'100%'}}>
    {
         item?.customer !==null ?
         <Box sx={{position:'relative',width:'100%'}} key={idx} onMouseLeave={()=>setFirstHover(false)} onMouseEnter={()=>setFirstHover(true)}>
             <Box sx={{...common, marginBottom:"5px"}}>
             <img src={item.customer?.profile_url ? item.customer?.profile_url : "/images/Reserved.svg" } style={{width:"33px", height:"33px", borderRadius:"50%"}} />
                 <Typography>{item.customer?.first_name} {item.customer?.last_name}</Typography>
                 <Typography sx={{fontFamily:"var(--font-regular)", fontSize:'12px',opacity:'0.7'}}>Level {GEtLevelOfPlayer(item)}</Typography>
             </Box>
           
             {isMobile && <Box sx={{height:"18px", borderRadius:"5px", border:"1px solid #DDDDDD", padding:"1px 8px", background:"white",fontFamily:'var(--font-regular)',fontSize:'14px',width:'max-content'}} onClick={(event:any)=>{event.stopPropagation();setFirstHover(true)}}>Action</Box>}
        {
        firstHover  && <Box display={'flex'} gap={1.5} marginRight={1} position={'absolute'} sx={{right:{xs:"0%", sm:'2%'}, top:{xs:"2%", sm:5}}}>
        <Paper onClick={()=>setIsOpen(true)}  sx={{padding:'0px 6px', color:'#22356DB2', cursor:'pointer',fontSize:{xs:'14px'}}} >{t('common.Move to players')}</Paper>
        {/* <Paper  sx={{padding:'0px 6px', color:'#22356DB2', cursor:'pointer',fontSize:{xs:'14px'}}} >{t('common.Approve')}</Paper>
        <Paper sx={{padding:'0px 6px', color:'#22356DB2', cursor:'pointer',fontSize:{xs:'14px'}}} >{t('common.Remove')}</Paper> */}
        </Box>
        }
         </Box>: <Box sx={{...common, justifyContent:"space-between"}}>
                                 <Box sx={{...common}}>
                                     <SearchTeammates updateList={refetch} customer={'customer2Id'} customerList={customerList} text={'second'} index={(idx+1)*2+(-1)} addingTeam={checkTeammate} />
                                 </Box>
                                 {/* <Box sx={{color:"#22356D !important"}}> {SelectedLocation?.currency} {selectEvent?.price}</Box> */}
                             </Box>
    }

    {
      item?.pairedData?.length!==0? <Box sx={{position:'relative',width:'100%'}} key={idx} onMouseLeave={()=>setSecondHover(false)} onMouseEnter={()=>setSecondHover(true)}>
      <Box sx={{...common, marginBottom:"5px"}}>
      <img src={item?.pairedData[0].customer?.profile_url ? item?.pairedData[0].customer?.profile_url : "/images/Reserved.svg" } style={{width:"33px", height:"33px", borderRadius:"50%"}} />
          <Typography>{item?.pairedData[0].customer?.first_name} {item?.pairedData[0].customer?.last_name}</Typography>
          <Typography sx={{fontFamily:"var(--font-regular)", fontSize:'12px',opacity:'0.7'}}>Level {GEtLevelOfPlayer(item?.pairedData[0])}</Typography>
      </Box>
      {item?.pairedData?.length === 0 && <Typography onClick={()=>hanldePositionsclick(getPositionPlayers())} sx={{fontFamily:'var(--font-regular)',fontsize:'13px',padding:'4px 8px',width:'max-content', background:idx%2===0?'white':'#f3f5f8',border:'1px solid #f3f5f8',borderRadius:'7px'}}>Applicants [{getPositionPlayers()?.length}]</Typography>}
      {isMobile && <Box sx={{height:"18px", borderRadius:"5px", border:"1px solid #DDDDDD", padding:"1px 8px", background:"white",fontFamily:'var(--font-regular)',fontSize:'14px',width:'max-content'}} onClick={(event:any)=>{event.stopPropagation();setSecondHover(true)}}>Action</Box>}
 {
 secondHover  && <Box display={'flex'} gap={1.5} marginRight={1} position={'absolute'} sx={{right:{xs:"0%", sm:'2%'}, top:{xs:"2%", sm:5}}}>
 <Paper onClick={()=>setIsOpen(true)}  sx={{padding:'0px 6px', color:'#22356DB2', cursor:'pointer',fontSize:{xs:'14px'}}} >{t('common.Move to players')}</Paper>
 {/* <Paper  sx={{padding:'0px 6px', color:'#22356DB2', cursor:'pointer',fontSize:{xs:'14px'}}} >{t('common.Approve')}</Paper>
 <Paper sx={{padding:'0px 6px', color:'#22356DB2', cursor:'pointer',fontSize:{xs:'14px'}}} >{t('common.Remove')}</Paper> */}
 </Box>
 }

  
  </Box>: <Box sx={{...common, justifyContent:"space-between"}}>
                          <Box sx={{...common}}>
                              <SearchTeammates updateList={refetch} customer={'customer2Id'} customerList={customerList} text={'second'} index={(idx+1)*2+(0)} addingTeam={checkTeammate} />
                          </Box>
                          {/* <Box sx={{color:"#22356D !important"}}> {SelectedLocation?.currency} {selectEvent?.price}</Box> */}
                      </Box>
    }

    </Box>
    <Dialog onClose={() => setShowTeamWaiting(false)} open={showTeamWaiting}>
        <DialogContent sx={{minWidth:{sm:'500px',xs:'auto'}}}>

          <Typography sx={{fontFamily:'var(--font-medium)',fontSize:'16px',marginBottom:'10px'}}>Players Waiting for team Approval</Typography>
       {
        waitingPosition?.length>0 ?
         <Box
         maxHeight={waitingPosition.length > 3 ? "288px" : "auto"}
         id="table-person"
       >
         <Box className="waitingList">
           {waitingPosition?.map((user: any, index: number) => (
             <Box
               key={user.id}
               display={"flex"}
               gap={1}
               sx={{ "&:hover": { background: "#889AB64D !important" } }}
               alignItems={"center"}
               padding={"8px 10px"}
             >
               <Box>
                 <Box
                   style={{
                     borderRadius: "7px",
                     width: "26px",
                     height: "26px",
                     textAlign: "center",
                     padding: "0px 10px",
                     boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
                     fontFamily:'var(--font-regular)'
                   }}
                 >
                   {index + 1}
                 </Box>
               </Box>
               <Box style={{ width: "-webkit-fill-available" }}>
                 <Box
                   display={"flex"}
                   justifyContent={"space-between"}
                   width={"100%"}
                   color={activeColor}
                   sx={{ ">img": { display: { xs: "none", sm: "block" } },gap:'5px' }}
                 >
                   <Box sx={{...common}}>
                     <img
                       src={
                         user?.customer?.profile_url
                           ? user?.customer?.profile_url
                           : "/images/Reserved.svg"
                       }
                       alt="user"
                       width="45px"
                       height="45px"
                       style={{ borderRadius: "50%" }}
                     />
                     <Box
                       sx={{
                         fontFamily: "var(--font-regular)",
                       }}
                     >
                       <Typography>
                         {user?.customer?.first_name}{" "}
                         {user?.customer?.last_name}
                       </Typography>
                       <Typography sx={{fontFamily:"var(--font-regular)", fontSize:'12px',opacity:'0.7',display:{xs:'none',sm:'block'}}}>Level {GEtLevelOfPlayer(user)}</Typography>
                     </Box>
                   </Box>
                   {/* <Box display={"flex"} alignItems={"center"} gap={1}>
                     <button
                       style={{
                         background: "white",
                         padding: "0px 15px",
                         border: "1px solid #EEEFEE",
                         borderRadius: "5px",
                         fontFamily:'var(--font-regular)',
                         fontSize:'14px',
                         boxShadow:
                           "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                       }}
                       onClick={() => {setIsOpen(true);setSelectedCust(user?.id)}}
                     >
                       {t('common.Move to Players')}
                     </button>
                   </Box> */}
                 </Box>
               </Box>
             </Box>
           ))}
         </Box>
       </Box>:
      <Box height={"91px"} id="table-person">
      <Box
        sx={{
          height: "77px",
          background: "#889AB61A",
          color: "#B4C1D5",
          padding: "8px 0px 8px 10px",
        }}
      >
        {" "}
        No Players
      </Box>
    </Box>}
        </DialogContent>
      </Dialog>


    <Dialog hideBackdrop open={isOpen} onClose={()=>{setIsOpen(false);setSelectedPoisition(undefined)}}>
        <DialogContent>
       <Typography sx={{fontFamily:'var(--font-regular)'}}>{t('common.Choose the players position')}</Typography>
       <Box sx={{mt:'20px',pr:'10px'}}>
        {
            teamMates?.map((player:any,index:number)=><Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:3,marginY:'20px'}}>
              <Box sx={{ borderRadius: "7px", width: "26px", height: "26px", textAlign: "center", padding: '0px 10px', boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',color:'#22356D',fontFamily:'var(--font-regular)' }}>{index+1}</Box>
         <Box sx={{display:'grid',gridTemplateColumns:{xs:'repeat(1, 1fr)',sm:'repeat(2, 1fr)'},gap:{xs:2,sm:10},width:'100%'}} >
             {!!player?.customer ?   <Box sx={{...common, marginBottom:"5px"}}>
             <img src={player.customer?.profile_url ? player.customer?.profile_url : "/images/Reserved.svg" } style={{width:"33px", height:"33px", borderRadius:"50%"}} />
             <Box sx={{display:'flex',flexDirection:'column'}}>
                 <Typography sx={{fontFamily:'var(--font-medium)',fontSize:'14px'}}>{player?.reserved?`Reserved by ${player.customer?.first_name} ${player.customer?.last_name}`:`${player.customer?.first_name} ${player.customer?.last_name}` }</Typography>
                 <Typography sx={{fontFamily:"var(--font-regular)", fontSize:'12px',opacity:'0.7'}}>Level {GEtLevelOfPlayer(player)}</Typography>
                 </Box>
             </Box>:<Box onClick={()=>handleSlot(index,1)} sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:3,cursor:'pointer'}} >
            <Box sx={{  width: '35px',
        height: '35px',
        borderRadius: '50%',
        border: '2px dashed #22356D',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: selectedPosition===(index+1)*2+(-1)?'#EAF956':'',}}>
              <GrAdd style={{color:"#22356D"}} />
            </Box>
            <Typography sx={{fontFamily:'var(--font-medium)',fontSize:'12px'}}>Available</Typography>
          </Box>}

             {!!player?.pairedData[0]?.customer ?   <Box sx={{...common, marginBottom:"5px"}}>
             <img src={player?.pairedData[0].customer?.profile_url ? player?.pairedData[0].customer?.profile_url : "/images/Reserved.svg" } style={{width:"33px", height:"33px", borderRadius:"50%"}} />
             <Box sx={{display:'flex',flexDirection:'column'}}>
                 <Typography sx={{fontFamily:'var(--font-medium)',fontSize:'14px'}}>{player?.pairedData[0]?.reserved?`Reserved by ${player?.pairedData[0].customer?.first_name} ${player?.pairedData[0].customer?.last_name}`:`${player?.pairedData[0].customer?.first_name} ${player?.pairedData[0].customer?.last_name}` }</Typography>
                 <Typography sx={{fontFamily:"var(--font-regular)", fontSize:'12px',opacity:'0.7'}}>Level {GEtLevelOfPlayer(player?.pairedData[0])}</Typography>
                 </Box>
             </Box>:<Box onClick={()=>handleSlot(index,2)} sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:3,cursor:'pointer'}} >
            <Box sx={{  width: '35px',
        height: '35px',
        borderRadius: '50%',
        border: '2px dashed #22356D',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: selectedPosition===((index+1)*2)?'#EAF956':'',}}>
              <GrAdd style={{color:"#22356D"}} />
            </Box>
            <Typography sx={{fontFamily:'var(--font-medium)',fontSize:'12px'}}>Available</Typography>
          </Box>}
          </Box>
            </Box>)
        }
        </Box>
    
       <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  marginTop={4}
                >
                  <button
                    style={{ ...style, color: activeColor }}
                    onClick={() => setIsOpen(false)}
                  >
                      {t('common.Cancel')}
                  </button>
                  <button
                    onClick={()=>movePlayers(item?.id,selectedPosition)}
                    style={{
                      ...style,
                      color: "white",
                      background: activeColor,
                    }}
                  >
                      {t('common.Save')}
                  </button>
                </Box>
        </DialogContent>
    </Dialog>
    </>
}

const SinglePairWaitingList = ({data,index,refetch,customerList,checkTeammate,movePlayers,teamMates,allWaiting}:{data:any,index:number,refetch:any,customerList:any,checkTeammate:any,movePlayers:any,teamMates:any,allWaiting:any}) => {
   
  return (
    <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:2,width:'100%'}}> <Box sx={{ borderRadius: "7px", width: "26px", height: "26px", textAlign: "center", padding: '0px 10px', boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)' }}>{index+1}</Box>
    <Box sx={{display:'flex',flexDirection:'column',gap:1,width:'100%'}}>
     
    <Box  sx={{...common,position:'relative',width:'100%'}}>
   <SinglePlayer allWaiting={allWaiting} teamMates={teamMates} movePlayers={movePlayers} item={data} idx={index} refetch={refetch} customerList={customerList} checkTeammate={checkTeammate} />
</Box>
     </Box>
   </Box>
  )
}

export default SinglePairWaitingList