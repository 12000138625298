import { useQuery } from "react-query";
import { apiCaller } from "./ApiCaller";
import { getClubId } from "./CalendarBooking";
import { useState } from "react";

    
export const useGetAllPayments = () => {
      let club_id = getClubId();
    const [location_id,setlocation_id]=useState<number>();
    const [viewType,setviewType]=useState<any>();

    const query = useQuery(
      ["AllPayments", location_id,viewType],
      async () => {
        const response :any= await apiCaller.get(`${club_id}/payments?location_id=${location_id}&start_date=${viewType.date_start}&end_date=${viewType.date_end}`);        return response.data.data;
      },
      { staleTime: 1000*3, retry: false, enabled: !!location_id&&!!viewType }
    );

    return {setviewType,setlocation_id,...query}
  };


export const GEtPaymentLogs = async (payment_id:number) => {
    let club_id = getClubId();
  try {
    const response :any= await apiCaller.get(`${club_id}/payments/logs/${payment_id}`);
    return response.data.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;  }
};


export const updateMembershipCancel = async (payment_id:number) => {
  let club_id = getClubId();
try {
  const response :any= await apiCaller.patch(`${club_id}/memberships/cancel/${payment_id}`);
  return response.data.data;
} catch (error: any) {
  let err = error.response?.data?.message;
  throw err;  }
};


export const updatevoucherCancel = async (customer_id:number) => {
  let club_id = getClubId();
try {
  const response :any= await apiCaller.patch(`${club_id}/customers/vouchers/${customer_id}`);
  return response.data.data;
} catch (error: any) {
  let err = error.response?.data?.message;
  throw err;  }
};



export const GEtPaymentLogsByPlayer = async (payment_id:number) => {
    let club_id = getClubId();
  try {
    const response :any= await apiCaller.get(`${club_id}/payments/players/logs/${payment_id}`);
    return response.data.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;  }
};



  
export const useGetLogsById = (payment_id: number) => {
    let club_id = getClubId();
  return useQuery(
    ["GetLogsByID", payment_id],
    async () => {
      const response :any= await apiCaller.get(`${club_id}/payments/logs/${payment_id}`); 
             return response.data.data;
    },
    { staleTime: 1000 * 60 * 5, retry: false, enabled: false, }
  );
};
