import React, {useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import { RxCross2 } from "react-icons/rx";
import CustomCalenderTime from "./CustomCalenderTime";
import { IoIosArrowDown } from "react-icons/io";
import formatResponse from "src/constants/FormatResponse";
import Cleave from "cleave.js/react";
import MyDropdown from "../../components/Payment/LocationSelectCheckBox";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ConditionalDaysRow = ({
  conditionData,
  handleUpdateForm,
  setValue,
  index,
  remove,
  customer_type,
  selectedlocation,
  setServicesOpen
}: {
  index: any;
  conditionData: any;
  handleUpdateForm: any;
  setValue: any;
  remove: any;
  customer_type:any;
  selectedlocation:{ id:number, location_name:string, currency:string },
  setServicesOpen:any
}) => {
  const {t} = useTranslation();
  const [CalendarShow, setCalendarShow] = useState(false);
  const [selectedcustomerType,setselectedcustomerType]=useState<any[]>([]);

  const handleClose = () => {
    setCalendarShow(false);
  };


  useEffect(() => {
    setValue(`Rows.${index}.memberships`,selectedcustomerType);
  }, [selectedcustomerType])

  
  const leftborderInput = {
    width: "190px",
    height: "28px",
    opacity: "0.9",
    padding: "5px 5px 5px 0px",
    fontSize: "14px",
    border: "1px solid #EEEFEE",
    borderRadius: "7px",
    color: "#22356D",
  };

  return (
    <Grid  sx={{ position:'relative',margin: "2px 0px",width:'100%',display:'flex',flexDirection:{xs:"column", sm:"row"},justifyContent:'space-between',alignItems:{xs:"baseline", sm:'center'},marginLeft:{xs:'15px',md:'0px'}}}>
      <Typography sx={{position:'absolute',left:'-15px',top:'3px', fontSize: "14px",
              fontFamily: "var(--font-regular)",color:'#22356D',display:{xs:'block',md:'none'}}}>{index+1}.</Typography>
      <Grid sx={{display:'flex', flexDirection:{xs:"column", sm:"row"}, alignItems:{xs:"baseline", sm:'center'}, gap:{xs:"4px", sm:"10px"}}}>
      
        <p
          className="flex items-center justify-between"
          onClick={() => setCalendarShow(true)}
          style={leftborderInput}
        >
          <input
            style={{
              width: "100%",
              fontSize: "14px",
              fontFamily: "var(--font-regular)",
            }}
            placeholder={t('common.Select day and time')}
            value={formatResponse([conditionData[index]],t)==="Select day and time"?t('common.Select day and time'):formatResponse([conditionData[index]],t)}
          />
          <IoIosArrowDown fontSize={"16px"} opacity={0.7} />
        </p>
        <MyDropdown isMembership={true} getOpeon={setServicesOpen} width={"200px"} border={true}  text="Membership Type" arr={customer_type}  selected={conditionData[index]?.memberships} setSelected={setselectedcustomerType}/>
      </Grid>
      <Grid className="flex flex-row items-center gap-1">
        <p
          style={{
            color: "rgba(34, 53, 109, 0.70)",
            fontFamily: "var(--font-regular)",
            fontSize: "14px",
            lineHeight: "17px",
          }}
        >
          {t('common.Price')}
        </p>
        <Cleave
          style={{
            width: "100px",
            color: "rgba(34, 53, 109)",
            fontFamily: "var(--font-regular)",
            fontSize: "14px",
            lineHeight: "17px",
            borderRadius: "7px",
            padding: "5px 8px",
            border: "1px solid #EEEFEE",
          }}
          onChange={(event: any) =>
            setValue(`Rows.${index}.price`, event.target.rawValue)
          }
          value={conditionData[index]?.price}
          placeholder="100"
          options={{
            numeral: true,
            numeralThousandsGroupStyle: "thousand",
            delimiter:selectedlocation.currency === 'IDR' ?'\u2024':','
          }}
        />
        <span style={{color:"#22356D",fontFamily:"var(--font-regular)", fontSize:"0.85rem"}}>{selectedlocation.currency}</span>
        <Typography sx={{color:"rgba(34, 53, 109, 1)",cursor:'pointer',display:{xs:'none',md:'block'}}}> <RxCross2 onClick={()=>remove(index)}/></Typography>
      </Grid>
      <Typography sx={{position:'absolute',top:'50%',right:'10px',color:"rgba(34, 53, 109, 1)",cursor:'pointer',display:{xs:'block',md:'none'}}}> <RxCross2 onClick={()=>remove(index)}/></Typography>
      <Dialog hideBackdrop={true} onClose={handleClose} open={CalendarShow}>
        <DialogContent>
          <CustomCalenderTime
          handleClose={handleClose}
            index={index}
            preTime={conditionData[index]}
            setGetDateTime={(e: any) => handleUpdateForm(e, index)}
            setShowCalendar={setCalendarShow}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};
export default ConditionalDaysRow;
