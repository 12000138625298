import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Calendar, Views, DateLocalizer, momentLocalizer } from 'react-big-calendar';
import { getDateAtSpecificTime } from 'src/constants/MomentTimeFor';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import BookedSlotPopover from './BookedSlotPopover';
import Typography from '@mui/material/Typography';
import MainContext from 'src/context/MainContext';
import { SlArrowLeft } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment'; 
import { calculateTotalHours } from 'src/constants/variables';
import { useTranslation } from 'react-i18next';


export function roundTimeToNextHour(starttime: any, endtime: any) {
  let start_time = moment(starttime, 'HH:mm:ss');
  let momentTime = moment(endtime, "HH:mm:ss");

  // If endtime is already "24:00:00" and starttime is "05:30:00", adjust starttime
  if (momentTime.format("HH:mm:ss") === "00:00:00" && start_time.minutes() === 30) {
    start_time = moment(start_time).subtract(30, 'minutes'); // Adjust start_time to "05:00:00"
  }

  // Round end time to the next hour if necessary
  if (momentTime.minutes() === 30 && start_time.minutes() !== 30) {
    momentTime.add(30, 'minutes');
  } else if (momentTime.minutes() !== 30 && momentTime.format("HH:mm:ss") !== "00:00:00" && start_time.minutes() === 30) {
    momentTime.add(30, 'minutes');
  }


  return {
    starttime: start_time.format("HH:mm:ss"),
    endtime: momentTime.format("HH:mm:ss")==="00:00:00"? "23:59:59":momentTime.format("HH:mm:ss")
  };
}

const localizer = momentLocalizer(moment);
const switchBUtton = {cursor:"pointer",background:"white", boxShadow:{xs:"0px 4px 4px 0px #0000000D inset", sm:"none"}, borderRadius:"7px", padding:{xs:"5px", sm:"0px"}, width:{xs:"70px", sm:"auto"}, height:{xs:"36px", sm:"auto"}}

let formats = {
  timeGutterFormat: 'HH:mm',
}

const arrows = {
  position:"absolute",
  top:"55%", 
  zIndex:"99",
  cursor:"pointer",
  height:"60px",
  display:{xs:"block", md:"none"},
  background:"white", 
  borderRadius:"4px", 
  padding:"21px 2.2px", 
  border:"1px solid #EEEFEE", 
  color:"#22356D", 
  boxSizing:"border-box",
  boxShadow: "4px 4px 4px 0px #0000000D"

}

const font1 = {fontFamily:'var(--font-medium)', fontSize:"0.9rem"};

export function isWithin30Minutes(givenTime:any, referenceTime:any) {
  let givenDate :any= new Date(`1970-01-01T${givenTime}:00`);
  let referenceDate :any= new Date(`1970-01-01T${referenceTime}:00`);
  let diffInMinutes = Math.abs((givenDate - referenceDate) / 1000 / 60);
  return diffInMinutes <= 15;
}

export default function CourtCalendar({event,EventLoading,setSelectView, setClickEvent, handleUpdate, setOpen, setClickCourt, setClickStart, setClickEnd, courtList}:{event:any, setClickEvent:any, handleUpdate:any, setOpen: any, setSelectView:any,setClickCourt:any, setClickStart: any, setClickEnd: any, courtList: any,EventLoading:any}) {

  const date = new Date();
  const theme = useTheme();
  const updatedData:any = {};
  const service_id:number[] = [];
  const context = useContext(MainContext);
  const setEvent = context?.setEvent;
  const totalCourts = courtList.length;
  const selectedDate = context.selectedDate;
  const SelectedLocation = context?.SelectedLocation;
  const [viewPort, setViewPort] = useState("Laptop");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [visibleCourts, setVisibleCourts] = useState([0, 10]); 
  const [filteredArray, setFilteredArray] = useState<any>([]);
  const [minTime, setMinTime] = useState<any>(moment().hour(7).minute(0).second(0).millisecond(0).toDate());
  const [maxTime,setMaxtime] = useState<any>("22:00:00")
  const [TotalHeight,setTotalHeight] = useState(900);
  const [currentTime, setCurrentTime] = useState(moment().format('HH:mm'));
  const {t} =useTranslation();
  
  // --- getting total player / maximum capapcity is_wating
  event.map((item:any)=>{

    if (service_id.includes(item?.service?.id)){
      updatedData[item?.service_id] = {
        ...updatedData[item?.service_id],
        value:updatedData[item?.service_id]?.value + item?.service[item?.service?.service_type.toLowerCase()]?.maximum_capacity
      }
    }else{
      service_id.push(item?.service_id);
      updatedData[item?.service_id] = {
        value: item?.service[item?.service?.service_type.toLowerCase()]?.maximum_capacity,
        players: item?.players?.filter((item:any)=>!item.is_wating && !item.is_canceled && !item.is_show).length
      }
    }
  })  

  const ShowRecurring = () =>{  
    return (
      <Box sx={{width:'12.5px', height:'12.5px', borderRadius:'17px', background:"#889AB6", fontSize:"9px",lineHeight:"10px", paddingBlock:'2.5px', textAlign:'center', fontWeight:'500'}}>R</Box>
    )
  }

  const ShowAvailablity = () =>{  
    return (
      <Box sx={{width:'12.5px', height:'12.5px', borderRadius:'17px', background:"#455585", fontSize:"9px",lineHeight:"10px", paddingBlock:'2.5px', textAlign:'center', fontWeight:'500'}}>A</Box>
    )
  }

  const CustomEvent = ({ event }:{event:any}) => {
    const [showPopHover, setPopHover] = useState(null);
    const [anchorE1, setAnchorE1] = React.useState<HTMLElement | null>(null);
    const [hoveredCourt,setHoveredCourt] = useState<any>();

    const handleTouchStart = (events: React.TouchEvent<HTMLDivElement>) => {
      events.stopPropagation();
      const filteredCourt = courtList?.filter((item:any)=>item?.id===event?.court_id);
      setHoveredCourt(filteredCourt[0]?.courts_timing[0])
      setPopHover(event);
      setAnchorE1(events.currentTarget);
    };

    const handleMouseEnter = (events: React.MouseEvent<HTMLElement>) =>{
      const filteredCourt = courtList?.filter((item:any)=>item?.id===event?.court_id);
      setHoveredCourt(filteredCourt[0]?.courts_timing[0])
        setPopHover(event);
      setAnchorE1(events.currentTarget);
    }
  
    const handlePopClose = () => {
      setAnchorE1(null);
      setPopHover(null);
    } 

    const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
      setAnchorE1(null);
      setPopHover(null);
    };
 
    function isThirtyMinutes(start_time: Date, end_time: Date): boolean {
      const startTime = moment(start_time);
      const endTime = moment(end_time);
      
      const duration = moment.duration(endTime.diff(startTime));
      
      if(duration.asMinutes() === 60){
        return true;
      }else if(duration.asMinutes() === 30){
        return true;
      }else{
        return false;
      }
    }
    const totaluser = event?.players?.filter((item:any)=>!item?.is_canceled);
    let unwaiting = []; 
    if(totaluser){
       unwaiting = totaluser?.filter((item:any)=>!item.is_wating && !item.is_canceled);
    } 


    const ENDTIME = moment(event.end_time).format("HH:mm")==="23:59"?"24:00":moment(event.end_time).format("HH:mm");

    if(event.service?.service_type==='Event'){
    
      return (
        <Box onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onMouseEnter={handleMouseEnter} onMouseLeave={handlePopClose} position={'relative'} height={'100%'} sx={{">p":isThirtyMinutes(event.start_time, event.end_time) ? {fontSize:  "0.73rem !important", lineHeight:"13px"} : '',border:'#7E93FF 1px'}}>
          {!isThirtyMinutes(event.start_time, event.end_time) && <Typography sx={{fontFamily:'var(--font-regular)', fontSize:"0.8rem", color:'#22356D',lineHeight:'20px'}}>{moment(event.start_time).format('HH:mm')} - {ENDTIME}</Typography>}
          <Typography sx={{...font1, color:'#F07727'}}>{event.service?.additional_service ? event.service?.additional_service : event?.service?.event?.event_name}</Typography>
          <Typography sx={{...font1,fontSize:"0.8rem", color:'#F07727',lineHeight:'10px'}}>{event?.service?.coaches[0]?.full_name}{event?.service?.coaches?.length>1 ? ` + ${event?.service?.coaches?.length-1}` : ""}</Typography>
          
          <Box position={'absolute'} bottom={'9px'} right={'9px'} sx={{
            background:`${unwaiting?.length == updatedData[event.service_id].value ? 'transparent' : '#F07727'}`,
            color:`${unwaiting?.length==updatedData[event.service_id].value ? '#F07727' : 'white'}`,
            borderRadius:'7px', width:'fit-content', height:'14px', fontSize:'11px',padding:'2px 7px', textAlign:'center'}}>
            {unwaiting?.length}{event?.service?.additional_service || !event?.service?.event ? "" : `/${updatedData[event.service_id].value}`}
          </Box>
          <Box sx={{position:"absolute", width:"93%", top:"5px", display:"flex", justifyContent:"end", gap:"5px"}}> 
            {event?.service?.event?.app_available && ShowAvailablity()}
            {event.service?.recurring && ShowRecurring()}
          </Box>
          <BookedSlotPopover SelectedLocation={SelectedLocation} viewType='daily' eventType={showPopHover} anchorEl={anchorE1} handlePopoverClose={handlePopClose} players={updatedData[event?.service_id].players} total={updatedData[event.service_id].value} />
        </Box>  
      )
    }else if(event.service?.service_type==="Booking"){
      
      let arr = event?.players.filter((player:any)=>!player.is_canceled);
  
      return (
        <Box onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onMouseEnter={handleMouseEnter} onMouseLeave={handlePopClose} height={'100%'} sx={{">p":isThirtyMinutes(event.start_time, event.end_time) ? {fontSize:  "0.73rem !important", lineHeight:"13px"} : ''}}>
          <Typography sx={{fontFamily:'var(--font-regular)', fontSize:"0.8rem", color:'#22356D',lineHeight:'20px'}}>{moment(event.start_time).format('HH:mm')} - {ENDTIME}</Typography>
          <Typography sx={{...font1, color:'#7E93FF',lineHeight:'10px'}}>
          {event.service?.booking_type==="Open Match" ? `Open Match ` : event.service?.additional_service ? event.service?.additional_service : arr?.length>0 ? `${arr[0]?.customer?.first_name} ${arr[0]?.customer?.last_name}` : "Booking"}
          </Typography>
         {unwaiting?.length!==updatedData[event.service_id]?.value && !event?.is_cancelled && event.service?.booking_type==="Open Match" && <span style={{fontFamily:'var(--font-regular)',height:'13px',fontSize:'12px',background:'#EAF956',padding:'1px 10px',color:'#7E93FF',borderRadius:'9px',border:'0.8px solid #d3e04f',position:'absolute',bottom:3}}>{t('common.Unfilled')}</span>}
          <Box position={'absolute'} bottom={'5px'} right={'5px'} sx={{
            background:event.service?.booking_type==="Open Match"?`${unwaiting?.length==updatedData[event.service_id].value ? 'transparent' : '#EAF956'}`:`${unwaiting?.length==updatedData[event.service_id].value ? 'transparent' : '#7E93FF'}`,
            color:event.service?.booking_type==="Open Match"?`${unwaiting?.length==updatedData[event.service_id].value ? '#7E93FF' : '#7E93FF'}`:`${unwaiting?.length==updatedData[event.service_id].value ? '#7E93FF' : '#ffff'}`,
            borderRadius:'7px', width:'fit-content', height:'12px', fontSize:'10px',padding:'2px 7px',border:event.service?.booking_type==="Open Match"?`${unwaiting?.length==updatedData[event.service_id].value?'':'0.8px solid #d3e04f'}`:'', textAlign:'center'}}>{unwaiting?.length}{event?.service?.additional_service || !event?.service?.booking ? "" : `/${updatedData[event.service_id].value}`}</Box>
          {/* recurring */}
          <Box sx={{position:"absolute", width:"93%", top:"5px", display:"flex", justifyContent:"end", gap:"5px"}}>
            {event?.by_app && ShowAvailablity()}
            {event.service?.recurring && ShowRecurring()}
          </Box>
        
          <BookedSlotPopover SelectedLocation={SelectedLocation} viewType='daily' players={updatedData[event.service_id].players} total={updatedData[event.service_id].value} eventType={showPopHover} anchorEl={anchorE1} handlePopoverClose={handlePopClose} />
        </Box>
      )
    }else if(event.service?.service_type==="Lesson"){
      return (
        <Box onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onMouseEnter={handleMouseEnter} onMouseLeave={handlePopClose} height={'100%'} sx={{">p":isThirtyMinutes(event.start_time, event.end_time) ? {fontSize:  "0.73rem !important", lineHeight:"13px"} : ''}}>
          {!isThirtyMinutes(event.start_time, event.end_time) &&<Typography sx={{fontFamily:'var(--font-regular)', fontSize:"14px", color:'#22356D',lineHeight:'20px'}}>{moment(event.start_time).format('HH:mm')} - {ENDTIME}</Typography>}
          
          <Typography sx={{...font1,fontSize:"0.8rem", color:'#A3A434'}}>{event?.service?.coaches[0]?.full_name}{event?.service?.coaches?.length>1 ? ` + ${event?.service?.coaches?.length-1}` : ""}</Typography>
          <Typography sx={{...font1, color:'#A3A434', textTransform:'capitalize',lineHeight:'10px'}}>{event.service?.additional_service ? event.service?.additional_service : event.service?.lesson?.lesson_name}</Typography>
          <Box position={'absolute'} bottom={'5px'} right={'5px'} sx={{
            background:`${unwaiting?.length==updatedData[event.service_id].value ? 'transparent' : '#A3A434'}`,
            color:`${unwaiting?.length==updatedData[event.service_id].value ? '#A3A434' : 'white'}`,
            borderRadius:'7px', width:'fit-content', height:'12px', fontSize:'10px',padding:'2px 7px', textAlign:'center'}}>{unwaiting?.length}{event?.service?.additional_service || !event?.service?.lesson ? "" : `/${updatedData[event.service_id].value}`}</Box>
          {/* ------ showing recurring ------ */}
          <Box sx={{position:"absolute", width:"93%", top:"5px", display:"flex", justifyContent:"end", gap:"5px"}}>
            {event?.service?.lesson?.app_available && ShowAvailablity()}
            {event.service?.recurring && ShowRecurring()}
          </Box>
          <BookedSlotPopover SelectedLocation={SelectedLocation} viewType='daily' eventType={showPopHover} anchorEl={anchorE1} handlePopoverClose={handlePopClose} />
        </Box>
      )
    }else if(event.service?.service_type==="Block"){
      return (
        <>
          <Box onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onMouseEnter={handleMouseEnter} onMouseLeave={handlePopClose} height={'100%'} sx={{">p":isThirtyMinutes(event.start_time, event.end_time) ? {fontSize:  "0.73rem !important", lineHeight:"13px"} : ''}}>
            <Typography sx={{fontFamily:'var(--font-regular)', fontSize:"0.8rem", color:'#889AB6CC'}}>{moment(event.start_time).format('HH:mm')} - {ENDTIME}</Typography>
            <Typography sx={{fontFamily:'var(--font-regular)', fontSize:"0.8rem", color:'#889AB6CC', textAlign:"center"}}>Blocked - {event?.service?.block_reason}</Typography>
          </Box>
          <BookedSlotPopover SelectedLocation={SelectedLocation} viewType='daily' eventType={showPopHover} anchorEl={anchorE1} handlePopoverClose={handlePopClose} />
        </>
      )
    }else if(event?.is_cancelled){
      return(
        <Box onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onMouseEnter={handleMouseEnter} onMouseLeave={handlePopClose} sx={{ pointerEvents: "all", cursor: "pointer"}} className="default-hover" width={"10px"} height={'100%'}>
          <BookedSlotPopover SelectedLocation={SelectedLocation} viewType='daily' eventType={showPopHover} anchorEl={anchorE1} handlePopoverClose={handlePopClose} />
        </Box>
      )
    }else{
      return (
        <>
          <Box onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onMouseEnter={handleMouseEnter} onMouseLeave={handlePopClose} sx={{ pointerEvents: "all", cursor: "pointer"}} height={'100%'}>
          </Box>
          <BookedSlotPopover SelectedLocation={SelectedLocation} viewType='daily' courtTiming={hoveredCourt} eventType={showPopHover} anchorEl={anchorE1} handlePopoverClose={handlePopClose} />
        </>
      )
    }
  }

  const handleClickOpen = (newEvent:any) => {
    const { start, end, resourceId } = newEvent;
    const start1 = moment(start).format('HH:mm');
    const end1 = moment(end).format("HH:mm");
    setClickCourt([resourceId]);
    setClickStart(start1);
    setClickEnd(end1);
    setClickEvent({});
    setOpen(true);
    // let background = checkEventOverlap(start, end, resourceId);
    // if(background){
    //   setOpen(false);
    // }else{
    //   setOpen(true);
    // }
    handleUpdate();
    setEvent({});
  };

  // checking if clicked on background event
  function checkEventOverlap(startTime:any, endTime:any, id:number) {
    return filteredArray.some((event:any) => {
      const eventStart = new Date(event.start_time);
      const eventEnd = new Date(event.end_time);
      const inputStart = new Date(startTime);
      const inputEnd = new Date(endTime);
      const isTimeOverlap = (inputStart > eventStart && inputStart < eventEnd) ||
                            (inputEnd > eventStart && inputEnd < eventEnd) ||
                            (inputStart <= eventStart && inputEnd >= eventEnd);

      return isTimeOverlap && event.court_id === id;
    });
  }

  const eventPropGetter = useCallback(
    (event:any, start:any, end:any) => {

      // const durationInHours = moment(end).diff(moment(start), 'hours');

      const isAnyPlayerShown = event?.players?.some((item:any) => item?.is_show);
      const pendingPayment:any = event?.players?.filter((item:any)=>!item?.is_show && !item?.is_canceled)?.some((item:any) => item?.status ==="Pending payment");
      const unfilledOpenMatch = event?.service?.booking_type==="Open Match";

      return {
        ...(event.service?.service_type.includes('Booking') && {
          // className: event.notPlay === 1 ? 'two-border' : 'court-border',
          className: isAnyPlayerShown && pendingPayment && unfilledOpenMatch?'three-border' : isAnyPlayerShown && pendingPayment ? "twos-border" : pendingPayment && unfilledOpenMatch ? "two-border" : isAnyPlayerShown ? "purple-border" : pendingPayment ? 'red-border' : unfilledOpenMatch? 'yellow-border' :'court-border',
          style: {
            backgroundColor: '#EBECFD99', // blue
            right:0,
            border:'#7E93FF 0.5px solid',
            borderLeft:isAnyPlayerShown || pendingPayment || unfilledOpenMatch?'none':'#7E93FF 0.5px solid'
          },
        }),
        ...(event.service?.service_type.includes('Event') && {
          className: isAnyPlayerShown && pendingPayment ? "twos-border": isAnyPlayerShown ? "purple-border" : pendingPayment ? 'red-border' :'court-border',
          style: {
            backgroundColor: '#FDF0E699', // orange
            border:'#F07727 solid 0.5px',
            borderLeft:isAnyPlayerShown || pendingPayment || unfilledOpenMatch?'none':'#F07727 solid 0.5px'
          },
        }),
        ...(event.service?.service_type.includes('Lesson') && {
          className: isAnyPlayerShown && pendingPayment ? "twos-border" : isAnyPlayerShown ? "purple-border" : pendingPayment ? 'red-border' :'court-border',
          style:{
            backgroundColor:'#FFFDD199', // yellow
            border:'#A3A434 solid 0.5px',
            borderLeft:isAnyPlayerShown || pendingPayment || unfilledOpenMatch ?'none':'#A3A434 solid 0.5px'
          }
        }),
        ...(event?.is_cancelled && event.service?.service_type.includes('Event') && {
          className: 'cancel eventCan',
          style:{
            borderRadius: '7px !important',
          },
        }),
        ...(event?.is_cancelled && event.service?.service_type.includes('Lesson')  && {
          className: 'cancel lessCan',
          style:{
            borderRadius: '7px !important',
          },
        }),
        ...(event?.is_cancelled && event.service?.service_type.includes('Booking')  && {
          className: 'cancel bookCan',
          style:{
            borderRadius: '7px !important',
          },
        }),
        ...(event.service?.service_type.includes('Block') && {
          className: 'blocked',
          style:{
            borderRadius: '7px !important',
            border:'rgba(136, 154, 182, 0.80) 0.5px solid'
          },
        }),
        ...(event?.type?.includes('working_hours') && {
          className: 'special',
        }),
        ...(event?.type?.includes("break_hours") && {
          className:"special",
        }),
        ...(event?.type?.includes("special_days") && {
          className:"special",
        })
      }
    },
    []
  ) 

  const handleEvent = (events: any) =>{
    setClickCourt([events?.court_id])
    setClickEvent(events);
    setOpen(true); 
  }

  const handleNext = () => {
    if(viewPort === "Mobile"){
      const newStart = visibleCourts[1] + 1;
      const newEnd = Math.min(visibleCourts[1] + 1, totalCourts - 1);
      if (newStart <= totalCourts - 1) {
        setVisibleCourts([newStart, newEnd]);
      }
    }else if(viewPort === "Tablet"){
      const newStart = visibleCourts[1] + 1;
      const newEnd = Math.min(visibleCourts[1] + 4, totalCourts - 1);
      if (newStart <= totalCourts - 1) {
        setVisibleCourts([newStart, newEnd]);
      }
    }else{
      const newStart = visibleCourts[1] + 1;
      const newEnd = Math.min(newStart + 10, totalCourts - 1);
      if (newStart <= totalCourts - 1) {
        setVisibleCourts([newStart, newEnd]);
      }
    }
  };
   
  const handlePrev = () => {
    if(viewPort === "Mobile"){
      const newEnd = visibleCourts[0] - 1;
      const newStart = Math.max(visibleCourts[0] - 1, 0);
      if (newEnd >= 0) {
        setVisibleCourts([newStart, newEnd]);
      }
    }else if (viewPort === "Tablet"){
      const newEnd = visibleCourts[0] - 1;
      const newStart = Math.max(visibleCourts[0] - 4, 0);
      if (newEnd >= 0) {
        setVisibleCourts([newStart, newEnd]);
      }
    }else{
      const newEnd = visibleCourts[0] - 1;
      const newStart = Math.max(newEnd - 10, 0);
      if (newEnd >= 0) {
        setVisibleCourts([newStart, newEnd]);
      }
    }
  };


  function getEarliestStartTimeForToday(courts:any) {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const today = new Date().getDay();
    const todayName = daysOfWeek[today];

    let earliestTime:any = null;
    let earliestObject:any = null;

    courts.forEach((court:any) => {
      const todayTiming = court.courts_timing.find((timing:any) => timing.days === todayName);
      if (todayTiming) {
        if (!earliestTime || todayTiming.start_time < earliestTime) {
          earliestTime = todayTiming.start_time;
          earliestObject = court;
        }
      }
    });
    let currentDate = moment(selectedDate).toDate();
    if(earliestTime){
      let value = getDateAtSpecificTime(currentDate, earliestTime);
      // setMinTime(value);
      return value;
    }else{
      return minTime;
    }
  }

  // Function to compare and adjust timings about background events
  useEffect(() => {
    if (courtList.length !== 0 && selectedDate) {
      setFilteredArray([]);
      let time = getEarliestStartTimeForToday(courtList);
  
      // Finding the latest end time
      let latestEndTime = "22:00:00";
      courtList.forEach((court: any) => {
        court.courts_timing.forEach((timing: any) => {
          if (new Date(timing?.date_start) <= new Date(selectedDate) && new Date(timing?.date_end) >= new Date(selectedDate)) {
            if (timing.end_time > latestEndTime) {
              latestEndTime = timing.end_time;
            }
          }else if(!timing.special){
            if (timing.end_time > latestEndTime) {
              latestEndTime = timing.end_time;
            }
          }
        });
      });
  
      // Finding the latest start time
      let latestStartTime: any = "07:00:00";
      courtList.forEach((court: any) => {
        court.courts_timing.forEach((timing: any) => {
          if (new Date(timing?.date_start) <= new Date(selectedDate) && new Date(timing?.date_end) >= new Date(selectedDate)) {
            if (timing.start_time < latestStartTime) {
              latestStartTime = timing.start_time;
            }
          }else if(!timing.special){
            if (timing.start_time < latestStartTime) {
              latestStartTime = timing.start_time;
            }
          }
        });
      });

      const {endtime:steptimeme,starttime}:any = roundTimeToNextHour(latestStartTime,latestEndTime);
      setMaxtime(steptimeme);
      const startTimeDate = moment().hour(starttime?.slice(0, 2)).minute(starttime?.slice(3, 5)).second(0).millisecond(0).toDate();
      setMinTime(startTimeDate);
     
  
      const adjustTimings = (court: any) => {
        const dateStr = moment(selectedDate).format("YYYY-MM-DD");
        const { id: court_id, courts_timing, court_name } = court;
        const sTimeMoment = moment(`${dateStr} ${starttime}`, 'YYYY-MM-DD HH:mm');
        const eTimeMoment = moment(`${dateStr} ${steptimeme}`, 'YYYY-MM-DD HH:mm:ss');
        const currentDay = moment(selectedDate).format("dddd");
  
        // Check if today is a special day
        let specialDay = false;
        let specialStartTime:any;
        let specialEndTime:any;

        if(!specialDay){
          const workingDays = courts_timing.filter((entry:any) => !entry.special).map((entry:any) => entry.days);
          const allDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
          const nonWorkingDays = allDays?.filter(day => !workingDays?.includes(day))?.filter((day:any)=>moment(selectedDate).format('dddd')===day);

          nonWorkingDays?.map((item:any)=>{
            let dateString = moment(selectedDate).format('DD-MMMM-YYYY')
              setFilteredArray((prevArray:any) => [
                ...prevArray,
                {
                  day:item, 
                  type: "working_hours",
                  start_time: moment(`${dateString} ${latestStartTime}`).toDate(),
                  end_time: moment(`${dateString} ${steptimeme}`, "DD-MMMM-YYYY HH:mm:ss").toDate(),
                  court_name,
                  court_id
                },
              ]);
          })
        }
  
        courts_timing.forEach(({ start_time, end_time, date_start, date_end, special ,break_times}: any) => {
          if (special) {
            const startday = moment(date_start).format("YYYY-MM-DD");
            const endday = moment(date_end).format("YYYY-MM-DD");
            const selected = moment(selectedDate).format("YYYY-MM-DD");
  
            if (selected >= startday && selected <= endday && special) {
              specialDay = true;
              specialStartTime = start_time;
              specialEndTime = end_time;
              break_times.forEach(({ start_break, end_break }: any) => {
                const startBreakTime = moment(`${dateStr} ${start_break}`, "YYYY-MM-DD HH:mm:ss");
                if (startBreakTime.isAfter(sTimeMoment)) {
                  setFilteredArray((prev: any) => [
                    ...prev, {
                      type: "break_hours",
                      start_time: startBreakTime.toDate(),
                      end_time: moment(`${dateStr} ${end_break}`, "YYYY-MM-DD HH:mm:ss").toDate(),
                      court_id,
                      court_name
                    }
                  ]);
                }
              });

            }
          }
        });


  
        courts_timing.forEach(({ start_time, end_time, days, break_times,special }: any) => {
          let startTime = specialDay ? specialStartTime : start_time;
          let endTime = specialDay ? specialEndTime : end_time;


         
  
          const startTimeMoment = moment(`${dateStr} ${startTime}`, "YYYY-MM-DD HH:mm:ss");
          const endTimeMoment = moment(`${dateStr} ${endTime}`, "YYYY-MM-DD HH:mm:ss");
  
          if (days === currentDay) {
            if (startTimeMoment.isAfter(sTimeMoment) && latestEndTime !== startTime) {
              setFilteredArray((prevArray: any) => [
                ...prevArray,
                {
                  type: "working_hours",
                  start_time: moment(sTimeMoment).toDate(),
                  end_time: startTimeMoment.toDate(),
                  court_id,
                  court_name
                },
              ]);
            }
            if (endTimeMoment.isBefore(eTimeMoment)) {
              setFilteredArray((prevArray: any) => [
                ...prevArray,
                {
                  type: "working_hours",
                  start_time: endTimeMoment.toDate(),
                  end_time: eTimeMoment.toDate(),
                  court_id,
                  court_name
                },
              ]);
            }
            if (break_times.length > 0 && !specialDay) {
              break_times.forEach(({ start_break, end_break }: any) => {
                const startBreakTime = moment(`${dateStr} ${start_break}`, "YYYY-MM-DD HH:mm:ss");
                if (startBreakTime.isAfter(sTimeMoment)) {
                  setFilteredArray((prev: any) => [
                    ...prev, {
                      type: "break_hours",
                      start_time: startBreakTime.toDate(),
                      end_time: moment(`${dateStr} ${end_break}`, "YYYY-MM-DD HH:mm:ss").toDate(),
                      court_id,
                      court_name
                    }
                  ]);
                }
              });
            }
          }
        });
      };
  
      courtList.forEach(adjustTimings);
    }
  }, [courtList, selectedDate]);
  
 
    // for showing the active line only if current day
    const elements = document.querySelectorAll('.rbc-current-time-indicator');
    const label = document.querySelectorAll('.rbc-label');

    useEffect(() => {
      const today = new Date()
      const isToday = moment(selectedDate).format('DD/MM/YY')===moment(today).format('DD/MM/YY');
      if(elements?.length>0 && !isToday){
        elements.forEach(function(element) {
          element.classList.add('hiddencalRow');
        });  
      }else{
      
        elements.forEach(function(element) {
          element.classList.remove('hiddencalRow');
        }); 
      }  
    }, [selectedDate,elements]);

    // updating the current time
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentTime((moment().format('HH:mm')));
      }, 1000);

      return () => clearInterval(intervalId);
    }, []);



    useEffect(() => {
      const min = moment(minTime).format("HH:mm:ss")
      const hours =  calculateTotalHours(min,maxTime);
      const height:any= (hours * 2)*27
      setTotalHeight(height);

    }, [minTime,maxTime])
    
    
    // showing the current time through JS
    useEffect(() => {
      const updateDisplayTime = () => {
        const firstDiv = document.querySelector('.rbc-current-time-indicator');
        const existingDisplayTime = document.getElementById('display-time');
        if (firstDiv !== null) {
          if (existingDisplayTime) {
            existingDisplayTime.textContent = currentTime;
          } else {
            const newDiv = document.createElement('div');
            newDiv.textContent = currentTime;
            newDiv.id = 'display-time';
            firstDiv.appendChild(newDiv);
          }
        }
      };
     
      const today = new Date()
    const isToday = moment(selectedDate).format('DD/MM/YY')===moment(today).format('DD/MM/YY');
    if(isToday){
      label.forEach(function(element:any) {
        if(isWithin30Minutes(element.innerText,currentTime)){
          element.style.display="none";
        }else{
          element.style.display="block";
        }
      });
    }else{
      label.forEach(function(element:any) {
        if(isWithin30Minutes(element.innerText,currentTime)){
          element.style.display="block";
        }
        });
    }
     

      // Delay the update until after the initial render
      const timeoutId = setTimeout(updateDisplayTime, 1000);

      return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
    }, [currentTime,label]);

    useEffect(() => {
      const handleResize = () => {
        if (window.matchMedia('(max-width: 600px)').matches ) {
          setViewPort("Mobile");
          setVisibleCourts([0, 0]);
        } else if (window.matchMedia('(max-width: 850px)').matches) {
          setViewPort("Tablet");
          setVisibleCourts([0, 3]);
        } else {
          setViewPort("Laptop");
          setVisibleCourts([0, 10]);
        }
      };

      handleResize();

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

   
    let dates = moment(`${moment(selectedDate).format("YYYY-MM-DD")}`, "YYYY-MM-DD").toDate();

    return (
      <Fragment>
        <div style={{width:"100%", position:"relative", marginBottom:"20px"} as React.CSSProperties}>
          {courtList?.length>(isMobile ? 0 : 10) && visibleCourts[0]!==0 && 
          <Box position={'absolute'} sx={{...switchBUtton, zIndex:"99", cursor:"pointer", left:{xs:"2%", sm:"4%"}, top:"1.5%"}} onClick={handlePrev}>
            <SlArrowLeft style={{color:"#22356D"}} className="switch-icon" />
          </Box>}

          {courtList?.length>(isMobile ? 0 : 10) && visibleCourts[1]!== totalCourts -1 && 
          <Box position={'absolute'} right={"2%"} sx={{...switchBUtton, zIndex:"99", top:{xs:"1.4%", sm:"1.4%"}}} onClick={handleNext}>
            <SlArrowRight style={{color:"#22356D"}} className="switch-icon" />
          </Box>}

          {/* arrow for mobile view only */}
          {courtList?.length > 0 && visibleCourts[0]!==0 && <Box sx={{...arrows, zIndex:"99", left:{xs:"-3%", sm:"6%"}}} onClick={handlePrev}><SlArrowLeft style={{color:"#22356D"}} /></Box>}

          {courtList?.length > 0 && visibleCourts[1]!== totalCourts -1 && 
          <Box sx={{...arrows, zIndex:"99", right:{xs:"-3%", sm:"-2%"}}} onClick={handleNext}><SlArrowRight style={{color:"#22356D"}} /></Box>}
          {/* ---- ends here ---- */}
          <Calendar 
            dayLayoutAlgorithm={'no-overlap'}
            backgroundEvents={filteredArray}
            date={dates}
            defaultView={Views.DAY}
            events={event}
            localizer={localizer}
            formats={formats}
            resourceIdAccessor="id"
            resourceAccessor="court_id"
            titleAccessor="court_id"
            startAccessor='start_time'
            endAccessor='end_time'
            min={minTime} 
            max={maxTime=== ("24:00:00"||"00:00:00")?moment().endOf('day').toDate():moment().hour(maxTime?.slice(0,2)).minute(maxTime?.slice(3,5)).second(0).millisecond(0).toDate()}
            resources={courtList.slice(visibleCourts[0], visibleCourts[1] + 1)}
            resourceTitleAccessor={(resource: any) => {            
              return(
                <div onClick={()=>{isMobile?console.log("No week calendar in mobile"):setSelectView(resource)}}>
                  <Typography sx={{fontFamily:"var(--font-medium)", textTransform:"capitalize", fontSize:{xs:"18px", sm:"1rem"}, color:"#22356DB2", paddingRight:{xs:"39px", sm:"0px"}}}>{resource.court_name}</Typography>
                  <Typography sx={{display:"flex",justifyContent:"center",fontFamily:"var(--font-regular)", fontSize:"11px", color:"#22356D99", paddingRight:{xs:"42px", sm:"0px"}, ">span":{display:{xs:"block", sm:"none"}}}}><span>{visibleCourts[1]+1}/{courtList.length} - </span>&nbsp;{resource.sport?.sport_name}</Typography>
                </div>
              )}
            } 
            step={30}
            selectable
            onSelectSlot={handleClickOpen}
            onSelectEvent={handleEvent}
            eventPropGetter={eventPropGetter}
            components={{
              event: (event)=>{
                return CustomEvent(event)
              }
            }}
            views={{ day: true, week: false, month: false }}
            style={{height: TotalHeight}}
          />
        </div>
        <Box sx={{position:'absolute', bottom:'10px', left:"-3px", color:"#22356DB2",display:isWithin30Minutes(moment(maxTime, "HH:mm:ss").format("HH:mm"),currentTime)?'none':'block'}}>{moment(maxTime, "HH:mm:ss").format("HH:mm")}</Box>
          {/*Loading */}
          {EventLoading && (
          <Box
            position={"absolute"}
            top={60}
            left={40}
            sx={{
              width: {xs:"90%",sm:"97.5%"},
              height: {xs:"95%",sm:"99%"},
              zIndex: 9999,
              background: "rgba(0, 0, 0, 0.10)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img style={{borderRadius:'7px',width:'40px',height:'40px'}} src="/images/Loadingic.gif" />
          </Box>
        )}
      </Fragment>
    )
}

CourtCalendar.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
  dayLayoutAlgorithm: PropTypes.string,
}