import { Box, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import CoachCrousel from './CoachCrouse'
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGetWeeklyServices } from 'src/api/CoachCalendar';
import MainContext from 'src/context/MainContext';
import WeekCoachPopover from './WeekCoachPopover';

function getDatesBetweenOptimized(startDate:string, endDate:string) {
    const start = moment(startDate);
    const end = moment(endDate);
    // Calculate total days between start and end dates
    const totalDays = end.diff(start, 'days');
  
    // Generate dates in a single loop
    const dates = Array.from({ length: totalDays + 1 }, (_, i) => 
      start.clone().add(i, 'days')
    );
    return dates;
  }
  

const MobileWeekCalendar = ({coachList,setWeeksGraph,columnsArray,setSelectView,resource,dates,removeResource,selectedWeek,view}:{coachList:any,setWeeksGraph:any,columnsArray:any,setSelectView:any,resource:any,removeResource:any,selectedWeek:any,dates:any,view:any}) => {
    const [AllDates,setAllDates] =useState<any[]>([])
    const {selectedMonth} = useContext(MainContext);
    const [ServiceList,setServiceList] = useState<any[]>([]);
    const {isSuccess,data,isError,setCoachID,setMonthYear,setWeekNumber,refetch} = useGetWeeklyServices();
   

    useEffect(() => {
    const AllSelDate = getDatesBetweenOptimized(dates.startDate,dates.endDate);
    setAllDates(AllSelDate);
    }, [dates])

    useEffect(() => {
        let month_year = moment(selectedMonth).format("MM-YYYY");
        setCoachID(resource.id);
        setMonthYear(month_year);
        setWeekNumber(selectedWeek);
    }, [selectedWeek,resource])
    

    useEffect(() => {
        if(isSuccess){
            const myArray = data?.serviceBookings;
            const newArray = myArray?.map((obj: any) => {
              const hello = obj?.service?.coaches?.map((item: any) => {
                const date = moment(obj?.date).format("DD/MM/YYYY");
                return {
                  ...obj,
                  full_name: item?.full_name,
                  service_id: obj?.id,
                  start_time: moment(
                    `${date} ${obj?.start_time}`,
                    "DD/MM/YYYY HH:mm:ss"
                  ).toDate(),
                  end_time: obj?.end_time ==="24:00:00" || obj?.end_time ==="00:00:00"? moment( `${date} "23:59:00"`,"DD/MM/YYYY HH:mm:ss").toDate() :moment(
                    `${date} ${obj?.end_time}`,
                    "DD/MM/YYYY HH:mm:ss"
                  ).toDate(),
                };
              });
      
              return hello[0];
            });
      
            const NotHidden = newArray?.filter((item: any) => !item?.is_hide && !item?.is_cancelled);
            setWeeksGraph(data?.stats);
            setServiceList(NotHidden || []);
        }
    
    }, [isSuccess,data,isError])
    

  return (
    <Box sx={{mt:'20px'}}>
        <CoachCrousel CoachList={coachList} selectedCoach={resource} SetSelectedCoach={removeResource} />
        <Box sx={{display:'flex',flexDirection:'column'}}>
         {
            AllDates?.map((item:any)=>
            <Box>
           <WeekCoachPopover columnsArray={columnsArray} refetch={refetch} selectedCoach={resource} selectedDate={item} serviceList={ServiceList}  />
            </Box>)
         }
        </Box>
    </Box>
  )
}

export default MobileWeekCalendar