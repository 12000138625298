import Box from "@mui/material/Box";
import React from 'react'
import { useTranslation } from "react-i18next";
import { addThousandSeparator } from "src/constants/SportSEparator";

const EventsRows = ({Data,index,selectedLocation}:{Data:any,index:any,selectedLocation:{id:number, location_name:string, currency:string}}) => {
    const {t} = useTranslation(); 
    const firststyle = {color:'rgba(34, 53, 109,1)',fontFamily:"var(--font-regular)",fontSize:'16px'}
    const secondStyle = {color:'rgba(34, 53, 109,1)',fontFamily:"var(--font-regular)",fontSize:'14px'}


  return (
    <div style={index%2==0 ? {backgroundColor:'rgba(136, 154, 182, 0.10)',padding:'8px 10px'}:{backgroundColor:'transparent',padding:'8px 10px'}} className=''>
     <Box margin={'5px'}>
        <Box display={'flex'} justifyContent={"space-between"}>
            <p style={firststyle}>{Data.event_name}</p>
            <p style={firststyle}>{addThousandSeparator(Data.price, selectedLocation.currency)} p/p</p>
        </Box>
        <Box display={'flex'} justifyContent={"space-between"}>
            <p style={secondStyle}>{Data?.app_available ?<span>{t('services.coach.app available')}{Data.min_level_restrication!==0 && Data.max_level_restrication!==0 &&` | ${Data.min_level_restrication} - ${Data.max_level_restrication}`}</span> :''}</p>
            <p style={secondStyle}>{Data.minimum_capacity}-{Data.maximum_capacity} {t('Calendar.player')}</p>
        </Box>
    </Box>
    </div> 
  )
}

export default EventsRows
