import React, { useEffect, useState } from "react";
import { allTimeFormats } from "src/constants/variables";
import AirDatepicker from "air-datepicker";
import "air-datepicker/air-datepicker.css";
import localeEn from "air-datepicker/locale/en";
import { IoCloseOutline } from "react-icons/io5";
import DayCheckBox from "../../components/Services/DayCheckBox";
import TimeRangePicker from "../../components/CustomDropdownTimeRange";
import { useTranslation } from "react-i18next";

const CustomCalenderTime = ({
  preTime,
  handleClose,
  setShowCalendar,
  setGetDateTime,
  index,
  isCoach
}: {
  preTime: any;
  handleClose:any;
  index?: any;
  setShowCalendar: any;
  setGetDateTime?: any;
  isCoach?:any
}) => {
  const {t} = useTranslation();
  const [time, setTime] = useState<any>(
    preTime === undefined
      ? ""
      : {
          start_time: preTime.start_time,
          end_time: preTime.end_time,
        }
  );

  const [daysChecked, setDaysChecked] = useState<any[]>(
    preTime?.days?.length === undefined ? [] : preTime.days
  );

  const [selectedDATES, setselectedDATES] = useState<any>(
    preTime?.date_start === undefined && preTime?.date_end === undefined
      ? ""
      : {
          date_start: preTime?.date_start,
          date_end: preTime?.date_end,
        }
  );


  const Handle= ()=>{
    const format =
    !!selectedDATES?.date_start &&
    !!selectedDATES?.date_end
      ? [
          {
            id: index,
            start_time: time?.start_time,
            end_time: time?.end_time,
            date_start: selectedDATES.date_start,
            date_end: selectedDATES.date_end,
            customer_type: null,
            price: null,
          },
        ]
      : [
          {
            id: index,
            start_time: time?.start_time,
            end_time: time?.end_time,
            days: daysChecked?.map((day: any) => day),
            customer_type: null,
            price: null,
          },
        ];
        handleClose();

        setGetDateTime(format);

  }

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];


  let isRange = true;
  useEffect(() => {
    new AirDatepicker("#calendar", {
      locale: localeEn,
      range: isRange,
      multipleDatesSeparator: " - ",
      selectedDates: selectedDATES?.date_start !== null &&
        selectedDATES?.date_end !== null &&
        selectedDATES?.date_start !== undefined &&
        selectedDATES?.date_end !== undefined && [
          selectedDATES?.date_start,
          selectedDATES?.date_end,
        ],
      onSelect: ({ date }) => {
        const newSelectedDates = Array.isArray(date) ? date : [date]; // Ensure date is always an array
        if (
          newSelectedDates[0] == undefined &&
          newSelectedDates[1] === undefined
        ) {
          setselectedDATES([]);
        } else if (
          newSelectedDates[0] !== undefined &&
          newSelectedDates[1] === undefined
        ) {
          isRange = false;
          const datesToStore = {
            date_start: newSelectedDates[0],
            date_end: newSelectedDates[0],
          };
          setselectedDATES(datesToStore);
        }else if (
          newSelectedDates[0] !== undefined &&
          newSelectedDates[1] !== undefined
        ) {
          isRange = true;
          const datesToStore = {
            date_start: newSelectedDates[0],
            date_end: newSelectedDates[1],
          };
          setselectedDATES(datesToStore);
        }
      },
    });
  }, []);
  
  return (
    <div
      style={{
        width: "261px",
        backgroundColor: "white",
        borderRadius: "7px",
      }}
    >
      <div
        style={{ position: "relative", height: "20px", marginBottom: "8px" }}
      >
        <div
          onClick={handleClose}
          style={{ cursor: "pointer", position: "absolute", right: "0" }}
        >
          <IoCloseOutline style={{ fontSize: "18px" }} />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center">
        <TimeRangePicker
          isCoach={isCoach}
          TimeRange={time}
          zIndex={"100000"}
          listData={allTimeFormats}
          getTime={setTime}
        />

        <div
          style={{ margin: "20px 0" }}
          className="flex flex-row justify-center relative gap-1"
        >
          {selectedDATES.date_start !== null &&
          selectedDATES.date_end !== undefined &&
          selectedDATES.date_end !== null &&
          selectedDATES.date_start !== undefined ? (
            <div
              style={{
                zIndex: "2",
                position: "absolute",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                width: "100%",
                height: "100%",
                overflow: "hidden",
              }}
            ></div>
          ) : (
            ""
          )}
          {days.map((item) => (
            <DayCheckBox
              preChecked={daysChecked}
              setDaysChecked={setDaysChecked}
              item={item}
            />
          ))}
        </div>
        <div className="relative">
          {daysChecked?.length !== 0 ? (
            <div
              style={{
                zIndex: "99999",
                position: "absolute",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                width: "100%",
                height: "100%",
                overflow: "hidden",
              }}
            ></div>
          ) : (
            ""
          )}
          <span
            style={{ width: "239px !important", justifySelf: "center" }}
            id="calendar"
          />
        </div>
      </div>
        <div className='flex flex-row justify-end items-center'>
      <button onClick={Handle} style={{
        marginTop:'10px',
            textTransform: "unset",
            fontFamily: "var(--font-semibold)",
            color: "white",
            backgroundColor: "#22356D",
            width: "92px",
            height: "37px",
            fontSize: "16px",
          }}>{t('common.Save')}</button>
      </div>
    </div>
  );
};

export default CustomCalenderTime;
