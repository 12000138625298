import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box,Button, Grid, Dialog, DialogContent, DialogProps, Typography } from '@mui/material'
import MyDropdown from "../../components/Payment/LocationSelectCheckBox";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { BsChevronDown } from 'react-icons/bs';
import { GetAllServicesList } from 'src/api/Voucher';
import { StructureTheData } from './VoucherSettingsPopup';
import { useForm } from 'react-hook-form';
import { createCoupon, deleteCoupon, updateCoupon } from 'src/api/Coupon';
import { useTranslation } from "react-i18next";
import CurrencySelector from "../../components/Admin/CurrencySelector";
import Cleave from "cleave.js/react";
import MainContext from "src/context/MainContext";
import { Permission, useGetPermissions } from "src/api/CalendarBooking";

const common = {display:"flex", alignItems:'center', justifyContent:'space-between'};
const selectStyle = {
    "& fieldset": { border: "none" },
    "& .MuiSelect-select": { paddingRight: "6px !important" },
    minHeight: "3px !important",
    height: "33px !important",
    padding: "7px 14px 7px 0px !important",
    borderRadius: "7px",
    border: "1px solid #EEEFEE !important",
    fontFamily: "var(--font-regular) !important",
    width:"184px",
    color: "#22356D !important",
};
const fontD = {
    fontFamily: "var(--font-regular) !important",
    color: "#22356DB2",
};

const CouponModel = ({data, open, handleClose, locationdata, update}:{data:any, open:boolean, handleClose:any, locationdata:any, update: any}) => {
    const {t} = useTranslation();
     const StaffPermission = useGetPermissions();
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body');
    const {Notification,setNotification,setIsPermissionOn} = useContext(MainContext);
    const { register,watch, control, setValue, handleSubmit, formState: { errors }, reset} = useForm();
    const [selectedlocations, setSelectedLocations] = useState<any>([]);
    const {currency,type,discount} = watch();
    const [servicesopen, setservicesopen] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const locations = locationdata.map((item:any) => {
        const {location_name,id,currency} = item;
        const name = location_name
        return { name, id,currency }
    });
    // const [allServices, setAllServices] = useState<any[]>([]);
    const [status, setStatus] = useState<any>();
    const [selectedevents,setselectedevents] = useState<any[]>([]);


    const onSubmit = async (value:any) => {
        if(!StaffPermission.edit_permissions){
            setIsPermissionOn(true);
            handleClose()
            return;
          }
  
          
        if (selectedevents?.length===0) {
            setError("events");
            setTimeout(() => setError(""), 2000);
            return;
          } else  if (selectedlocations?.length===0) {
            setError("location");
            setTimeout(() => setError(""), 2000);
            return;
          } else  if (!type) {
            setError("type");   
            setTimeout(() => setError(""), 2000);
            return;
          }else  if (!discount) {
            setError("discount");
            setTimeout(() => setError(""), 2000);
            return;
          } else  if (!status) {
            setError("status");
            setTimeout(() => setError(""), 2000);
            return;
          }

        let total_data = {
            ...value,
            type,
            currency:selectedlocations[0]?.currency||currency,
            status:status,
            allowBookings: selectedevents.some(item => item.name.includes("Bookings")),
            allowLessons: selectedevents.some(item => item.name.includes("Lessons")),
            allowEvents: selectedevents.some(item => item.name.includes("Events")),
            allowProducts: selectedevents.some(item => item.name.includes("Products")),
            locations: selectedlocations.map((item:{name:string, id:number}) =>item.id)
        }
        try{
            if(Object.keys(data).length>0){
                let res = await updateCoupon(data.id, total_data);
                setNotification({
                    isShow: true,
                    message: 'voucher updated Successfully',
                    subMessage: "",
                    type: "success",
                  });
                console.log(res);   
            }else{;
                let res = await createCoupon(total_data);
                setNotification({
                    isShow: true,
                    message: 'voucher created Successfully',
                    subMessage: "",
                    type: "success",
                  });
                console.log(res);
            }
            update();
            handleClose();
        }catch(error:any){
            setNotification({
                isShow: true,
                message: error,
                subMessage: "",
                type: "error",
              });
            console.log(error);
        }
    }

    const handleDelete =  async () => {
        if(!StaffPermission.edit_permissions){
            setIsPermissionOn(true);
            handleClose()
            return;
          }
          
        if(Object.keys(data).length>0){
            try {
                let res = await deleteCoupon(data.id);
                setNotification({
                    isShow: true,
                    message: 'voucher deleted Successfully',
                    subMessage: "",
                    type: "success",
                  });
                handleClose();
                update();
            } catch (error:any) {
                setNotification({
                    isShow: true,
                    message: error,
                    subMessage: "",
                    type: "error",
                  });
            }
        }else{
            console.log("Deleted");
        }
    }

    useEffect(()=>{
        if(Object.keys(data).length>0){
            let arr = [];
            if(data.allowBookings){
                arr.push({name:"Bookings",id: 1});
            }
            if(data.allowLessons){
                arr.push({name:"Lessons", id: 2});
            }
            if(data.allowEvents){
                arr.push({name:"Events", id: 3});
            }
            if(data.allowProducts){
                arr.push({name:"Products", id: 4});
            }
            reset({
                name: data.name,
                type: data.type,
                discount: data.discount
            })
            setStatus(data.status);
            setselectedevents(arr);
            setSelectedLocations(data.locations.map((item:any)=>{return {name:item.location_name, id:item.id,currency:item.currency}}))
        }else{
            reset({
                name:"",
                type:"",
                discount:"",
            });
            setStatus('');
            setSelectedLocations([]);
            setselectedevents([]);
        }
    },[data])
   
  return (
    <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{".MuiDialog-paper":{
            paddingInline:{xs:"10px",sm:"0px", md:'1rem'},
            width:{xs:"auto !important", sm:'800px !important'}
          },
          ".MuiDialogContent-root":{
            padding:{xs:"12px 10px",sm:"20px 24px"}
          }
        }}
      >
        <DialogContent>
            <Box position={'relative'} sx={{marginBottom:{xs:"30px", sm:"0"}}}>
                <AiOutlineClose style={{position:'absolute', right:0, color:"#22356DB2", cursor:"pointer",zIndex:'10'}} onClick={handleClose} />
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{fontFamily:"var(--font-regular) !important", color:"#22356D"}}>
                    <Typography sx={{width:"fit-content",fontFamily:"var(--font-semibold)", borderBottom:'1px solid #22356D'}}>{t('payments.Coupon Settings')}</Typography>
                    <Box sx={{display:"flex", flexDirection:"column", gap:"15px", marginTop:"20px"}}>
                        <Box sx={{...common,flexWrap:'wrap'}}>
                            <Box>{t('payments.Coupon Name')}<span style={{color:'red'}}> *</span></Box>
                            <Box>
                            <input 
                               id={errors.name &&'customerror'} 
                                type="text" 
                                placeholder={t('common.Enter Name')}
                                style = {{border:'1px solid #EEEFEE', borderRadius:"7px", width:"184px", height:"33px", color:"#22356D"}} 
                                {...register("name", {required: true})}
                            />
                                    {/* {errors.name && (
                            <Typography
                                sx={{
                                fontFamily: "var(--font-regular)",
                                fontSize: "12px",
                                color: "red",
                                }}
                            >
                                {t('This is a required field')}
                            </Typography>
                            )} */}
                            </Box>
                        </Box>
                        <Box sx={common}>
                            <Box>{t('title.Services')}<span style={{color:'red'}}> *</span></Box>
                            <Box width={'184px'}>
                                <MyDropdown 
                                    getOpeon={setservicesopen} 
                                    border={true}
                                    error={error==="events" && error}
                                    text="Services"
                                    arr={[
                                            {name:"Bookings", id:1},
                                            {name: "Lessons", id:2},
                                            {name:"Events", id:3},
                                            {name:"Products", id:4}
                                        ]}
                                    selected={selectedevents}
                                    setSelected={setselectedevents}
                                />
                                 {/* {error==="events" && (
                            <Typography
                                sx={{
                                fontFamily: "var(--font-regular)",
                                fontSize: "12px",
                                color: "red",
                                }}
                            >
                                {t('This is a required field')}
                            </Typography>
                            )} */}
                            </Box>
                        </Box>
                        <Box sx={common}>
                            <Box>{t('common.Locations')}<span style={{color:'red'}}> *</span></Box>
                            <Box width={'184px'}>
                                <MyDropdown 
                                    isValidation={true}
                                    getOpeon={setservicesopen} 
                                    border={true}
                                    error={error==='location' && error}
                                    text="Locations"
                                    arr={locations}
                                    selected={selectedlocations}
                                    setSelected={setSelectedLocations}
                                />
                                 {/* {error==="location" && (
                            <Typography
                                sx={{
                                fontFamily: "var(--font-regular)",
                                fontSize: "12px",
                                color: "red",
                                }}
                            >
                                {t('This is a required field')}
                            </Typography>
                            )} */}
                            </Box>
                        </Box>
                        <Box sx={{...common,flexWrap:'wrap'}}>
                            <Box>{t('payments.Percentage/Amount Based')}<span style={{color:'red'}}> *</span></Box>
                            <Box>
                                <Select
                                    MenuProps={{ sx: dopdowncustomcss }}
                                    displayEmpty
                                    value={watch("type")}  // Changed from [watch("type")] to watch("type")
                                    {...register("type", { required: true })}
                                    IconComponent={() => (
                                        <BsChevronDown
                                            style={{ fontSize: "20px", paddingTop: "2px" }}
                                        />
                                    )}
                                    renderValue={(selected) => {
                                        if (!selected) {
                                            return <p style={fontD}>{t('common.select type')}</p>;
                                        } else {
                                            return selected;
                                        }
                                    }}
                                    sx={{ ...selectStyle, background: "white", color: '#22356D !important' }}
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem key="Percentage" value="Percentage">{t('common.Percentage')}</MenuItem>
                                    <MenuItem key="Amount" value="Amount">{t('common.Amount')}</MenuItem>
                                </Select>
                                {/* {error==="type" && (
                            <Typography
                                sx={{
                                fontFamily: "var(--font-regular)",
                                fontSize: "12px",
                                color: "red",
                                }}
                            >
                                {t('This is a required field')}
                            </Typography>
                            )} */}
                            </Box>
                        </Box>
                        <Box sx={common}>
                            <Box>{t('common.Discount')}<span style={{color:'red'}}> *</span></Box>
                            <Box sx={{...common, width:"184px",gap:'5px'}}>
                            <Box>
                            <Cleave
                                style={{border:'1px solid #EEEFEE', borderRadius:"7px", width:type==="Percentage"?'180px':'110px', height:"33px", color:"#22356D"}} 
                               onChange={(e)=>setValue('discount',e.target.rawValue)}
                                value={discount}
                                placeholder={t("common.Enter Price")}
                                options={{
                                    numeral: true,
                                    numeralThousandsGroupStyle: "thousand",
                                    delimiter:currency==="IDR"?'\u2024':','
                                }}
                                />
                                 {/* {error==="discount" && (
                            <Typography
                                sx={{
                                fontFamily: "var(--font-regular)",
                                fontSize: "12px",
                                color: "red",
                                }}
                            >
                                {t('This is a required field')}
                            </Typography>
                            )} */}
                            </Box>
                                <p>{type==="Percentage" ? "%":  selectedlocations?.length>0 ?selectedlocations[0]?.currency:<CurrencySelector inHeight={'30px'} maxHeight={'150px'} setIsopen={setservicesopen} width='60px' selected={currency} setCurrency={(curr:any)=>setValue('currency',curr)}/>}</p>
                            </Box>
                        </Box>
                        <Box sx={common}>
                            <Box>{t('common.Status')}<span style={{color:'red'}}> *</span></Box>
                            <Box>
                                <Select  MenuProps={{sx:dopdowncustomcss}}
                                    displayEmpty
                                    name="status"
                                    value={[status]}
                                    IconComponent={() => (
                                    <BsChevronDown
                                        style={{ fontSize: "20px", paddingTop: "2px" }}
                                    />
                                    )}
                                    renderValue={(selected) => {
                                    if (selected[0]==='' || selected[0]===undefined) {
                                        return <p style={fontD}>{t('common.Status')}</p>;
                                    }
                                    return selected;
                                    }}
                                    sx={{ ...selectStyle, background: "white", color:'rgba(34, 53, 109, 0.90)'}}
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem key={"Active"} value={"Active"} onClick={()=>setStatus("Active")}>{t('common.Active')}</MenuItem>
                                    <MenuItem key={"Inactive"} value={"Inactive"} onClick={()=>setStatus("Inactive")}>{t('common.Inactive')}</MenuItem>
                                </Select>
                                {/* {error==="status" && (
                            <Typography
                                sx={{
                                fontFamily: "var(--font-regular)",
                                fontSize: "12px",
                                color: "red",
                                }}
                            >
                                {t('This is a required field')}
                            </Typography>
                            )} */}
                            </Box>
                        </Box>
                    </Box>
                    <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        marginTop={servicesopen ?"150px":"20px"}
                    >
                        <Button
                            type='reset'
                            onClick={()=>handleDelete()}
                            style={{
                                textTransform: "unset",
                                fontFamily: "var(--font-semibold)",
                                width: "150px",
                                height: "37px",
                                color: "#22356D",
                                backgroundColor: "white",
                                fontSize: "16px",
                                padding: "10px 30px",
                                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
                            }}
                        >
                        {t('common.Delete')}
                        </Button>
                        <Button
                            type="submit"
                            style={{
                                zIndex: 1,
                                textTransform: "unset",
                                fontFamily: "var(--font-semibold)",
                                color: "white",
                                backgroundColor: "#22356D",
                                width: "92px",
                                height: "37px",
                                fontSize: "16px",
                            }}
                        >
                            {t('common.Save')}
                        </Button>
                    </Grid>
                </Box>
            </form>
        </DialogContent>
      </Dialog>
  )
}

export default CouponModel