import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React, { useContext, useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { IoIosArrowDown } from "react-icons/io";
import formatResponse from "src/constants/FormatResponse";
import CustomCalenderTime from "./CustomCalenderTime";
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import { FaAngleDown } from "react-icons/fa6";
import MainContext from "src/context/MainContext";

const leftborderInput = {
  width: "200px",
  height: "28px",
  opacity: "0.9",
  padding: "5px 8px",
  fontSize: "14px",
  border: "1px solid #EEEFEE",
  borderRadius: "7px",
  color: "#22356D",
};

const TimeBasedCondition = ({
  index,
  preSpecialDays,
  setValue,
  handleUpdateSpecial,
  remove,
  deletedTime
}: {
  index: number;
  preSpecialDays: any;
  setValue: any;
  handleUpdateSpecial: any;
  remove: any;
  deletedTime?:any
}) => {

  const {t} =useTranslation();
  const {SelectedCourtDurations} = useContext(MainContext);
  // const [selectTime,setSelectTime] = useState<any[]>([]);
  const [showSpecialcalendar, setShowSpecialCalendar] = useState(false);

  const handleSpecialClose = () => {
    setShowSpecialCalendar(false);
  };

  // useEffect(() => {
  //   const dur = preSpecialDays[index]?.duration?.length>0?preSpecialDays[index]?.duration:[]
  //   setSelectTime(dur)
  // }, [preSpecialDays])


  // useEffect(() => {
  //   const AllTime = selectTime?.map((item:any)=>item?.id)
  //   setValue(`Rows.${index}.duration`,AllTime)
  // }, [selectTime])


  const handleRemove = ()=>{
    setValue('deletedTime',[...deletedTime,preSpecialDays[index]?.id])
    remove(index);
  }

  const handleDurationSelect = (data:any)=>{
    const AllTime = () => {
      if(preSpecialDays[index]?.duration?.some((item:any)=>item?.id===data?.id)){
    return preSpecialDays[index]?.duration?.filter((item:any)=>item?.id!==data.id)
      }else{
        return [...preSpecialDays[index]?.duration,data]
      }
    }
    const updated = AllTime();
    setValue(`Rows.${index}.duration`,updated)
  }
  

  return (
    <Box sx={{ marginLeft: { xs: "0px", sm: "20px" } }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "baseline", sm: "center" },
          justifyContent:'space-between',
          gap: { xs: "10px", sm: "10px" },
        }}
      >
         <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "baseline", sm: "center" },
          gap: { xs: "10px", sm: "10px" },
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <p
            className="flex items-center justify-between"
            onClick={() => setShowSpecialCalendar(true)}
            style={leftborderInput}
          >
            <input
              style={{
                padding: "0px",
                width: "100%",
                fontSize: "14px",
                fontFamily: "var(--font-regular)",
              }}
              placeholder={t("common.Select day and time")}
              value={formatResponse([preSpecialDays[index]],t)==="Select day and time"?t('common.Select day and time'):formatResponse([preSpecialDays[index]],t)}
            />
            <IoIosArrowDown opacity={0.7} />
          </p>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <FormControl>
            <Select
            multiple
            displayEmpty
            input={<OutlinedInput />}
            value={preSpecialDays[index]?.duration||[]}
            renderValue={(selected:any) => {
              if (selected?.length > 0) {
                const InputString = selected?.map((item:any)=>item?.duration); 
                return `${InputString} minutes`;
              }
              return <p>{t('common.Select Duration')}</p>;
            }}
            // onChange={(e:any)=>setSelectTime(e.target.value?.id)}
              id="customdropdown"
              style={{
                height: "27px",
                border: "1px solid #EEEEFE",
                borderRadius: "7px",
                fontSize: "14px",
                opacity: "1",
                fontFamily: "var(--font-regular)",
                color: "#22356D",
                width:'150px'
              }}
              MenuProps={{ sx: {...dopdowncustomcss, '& .Mui-selected::after': {content:'none'}}}}
              IconComponent={FaAngleDown}
            >
              {SelectedCourtDurations.map((day:any) => (
                <MenuItem
                onClick={()=> handleDurationSelect(day)}
                  style={{
                    fontSize: "14px",
                    opacity: "1",
                    fontFamily: "var(--font-regular) !important",
                    color: "#22356D",
                  }}
                  key={day?.id}
                  value={day?.id}
                >
                  {day?.duration} minutes
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        </Box>
        <RxCross2 style={{color: "rgba(34, 53, 109, 1)", cursor: "pointer" }} onClick={handleRemove} />
      </Box>
      <Dialog
        sx={{
          ".MuiDialog-paper": {
            width: "auto",
            minHeight: "300px",
          },
        }}
        hideBackdrop={true}
        onClose={handleSpecialClose}
        open={showSpecialcalendar}
      >
        <DialogContent>
          <CustomCalenderTime
            handleClose={handleSpecialClose}
            index={index}
            preTime={preSpecialDays[index]}
            setGetDateTime={(e: any) => handleUpdateSpecial(e, index)}
            setShowCalendar={showSpecialcalendar}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default TimeBasedCondition;
