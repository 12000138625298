import { Box, FormControl, MenuItem, Select } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from 'react-i18next';
import { dopdowncustomcss } from 'src/constants/dropdownCSS';
import MainContext from 'src/context/MainContext';
import { useGetClubWalletOfCustomer } from 'src/api/Customer';
import { useGetAllPaymentMethodsByCust } from 'src/api/Voucher';
import { addThousandSeparator } from 'src/constants/SportSEparator';
import { useGetAllPaymentMethodsByLoc } from 'src/hooks/useGetAllPaymentMethods';


interface walletTypes {
    currency: string;
    balance: number;
    id: number;
  }

const SelectedPayment = ({setValue,unregister,currentFields,index,selectedLocationID,isNoCustomer,SelectedLocation,customer_id}:{setValue:any,unregister:any,currentFields:any,index:any,selectedLocationID:number,isNoCustomer:boolean,SelectedLocation:any,customer_id?:any}) => {
    const {t} = useTranslation();
    const location_restriction = JSON.parse(
      localStorage.getItem("location_restriction") || JSON.stringify("false")
    );
    const [PaymentMethods, setPaymentMethods] = useState<any>([]);
    const [SelectedVoucher, setSelectedVoucher] = useState<any>();
    const {data:PaymentMet,isSuccess:PaymentMethodsSuccess,isError:PaymentMethodsError,setlocation_id:SetLocationID} = useGetAllPaymentMethodsByLoc();
    const {
        data,
        isSuccess,
        isError,
        setCustomer_id,
        refetch: RefetchClubWallet,
      } = useGetClubWalletOfCustomer();
      const {
        data: PaymentMethodsData,
        isSuccess: PaymentMethodSuccess,
        isError: PaymentMethodError,
        setCustomer_id: SetPCustomer_id,
        setLocation_id,
      } = useGetAllPaymentMethodsByCust();
    
    const { Notification, setNotification } = useContext(MainContext);

    const handleSelectPayment = (data: any) => {
        if (data?.hasOwnProperty("balance")) {

          if(data?.currency !== SelectedLocation?.currency){
            setSelectedVoucher('');
            setNotification({
              isShow: true,
              message: "Customer wallet currency is different then product",
              subMessage: "",
              type: "error",
            });
          }else{
            setValue(
              `payments.${index}.wallet_id`,
              data?.id
            );
            unregister(`payments.${index}.method_id`)
            setSelectedVoucher(data);
          }
           
        } else if (data?.hasOwnProperty("method_name")) {
          setSelectedVoucher(data);
          unregister(`payments.${index}.wallet_id`)
          setValue(
            `payments.${index}.method_id`,
            data?.id
          );
        }
      };


      useEffect(() => {
      if(!isNoCustomer){
        setCustomer_id(currentFields[index].customer || customer_id);
        SetPCustomer_id(currentFields[index]?.customer || customer_id);
        setLocation_id(selectedLocationID);
      }else{
        SetLocationID(selectedLocationID);
      }
      }, [currentFields,selectedLocationID,isNoCustomer,customer_id]);


      
      useEffect(() => {
        if(PaymentMethodsSuccess){
          setPaymentMethods(PaymentMet)
        }else{
          setPaymentMethods([]);
        }
        }, [PaymentMet,PaymentMethodsSuccess,PaymentMethodsError]);

        useEffect(() => {
          if (
            SelectedVoucher?.balance < (currentFields[index]?.products?.reduce((acc:any,item:any)=>Number(item.price)+acc,0)-currentFields[index]?.products?.reduce((acc:any,item:any)=>Number(item.discount)+acc,0))
          ) {
            setNotification({
              isShow: true,
              message: "Customer doesn’t have sufficient wallet credits",
              subMessage: "",
              type: "error",
            });
            setSelectedVoucher('');
            unregister(`payments.${index}.wallet_id`)
          }
        }, [currentFields[index]?.products,SelectedVoucher])
        
  

      useEffect(() => {
        if (isSuccess && PaymentMethodSuccess) {
          const updated = location_restriction?data?.filter((item:any)=>item?.location_id===selectedLocationID)?.map((item: walletTypes) => {
            const method_name = `Wallet ${addThousandSeparator(
              item.balance,
              item.currency
            )} ${item.currency}`;
            return { ...item, method_name };
          }): data?.map((item: walletTypes) => {
            const method_name = `Wallet ${addThousandSeparator(
              item.balance,
              item.currency
            )} ${item.currency}`;
            return { ...item, method_name };
          });
          setPaymentMethods([...updated, ...PaymentMethodsData?.paymentMethods]);
        } else if (PaymentMethodSuccess) {
          setPaymentMethods(PaymentMethodsData?.paymentMethods);
        } else if (isSuccess) {
          const updated = location_restriction ? data?.filter((item:any)=>item?.location_id===selectedLocationID)?.map((item: walletTypes) => {
            const method_name = `Wallet ${addThousandSeparator(
              item.balance,
              item.currency
            )} ${item.currency}`;
            return { ...item, method_name };
          }):data?.map((item: walletTypes) => {
            const method_name = `Wallet ${addThousandSeparator(
              item.balance,
              item.currency
            )} ${item.currency}`;
            return { ...item, method_name };
          });
          setPaymentMethods(updated);
        } else {
          setPaymentMethods([]);
        }
      }, [
        data,
        isSuccess,
        isError,
        PaymentMethodsData,
        PaymentMethodSuccess,
        PaymentMethodError,
      ]);


  return (
    <Box sx={{marginTop:'25px',mariongBottom:`${currentFields[index].payments?.length* 14} px`}}>
    <FormControl sx={{width:{xs:"100%", md:"unset"}}}>
              <Select
                IconComponent={KeyboardArrowDownIcon}
                placeholder="Customer type"
                value={!!SelectedVoucher ? SelectedVoucher : t("customers.Payment Method")}
                id="customdropdown"
                renderValue={(value) => {
                  if (value?.method_name) {
                    return value?.method_name;
                  }
                  return <em>{t("customers.Payment Method")}</em>;
                }}
                sx={{
                  width: {xs:"100%", md:"180px"},
                  height: "35px",
                  border: "1px solid #EEEEFE",
                  borderRadius: "7px",
                  fontSize: "14px",
                  opacity: "1",
                  fontFamily: "var(--font-regular)",
                  color: "#22356D",
                }}
                MenuProps={{ sx: dopdowncustomcss }}
              >
                {PaymentMethods?.map((location: any) => (
                  <MenuItem
                    onClick={(e: any) => handleSelectPayment(location)}
                    key={location.id}
                    value={location}
                  >
                    {location?.method_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Box>
  )
}

export default SelectedPayment