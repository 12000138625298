import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { activeColor } from "src/constants/color";
import MainContext from "src/context/MainContext";
import { useMediaQuery, useTheme } from "@mui/material";
import StatusWarning from "./StatusWarning";
import { IoEyeOutline } from "react-icons/io5";
import PaymentPopup from "src/features/Payments/PaymentPopup";
import { addThousandSeparator } from "src/constants/SportSEparator";

const PersonTableRow = ({
  sendNotification,
  SetSendNotification,
  index,
  userData,
  selectedOpenRegular,
  handleCheckboxChange,
  handleEditInfo,
  setCustomerList,
  setSelectedCheckBox,
  selectedCheckbox,
  isCheckBox,
  isWaitingApproval,
}: {
  selectedCheckbox: any;
  setSelectedCheckBox: any;
  index: number;
  userData: any;
  selectedOpenRegular: any;
  handleCheckboxChange: any;
  handleEditInfo: any;
  setCustomerList: any;
  isCheckBox?: any;
  isWaitingApproval?: boolean;
  sendNotification: any;
  SetSendNotification: any;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isPayOpen, setISPayOpen] = useState<boolean>(false);
  const [showOption, setShowOption] = useState<boolean>(false);
  const [PlayerId, setPlayerId] = useState<number>(0);
  const [showWarning, setshowWarning] = useState(false);
  const context = useContext(MainContext);
  const event = context?.event;
  const SelectedLocation = context?.SelectedLocation;
  const [name, setName] = useState<string>("");
  const [ischecked, setIschecked] = useState(false);
  const { Notification, setNotification } = useContext(MainContext);
  const setSelectedCustomerId = context?.setSelectedCustomerId;
  const SPORT_NAME =
    Object.keys(event)?.length > 0 ? event?.courts[0].sport?.sport_name : "";
  const GEtLevelOfPlayer = (player: any) => {
    const Level = player?.customer?.sportsLevel;
    const FilteredLevel =
      Level?.filter((item: any) => item?.sport_name === SPORT_NAME)[0]?.level ||
      "0.0";
    return FilteredLevel;
  };

  const handleClicked = () => {
    setIschecked((prev) => !prev);

    setSelectedCheckBox((prev: any) => {
      if (prev?.length === 0) {
        return [userData];
      } else if (prev.some((avail: any) => avail.id === userData.id)) {
        return prev?.filter((old: any) => old.id !== userData.id);
      } else {
        return [...prev, userData];
      }
    });
  };

  const handleClick = (data: any) => {
    setCustomerList(true);
  };

  const handleshowWarning = (event: any, name: any) => {
    event.stopPropagation();
    setName(name);
    setshowWarning(true);
  };

  const handleCloseModel = () => {
    setshowWarning(false);
    SetSendNotification(false);
  };

  const handleShowAndCancel = (event: any) => {
    if (name === "show") {
      handleEditInfo(event, userData, "show");
      handleCloseModel();
    } else if (name === "cancel") {
      handleEditInfo(event, userData, "cancel");
      handleCloseModel();
    }
  };

  const passdata = {
    ...userData?.customer,
    id: userData?.customer?.id,
  };

  const handleErrorRefund = (refundStatus: any) => {
    if (refundStatus) {
      setNotification({
        isShow: true,
        message: "You cannot select a refunded transaction",
        subMessage: "",
        type: "error",
      });
    }
  };

  const handlepopup = async (id: number) => {
    setISPayOpen(true);
    setPlayerId(id);
  };

  useEffect(() => {
    if (isMobile) {
      document.addEventListener("click", () => {
        setShowOption(false);
      });
    }
  }, []);

  return (
    <Box>
      <Box
        onClick={() => setSelectedCustomerId(passdata)}
        display={"flex"}
        justifyContent={"space-between"}
        width={"100%"}
        position={"relative"}
        color={activeColor}
        onMouseOver={() => (isMobile ? "" : setShowOption(true))}
        onMouseOut={() => (isMobile ? "" : setShowOption(false))}
        gap={2}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={1}
          onClick={() => handleClick(userData)}
          sx={{
            cursor: "pointer",
            ">img": { display: { xs: "none", sm: "block" } },
          }}
        >
          <img
            src={
              userData?.customer.profile_url
                ? userData?.customer.profile_url
                : "/images/Reserved.svg"
            }
            alt="user"
            style={{ width: "40px", height: "40px", borderRadius: "50%" }}
          />
          <Box sx={{ fontFamily: "var(--font-regular)" }} lineHeight={"20px"}>
            <Box
              fontSize={"16px"}
              sx={{ ">br": { display: { xs: "block", sm: "none" } } }}
            >
              {userData?.customer?.first_name} {userData?.customer?.last_name}{" "}
              &nbsp;
              <br />
              {userData.is_show && (
                <span
                  style={{
                    background: "#FFFDC5",
                    color: "#838383D9",
                    padding: "2px 8px",
                    borderRadius: "4px",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                  }}
                >
                  No Show
                </span>
              )}
              {userData.is_canceled && (
                <span
                  style={{
                    background: "#FFE8E8",
                    color: "#FF1313D9",
                    padding: "2px 8px",
                    borderRadius: "4px",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                  }}
                >
                  Cancelled
                </span>
              )}
            </Box>
            <Typography
              sx={{
                fontFamily: "var(--font-regular)",
                fontSize: "12px",
                opacity: "0.7",
              }}
            >
              Level {GEtLevelOfPlayer(userData)}
            </Typography>
            {showOption && !isWaitingApproval && (
              <Box
                display={"flex"}
                gap={1.5}
                marginRight={1}
                position={"absolute"}
                sx={{
                  left: { xs: "0%", sm: "20%" },
                  bottom: { xs: "-1%", sm: "1%" },
                }}
              >
                <Paper
                  sx={{
                    padding: "0px 6px",
                    color: "#22356DB2",
                    cursor: "pointer",
                  }}
                  onClick={(event) => handleshowWarning(event, "show")}
                >
                  {userData.is_show ? "Undo" : "No Show"}
                </Paper>
                <Paper
                  sx={{
                    padding: "0px 6px",
                    color: "#FF1313",
                    cursor: "pointer",
                  }}
                  onClick={(event) => handleshowWarning(event, "cancel")}
                >
                  {userData.is_canceled ? "Undo" : "Cancel"}
                </Paper>
              </Box>
            )}

            <Box
              sx={{
                display: { xs: "block", sm: "none" },
                width: "61px",
                height: "25px",
                borderRadius: "5px",
                border: "1px solid #DDDDDD",
                padding: "1px 8px",
                background: "white",
              }}
              onClick={(event: any) => {
                event.stopPropagation();
                setShowOption(true);
              }}
            >
              Action
            </Box>
          </Box>
        </Box>

        {!isWaitingApproval && (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={1}
            marginRight={"10px"}
          >
            {/* {userData.paid && <p style={{fontSize:'14px'}}>Credit card</p>}
        {userData.paid && <span style={{...divider}}></span>} */}
            {userData?.status === "Paid" && (
              <IoEyeOutline
                cursor={"pointer"}
                onClick={() => handlepopup(userData?.id)}
                fontSize={12}
              />
            )}
            <Typography
              sx={{
                fontFamily: "var(--font-regular)",
                fontSize: { xs: "0.85rem", sm: "1rem" },
                color: userData?.pending_price === 0 ? "#5B8777" : "#FF1313",
              }}
            >
              <span style={{ color: "#22356DB2" }}>
                {userData?.refunded_status ? `Refunded | ` : ""}
                {!!userData?.payment_method &&
                userData?.status !== "Pending payment" &&
                !userData?.refunded_status
                  ? `${userData?.payment_method} | `
                  : " "}
              </span>
              {SelectedLocation?.currency}{" "}
              {userData?.pending_price === 0 && !userData?.refunded_status
                ? addThousandSeparator(
                    userData?.paid_price,
                    SelectedLocation.currency
                  )
                : userData?.pending_price > 0
                ? addThousandSeparator(
                    userData?.pending_price,
                    SelectedLocation.currency
                  )
                : userData?.refunded_status
                ? addThousandSeparator(
                    userData?.refund_price,
                    SelectedLocation.currency
                  )
                : ""}
            </Typography>
            {isCheckBox ? (
              ""
            ) : (
              <label
                onClick={() => handleErrorRefund(userData?.refunded_status)}
                className="container"
              >
                <input
                  disabled={userData?.refunded_status}
                  checked={selectedCheckbox.some(
                    (avail: any) => avail.id === userData.id
                  )}
                  onChange={handleClicked}
                  type="checkbox"
                />
                <span className="mark"></span>
              </label>
            )}
          </Box>
        )}
      </Box>

      <PaymentPopup
        selectedLocation={SelectedLocation}
        index={0}
        bookingData={""}
        open={isPayOpen}
        player={userData}
        setOpen={setISPayOpen}
        playerId={PlayerId}
      />

      <Dialog onClose={() => handleCloseModel()} open={showWarning}>
        <DialogContent>
          <StatusWarning
            sendNotification={sendNotification}
            SetSendNotification={SetSendNotification}
            handleShowAndCancel={handleShowAndCancel}
            name={name}
            handleCloseModel={handleCloseModel}
            userData={userData}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PersonTableRow;
