import { Box, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { Country } from 'src/constants/CountryCodes';

const fallback={
  code:'',
  dial_code:'',

}
const CountrySelectorDr = ({ selected, setSelected }: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>('');
  const [filteredCountry, setFilteredCountry] = useState<any[]>(Country);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Filter country list based on search input
    const filtered = filterValue
      ? Country.filter((item: any) =>
          item.name.toLowerCase().includes(filterValue.toLowerCase())||item.dial_code.includes(filterValue)
        )
      : Country;
    setFilteredCountry(filtered);
  }, [filterValue]);

  useEffect(() => {
    // Close dropdown on outside click
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      window.addEventListener('click', handleOutsideClick);
    }
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);


  const handleDropdownToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelectCountry = (dialCode: string) => {
    setSelected(dialCode);
    setIsOpen(false); // Close dropdown after selection
  };

  const fallbackSelect = selected ? Country.filter((item: any) => item.dial_code === selected)[0]
  : fallback;
    

  return (
    <Box sx={{ position: 'relative' }} ref={dropdownRef}>
      {/* Selected Country */}
      <div
        style={{
          fontFamily: 'var(--font-regular)',
          cursor: 'pointer',
          width: '58px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '3px',
          fontSize: '14px',
        }}
        onClick={handleDropdownToggle}
      >
        <img
          style={{
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            objectFit: 'cover',
          }}
          alt="country-icon"
          src={`https://flagcdn.com/${fallbackSelect?.code?.toLowerCase()}.svg`}
        />
        <Typography
          sx={{
            width: 'max-content',
            fontSize: '14px',
            opacity: '0.6',
            fontFamily: 'var(--font-regular)',
          }}
        >
          {fallbackSelect?.dial_code}
        </Typography>
        <FaAngleDown />
      </div>

      {/* Dropdown */}
      {isOpen && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 25,
            background: 'white',
            p: '10px',
            maxHeight: '300px',
            overflowY: 'auto',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            width: {xs:'160px',sm:'300px'},
          }}
        >
          {/* Search Input */}
          <input
            style={{
              border: '1px solid #22356D',
              width: '100%',
              padding: '5px',
              marginBottom: '5px',
              borderRadius: '4px',
            }}
            placeholder="Enter Country"
            value={filterValue}
            onChange={(e: any) => setFilterValue(e.target.value)}
          />

          {/* Country List */}
          {filteredCountry.map((item: any) => (
            <Typography
              key={item.code}
              onClick={() => handleSelectCountry(item?.dial_code)}
              sx={{
                fontSize: '14px',
                fontFamily: 'var(--font-regular)',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1,
                cursor: 'pointer',
                p: '5px',
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                },
              }}
            >
              <img
                style={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
                alt="country-icon"
                src={`https://flagcdn.com/${item?.code?.toLowerCase()}.svg`}
              />
              {item?.name}
              <Typography
                sx={{
                  width: 'max-content',
                  fontSize: '14px',
                  opacity: '0.6',
                  fontFamily: 'var(--font-regular)',
                }}
              >
                {item?.dial_code}
              </Typography>
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CountrySelectorDr;
