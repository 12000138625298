import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import React, { useContext, useEffect, useState } from "react";
import PersonList from "./PersonList";
import { activeColor } from "src/constants/color";
import ScoreList from "../ScoreList";
import MainContext from "src/context/MainContext";
import {
  Permission,
  UpdateServicesPlayers,
  getClubId,
  getPlayerList,
  useGetAllCustomerList,
  useGetAllWaitingApprovalList,
  useGetAllWaitingList,
  useGetPermissions,
  useGetPlayersNotes,
  useGetScore,
} from "src/api/CalendarBooking";
import WaitingList from "./WaitingList";
import { apiCaller } from "src/api/ApiCaller";
import TeamMatesList, { pairData } from "./TeamMatesList";
import "../tableStyling.css";
import WaitingTeam from "./WaitingTeam";
import { useTranslation } from "react-i18next";
import ChatApp from "./ChatApp";
import PersonTableRow from "./PersonTableRow";
import WaitingApprovalList from "./WaitingApprovalList";

interface User {
  id: number;
  name: string;
  image: string;
  paid: Boolean;
  noShow: Boolean;
  cancel: Boolean;
}

export function fillWithPairedData(data: any, maxCapacity: any) {
  const filledData: any = [];

  filledData.push(...data);
  const dummydata = {
    customer: null,
    pairedData: [],
  };

  const TotalReamingnig = maxCapacity / 2 - data?.length;
  Array(TotalReamingnig)
    .fill("-")
    ?.map((_) => filledData.push(dummydata));
  return filledData;
}

export function organizePlayers(data: any, maxCapacity: any) {
  // Step 1: Sort the data based on position
  const sortedData = data
    .filter((item: any) => !item.is_canceled && !item.is_show)
    .sort((a: any, b: any) => a.position - b.position);

  // Step 2: Create an array to hold the organized players
  const organizedData = [];

  // Step 3: Iterate from 1 to maxCapacity and fill in the gaps
  for (let i = 1; i <= maxCapacity; i++) {
    const existingPlayer = sortedData.find(
      (player: any) => player.position === i
    );
    if (i % 2 !== 0) {
      // For odd positions, place the player in the main data
      if (existingPlayer) {
        organizedData.push({ ...existingPlayer, pairedData: [] });
      } else {
        // If no player exists at this position, add an empty placeholder
        organizedData.push({
          customer: null,
          pairedData: [],
        });
      }
    } else {
      // For even positions, place the player inside pairedData
      if (existingPlayer) {
        organizedData[organizedData.length - 1].pairedData.push(existingPlayer);
      }
    }
  }

  return organizedData;
}

const EventTab = ({
  columnsArray,
  updateList,
  update,
  fromWh,
  events,
  userData,
  selectedOpenRegular,
  setSave,
  selectedBookingType,
  setCustomerList,
  setPayPlayers,
  selectEvent,
  selectAll,
  handleCheckboxChange,
  handleMasterCheckboxChange,
  approve_before_join,
  friendly_match,
  TabCount,
}: {
  updateList: any;
  TabCount: any[];
  fromWh: any;
  update: any;
  columnsArray: any;
  events: any;
  userData: any;
  selectedOpenRegular: string;
  setSave: any;
  selectedBookingType: string;
  setCustomerList: any;
  selectEvent: any;
  setPayPlayers: any;
  selectAll: boolean;
  handleCheckboxChange: any;
  handleMasterCheckboxChange: any;
  approve_before_join: boolean;
  friendly_match: boolean;
}) => {
  const { t } = useTranslation();
  const [customerList, setCustList] = useState<any[]>([]);
  const [filterCustomerList,setfitlerCustomerList] =useState<any[]>([]);
  const [sendNotification, SetSendNotification] = useState<boolean>(false);
  const StaffPermission = useGetPermissions();
  const context = useContext(MainContext);
  const {
    CancelledPlayer,
    setCancelledPlayer,
    setIsPermissionOn,
    setNotification,
  } = useContext(MainContext);
  const event = context?.event;
  const max_capacity = events?.service;
  const [value, setValue] = React.useState("1");
  const [playerList, setPlayerList] = useState([]);
  const [waitPlayers, setWaitPlayers] = useState([]);
  const [teams, setTeams] = useState<any>([]);
  const [AllPlayers, setAllPlayers] = useState<any[]>([]);
  const [teamMates, setTeamMates] = useState([]);
  const [addTeam, SetAddTeam] = useState<number[]>([]);
  const [AllWaitingList, setAllWaitingList] = useState<any[]>([]);
  const {
    data: CustomerData,
    isSuccess:CustomerSuccess,
    isError:CustomerError,
    refetch:CustomerRefetch,
  } = useGetAllCustomerList();
  const {
    data: PlayNot,
    isSuccess: PlayNotSuccess,
    isError: PlayNotError,
    refetch: PlayNotRefetch,
    setService_id: SetServiceID,
  } = useGetPlayersNotes();
  const {
    isSuccess: getScoreSuccess,
    data: getScoreData,
    isError: getScoreError,
    setService_id: getScoreServiceID,
    refetch: getScoreRefetch,
  } = useGetScore();
  // const {data:waitingTeam, isSuccess:succ, isError: err, refetch: upd, setService_id:setServiceID} = useGetAllWaitingList();
  const {
    isSuccess: WaitingAppSuccess,
    data: WaitingAppData,
    isError: WaitingAppError,
    setService_id: WaitingAppService,
    refetch: WaitingAppRefetch,
  } = useGetAllWaitingApprovalList();

  useEffect(() => {
    if (WaitingAppSuccess) {
      setAllWaitingList(WaitingAppData);
    }
  }, [WaitingAppSuccess, WaitingAppData, WaitingAppError]);

  function isEmptyObjectOrUndefined(value: any) {
    return (
      value === undefined ||
      (typeof value === "object" && Object.keys(value).length === 0)
    );
  }

  const handleSelectTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


  const handleEditInfo = async (e: any, item: any, type: string) => {
    e.stopPropagation();
    if (!StaffPermission.edit_permissions) {
      setIsPermissionOn(true);
      return;
    }

    if (type === "show") {
      try {
        const response = await UpdateServicesPlayers(
          item.id,
          type,
          sendNotification
        );
        PlayNotRefetch();
        if (
          event?.service?.booking_type === "Open Match" ||
          event?.service?.event_type === "Double"
        ) {
          getAllPlayerList(event?.service_id);
        }
        updateList();
      } catch (error: any) {
        setNotification({
          message: error,
          isShow: true,
          subMessage: "",
          type: "error",
        });
      }
    } else if (type === "cancel") {
      try {
        const response = await UpdateServicesPlayers(
          item.id,
          type,
          sendNotification
        );
        PlayNotRefetch();
        if (
          event?.service?.booking_type === "Open Match" ||
          event?.service?.event_type === "Double"
        ) {
          getAllPlayerList(event?.service_id);
        }
        updateList();
      } catch (error: any) {
        setNotification({
          message: error,
          isShow: true,
          subMessage: "",
          type: "error",
        });
      }
    }
  };


  const handleUpdate = ()=>{
   if(events?.service?.event_type==="Double"){
    setValue('1')
   }
    updateList();
    WaitingAppRefetch();
    PlayNotRefetch();
  }

  const getAllPlayerList = async (service_id: number) => {
    if (service_id) {
      try {
        let res: any = await getPlayerList(service_id);
        let data = res?.data?.data;
        setTeams(data);
        setAllPlayers(data);

        //  let filterData = pairData(data);
        const maxxxxxx =
          selectEvent?.maximum_capacity ||
          events?.service?.event?.maximum_capacity ||
          events?.service?.booking?.maximum_capacity;
        const UpdatedDataPositionaly: any = organizePlayers(data, maxxxxxx);
        //  const finaDAtataa = fillWithPairedData(filterData,maxxxxxx);
        const canclledPlayer = data?.filter(
          (pla: any) => pla?.is_canceled || pla?.is_show
        );
        setCancelledPlayer(canclledPlayer);
        setTeamMates(UpdatedDataPositionaly);
        if (Object.keys(events).length > 0) {
          let max_capacity =
            selectEvent?.maximum_capacity ||
            events?.service?.event?.maximum_capacity ||
            events?.service?.booking?.maximum_capacity;
          const val =
            Math.floor(max_capacity / 2) - UpdatedDataPositionaly.length;
          let arr = new Array(val)?.fill(0);
          SetAddTeam(arr);
        }
      } catch (error: any) {
        let err = error?.response?.data?.message;
        if (
          (Object.keys(events).length > 0 &&
            events?.service?.event_type === "Double") ||
          events?.service?.booking_type === "Open Match"
        ) {
          let max_capacity =
            selectEvent?.maximum_capacity ||
            events?.service?.event?.maximum_capacity ||
            events?.service?.booking?.maximum_capacity ||
            10;
          const val = Math.floor(max_capacity / 2);
          let arr = new Array(val)?.fill(0);
          const finaDAtataa: any = organizePlayers([], max_capacity);
          setTeamMates(finaDAtataa);
          //  SetAddTeam(arr);
        }
      }
    }
  };

  useEffect(() => {
    if(CustomerSuccess){
      setCustList(CustomerData);
      setfitlerCustomerList(CustomerData);
    }else{
      setCustList([]);
      setfitlerCustomerList([]);
    }
  }, [CustomerData,CustomerSuccess,CustomerError])
  

  useEffect(() => {
    if (PlayNotSuccess) {
      const cancelledPlayer = PlayNot?.serviceDetails?.players?.filter(
        (item: any) => item?.is_canceled || item?.is_show
      );

      const NotCancell = PlayNot?.serviceDetails?.players?.filter(
        (item: any) => !item?.is_canceled && !item?.is_show
      );
      const UpdatedPlayerList = customerList?.filter((item:any)=>
        !PlayNot?.serviceDetails?.players?.some((plSe:any)=>plSe?.customer?.id===item?.id)
      )?.filter((item:any)=>!WaitingAppData?.some((plse:any)=>plse?.customer?.id===item?.id));

      setfitlerCustomerList(UpdatedPlayerList);
      setPlayerList(NotCancell);
      setCancelledPlayer(cancelledPlayer);
    }
  }, [PlayNot, PlayNotSuccess, PlayNotError,customerList,WaitingAppSuccess, WaitingAppData]);

  useEffect(() => {
    if (WaitingAppSuccess) {
      setWaitPlayers(WaitingAppData); 
    } else {
      setWaitPlayers([]);
    }
  }, [WaitingAppData, WaitingAppSuccess, WaitingAppError]);

  useEffect(() => {
    if (Object.keys(event).length > 0) {
      // findPlayerList(event?.service?.id);

      WaitingAppService(event?.service_id);
      getScoreServiceID(event?.service_id);
      SetServiceID(event?.service_id);
    } else {
      setPlayerList([]);
      setWaitPlayers([]);
    }
  }, [event, value]);

  useEffect(() => {}, [
    userData,
    playerList,
    selectedOpenRegular,
    selectedBookingType,
  ]);


  useEffect(() => {
    if (CancelledPlayer?.length === 0) {
      setValue("1");
    }
  }, [CancelledPlayer]);

  const ApprovedList = AllWaitingList?.filter(
    (item: any) => item.status === "approved"
  )?.length;

  if (approve_before_join && selectedOpenRegular === "Open Match")
    TabCount.push("1");
  if (selectedBookingType !== "Booking") TabCount.push("2");
  if (
    selectedOpenRegular === "Open Match" ||
    selectedOpenRegular === "Single" ||
    selectedOpenRegular === "Double"
  )
    TabCount.push("3");
  if (CancelledPlayer?.length > 0) TabCount.push("4");
  if (!friendly_match && selectedOpenRegular === "Open Match")
    TabCount.push("5");

  const APIScore = getScoreData?.Scores || [];
  const teamAScores = [
    APIScore[0]?.score1,
    APIScore[0]?.score2,
    APIScore[0]?.score3,
  ];
  const ScoreSET =
    teamAScores?.filter((score) => score !== 0 && !!score).length || 0;

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          columnGap: "5px",
          rowGap: "5px",
        }}
      >
        <TabContext value={value}>
          <Box>
            <TabList
              onChange={handleSelectTab}
              aria-label="people list"
              TabIndicatorProps={{ sx: { backgroundColor: activeColor } }}
              sx={{
                ".MuiTab-root": {
                  paddingBottom: "0px",
                  textTransform: "capitalize",
                  color: "#22356D99",
                  fontSize: "1rem",
                },
                ".Mui-selected": {
                  color: "#22356D !important",
                  fontFamily: "var(--font-semibold)",
                },
              }}
            >
              <Tab
                label={`${t("common.Players")} ${
                  !isEmptyObjectOrUndefined(events)
                    ? playerList?.length
                    : 0
                }/${
                  !isEmptyObjectOrUndefined(selectEvent)
                    ? selectEvent?.maximum_capacity
                    : 0
                }`}
                value="1"
                sx={{
                  fontFamily: "var(--font-regular)",
                  zIndex: "14",
                  position: "relative",
                }}
              />

              {approve_before_join && selectedOpenRegular === "Open Match" && (
                <Tab
                  label={`Waiting Approval ${ApprovedList}/${AllWaitingList?.length}`}
                  value="6"
                  sx={{
                    fontFamily: "var(--font-regular)",
                    zIndex: "14",
                    position: "relative",
                  }}
                />
              )}

              {selectedBookingType !== "Booking" && (
                <Tab
                  label={`${t("Calendar.Waiting List")} ${waitPlayers?.filter((item:any)=>item.status==='waiting'|| item.status==='waiting_approved')?.length}`}
                  value="2"
                  sx={{
                    fontFamily: "var(--font-regular)",
                    zIndex: "14",
                    position: "relative",
                  }}
                />
              )}

{/*             selectedOpenRegular === "Single" ||
                selectedOpenRegular === "Double"*/} 
              {(selectedOpenRegular === "Open Match" ) && (
                <Tab
                  label="Chat"
                  value="3"
                  sx={{
                    fontFamily: "var(--font-regular)",
                    zIndex: "14",
                    position: "relative",
                  }}
                />
              )}

              {CancelledPlayer?.length > 0 && (
                <Tab
                  label={`Cancelled ${CancelledPlayer?.length}`}
                  value="4"
                  sx={{
                    fontFamily: "var(--font-regular)",
                    zIndex: "14",
                    position: "relative",
                  }}
                />
              )}

              {!friendly_match && selectedOpenRegular === "Open Match" && (
                <Tab
                  label={`Score (${ScoreSET})`}
                  value="5"
                  sx={{
                    fontFamily: "var(--font-regular)",
                    zIndex: "14",
                    position: "relative",
                  }}
                />
              )}
            </TabList>
          </Box>
          {/* -------- people listed ------- */}
          <TabPanel value="1" sx={{ padding: "10px 0", zIndex: "0" }}>
            {selectedOpenRegular == "Double" ||
            selectedOpenRegular == "Open Match" ? (
              <TeamMatesList
              AllcustomerList={customerList}
              WaitingAppRefetch={WaitingAppRefetch} 
              waitPlayers={waitPlayers}
              filterCustomerList={filterCustomerList}
                sendNotification={sendNotification}
                SetSendNotification={SetSendNotification}
                SetAddTeam={setTeamMates}
                AllPlayers={AllPlayers}
                addTeam={addTeam}
                teamMates={teamMates}
                fromWh={fromWh}
                updatePAB={update}
                handleEditInfo={handleEditInfo}
                location={columnsArray}
                events={events}
                serviceLists={updateList}
                updateTab={PlayNotRefetch}
                selectEvent={selectEvent}
                setTeammatesData={setCustomerList}
                setTeams={setTeams}
                getAllPlayerList={getAllPlayerList}
              />
            ) : (
              <PersonList
              filterCustomerList={filterCustomerList}
                sendNotification={sendNotification}
                SetSendNotification={SetSendNotification}
                fromWh={fromWh}
                updatePAB={update}
                location={columnsArray}
                update={PlayNotRefetch}
                serviceLists={updateList}
                events={events}
                userData={playerList}
                updateList={setPlayerList}
                handleMasterCheckboxChange={handleMasterCheckboxChange}
                selectedOpenRegular={selectedOpenRegular}
                handleCheckboxChange={handleCheckboxChange}
                setSave={setSave}
                handleEditInfo={handleEditInfo}
                setCustomerList={setCustomerList}
                setPayPlayers={setPayPlayers}
                selectEvent={selectEvent}
              />
            )}
          </TabPanel>
          {/* ------ waiting list ----- */}
          <TabPanel value="2" sx={{ padding: "10px 0", zIndex: "11" }}>
            {
              // selectedBookingType!=="Booking"
              // ?
              selectedOpenRegular === "Double" ? (
                <WaitingTeam
                waitPlayers={waitPlayers}
                filterCustomerList={filterCustomerList}
                  teamMates={teamMates}
                  events={events}
                  serviceLists={()=>handleUpdate()}
                  selectEvent={selectEvent}
                  WaitingAppRefetch={WaitingAppRefetch}
                  teams={teams}
                />
              ) : (
                <WaitingList
                filterCustomerList={filterCustomerList}
                  userData={waitPlayers}
                  PlayNotRefetch={()=>handleUpdate()}
                  waitingList={WaitingAppRefetch}
                  updateList={setWaitPlayers}
                  handleMasterCheckboxChange={handleMasterCheckboxChange}
                  selectAll={selectAll}
                  selectedOpenRegular={selectedOpenRegular}
                  handleCheckboxChange={handleCheckboxChange}
                />
              )
              // :  <ScoreList userData={waitingList} handleMasterCheckboxChange={handleMasterCheckboxChange} selectAll={selectAll} selectedOpenRegular={selectedOpenRegular} handleCheckboxChange={handleCheckboxChange} />
            }
          </TabPanel>
          <TabPanel value="3" sx={{ padding: "10px 0", zIndex: "0" }}>
            <ChatApp />
          </TabPanel>
          <TabPanel value="4" sx={{ padding: "10px 0", zIndex: "10" }}>
            <Box
              id="table-person"
              sx={{
                marginTop: "0px !important",
                maxHeight: "313px !important",
              }}
            >
              <Box sx={{ position: "relative" }} className="person-list">
                {/* {event?.is_cancelled && (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      background: "white",
                      zIndex: 11,
                      opacity: "0.3",
                    }}
                  ></Box>
                )} */}
                {CancelledPlayer?.map((player: any, index: number) => (
                  <PersonTableRow
                    sendNotification={sendNotification}
                    SetSendNotification={SetSendNotification}
                    isCheckBox={true}
                    selectedCheckbox={[]}
                    setSelectedCheckBox={() => console.log("")}
                    index={index}
                    userData={player}
                    selectedOpenRegular={selectedOpenRegular}
                    handleCheckboxChange={handleCheckboxChange}
                    handleEditInfo={handleEditInfo}
                    setCustomerList={setCustomerList}
                  />
                ))}
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value="5" sx={{ padding: "10px 0", zIndex: "0" }}>
            <ScoreList
              ScoreData={{
                getScoreSuccess,
                getScoreData,
                getScoreError,
                getScoreRefetch,
              }}
              userData={teamMates}
            />
          </TabPanel>
          <TabPanel value="6" sx={{ padding: "10px 0", zIndex: "0" }}>
            <WaitingApprovalList
              AllWaitingList={AllWaitingList}
              refetch={WaitingAppRefetch}
            />
          </TabPanel>
        </TabContext>
      </Grid>
    </>
  );
};

export default EventTab;
