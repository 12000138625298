import AirDatepicker from "air-datepicker";
import moment from "moment";
import React, { useEffect, useState } from "react";
import localeEn from "air-datepicker/locale/en";
// import localeRu from 'air-datepicker/locale/ru';
// import localeEs from 'air-datepicker/locale/es';
import Popover from "@mui/material/Popover";
import { IoIosArrowDown } from "react-icons/io";
import { Box } from "@mui/material";
import { CiCalendar } from "react-icons/ci";
import { useTranslation } from "react-i18next";

const CustomTimeSelecotr = ({
  setSelectedDays,
  format,
  selectedDays,
  type,
  handleClose,
}: {
  format: any;
  handleClose: any;
  setSelectedDays: any;
  selectedDays: any;
  type: string;
}) => {
  const { i18n } = useTranslation();
  const [selDate, setSelDate] = useState<any>(selectedDays);
  const CurrenLanguage = i18n.language;

  const dateFormat = type === "single" ? "dd/MM/yyyy" : "";
  let isRANGECAL = true;
  const isRange = type === "single" ? false : isRANGECAL;
  useEffect(() => {
    new AirDatepicker("#calendar", {
      locale: localeEn,
      range: isRange,
      dateFormat: dateFormat,
      multipleDatesSeparator: " - ",
      selectedDates:
        type === "single"
          ? [selectedDays]
          : [selectedDays.date_start, selectedDays.date_end],
      onSelect: ({ formattedDate }) => {
        const newSelectedDates = Array.isArray(formattedDate)
          ? formattedDate
          : [formattedDate]; // Ensure date is always an array
        if (type === "single") {
          setSelDate(newSelectedDates[0]);
          handleClose();
        } else if (
          newSelectedDates[0] == undefined &&
          newSelectedDates[1] === undefined
        ) {
          setSelDate([]);
        } else if (
          newSelectedDates[0] !== undefined &&
          newSelectedDates[1] === undefined
        ) {
          isRANGECAL = false;
          const datesToStore = {
            date_start: newSelectedDates[0],
            date_end: newSelectedDates[0],
          };
          setSelDate(datesToStore);
        } else if (
          newSelectedDates[0] !== undefined &&
          newSelectedDates[1] !== undefined
        ) {
          isRANGECAL = true;
          const datesToStore = {
            date_start: newSelectedDates[0],
            date_end: newSelectedDates[1],
          };
          setSelDate(datesToStore);
        }
      },
    });
  }, []);

  useEffect(() => {
    const currentDate = moment(new Date()).format("DD/MM/YYYY");
    if (type === "single") {
      selDate ? setSelectedDays(selDate) : setSelDate(currentDate);
    }
  }, [type, selDate]);

  const handleSave = () => {
    setSelectedDays(selDate);
    handleClose();
  };

  return (
    <div className="flex flex-col">
      <div
        style={{ width: "239px !important", justifySelf: "center" }}
        id="calendar"
      />
      {type !== "single" && (
        <div className="flex flex-row justify-end items-center">
          <button
            onClick={handleSave}
            style={{
              marginTop: "10px",
              textTransform: "unset",
              fontFamily: "var(--font-semibold)",
              color: "white",
              backgroundColor: "#22356D",
              width: "92px",
              height: "37px",
              fontSize: "16px",
            }}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomTimeSelecotr;

export const SingleDate = ({
  isDisable,
  style,
  containercss,
  placeholder,
  icon,
  selectedDay,
  setSelectedDays,
  type,
  format,
  IcPosition,
}: {
  IcPosition: string;
  format: any;
  type: any;
  containercss: any;
  placeholder: any;
  style: any;
  icon: boolean;
  selectedDay: any;
  setSelectedDays: any;
  isDisable?: boolean;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const { t, i18n } = useTranslation();
  const handleClick: any = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isDisable) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const currentLanguage = i18n.language;
  const value =
    selectedDay !== "No"
      ? moment(selectedDay).locale(currentLanguage).format(format)
      : "Select Date";

  return (
    <div style={containercss} className="flex flex-row items-center ">
      {icon && IcPosition === "left" && (
        <Box
          sx={{
            marginInline: "5px",
            ">img": { display: { xs: "none", sm: "none", md: "block" } },
          }}
          onClick={handleClick}
        >
          <CiCalendar
            style={{ color: "rgba(34, 53, 109, 0.90)", fontSize: "19px" }}
          />
        </Box>
      )}
      <input
        onClick={handleClick}
        value={value}
        placeholder={placeholder}
        style={style}
        className="calendar-ui"
        readOnly
      />
      {icon && IcPosition === "right" && (
        <span style={{ marginLeft: "5px" }}>
          <IoIosArrowDown style={{ color: "rgba(34, 53, 109, 0.90)" }} />
        </span>
      )}
      <Popover
        id={id}
        open={open}
        style={{ overflow: "hidden", overflowY: "hidden" }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <CustomTimeSelecotr
          format={format}
          handleClose={handleClose}
          type={type}
          selectedDays={selectedDay}
          setSelectedDays={setSelectedDays}
        />
        {/* <Typography sx={{width:'240px',height:'250px'}}></Typography> */}
      </Popover>
    </div>
  );
};

export const SingleDateMu = ({
  style,
  containercss,
  placeholder,
  icon,
  selectedDay,
  setSelectedDays,
  type,
  format,
}: {
  format: any;
  type: any;
  containercss: any;
  placeholder: any;
  style: any;
  icon: any;
  selectedDay: any;
  setSelectedDays: any;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick: any = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div style={containercss} className="flex flex-row items-center">
      <input
        onClick={handleClick}
        value={selectedDay}
        placeholder={placeholder}
        style={{ ...style, color: "#22356D" }}
        className="calendar-ui"
        readOnly
      />{" "}
      <span style={{ marginLeft: "5px" }}>{icon}</span>
      <Popover
        id={id}
        open={open}
        style={{ overflow: "hidden", overflowY: "hidden" }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <SingleD
          handleClose={handleClose}
          selectedMonth={selectedDay}
          setSelectedMonth={setSelectedDays}
        />
        {/* <Typography sx={{width:'240px',height:'250px'}}></Typography> */}
      </Popover>
    </div>
  );
};

export const FinalCalednarasdmnf = ({
  style,
  containercss,
  placeholder,
  icon,
  selectedDay,
  setSelectedDays,
  type,
}: {
  type: any;
  containercss: any;
  placeholder: any;
  style: any;
  icon: boolean;
  selectedDay: any;
  setSelectedDays: any;
}) => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const currentLanguage = i18n.language;
  const handleClick: any = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const isToday =
    moment(new Date()).locale(currentLanguage).format("YYYY-MM-DD") ===
      selectedDay.date_start &&
    moment(new Date()).locale(currentLanguage).format("YYYY-MM-DD") ===
      selectedDay.date_end;
  const SameDate =
    moment(selectedDay.date_start, "MM/DD/YYYY")
      .locale(currentLanguage)
      .format("DD-MMMM") ===
    moment(selectedDay.date_end, "MM/DD/YYYY")
      .locale(currentLanguage)
      .format("DD-MMMM")
      ? moment(selectedDay.date_end, "MM/DD/YYYY")
          .locale(currentLanguage)
          .format("DD-MMMM")
      : `${moment(selectedDay.date_start, "MM/DD/YYYY")
          .locale(currentLanguage)
          .format("DD-MMMM")}-${moment(selectedDay.date_end, "MM/DD/YYYY")
          .locale(currentLanguage)
          .format("DD-MMMM")}`;
  return (
    <div style={containercss} className="flex flex-row items-center">
      <input
        onClick={handleClick}
        value={isToday ? t("common.Today") : SameDate}
        placeholder={placeholder}
        style={style}
        className="calendar-ui"
        readOnly
      />
      {icon && (
        <span style={{ marginLeft: "5px" }}>
          <IoIosArrowDown style={{ color: "rgba(34, 53, 109, 0.90)" }} />
        </span>
      )}
      <Popover
        id={id}
        open={open}
        style={{ overflow: "hidden", overflowY: "hidden" }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <CustomTimeSelecotr
          format={""}
          type={type}
          handleClose={handleClose}
          selectedDays={selectedDay}
          setSelectedDays={setSelectedDays}
        />
        {/* <Typography sx={{width:'240px',height:'250px'}}></Typography> */}
      </Popover>
    </div>
  );
};

export const SingleD = ({
  setSelectedMonth,
  selectedMonth,
  handleClose,
}: {
  handleClose: any;
  setSelectedMonth: any;
  selectedMonth: any;
}) => {
  useEffect(() => {
    new AirDatepicker("#calendar", {
      dateFormat: "yyyy-MM-dd",
      selectedDates: [selectedMonth],
      locale: localeEn,
      onSelect: ({ formattedDate }) => {
        setSelectedMonth(formattedDate);
        handleClose();
      },
    });
  }, []);

  return (
    <div>
      <span
        style={{ width: "239px !important", justifySelf: "center" }}
        id="calendar"
      />
    </div>
  );
};

export const CustomMonthSelector = ({
  setSelectedMonth,
  selectedMonth,
  handleClose,
}: {
  handleClose: any;
  setSelectedMonth: any;
  selectedMonth: any;
}) => {
  useEffect(() => {
    new AirDatepicker("#calendar", {
      view: "months",
      minView: "months",
      dateFormat: "MMMM yyyy",
      selectedDates: [selectedMonth],
      locale: localeEn,
      onSelect: ({ formattedDate }) => {
        setSelectedMonth(formattedDate);
        handleClose();
      },
    });
  }, []);

  return (
    <div>
      <span
        style={{ width: "239px !important", justifySelf: "center" }}
        id="calendar"
      />
    </div>
  );
};

export const FinalMonthCalendar = ({
  style,
  containercss,
  placeholder,
  icon,
  selectedDay,
  setSelectedDays,
}: {
  containercss: any;
  placeholder: any;
  style: any;
  icon: boolean;
  selectedDay: any;
  setSelectedDays: any;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick: any = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div style={containercss} className="flex flex-row items-center">
      <input
        onClick={handleClick}
        value={moment(selectedDay).format("MMMM YYYY")}
        placeholder={placeholder}
        style={style}
        className="calendar-ui"
        readOnly
      />
      {icon && (
        <span style={{ marginLeft: "5px" }}>
          <IoIosArrowDown style={{ color: "rgba(34, 53, 109, 0.90)" }} />
        </span>
      )}
      <Popover
        id={id}
        open={open}
        style={{ overflow: "hidden", overflowY: "hidden" }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <CustomMonthSelector
          handleClose={handleClose}
          selectedMonth={selectedDay}
          setSelectedMonth={setSelectedDays}
        />
        {/* <Typography sx={{width:'240px',maxHeight:'250px',minHeight:'220px'}}></Typography> */}
      </Popover>
    </div>
  );
};
