import { Box, Typography } from '@mui/material'
import moment from 'moment';
import React, { useEffect, useState } from 'react'


interface admin{
    id:number,
    full_name:string,
    role_id:number
}
interface chats{
    _id:string,
    message:string,
    created_at:string,
    customer_id:number,
    admin_id:number
}
interface customer{
    first_name:string,
    id:number,
    last_name:string,
}
interface user{
    id:number,
    is_cancelled:boolean,
    customer:customer
}
const AllRoles = [{
    id:1,
    name:'Super Admin'
},{
    id:2,
    name:'club Admin'
},{
    id:4,
    name:'Staff Manager'
},{
     id:6,
    name:'Staff'
}];

const Singlechat = ({item,admins,users}:{item:chats,admins:admin[],users:user[]}) => {
    const findAdminUser = ()=>{
        const currentuser = item?.customer_id!== null ? users?.filter((user:user)=>user?.customer?.id===Number(item?.customer_id)):admins?.filter((admin:admin)=>admin.id===Number(item?.admin_id));
        return currentuser||[]
    }
    const currentUser :any= findAdminUser()[0]||[]
    const findType = ()=>{
        return item?.admin_id!==null?AllRoles?.filter((item:any)=>item.id===currentUser?.role_id)[0]?.name:'customer'
    }
 
  return (
    <div>
        {item?.customer_id!==null ? <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          marginBottom: "10px",
        }}
      >
        <Box
          sx={{
            boxShadow: "0px 4px 4px 0px #0000001A",
            background: "#889AB61A",
            width: "fit-content",
            padding: "7px",
            color: "#22356D",
            ">div>p": { fontFamily: "var(--font-regular) !important" },
            display: "flex",
            gap: "15px",
            borderRadius: "7px",
          }}
        >
          <Box>
            <Typography>{`${currentUser?.customer?.first_name} ${currentUser?.customer?.last_name}`} ({findType()})</Typography>
            <Typography sx={{ fontSize: "14px" }}>
             {item?.message}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography sx={{ color: "#22356D80", fontSize: "13px" }}>
            {moment(item?.created_at).format('HH:mm')}
            </Typography>
          </Box>
        </Box>
      </Box>:
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Box
          sx={{
            boxShadow: "0px 4px 4px 0px #0000001A",
            background: "#22356DB2",
            width: "fit-content",
            padding: "7px",
            color: "white !important",
            ">div>p": {
              fontFamily: "var(--font-regular) !important",
              color: "white !important",
            },
            display: "flex",
            gap: "15px",
            borderRadius: "7px",
          }}
        >
          <Box>
          <Typography>{currentUser?.full_name} ({findType()})</Typography>
            <Typography sx={{ fontSize: "14px" }}>
            {item?.message}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography sx={{ color: "white", fontSize: "13px" }}>
            {moment(item?.created_at).format('HH:mm')}
            </Typography>
          </Box>
        </Box>
      </Box>}
    </div>
  )
}

export default Singlechat