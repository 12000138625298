import { Box, Button, Dialog, FormControl, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { dopdowncustomcss } from 'src/constants/dropdownCSS';
import CustomTimeSelecotr, { FinalCalednarasdmnf } from '../../components/customTimeSeletor';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const Duration =[
    "no time based","no time expiration","1 day","2 days","3 days",
    "4 days",
    "5 days",
    "6 days",
    "7 days",
    "8 days",
    "9 days",
    "10 days",
    "11 days",
    "12 days",
    "13 days",
    "14 days",
    "15 days",
    "16 days",
    "17 days",
    "18 days",
    "19 days",
    "20 days",
    "21 days",
    "22 days",
    "23 days",
    "24 days",
    "25 days",
    "26 days",
    "27 days",
    "28 days",
    "29 days",
    "30 days","1 month","2 months","3 months","4 months",
    "5 months","6 months","7 months","8 months","9 months","10 months","11 months","12 months",
]


const DurationSelector = ({setIsopen,duration,setValue,duration_date}:{setIsopen:any,duration:any,setValue:any,duration_date:any}) => {
    // const [SelectDuration,setSelectedDuration] = useState('');
    const {t} = useTranslation();
   
    // const [selectedDay,setSelectedDays] = useState('');

    const handleClose=()=>{
        setIsopen(false)
    }

    const handelClickDuration =(dur:any)=>{
       if(duration===dur){
        setValue('duration','')
       }else{
        setValue('duration',dur)
       }
    }

  return (
    <div>
         <Box sx={{opacity:duration_date?0.8:1,pointerEvents:duration_date?'none':'auto'}}>
          <FormControl>
            <Select
                IconComponent={KeyboardArrowDownIcon}
                placeholder="Customer type"
                id="customdropdown"
                value={duration ? duration :'Select Duration'}
                renderValue={(value) => {
                    if (value) {
                    return value;
                    }
                    return <em>{"Select Duration"}</em>;
                }}
                style={{
                width: "240px",
                height: "35px",
                border: "1px solid #EEEEFE",
                borderRadius: "7px",
                fontSize: "14px",
                opacity: "1",
                fontFamily: "var(--font-regular)",
                color: "#22356D",
                }}
                MenuProps={{ sx: dopdowncustomcss }}
            >
                {Duration?.map((duration: any) => (
                <MenuItem
                sx={{textTransform:'capitalize'}}
                onClick={()=>handelClickDuration(duration)}
                    key={duration}
                    value={duration}
                >
                    {duration}
                </MenuItem>
                ))}
            </Select>
          </FormControl>
          </Box>
          <Typography sx={{height:"33px",color:"rgba(34, 53, 109)",fontFamily: "var(--font-regular)",fontSize: "15px",marginTop:'15px'}}>Or select finishing date</Typography>
          <Box sx={{opacity:duration?0.8:1,pointerEvents:duration?'none':'auto'}}>
          <CustomTimeSelecotr format={""} type={'single'} handleClose={()=>console.log('')} selectedDays={moment(duration_date,'DD/MM/YYYY').toDate()} setSelectedDays={(date:any)=>setValue('duration_date',date)}/>
         
          </Box>
          <Box sx={{display:'flex',justifyContent:'flex-end'}}>
          <Button
          onClick={()=>setIsopen(false)}
                style={{
                    zIndex: 1,
                    textTransform: "unset",
                    fontFamily: "var(--font-semibold)",
                    color: "white",
                    backgroundColor: "#22356D",
                    width: "92px",
                    height: "37px",
                    fontSize: "16px",
                    marginTop:'20px',
                }}
            >
                {t('common.Save')}
            </Button>
            </Box>
    </div>
  )
}

export default DurationSelector