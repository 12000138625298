import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import WatitingApproveSingle from './WatitingApproveSingle';

const WaitingApprovalList = ({refetch,AllWaitingList}:{refetch:any,AllWaitingList:any[]}) => {
    const {t} = useTranslation();    
   
    
  return (
    <Box id="table-person" sx={{marginTop:"0px !important", maxHeight:"313px !important"}}>
              <Box className="waitingList">
                {
                AllWaitingList?.length>0 ?  AllWaitingList?.map((player:any,index:number)=>  <WatitingApproveSingle index={index} player={player} refetch={refetch}/>
                   ): <Typography sx={{fontFamily:'var(--font-regular)',padding:'10px'}}>{t('common.No user added')}</Typography>
                }
                </Box>   
              </Box>
  )
}

export default WaitingApprovalList