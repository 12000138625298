import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useContext, useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { allTimeFormats } from "src/constants/variables";
import CourtSettingsLeft2 from "./CourtSettingsLeft2";
import TimeRangePicker from "../../components/CustomDropdownTimeRange";
import BreakTimeRangePicker from "../../components/CustomDropdownBreakTimeRange";
import {
  AddCourtBySportAndLocation,
  AddCourtBySportID,
  DeleteCourtByID,
  updateCourtByCourtID,
  useGetCourtsByCourtID,
} from "src/api/CourtSettings";
import validateWorkingHours from "src/constants/workingHoursIschecked";
import ServicesRow from "./ServicesRow";
import { useFieldArray, useForm } from "react-hook-form";
import ConditionalDaysRow from "../../components/Services/ConditionalDaysRow";
import MainContext from "src/context/MainContext";
import { useTranslation } from "react-i18next";
import { useGetPermissions } from "src/api/CalendarBooking";

const CourtSettingLeft = ({
  courtName,
  setCourtNAme,
  Selectedcourt,
  selectedLocation,
  sport,
  fromaddCourt,
  getCourtByID,
  update,
  setBookableDaysFut,
  setTimeSlot_format
}: {
  courtName: string;
  setCourtNAme: (courtName: string) => void;
  fromaddCourt: any;
  Selectedcourt: any;
  selectedLocation: any;
  sport: any;
  getCourtByID: any;
  update:any;
  setBookableDaysFut:any;
  setTimeSlot_format:any;
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const {t} = useTranslation();
  const [showBreak, setShowBreak] = useState(false);
  const { control, register, setValue, watch } = useForm();
  const {setSelectedCourtDurations} = useContext(MainContext);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "Rows",
  });
  const conditionData = watch("Rows");
   const StaffPermission = useGetPermissions();
  const {Notification,setNotification,setIsPermissionOn} = useContext(MainContext);
  const [servicesopen, setServicesOpen] = useState(false);
  const [workingHoursDiff, setWorkingHoursDiff] = useState([]);
  const [TimeBreak, getTimeBreak] = useState<any>([]);
  const [SelectServices, setSelectedServices] = useState<any[]>();
  const [Error, setError] = useState<string>("");
  const [Bookings, setBookings] = useState<any>([]);
  const [Lessons, setLessons] = useState<any>([]);
  const [Events, setEvents] = useState<any>([]);

  const [time, setTime] = useState<any>();
  const {
    data: CourtData,
    isLoading: isCourtLoading,
    isError: isCoartError,
    refetch: refetchCourt,
  } = useGetCourtsByCourtID(Selectedcourt?.court_id);

  const finalData: any = {
    // min_capacity: min_capacity,
    // max_capacity: max_capacity,
    is_checked: isChecked,
    working_hours: isChecked
      ? workingHoursDiff
          ?.filter((item: any) => item.is_checked === true)
          ?.map((item: any) => ({
            start_time: item.start_time,
            end_time: item.end_time,
            is_checked: item.is_checked,
            days: item.days,
            break_times: item?.break_times?.map((data: any) => ({
              start_break: data.start_break,
              end_break: data.end_break,
            })),
          }))
      : [
          {
            start_time: time?.start_time,
            end_time: time?.end_time,
            break_times: TimeBreak?.map((item: any) => ({
              start_break: item.start_break,
              end_break: item.end_break,
            })),
          },
        ],
    special_days: conditionData,
    services: {
      bookings: Bookings[0]?.bookings,
      lessons: Lessons[0]?.lessons,
      events: Events[0]?.events,
    },
  };
  

  useEffect(() => {
    if (Selectedcourt.court_id > 0 && !fromaddCourt) {
      refetchCourt(Selectedcourt.court_id);
    }else if(fromaddCourt){
      setCourtNAme('');
      setTime({ start_time: "08:00:00", end_time: "22:00:00" });
      remove();
    }
  }, [Selectedcourt, fromaddCourt]);

  useEffect(() => {
    GetCourts();
  }, [CourtData]);

  useEffect(() => {
    setSelectedServices([]);
   
    if (Selectedcourt?.court_id === 0) {
      setTimeSlot_format('');
      setBookableDaysFut('');
      setTime({ start_time: "Select Time", end_time: "Select Time" });
      remove();
    }
  }, [Selectedcourt]);



  const GetCourts = async () => {
    if (Selectedcourt.court_id > 0 && !fromaddCourt) {
      setTime("");
      getTimeBreak([]);
      setIsChecked(false);
      setWorkingHoursDiff([]);
      setCourtNAme("");
      setSelectedServices([]);
      remove();
      if (!!CourtData) {
        setTimeSlot_format(CourtData?.timeslot_format);
        setSelectedServices(CourtData);
        const isCHECKED = validateWorkingHours(CourtData?.working_hours);
        if (isCHECKED) {
          setIsChecked(isCHECKED);
          const finalCondition = CourtData?.working_hours?.map((item: any) => {
            let { id, ...newitem } = item;
            id = item.days;
            let ischecked = true;
            return { id, ischecked, ...newitem };
          });
          setWorkingHoursDiff(finalCondition);
        } else {
          setIsChecked(false);
          setTime(CourtData?.working_hours[0]);

          getTimeBreak(CourtData?.working_hours[0]?.break_times);
        }
        const updatedDuratin = CourtData?.bookings?.map((dur:any)=>{
          const {name,...newdur} =dur;
          return newdur
        })
        setSelectedCourtDurations(updatedDuratin);
        CourtData?.special_days?.map((item: any, index: number) => {
          append(item);
        });
        setBookableDaysFut(CourtData.app_bookable_days_in_future)
        setCourtNAme(CourtData.court_name);
      }
    }
  };
  

  const handleDelete = async () => {
    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      return;
    }

    if (
      Selectedcourt?.court_id > 0 &&
      Selectedcourt?.court_id !== undefined &&
      fromaddCourt === false
    ) {
     try {
      const response = await DeleteCourtByID(Selectedcourt?.court_id);
      setNotification({message:'Court Deleted Successfully',subMessage:'',isShow:true,type:'success'});
        getCourtByID(sport?.id);
        update();
     } catch (error:any) {
      setNotification({message:error,subMessage:'',isShow:true,type:'error'});
     }
    
    }
  };

  const handleSave = () => {
    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      return;
    }
    
    if (
      selectedLocation?.location_name !== undefined &&
      sport?.sport_name !== undefined &&
      fromaddCourt === false &&
      Selectedcourt.court_id == 0
    ) {
      handleAddCourtBySportAndLocation();
    } else if (
      selectedLocation?.location_name !== undefined &&
      sport?.id !== 0 &&
      fromaddCourt === true
    ) {
      AddCourt();
    } else if (
      Selectedcourt.court_id !== 0 &&
      Selectedcourt.court_id !== undefined &&
      fromaddCourt === false
    ) {
      handleSubmit();
    }
  };

  // update by court id
  const handleSubmit = async () => {
    finalData.court_name = courtName;

    if(!courtName){
      setError("name");
      setTimeout(() => setError(""), 2000);
      return;
     }
    
    if(courtName){
      try {
        const response = await updateCourtByCourtID(
          Selectedcourt.court_id,
          finalData
        );
        if(response){
          setNotification({isShow:true,message:response,subMessage:'',type:'success'})
          update();
        }
      } catch (error:any) {
        setNotification({isShow:true,message:error?.response?.data?.message,subMessage:'',type:'error'})
      }
    }else{
      setNotification({isShow:true,message:"Please Enter the court name",subMessage:'',type:'error'})
    }
  };

  // update all court
  const handleAddCourtBySportAndLocation = async () => {
    const isTime = (isChecked && !!workingHoursDiff?.length) || (time?.start_time !== "Select Time" && time?.end_time!=="Select Time")
  if(isTime){
    try {
      const response = await AddCourtBySportAndLocation(
        sport.id,
        selectedLocation.location_name,
        finalData
      );
      if(response){
        setNotification({isShow:true,message:response,subMessage:'',type:'success'})
        setTime({ start_time: "Select Time", end_time: "Select Time" });
        update();
      }
     } catch (error:any) {
      setNotification({isShow:true,message:error?.response?.data?.message,subMessage:'',type:'error'});
     }
  }else{
    setNotification({isShow:true,message:"Please select new working hours for all courts before saving",subMessage:'',type:'error'});
  }
  };

  //addcourt
  const AddCourt = async () => {
    finalData.court_name = courtName;
    if(!courtName){
      setError("name");
      setTimeout(() => setError(""), 2000);
      return;
     }

    const isTime = (isChecked && !!workingHoursDiff?.length) || (time?.start_time !== "Select Time" && time?.end_time!=="Select Time")
   if(isTime){
    try {
      const response = await AddCourtBySportID(sport?.id, finalData);
      if (response) {
        setNotification({isShow:true,message:response,subMessage:'',type:'success'})
        getCourtByID(sport?.id);
        update();
      }
     } catch (error:any) {
      setNotification({isShow:true,message:error?.response?.data?.message,subMessage:'',type:'error'});
     }
   }else{
    !isTime && setNotification({isShow:true,message:'Please select opening and closing hours',subMessage:'',type:'error'});
    !courtName && setNotification({isShow:true,message:'Court Name is Required',subMessage:'',type:'error'});
   }
  };

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const removeBreak = (i: number) => {
    const filteredBreaks = TimeBreak.filter(
      (item: any, index: any) => index !== i
    );
    getTimeBreak(filteredBreaks);
  };

  const handleClose = () => {
    setShowBreak(false);
  };

  const handleAddCondition = () => {
    append({ start_time: "08:00:00", end_time: "22:00:00" });
  };

  const borderInput = {
    width: "46px",
    opacity: "0.7",
    padding: "5px 8px",
    fontSize: "14px",
    border: "1px solid #EEEFEE",
    borderRadius: "7px",
    color: "#22356D",
  };

  const handleUpdateForm = (data: any, index: number) => {
    const updatedObj = conditionData[index];
    setValue(`Rows.${index}.start_time`, data[0].start_time);
    setValue(`Rows.${index}.end_time`, data[0].end_time);
    if (data[0]?.date_end !== undefined && data[0]?.date_start !== undefined) {
      delete updatedObj["days"];
      setValue(`Rows.${index}.date_start`, data[0].date_start);
      setValue(`Rows.${index}.date_end`, data[0].date_end);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        backgroundColor: "white",
        padding: { xs: "0px",sm:"0px", md: "33px 50px 55px 50px" },
        borderRadius: "7px",
        marginLeft: { xs: "0px", sm:"0px", md: "60px" },
        marginTop: { xs: "0", sm:"0px", md: "15px" },
        height: "100%",
      }}
    >
      <Grid>
        <Box
          sx={{
            display: { xs: "none", sm:"none", md: "block" },
            margin: "10px 0",
            color: "#22356D",
            fontFamily: "var(--font-semibold)",
            fontSize: "16px",
          }}
        >
          {fromaddCourt ? (
            <input
             id={Error==="name"?'customerror':''}
              onChange={(e: any) => setCourtNAme(e.target.value)}
              value={courtName}
              style={{
                ...borderInput,
                width: "300px",
                fontSize: "16px",
                fontFamily: "var(--font-semibold)",
                opacity: 1,
              }}
              type="text"
              placeholder={t('courts.Enter Court Name')}
            />
          ) : Selectedcourt.court_id === 0 || Selectedcourt === "" ? (
            <Box sx={{ color: "#22356D" }}>{t('courts.All Courts')}</Box>
          ) : (
            <input
              id={Error==="name"?'customerror':''}
              onChange={(e: any) => setCourtNAme(e.target.value)}
              value={courtName}
              style={{
                ...borderInput,
                width: "300px",
                fontSize: "16px",
                fontFamily: "var(--font-semibold)",
                opacity: 1,
              }}
              type="text"
              placeholder={t('courts.Enter Court Name')}
            />
          )}
        </Box>
        {/* {Error ==="name" && (
                 
                )} */}
        <Box
          sx={{
            width: "max-content",
            display: { xs: "none", sm:"none", md: "block" },
            margin: "10px 0",
            color: "#22356D",
            fontFamily: "var(--font-semibold)",
            fontSize: "16px",
            borderBottom: "1px solid #22356D",
          }}
        >
          {t('courts.Courts Settings')}
        </Box>
        <Grid>
          <div
            style={{ margin: "10px 0px 5px 0px ", gap: "15px" }}
            className="flex flex-row items-center"
          >
            <h3
              style={{
                color: "#22356D",
                fontFamily: "var(--font-regular)",
                fontSize: "16px",
                lineHeight: "17px",
              }}
            >
             {t('courts.Working Hours')}
            </h3>
            <p
              style={{
                color: "rgba(34, 53, 109, 0.70)",
                fontFamily: "var(--font-regular)",
                fontSize: "14px",
                lineHeight: "17px",
              }}
            >
              {t('courts.Different every day')}
            </p>
            <label className="switch">
              <input
                checked={isChecked}
                onChange={handleCheckboxChange}
                type="checkbox"
              />
              <span className="slider round"></span>
            </label>
          </div>
          {isChecked ? (
            <CourtSettingsLeft2
              preWorkingHours={workingHoursDiff}
              setWorkingHoursDiff={setWorkingHoursDiff}
            />
          ) : (
            <Box
              className="CourtSettings"
              sx={{
                alignItems: { xs: "baseline", sm:"baseline", md: "center" },
                display: "flex",
                flexDirection: { xs: "column", sm:"column", md: "row" },
              }}
            >
              <Grid
                alignItems={"center"}
                container
                gap={"5px"}
                sx={{ margin: { xs: "0px", sm:"0px", md: "0px 20px" } }}
              >
                <TimeRangePicker
                  TimeRange={time}
                  listData={allTimeFormats}
                  getTime={setTime}
                />
                {TimeBreak?.length == 0 ? (
                  ""
                ) : (
                  <Grid container>
                    {TimeBreak?.map((item: any, index: any) => (
                      <div
                        key={item}
                        style={{ marginRight: "5px" }}
                        className="relative"
                      >
                        <span
                          key={index}
                          className="flex justify-between items-center"
                          style={{
                            height: "28px",
                            width: "110px",
                            padding: "5px 8px",
                            rowGap: "5px",
                            color: "#22356D",
                            borderRadius: "7px",
                            backgroundColor: "rgba(136, 154, 182, 0.15)",
                            fontFamily: "var(--font-regular)",
                            fontSize: "14px",
                          }}
                        >
                          <span>
                            {item?.start_break.slice(0, 5)}-
                            {item?.end_break.slice(0, 5)}
                          </span>
                          <Box
                            onClick={() => removeBreak(index)}
                            sx={{
                              marginTop: "-2px",
                              cursor: "pointer",
                              "&:hover": {
                                background: "#22356DB2",
                                color: "#FFFFFF",
                              },
                            }}
                          >
                            <RxCross2 />
                          </Box>
                        </span>
                      </div>
                    ))}
                  </Grid>
                )}
              </Grid>

              <div>
                <Button
                  onClick={() => setShowBreak(true)}
                  style={{
                    width: "160px",
                    color: "rgba(34, 53, 109, 0.70)",
                    fontFamily: "var(--font-regular)",
                    fontSize: "14px",
                    gap: "10px",
                    textTransform: "unset",
                  }}
                >
                  {t('courts.Add time break')}
                  <AddIcon
                    style={{ verticalAlign: "middle" }}
                    fontSize="small"
                  />
                </Button>
              </div>
            </Box>
          )}
        </Grid>
        <Grid style={{ margin: "25px 0" }}>
          <Grid container>
            <Grid container alignItems={"center"} gap={"15px"}>
              <h3
                style={{
                  color: "#22356D",
                  fontFamily: "var(--font-regular)",
                  fontSize: "16px",
                }}
              >
                {t('title.Services')}
              </h3>
            </Grid>
            <ServicesRow
            fromaddCourt={fromaddCourt}
              sport={sport}
              name={"Bookings"}
              selected={SelectServices}
              setSerivces={setBookings}
              court={Selectedcourt}
              data={"bookings"}
            />
            <ServicesRow
             fromaddCourt={fromaddCourt}
              sport={sport}
              name={"Lessons"}
              court={Selectedcourt}
              selected={SelectServices}
              setSerivces={setLessons}
              data={"lessons"}
            />
            <ServicesRow
             fromaddCourt={fromaddCourt}
              sport={sport}
              name={"Events"}
              selected={SelectServices}
              court={Selectedcourt}
              setSerivces={setEvents}
              data={"events"}
            />
          </Grid>
        </Grid>
        <Grid style={{ margin: "25px 0" }}>
          <Grid container>
            <Grid container alignItems={"center"} gap={"15px"}>
              <h3
                style={{
                  color: "#22356D",
                  fontFamily: "var(--font-regular)",
                  fontSize: "16px",
                }}
              >
            {t('courts.Special Days')}
              </h3>
              <Button
                onClick={handleAddCondition}
                style={{
                  color: "rgba(34, 53, 109, 0.70)",
                  fontFamily: "var(--font-regular)",
                  fontSize: "14px",
                  gap: "10px",
                  textTransform: "none",
                }}
              >
                  {t('courts.Add special day')}
               
                <AddIcon style={{ opacity: 0.7 }} fontSize="small" />
              </Button>
            </Grid>
            <div style={{ margin: "0px 20px", width: "100%" }}>
              {fields?.length !== 0 &&
                fields?.map((item: any, index: any) => (
                  <ConditionalDaysRow
                    setServicesOpen={setServicesOpen}
                    conditionData={conditionData}
                    handleUpdateForm={handleUpdateForm}
                    index={index}
                    remove={remove}
                    setValue={setValue}
                    key={index}
                  />
                ))}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={Selectedcourt?.court_id !== 0 &&
            Selectedcourt?.court_id !== undefined ?"space-between":'flex-end'}
        alignItems={"center"}
        marginTop={servicesopen ? "200px" : "20px"}
      >
        <Button
          onClick={handleDelete}
          style={
            Selectedcourt?.court_id !== 0 &&
            Selectedcourt?.court_id !== undefined
              ? {
                  textTransform: "unset",
                  fontFamily: "var(--font-semibold)",
                  width: "158px",
                  height: "37px",
                  color: "#22356D",
                  backgroundColor: "white",
                  fontSize: "16px",
                  padding: "10px 30px",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
                }
              : {
                  textTransform: "unset",
                  fontFamily: "var(--font-semibold)",
                  width: "104px",
                  height: "37px",
                  color: "#22356D",
                  display:'none',
                  backgroundColor: "white",
                  fontSize: "16px",
                  padding: "10px 30px",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
                }
          }
        >
        {t("courts.Delete Court")}
        </Button>
        <Button
          onClick={handleSave}
            style={Selectedcourt.court_id === 0 || Selectedcourt === "" && !fromaddCourt ?{
            textTransform: "unset",
            fontFamily: "var(--font-regular)",
            color: "white",
            backgroundColor: "#22356D",
            lineHeight:'14px',
            width: "140px",
            height: "37px",
            fontSize: "16px",
          }:{
            textTransform: "unset",
            fontFamily: "var(--font-semibold)",
            color: "white",
            backgroundColor: "#22356D",
            lineHeight:'14px',
            width: "92px",
            height: "37px",
            fontSize: "16px",
          }}
        >
         { fromaddCourt ? t("common.Save") : Selectedcourt===""|| Selectedcourt.court_id === 0 ? t("courts.Save for all court"):t("common.Save") }
        </Button>
      </Grid>
      <Dialog
        sx={{
          ".MuiDialog-paper": {
            width: "auto",
            height: "200px",
          },
        }}
        hideBackdrop={true}
        onClose={handleClose}
        open={showBreak}
      >
        <DialogContent>
          <Typography
            sx={{
              textTransform: "unset",
              fontFamily: "var(--font-semibold)",
              height: "37px",
              color: "#22356D",
              backgroundColor: "white",
              fontSize: "16px",
              margin: "10px",
            }}
          >
           {t('courts.Add break times')}
          </Typography>
          <BreakTimeRangePicker
            handleClose={handleClose}
            preTime={TimeBreak}
            getTime={getTimeBreak}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CourtSettingLeft;
