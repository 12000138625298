import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Calendar,
  DateLocalizer,
  Views,
  momentLocalizer,
} from "react-big-calendar";
import { getDateAtSpecificTime } from "src/constants/MomentTimeFor";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CoachPopover from "../CoachCalendar/CoachPopover";
import { useGetCoachesWeekly } from "src/api/CoachCalendar";
import BookingModel from "../calenderDaily/BookingModel";
import MainContext from "src/context/MainContext";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SlArrowRight } from "react-icons/sl";
import { SlArrowLeft } from "react-icons/sl";
import PropTypes from "prop-types";
import moment from "moment";
import BookedSlotPopover from "../calenderDaily/BookedSlotPopover";
import { roundTimeToNextHour } from "../calenderDaily/CourtCalendar";
import { calculateTotalHours } from "src/constants/variables";
import { useTranslation } from "react-i18next";

const localizer = momentLocalizer(moment);

const WeekCalendar = ({
  columnsArray,
  setSelectView,
  setWeeksGraph,
  resource,
  coachList,
  removeResource,
  selectedWeek,
  dates,
  view,
}: {
  columnsArray: string;
  setSelectView: (selectView: string) => void;
  resource: any;
  coachList: any;
  removeResource: any;
  selectedWeek: number;
  dates: any;
  view: string;
  setWeeksGraph: any;
}) => {
  const date = new Date(dates?.startDate);
  const min = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8);
  const max = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    0
  );
  const context = useContext(MainContext);
  const setEvent = context?.setEvent;
  const selectedDate = context?.selectedDate;
  const selectedMonth = context?.selectedMonth;
  const formats = { timeGutterFormat: "HH:mm" };
  const [minTime, setMinTime] = useState<any>(
    moment().hour(7).minute(0).second(0).millisecond(0).toDate()
  );
  const [TotalHeight, setTotalHeight] = useState(900);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const {
    data,
    isSuccess,
    isError,
    refetch,
    setcoach_id,
    setMonthYear,
    setWeekNumber,
  } = useGetCoachesWeekly();
  const {t,i18n} = useTranslation();
  const currentLanguage = i18n.language;
  const [currentTime, setCurrentTime] = useState(moment().format("HH:mm"));
  const [resources, setResources] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [serviceList, setServiceList] = useState<any>([]);
  const [filteredArray, setFilteredArray] = useState<any>([]);
  const [update, setUpdate] = useState<boolean>(false);
  const [maxTime, setMaxtime] = useState<any>("22:00:00");
  const [clickStart, setClickStart] = useState("");
  const [clickEvent, setClickEvent] = useState({});
  const [clickEnd, setClickEnd] = useState("");
  const [weekLists, setWeeks] = useState<any>([]);
  const updatedData: any = {};
  const service_id: number[] = [];
  const [clickCoach, setClickCoach] = useState<any>([]);
  const timeDisplayRef = useRef<any>(null);
  const manipulatedData: any = [];
  const itemsPerPage = 1;
  const setSelectedDate = context?.setSelectedDate;

  serviceList?.map((item: any) => {
    if (service_id.includes(item?.service?.id)) {
      updatedData[item?.service?.id] = {
        ...updatedData[item?.service?.id],
        value:
          updatedData[item?.service?.id]?.value +
          item?.service[item?.service?.service_type.toLowerCase()]
            ?.maximum_capacity,
      };
    } else {
      service_id.push(item?.service?.id);
      updatedData[item?.service?.id] = {
        value:
          item?.service[item?.service?.service_type.toLowerCase()]
            ?.maximum_capacity,
        players: item?.players?.length,
      };
    }
  });

  const ShowRecurring = () =>{  
    return (
      <Box sx={{width:'12.5px', height:'12.5px', borderRadius:'17px', background:"#889AB6", fontSize:"9px",lineHeight:"8px", paddingBlock:'2.5px', textAlign:'center', fontWeight:'500'}}>R</Box>
    )
  }

  const ShowAvailablity = () =>{  
    return (
      <Box sx={{width:'12.5px', height:'12.5px', borderRadius:'17px', background:"#455585", fontSize:"9px",lineHeight:"8px", paddingBlock:'2.5px', textAlign:'center', fontWeight:'500'}}>A</Box>
    )
  }

  const CustomEvent = ({ event }: { event: any }) => {
 
    const [showPopHover, setPopHover] = useState(null);
    const context = useContext(MainContext);
    const SelectedLocation = context?.SelectedLocation;
    const [anchorE1, setAnchorE1] = React.useState<HTMLElement | null>(null);
    // console.log(event)
    const handleMouseEnter = (events: React.MouseEvent<HTMLElement>) => {
      setPopHover(event);
      setAnchorE1(events.currentTarget);
    };

    const handlePopClose = () => {
      setAnchorE1(null);
      setPopHover(null);
    };

    const ENDTIME = moment(event.end_time).format("HH:mm")==="23:59"?"24:00":moment(event.end_time).format("HH:mm");
    if (event?.service?.service_type === "Event") {
    
      return (
        <Box
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handlePopClose}
          position={"relative"}
          height={"100%"}
        >
          <Typography
            sx={{
              fontFamily: "var(--font-regular)",
              fontSize: "0.8rem",
              color: "#22356D",
            }}
          >
            {moment(event.start_time).format("HH:mm")} -{" "}
            {ENDTIME}
          </Typography>
          <Typography
            sx={{
              fontFamily: "var(--font-medium)",
              fontSize: "0.9rem",
              color: "#F07727",
              textTransform:"capitalize"
            }}
          >
            {event?.service?.event?.event_name}
          </Typography>
          <Typography sx={{fontFamily:'var(--font-medium)', fontSize:"0.9rem", color:'#F07727', textTransform:"capitalize"}}>{event?.courts?.length > 1 ? `${event?.courts[0]?.court_name} +${event.courts?.length -1} more` : event?.courts[0]?.court_name}</Typography>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"flex-end"}
          >

            <Typography
              sx={{
                fontFamily: "var(--font-regular)",
                borderRadius: "7px",
                width: "fit-content",
                height: "14px",
                fontSize: "11px",
                padding: "2px 7px",
                textAlign: "center",
                background: `${
                  event?.players.length ==
                  event?.service?.event?.maximum_capacity
                    ? "transparent"
                    : "#F07727"
                }`,
                color: `${
                  event?.players.length ==
                  event?.service?.event?.maximum_capacity
                    ? "#F07727"
                    : "white"
                }`,
                position: "absolute",
                bottom: 0,
                right: 0,
              }}
            >
              {event?.players.length}
              {event?.service?.event?.maximum_capacity
                ? `/${event?.service?.event?.maximum_capacity}`
                : ""}
            </Typography>
          </Box>
          {/* ------ showing recurring ------ */}
          <Box sx={{position:"absolute", width:"100%", top:"5px", display:"flex", justifyContent:"end", gap:"5px"}}>
            {event?.service?.event?.app_available && ShowAvailablity()}
            {event.service?.recurring && ShowRecurring()}
          </Box>
          <BookedSlotPopover
            SelectedLocation={SelectedLocation}
            viewType="daily"
            players={updatedData[event.service?.id].players}
            total={updatedData[event.service?.id].value}
            eventType={showPopHover}
            anchorEl={anchorE1}
            handlePopoverClose={handlePopClose}
          />
        </Box>
      );
    } else if (event?.service?.service_type === "Lesson") {
  
      return (
        <Box
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handlePopClose}
          height={"100%"}
          position={"relative"}
        >
          <Typography
            sx={{
              fontFamily: "var(--font-regular)",
              fontSize: "0.8rem",
              color: "#22356D",
            }}
          >
            {moment(event.start_time).format("HH:mm")} -{" "}
            {ENDTIME}
          </Typography>
          <Typography
            sx={{
              fontFamily: "var(--font-medium)",
              fontSize: "0.9rem",
              color: "#A3A434",
            }}
          >
            {event?.service?.lesson?.lesson_name}
          </Typography>
          <Typography sx={{fontFamily:'var(--font-medium)', fontSize:"0.9rem", color:'#A3A434', textTransform:"capitalize"}}>{event?.courts.length > 1 ? `${event.courts[0].court_name} +${event.courts.length -1} more` : event.courts[0].court_name}</Typography>
          {/* ------ showing recurring ------ */}
          <Box sx={{position:"absolute", width:"100%", top:"5px", display:"flex", justifyContent:"end", gap:"5px"}}>
            {event?.service?.lesson?.app_available && ShowAvailablity()}
            {event.service?.recurring && ShowRecurring()}
          </Box>
          <BookedSlotPopover
            SelectedLocation={SelectedLocation}
            viewType="daily"
            players={updatedData[event.service?.id].players}
            total={updatedData[event.service?.id].value}
            eventType={showPopHover}
            anchorEl={anchorE1}
            handlePopoverClose={handlePopClose}
          />
        </Box>
      );
    } else if (event?.service?.service_type == "Block") {
      return (
        <>
          <Box
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handlePopClose}
            className="default-hover"
            height={"100%"}
          >
            <Typography
              sx={{
                fontFamily: "var(--font-regular)",
                fontSize: "0.8rem",
                color: "#889AB6CC",
              }}
            >
              {moment(event.start_time).format("HH:mm")} -{" "}
              {ENDTIME}
            </Typography>
            <Typography
              sx={{
                fontFamily: "var(--font-medium)",
                fontSize: "0.9rem",
                color: "#889AB6CC",
              }}
            >
              Blocked
            </Typography>
            <BookedSlotPopover
              SelectedLocation={SelectedLocation}
              viewType="weekly"
              players={updatedData[event.service?.id]?.players}
              total={updatedData[event.service?.id].value}
              eventType={showPopHover}
              anchorEl={anchorE1}
              handlePopoverClose={handlePopClose}
            />
          </Box>
        </>
      );
    } else if (event?.is_cancelled) {
      return (
        <Box
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handlePopClose}
          sx={{ pointerEvents: "all", cursor: "pointer" }}
          className="default-hover"
          width={"10px"}
          height={"100%"}
        >
          <BookedSlotPopover
            SelectedLocation={SelectedLocation}
            viewType="daily"
            players={updatedData[event.service?.id].players}
            total={updatedData[event.service?.id].value}
            eventType={showPopHover}
            anchorEl={anchorE1}
            handlePopoverClose={handlePopClose}
          />
        </Box>
      );
    } else {
      return (
        <>
          <Box
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handlePopClose}
            sx={{ pointerEvents: "all", cursor: "pointer" }}
            height={"100%"}
          ></Box>
          <CoachPopover
            coachTiming={resource}
            SelectedLocation={SelectedLocation}
            viewType="daily"
            eventType={showPopHover}
            anchorEl={anchorE1}
            handlePopoverClose={handlePopClose}
          />
        </>
      );
    }
  };

  // for styling
  const eventPropGetter = useCallback(
    (event: any, start: any, end: any, isSelected: any) => {
      const isAnyPlayerShown = event.players?.some(
        (item: any) => item?.is_show
      );
      const pendingPayment:any = event?.players?.filter((item:any)=>!item?.is_show && !item?.is_canceled)?.some((item:any) => item?.status ==="Pending payment");
      // const unfilledOpenMatch = event[event?.service_type?.toLowerCase()].event?.maximum_capacity!==event?.players?.length;

      return {
        ...(!event?.is_cancelled &&
          event?.service?.service_type?.includes("Event") && {
            className:
              isAnyPlayerShown && pendingPayment
                ? "twos-border"
                : isAnyPlayerShown
                ? "purple-border"
                : pendingPayment
                ? "red-border"
                : "court-border",
            style: {
              backgroundColor: "#FDF0E699",
              border: "#F07727 solid 0.5px",
            },
          }),
        ...(!event?.is_cancelled &&
          event?.service?.service_type?.includes("Lesson") && {
            className:
              isAnyPlayerShown && pendingPayment
                ? "twos-border"
                : isAnyPlayerShown
                ? "purple-border"
                : pendingPayment
                ? "red-border"
                : "court-border",
            style: {
              backgroundColor: "#FFFDD199",
              border: "#A3A434 solid 0.5px",
            },
          }),
        ...(event?.is_cancelled &&
          event?.service?.service_type?.includes("Event") && {
            className: "cancel eventCan",
            style: {
              borderRadius: "7px !important",
            },
          }),
        ...(event?.is_cancelled &&
          event?.service?.service_type?.includes("Lesson") && {
            className: "cancel lessCan",
            style: {
              borderRadius: "7px !important",
            },
          }),
        ...(event?.type?.includes("working_hours") && {
          className: "special",
        }),
        ...(event?.type?.includes("break_hours") && {
          className: "special",
        }),
        ...(event?.type?.includes("special_days") && {
          className: "special",
        }),
      };
    },
    []
  );

  function checkEventOverlap(startTime: any, endTime: any, fullName: string) {
    return filteredArray.some((event: any) => {
      const eventStart = new Date(event.start_time);
      const eventEnd = new Date(event.end_time);
      const inputStart = new Date(startTime);
      const inputEnd = new Date(endTime);
      const isTimeOverlap =
        (inputStart > eventStart && inputStart < eventEnd) ||
        (inputEnd > eventStart && inputEnd < eventEnd) ||
        (inputStart <= eventStart && inputEnd >= eventEnd);

      return isTimeOverlap && event.full_name === fullName;
    });
  }

  const handleClickOpen = (event: any) => {
    let data: { id?: number; full_name?: string } = {};
    const { start, end, resourceId } = event;
    setSelectedDate(start);
    coachList.filter((item: any) => {
      if (item.full_name === resourceId) {
        data = {
          id: item.id,
          full_name: item.full_name,
        };
      }
    });
    let start_time = moment(start).format("HH:mm");
    let end_time = moment(end).format("HH:mm");
    setClickStart(start_time);
    setClickEnd(end_time);
    setClickEvent({});
    setEvent({});
    setOpen(true);
    setClickCoach([data]);
    // let background = checkEventOverlap(start, end, resourceId);
    // if(background){
    //   setOpen(false);
    // }else{
    //   setOpen(true);
    // }
  };

  const handleEvent = (data: any) => {
    const { start_time } = data;
    setSelectedDate(start_time);
    setClickEvent(data);
    setOpen(true);
  };

  const previousPage = () => {
    if (currentIndex !== 0) {
      setCurrentIndex((prevIndex: any) =>
        Math.max(prevIndex - itemsPerPage, 0)
      );
    }
  };

  const nextPage = () => {
    if (currentIndex + itemsPerPage < resources.length) {
      setCurrentIndex((prevIndex: any) =>
        Math.min(prevIndex + itemsPerPage, resources.length)
      );
    }
  };

  const handleNavigate = (newDate: any) => {
    // Assuming newDate is the starting date of the week
    const startOfWeek = moment(newDate).startOf("week").toDate();
    const endOfWeek = moment(newDate).endOf("week").toDate();
    // setCurrentDate(startOfWeek);
    // Now you can use startOfWeek and endOfWeek to fetch and set the events for the correct week range
  };

  const displayedItems = resources.slice(
    currentIndex,
    currentIndex + itemsPerPage
  );

  function getWeekList(date: any) {
    const startDate = moment(date).startOf("week"); // Start date of the week
    const endDate = moment(date).endOf("week"); // End date of the week
    const weekList = [];

    // Loop through each day of the week
    for (
      let currentDay = startDate.clone();
      currentDay.isSameOrBefore(endDate);
      currentDay.add(1, "day")
    ) {
      weekList.push({
        date: currentDay.format("DD"),
        dayName: currentDay.format("dddd"),
        monthName: currentDay.format("MMMM"),
      });
    }
    setWeeks(weekList);
  }
  

  const DayHeader = ({date}:{date:any}) => {
     
    return (
      <div style={{fontFamily:"var(--font-regular)",lineHeight:'16px'}}>
        <p>{moment(date).locale(currentLanguage).format('dddd')}</p>
        <p><b>{moment(date).locale(currentLanguage).format('DD')}</b></p>
        <p>{moment(date).locale(currentLanguage).format('MMMM')}</p>
      </div>
    );
  };


  useEffect(() => {
    if (isSuccess) {
      const myArray = data?.serviceBookings;
      const newArray = myArray?.map((obj: any) => {
        const hello = obj?.service?.coaches?.map((item: any) => {
          const date = moment(obj?.date).format("DD/MM/YYYY");
          return {
            ...obj,
            full_name: item?.full_name,
            service_id: obj?.id,
            start_time: moment(
              `${date} ${obj?.start_time}`,
              "DD/MM/YYYY HH:mm:ss"
            ).toDate(),
            end_time: obj?.end_time ==="24:00:00" || obj?.end_time ==="00:00:00"? moment( `${date} "23:59:00"`,"DD/MM/YYYY HH:mm:ss").toDate() :moment(
              `${date} ${obj?.end_time}`,
              "DD/MM/YYYY HH:mm:ss"
            ).toDate(),
          };
        });

        return hello[0];
      });

      const NotHidden = newArray?.filter((item: any) => !item?.is_hide && !item?.is_cancelled);
      setWeeksGraph(data?.stats);
      setServiceList(NotHidden || []);
    } else {
      setServiceList([]);
    }
  }, [data, isSuccess, isError]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().format("HH:mm"));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (Object.keys(resource).length !== 0) {
      let month_year = moment(selectedMonth).format("MM-YYYY");
      setResources([resource]);
      setcoach_id(resource?.id);
      setMonthYear(month_year);
      setWeekNumber(selectedWeek);
      // getWeekCoachData(resource, selectedMonth, selectedWeek);
    } else {
      let month_year = moment(selectedMonth).format("MM-YYYY");
      setResources(coachList);
      setcoach_id(coachList[0]?.id);
      setMonthYear(month_year);
      setWeekNumber(selectedWeek);
      // getWeekCoachData(coachList[0], selectedMonth, selectedWeek)
    }
  }, [resource, selectedMonth, coachList, selectedWeek]);

  // const getServiceList = () =>{
  //   getWeekCoachData(resources[currentIndex], selectedDate, selectedWeek);
  // }

  useEffect(() => {
    const updateDisplayTime = () => {
      const firstDiv = document.querySelector(".rbc-current-time-indicator");
      const existingDisplayTime = document.getElementById("display-time-week");
      if (firstDiv !== null) {
        if (existingDisplayTime) {
          existingDisplayTime.textContent = currentTime;
        } else {
          const newDiv = document.createElement("div");
          newDiv.textContent = currentTime;
          newDiv.id = "display-time-week";
          firstDiv.appendChild(newDiv);
        }
      }
    };
    // Delay the update until after the initial render
    const timeoutId = setTimeout(updateDisplayTime, 1000);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
  }, [currentTime]);

  // useEffect(()=>{
  //   getServiceList();
  // },[currentIndex]);

  function getEarliestStartTimeForToday(coaches: any) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const today = new Date().getDay();
    const todayName = daysOfWeek[today];

    let earliestTime: any = null;
    let earliestObject: any = null;

    coaches?.forEach((coach: any) => {
      const todayTiming = coach.bookabledays.find(
        (timing: any) => timing.days === todayName
      );
      if (todayTiming) {
        if (!earliestTime || todayTiming.start_time < earliestTime) {
          earliestTime = todayTiming.start_time;
          earliestObject = coach;
        }
      }
    });
    let currentDate = moment(selectedDate).toDate();
    if (earliestTime) {
      let value = getDateAtSpecificTime(currentDate, earliestTime);
      setMinTime(value);
      return value;
    } else {
      return minTime;
    }
  }

  function getDateOfWeekInMonth(days: string) {
    const date = moment(selectedMonth, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
    const year = date.format("YYYY");
    let dateString: string = "";
    weekLists.forEach((element: any) => {
      if (element.dayName === days) {
        let date = element.date;
        let month = element.monthName;
        dateString = date + "-" + month + "-" + year;
      }
    });
    return dateString;
  }

  useEffect(() => {
    setFilteredArray([]);
    let dateStringss = getEarliestStartTimeForToday(resources);
    // const date = moment(dateStringss, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");

    //finding late end time
    let LateEndTime = "22:00:00";
    resources[0]?.bookabledays?.forEach((timing: any) => {
      if (timing.end_time > LateEndTime) {
        LateEndTime = timing.end_time;
      }
    });

    let latestStartTime: any = "07:00:00";
    resources[0]?.bookabledays?.forEach((timing: any) => {
      if (timing.start_time < latestStartTime) {
        latestStartTime = timing.start_time;
      }
    });

    let { endtime: latestEndTime, starttime }: any = roundTimeToNextHour(
      latestStartTime,
      LateEndTime
    );

    setMaxtime(latestEndTime);
    const startTimeDAy = moment()
      .hour(starttime?.slice(0, 2))
      .minute(starttime?.slice(3, 5))
      .second(0)
      .millisecond(0)
      .toDate();
    setMinTime(startTimeDAy);

    // Extract the time
    // const time = date.format("HH:mm:ss");
    const adjustTimings = (court: any) => {
      const { id, full_name, bookabledays } = court;
      const sTimeMoment = moment(starttime, "HH:mm:ss");
      const eTimeMoment = moment(latestEndTime, "HH:mm");
      const workingDays = bookabledays
        .filter((entry: any) => !entry.special)
        .map((entry: any) => entry.days);
      const allDays = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];
      const nonWorkingDays = allDays?.filter(
        (day) => !workingDays?.includes(day)
      );

      nonWorkingDays?.map((item: any) => {
        let dateString = getDateOfWeekInMonth(item);
        setFilteredArray((prevArray: any) => [
          ...prevArray,
          {
            day: item,
            type: "working_hours",
            start_time: moment(`${dateString} ${latestStartTime}`).toDate(),
            end_time: moment(
              `${dateString} 23:00:00`,
              "DD-MMMM-YYYY HH:mm:ss"
            ).toDate(),
            full_name,
            id,
          },
        ]);
      });

      bookabledays.forEach(
        ({ start_time, end_time, days, break_times, is_special, date_start, date_end }: any) => {
          const startTime = moment(start_time, "HH:mm:ss");
          const endTime = moment(end_time, "HH:mm:ss");
      
          if (days) {
            let dateString = getDateOfWeekInMonth(days);
            const specialDays = bookabledays?.filter(
              (item: any) => item.is_special === true
            );
      
            if (!!specialDays?.length) {
              let isHandled = false;
      
              specialDays?.forEach((speci: any) => {
                const startDate = new Date(speci?.date_start);
                const endDate = new Date(speci?.date_end);
                const selectedDate = new Date(dateString);
                const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
                const breakTimes = speci?.break_times;
                const isToday =
                  selectedDate >= startDate && selectedDate <= endDate;
                const sameDay = moment(selectedDate).format("dddd") === speci?.days;
                const SpecialstartTime = moment(speci.start_time, "HH:mm:ss");
                const SpecialendTime = moment(speci.end_time, "HH:mm:ss");
      
                if (isToday || sameDay) {
                  isHandled = true;
      
                  if (speci?.Unavailable) {
                    setFilteredArray((prev: any) => [
                      ...prev,
                      {
                        type: "working_hours",
                        day: days,
                        start_time: moment(
                          `${formattedDate} ${starttime}`,
                          "YYYY-MM-DD HH:mm:ss"
                        ).toDate(),
                        end_time: moment(
                          `${formattedDate} ${latestEndTime}`,
                          "YYYY-MM-DD HH:mm:ss"
                        ).toDate(),
                        id,
                        full_name,
                      },
                    ]);
                  } else {

                    handleNonSpecialCase(days, SpecialstartTime, SpecialendTime, break_times, is_special, dateString, full_name, id);

                    breakTimes.forEach(({ start_break, end_break }: any) => {
                      const startBreakTime = moment(
                        `${formattedDate} ${start_break}`,
                        "YYYY-MM-DD HH:mm:ss"
                      );
                      setFilteredArray((prev: any) => [
                        ...prev,
                        {
                          type: "break_hours",
                          start_time: startBreakTime.toDate(),
                          end_time: moment(
                            `${formattedDate} ${end_break}`,
                            "YYYY-MM-DD HH:mm:ss"
                          ).toDate(),
                          id,
                          day: days,
                          full_name,
                        },
                      ]);
                    });
                  }
                }
              });
      
              // If no special condition was met, fallback to default logic
              if (!isHandled) {
                handleNonSpecialCase(days, startTime, endTime, break_times, is_special, dateString, full_name, id);
              }
            } else {
              // No special days, just handle normally
              handleNonSpecialCase(days, startTime, endTime, break_times, is_special, dateString, full_name, id);
            }
          }
        }
      );
      
      function handleNonSpecialCase(days:any, startTime:any, endTime:any, break_times:any, is_special:any, dateString:any, full_name:any, id:any) {
        if (startTime.isAfter(sTimeMoment) && !is_special) {
          setFilteredArray((prevArray: any) => [
            ...prevArray,
            {
              type: "working_hours",
              day: days,
              start_time: moment(
                `${dateString} ${starttime}`,
                "DD-MMMM-YYYY HH:mm:ss"
              ).toDate(),
              end_time: moment(
                `${dateString} ${startTime.format("HH:mm:ss")}`,
                "DD-MMMM-YYYY HH:mm:ss"
              ).toDate(),
              full_name,
              id,
            },
          ]);
        }
      
        if (endTime.isBefore(eTimeMoment) && !is_special) {
          setFilteredArray((prevArray: any) => [
            ...prevArray,
            {
              type: "working_hours",
              day: days,
              start_time: moment(
                `${dateString} ${endTime.format("HH:mm:ss")}`,
                "DD-MMMM-YYYY HH:mm:ss"
              ).toDate(),
              end_time: moment(
                `${dateString} ${latestEndTime}`,
                "DD-MMMM-YYYY HH:mm:ss"
              ).toDate(),
              full_name,
              id,
            },
          ]);
        }
      
        if (break_times.length > 0 && !is_special) {
          break_times.forEach(({ start_break, end_break }: any) => {
            const startBreakTime = moment(start_break, "HH:mm:ss");
            if (startBreakTime.isAfter(moment(latestStartTime, "HH:mm:ss"))) {
              setFilteredArray((prev: any) => [
                ...prev,
                {
                  type: "break_hours",
                  day: days,
                  start_time: moment(
                    `${dateString} ${start_break}`,
                    "DD-MMMM-YYYY HH:mm:ss"
                  ).toDate(),
                  end_time: moment(
                    `${dateString} ${end_break}`,
                    "DD-MMMM-YYYY HH:mm:ss"
                  ),
                  full_name,
                  id,
                },
              ]);
            }
          });
        }
      }
      
    };

    resources.forEach(adjustTimings);
  }, [columnsArray, resources]);

  function removeDuplicatesAndOverlaps(data: any) {
    const result: any = [];

    data.forEach((current: any) => {
      const existingIndex = result.findIndex(
        (item: any) => item.day === current.day && item.id === current.id
      );

      if (existingIndex !== -1) {
        const existing = result[existingIndex];

        // Check if the current entry overlaps with the existing entry
        const currentStartTime: any = new Date(current.start_time);
        const currentEndTime: any = new Date(current.end_time);
        const existingStartTime: any = new Date(existing.start_time);
        const existingEndTime: any = new Date(existing.end_time);

        if (
          (currentStartTime >= existingStartTime &&
            currentStartTime < existingEndTime) ||
          (currentEndTime > existingStartTime &&
            currentEndTime <= existingEndTime) ||
          (currentStartTime <= existingStartTime &&
            currentEndTime >= existingEndTime)
        ) {
          // Merge time ranges if they overlap
          result[existingIndex] = {
            ...existing,
            start_time: new Date(
              Math.min(existingStartTime, currentStartTime)
            ).toISOString(),
            end_time: new Date(
              Math.max(existingEndTime, currentEndTime)
            ).toISOString(),
          };
        } else {
          // If no overlap, add the current entry as a separate entry
          result.push(current);
        }
      } else {
        // If no existing entry found, add the current one
        result.push(current);
      }
    });

    return result;
  }

  const filltered = removeDuplicatesAndOverlaps(filteredArray)||[];



  useEffect(() => {
    const min = moment(minTime).format("HH:mm:ss");
    const hours = calculateTotalHours(min, maxTime);
    const height: any = hours * 2 * 27;
    setTotalHeight(height);
  }, [minTime, maxTime]);

  useEffect(() => {
    getWeekList(moment(dates.startDate));
  }, [dates.startDate, selectedWeek]);

  useEffect(() => {}, [weekLists]);

  return (
    <Box marginRight={4}>
      <div
        style={{ width: "100%", position: "relative" } as React.CSSProperties}
      >
        {resources.length > 1 && currentIndex > 0 && (
          <Box
            position={"absolute"}
            top={"1.5%"}
            left={"4%"}
            sx={{ cursor: "pointer" }}
            onClick={previousPage}
          >
            <SlArrowLeft style={{ color: "#22356D" }} />
          </Box>
        )}
        {resources.length > 1 && resources.length !== currentIndex + 1 && (
          <Box
            position={"absolute"}
            top={"1.4%"}
            right={"0.5%"}
            sx={{ cursor: "pointer" }}
            onClick={nextPage}
          >
            <SlArrowRight style={{ color: "#22356D" }} />
          </Box>
        )}

        <Calendar
          key={weekLists}
          formats={formats}
          localizer={localizer}
          defaultView={Views.WEEK}
          dayLayoutAlgorithm={"no-overlap"}
          date={moment(dates?.startDate).startOf("week").toDate()}
          onNavigate={handleNavigate}
          backgroundEvents={filltered}
          events={serviceList}
          resourceIdAccessor="full_name"
          resourceAccessor="full_name"
          resourceTitleAccessor={(resource: any) => (
            <Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={1}
                onClick={() => {
                  setSelectView("Daily");
                  removeResource({});
                }}
              >
                {resource.profile_url && (
                  <img
                    src={resource.profile_url}
                    alt="coach"
                    style={{ width: "29px", height: "29px", borderRadius: "50%" }}
                  />
                )}
                <p>{resource.full_name}</p>
              </Box>
              <p>{resource?.sport?.sport_name}</p>
            </Box>
          )}
          titleAccessor="title"
          startAccessor="start_time"
          endAccessor="end_time"
          min={minTime}
          max={
            maxTime === "24:00:00"
              ? moment().endOf("day").toDate()
              : moment()
                  .hour(maxTime?.slice(0, 2))
                  .minute(maxTime?.slice(3, 5))
                  .second(0)
                  .millisecond(0)
                  .toDate()
          }
          resources={displayedItems}
          step={30}
          selectable
          onSelectSlot={handleClickOpen}
          onSelectEvent={handleEvent}
          eventPropGetter={eventPropGetter}
          components={{
            week: {
              header: DayHeader,
            },
            event: (serviceList) => {
              return CustomEvent(serviceList);
            },
          }}
          views={{ day: false, week: true, month: false }}
          style={{ height: TotalHeight }}
        />
        <Typography
          sx={{
            position: "absolute",
            bottom: "-10px",
            left: "5px",
            color: "#22356DB2",
            fontSize: "16px",
            fontFamily: "var(--font-regular)",
          }}
        >
          {moment(maxTime, "HH:mm:ss").format("HH:mm")}
        </Typography>
      </div>
      {/* ----- Model Box ------ */}
      <BookingModel
        fromWh={""}
        clickCoach={clickCoach}
        coach={true}
        events={clickEvent}
        update={update}
        serviceList={refetch}
        columnsArray={columnsArray}
        openBookingModel={open}
        setOpen={setOpen}
        clickStart={clickStart}
        clickEnd={clickEnd}
        clickCourt={[]}
        courtList={[]}
        view={"Coaches"}
      />
    </Box>
  );
};

export default WeekCalendar;

WeekCalendar.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
  dayLayoutAlgorithm: PropTypes.string,
};
