import { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { apiCaller } from "src/api/ApiCaller";
import { getClubId } from "src/api/CalendarBooking";

//get court details
export const useSportQuery = () => {
    const [location_id, setlocation_id] = useState<number>();

      let club_id = getClubId();
    const query = useQuery(
      ["Allsports", location_id],
      async () => {
        const response = await apiCaller.get(`${club_id}/services/sports/${location_id}`);
        const sortedData = response.data.data.length >0 ? response.data.data?.sort((a:any, b:any) => a.sequence - b.sequence) : response.data.data
        return sortedData
      },
      {
        retry:false,
        enabled: location_id ?true:false,
        staleTime: 1000 * 60 * 5,
      }
    );
    
      return { ...query, setlocation_id};
  };

