import { useState } from "react";
import { useQuery } from "react-query";
import { apiCaller } from "src/api/ApiCaller";
import { getClubId } from "src/api/CalendarBooking";

export const useGetCourtList= () => {
    const [location_id, setLocation_id] = useState<number>();
  
    const club_id = getClubId();
  
    const query = useQuery(
      ["GetCourtList",location_id ],
      async () => {
        const response = await apiCaller.get(`${club_id}/calendars/courts/${location_id}`);
        return response.data.data;
      },
      {
        enabled: !!location_id,
        staleTime: 2000,
        retry: false,
        refetchOnWindowFocus: false,
      }
    );
  
    return {...query,setLocation_id};
  };