import React, { useContext, useEffect, useState } from "react";
import { activeColor, pending } from "src/constants/color";
import PersonTableRow from "./PersonTableRow";
import AddPlayer from "./AddPlayer";
import Box from "@mui/material/Box";
import "../tableStyling.css";
import {
  Permission,
  UpdatePayments,
  getClubId,
  useGetAllCustomerList,
  useGetPermissions,
} from "src/api/CalendarBooking";
import MainContext from "src/context/MainContext";
import { apiCaller } from "src/api/ApiCaller";
import { RxCross2 } from "react-icons/rx";
import { useGetAllCustomSettings } from "src/api/Customer";
import { customSettingsObj } from "src/pages/Customers";
import PaymentSettings from "../../components/PaymentSettings";
import { useTranslation } from "react-i18next";
import { addThousandSeparator } from "src/constants/SportSEparator";

interface MyObject {
  id: number;
  first_name: string;
  last_name: string;
  email?: string;
}

const PersonList = ({
  sendNotification,
  SetSendNotification,
  fromWh,
  filterCustomerList,
  location,
  update,
  serviceLists,
  updatePAB,
  events,
  handleMasterCheckboxChange,
  userData,
  updateList,
  selectedOpenRegular,
  handleCheckboxChange,
  setSave,
  handleEditInfo,
  setCustomerList,
  setPayPlayers,
  selectEvent,
}: {
  location: any;
  update: any;
  serviceLists: any;
  events: any;
  fromWh: any;
  handleMasterCheckboxChange: any;
  updatePAB: any;
  userData: any;
  updateList: any;
  selectedOpenRegular: string;
  handleCheckboxChange: any;
  setSave: any;
  handleEditInfo: any;
  setCustomerList: any;
  setPayPlayers: any;
  selectEvent: any;
  filterCustomerList: any;
  sendNotification: any;
  SetSendNotification: any;
}) => {
  const context = useContext(MainContext);
  const { setIsPermissionOn } = useContext(MainContext);
  const { t } = useTranslation();
  const event = context?.event;
  const StaffPermission = useGetPermissions();
  const SelectedLocation = context?.SelectedLocation;
  const setSelectdPlayers = context?.setSelectdPlayers;
  const selectedLocationID = context?.selectedLocationId;
  const [addPlayer, setAddPlayer] = useState<string>("");
  const [addedPlayer, setAddedPlayer] = useState<any>([]);
  const [showList, setShowList] = useState<boolean>(false);
  const [data, setData] = useState([]);
  const [voucherAdd, setVoucherADD] = useState(false);
  const [payments, setPayments] = useState<any[]>([]);
  const [selectedCheckbox, setSelectedCheckBox] = useState([]);
  const [selectedRefunds, setSelectedRefunds] = useState<any>([]);
  const [selectedPayment, setSelectedPayment] = useState([]);
  const setUpdatePayments = context.setUpdatePayments;
  const [selected, setSelected] = useState<any[]>([]);
  const [customFields, setCustomFields] = useState<any[]>([]);
  const selectedPlayers = context?.selectedPlayers;
  const {
    data: CustomData,
    isSuccess: CustomSetSuccess,
    isError: CustomSettError,
  } = useGetAllCustomSettings();

  useEffect(() => {
    if (selectedPlayers?.length > 0 && Object.keys(event)?.length == 0) {
      const updatedPlayerList = filterCustomerList?.filter((item: any) => {
        return !selectedPlayers?.some(
          (sel: any) => Number(sel?.customerId) === Number(item?.id)
        );
      });
      setData(updatedPlayerList);
    } else {
      setData(filterCustomerList);
    }
  }, [filterCustomerList, events, selectedPlayers]);

  useEffect(() => {
    setSelectedPayment(
      selectedCheckbox?.filter((item: any) => item.status === "Pending payment")
    );
    setSelectedRefunds(
      selectedCheckbox?.filter((item: any) => item.status === "Paid")
    );
  }, [selectedCheckbox]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;
    setAddPlayer(val);
    setShowList(true);
    let arr = filterCustomerList.filter((item: any) => {
      let full_name = `${item.first_name} ${item.last_name}`;
      return full_name.toLowerCase().includes(val?.toLowerCase());
    });
    setData(arr);
  };

  const selectedUser = async (item: any) => {
    if (!StaffPermission.edit_permissions) {
      setIsPermissionOn(true);
      return;
    }

    setShowList(false);
    setAddPlayer(item.full_name);
    let data = {
      customerId: item.id,
    };
    if (Object.keys(event).length > 0) {
      try {
        let club_id = getClubId();
        let res: any = await apiCaller.post(
          `${club_id}/calendars/services/players/${event?.service?.id}/${event?.id}`,
          data
        );
        updateList(res?.data?.data);
        update();
        serviceLists();
        setAddPlayer("");
        if (fromWh == "payments" || fromWh === "bookings") {
          updatePAB();
        }
      } catch (error: any) {
        let err = error.response?.data?.message;
        console.log(err);
      }
    } else {
      setAddedPlayer((prev: any) => [...prev, item]);
      setSelectdPlayers((prev: any) => [...prev, data]);
      setAddPlayer("");
    }
  };

  useEffect(() => {
    const updatedPlayerList = userData?.map((item: any) => {
      let { email, phone_number, full_name, id } = item?.customer;
      return { email, phone_number, full_name, id };
    });
    // setDoPayment(userData?.filter((item:any)=>item.status ==="Pending payment"));
    // setDoRefunds(userData?.filter((item:any)=>item.status==="Paid" && !item?.refunded_status));
    setSelected(updatedPlayerList);
  }, [userData]);

  useEffect(() => {
    if (Object.keys(event).length > 0) {
      setAddedPlayer([]);
    }
  }, [selected]);

  useEffect(() => {
    document.addEventListener("click", () => {
      setShowList(false);
    });
  }, []);

  const handleClicked = (userData: any) => {
    setSelectedPayment((prev: any) => {
      if (prev?.length === 0) {
        return [userData];
      } else if (prev.some((avail: any) => avail.id === userData.id)) {
        return prev?.filter((old: any) => old.id !== userData.id);
      } else {
        return [...prev, userData];
      }
    });
  };

  const handleRefunds = (userData: any) => {
    setSelectedRefunds((prev: any) => {
      if (prev?.length === 0) {
        return [userData];
      } else if (prev.some((avail: any) => avail.id === userData.id)) {
        return prev?.filter((old: any) => old.id !== userData.id);
      } else {
        return [...prev, userData];
      }
    });
  };

  const handleClosePayment = () => {
    setUpdatePayments(false);
    setSelectedCheckBox([]);
    setSelectedPayment([]);
    setSelectedRefunds([]);
    setPayments([]);
    setVoucherADD(false);
  };

  function isEmptyObjectOrUndefined(value: any) {
    return (
      value === undefined ||
      (typeof value === "object" && Object.keys(value).length === 0)
    );
  }

  function formatNumber(num: number) {
    let numStr = num.toString();

    // If the number has more than 4 digits
    if (numStr.length > 3) {
      let formattedNum = (num / 1000).toFixed(3);
      return formattedNum;
    }

    return num;
  }

  //get customsettings fields
  useEffect(() => {
    if (CustomSetSuccess) {
      const filtered = CustomData?.filter(
        (item: customSettingsObj) => item.columnName !== "userId"
      );
      setCustomFields(filtered);
    }
  }, [CustomData, CustomSetSuccess, CustomSettError]);

  const removeitem = (index: number) => {
    const fieltered = addedPlayer?.filter(
      (item: any, i: number) => i !== index
    );
    const fielteredID = fieltered?.map((item: any) => {
      return { customerId: item.id };
    });
    setAddedPlayer(fieltered);
    setSelectdPlayers(fielteredID);
  };

  let totalDiscount: any =
    payments?.length === 0
      ? 0
      : payments?.reduce((item, acc: any) => item + acc?.discount, 0);
  let TotalPrice = payments?.reduce((acc, item: any) => acc + item?.price, 0);
  let finalPrice = TotalPrice - totalDiscount;
  let TotalRefunds = selectedRefunds?.reduce(
    (acc: any, item: any) => acc + item?.price,
    0
  );

  const Regular: boolean = true;
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <AddPlayer
          maxHeight={"200px"}
          update={() => console.log("refetch ")}
          isPlayer={Regular}
          addPlayer={addPlayer}
          handleChange={handleChange}
          showList={showList}
          setShowList={setShowList}
          data={data}
          selectedUser={selectedUser}
        />
        <Box width={"fit-content"} display={"flex"} alignItems={"center"}>
          <p className="selectalldflkndfg">{t("customers.Select all")}</p>
          <label style={{ margin: "10px" }} className="container">
            <input
              type="checkbox"
              checked={
                selectedCheckbox?.length === userData?.length &&
                selectedCheckbox?.length !== 0
              }
              onChange={() =>
                setSelectedCheckBox((prev: any) =>
                  prev?.length === 0
                    ? userData?.filter(
                        (use: any) => use?.refunded_status !== true
                      )
                    : []
                )
              }
            />
            <span className="mark"></span>
          </label>
        </Box>
      </Box>
      {/* ----- player list ----- */}
      {userData?.length > 0 && (
        <Box maxHeight={"280px"} id="table-person">
          <Box className="person-list">
            <Box>
              {userData.map((user: any, index: number) => (
                <Box
                  key={user.id}
                  display={"flex"}
                  gap={1}
                  sx={{ "&:hover": { background: "#889AB64D !important" } }}
                >
                  <Box sx={{ width: "26px", height: "26px" }}>
                    <Box
                      sx={{
                        borderRadius: "7px",
                        width: "100%",
                        height: "100%",
                        textAlign: "center",
                        padding: "0px 10px",
                        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      {index + 1}
                    </Box>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <PersonTableRow
                      sendNotification={sendNotification}
                      SetSendNotification={SetSendNotification}
                      selectedCheckbox={selectedCheckbox}
                      setSelectedCheckBox={setSelectedCheckBox}
                      index={index}
                      userData={user}
                      selectedOpenRegular={selectedOpenRegular}
                      handleCheckboxChange={handleCheckboxChange}
                      handleEditInfo={handleEditInfo}
                      setCustomerList={setCustomerList}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
      {addedPlayer?.length > 0 && (
        <Box maxHeight={"280px"} id="table-person">
          <Box className="person-list">
            <Box>
              {addedPlayer.map((user: any, index: number) => (
                <Box
                  key={user.id}
                  display={"flex"}
                  gap={1}
                  sx={{
                    justifyContent: "space-between",
                    "&:hover": { background: "#889AB64D !important" },
                  }}
                >
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <Box>
                      <Box
                        style={{
                          borderRadius: "7px",
                          width: "26px",
                          height: "26px",
                          textAlign: "center",
                          padding: "0px 10px",
                          boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {index + 1}
                      </Box>
                    </Box>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      gap={1}
                      sx={{
                        cursor: "pointer",
                        ">img": { display: { xs: "none", sm: "block" } },
                      }}
                    >
                      <img
                        src={
                          user?.profile_url
                            ? user?.profile_url
                            : "/images/Reserved.svg"
                        }
                        alt="user"
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                      />
                      <Box
                        sx={{
                          fontFamily: "var(--font-regular)",
                          color: "#22356D",
                        }}
                        lineHeight={"20px"}
                      >
                        <Box fontSize={"16px"}>
                          {user?.first_name} {user?.last_name}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      color: "red",
                      marginRight: "10px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {/* <Box>
                    {!isEmptyObjectOrUndefined(selectEvent) ? formatNumber(selectEvent.price) : ''}
                    </Box> */}
                    <RxCross2
                      onClick={() => removeitem(index)}
                      style={{ color: "#22356D", cursor: "pointer" }}
                    />{" "}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
      {userData?.length === 0 && addedPlayer?.length === 0 && (
        <Box height={"91px"} id="table-person">
          <Box
            sx={{
              height: "77px",
              background: "#889AB61A",
              color: "#B4C1D5",
              padding: "8px 0px 8px 10px",
            }}
          >
            {t("Calendar.No Players")}
          </Box>
        </Box>
      )}
      <Box display={"flex"} justifyContent={"space-between"} paddingY={"18px"}>
        <p style={{ fontFamily: "var(--font-semibold)", color: activeColor }}>
          {t("customers.Pending Payment")}
        </p>
        <Box
          sx={{
            fontFamily: "var(--font-regular)",
            color: "white",
            textAlign: "center",
          }}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          {userData?.every((item: any) => item.status === "Paid") ? (
            <p
              style={{
                background: "#FF1313",
                opacity: "0.3",
                borderRadius: "5px",
                marginBottom: "7px",
                minWidth: "55px",
                paddingInline: "12px",
                fontFamily: "var(--font-regular)",
              }}
            >
              {SelectedLocation?.currency} 0 k
            </p>
          ) : (
            <>
              {userData?.reduce(
                (acc: any, item: any) => acc + item?.pending_price,
                0
              ) > 1000 ? (
                <p
                  style={{
                    background: userData?.length > 0 ? pending : "#FF1313",
                    borderRadius: "5px",
                    marginBottom: "7px",
                    minWidth: "55px",
                    paddingInline: "12px",
                    fontFamily: "var(--font-regular)",
                  }}
                >
                  {SelectedLocation?.currency}{" "}
                  {addThousandSeparator(
                    userData?.reduce(
                      (acc: any, item: any) => acc + item?.pending_price,
                      0
                    ),
                    SelectedLocation?.currency
                  )}
                </p>
              ) : (
                <p
                  style={{
                    background: userData?.length > 0 ? pending : "#FF1313",
                    borderRadius: "5px",
                    marginBottom: "7px",
                    minWidth: "55px",
                    paddingInline: "12px",
                    fontFamily: "var(--font-regular)",
                  }}
                >
                  {SelectedLocation?.currency}{" "}
                  {addThousandSeparator(
                    userData?.reduce(
                      (acc: any, item: any) => acc + item?.pending_price,
                      0
                    ),
                    SelectedLocation?.currency
                  )}
                </p>
              )}
            </>
          )}
          {selectedCheckbox?.length > 0 ? (
            <Box
              onClick={() => setVoucherADD(true)}
              sx={{
                background: "#22356D",
                borderRadius: "7px",
                border: "1px solid #EEEFEE",
                padding: "7px 17px",
                width: "141px",
                height: "37px",
                cursor: "pointer",
                boxShadow: "0px 4px 4px 0px #0000001A",
              }}
            >
              {t("common.Open Payments")}
            </Box>
          ) : (
            <Box
              sx={{
                background: "#22356D66",
                borderRadius: "5px",
                width: "83px",
                cursor: "pointer",
              }}
            >
              {t("common.Pay")} 0
            </Box>
          )}
        </Box>

        <PaymentSettings
          fromwh={fromWh}
          updatePAB={updatePAB}
          update={() => {
            update();
            serviceLists();
          }}
          RefundingPlayers={selectedRefunds}
          selectedLocationID={selectedLocationID}
          serviceID={event?.service_id}
          PendingPlayers={selectedPayment}
          open={voucherAdd}
          handleClose={handleClosePayment}
        />
      </Box>
    </>
  );
};

export default PersonList;
