import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import React, { useState } from 'react'
import Admin from './Admin';
import LocationManager from 'src/features/Admin/LocationManager';
import TopUserInfo from '../components/TopUserInfo';



const selectedTab = {
    textDecoration: "underline",
    textDecorationColor: "#22356D",
    padding: "0px",
    textUnderlineOffset: "6px",
 };
 
 const headingStyle: any = {
    color: "#22356D",
    fontFamily: "var(--font-semibold)",
    textTransform: "capitalize",
    fontSize:{xs:"1.3rem", sm:'1.5rem'}
 };
 
 const unSelected: any = {
    opacity: "0.7",
    paddingLeft: "0", 
    color:'#22356D99 !important',
    fontFamily:"var(--font-regular) !important",
    marginLeft:'15px'
 }

 

const SuperAdminSettings = () => {
    const [value,setValue] = useState('SuperAdmin');

    const handleSelectTab = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
      };
  return (
    <Box>
         <TopUserInfo/>
    
    <Box sx={{padding:{xs:"20px", sm:"20px", md:"2.3rem"}}}>
        <TabContext value={value}>
            <TabList  TabIndicatorProps={{sx: {backgroundColor: "#22356D"}}} 
                     sx={{
                        ".Mui-selected": {
                        color: "#22356D !important",
                        fontFamily: 'var(--font-semibold)'
                     },}} className='CoachSettingsTabs' onChange={handleSelectTab}>
            <Tab sx={value == "SuperAdmin"
                     ? { ...selectedTab, ...headingStyle }
                     : { ...headingStyle, ...unSelected }} value='SuperAdmin' label={'Super Admin Manager'}/>
            <Tab sx={value == "Location"
                     ? { ...selectedTab, ...headingStyle }
                     : { ...headingStyle, ...unSelected }} value='Location' label={'Location Manager'}/>
            </TabList>
            <TabPanel style={{ color: "#22356D", padding: "0px" }} value="SuperAdmin" sx={{padding:"0px 0"}}>
            <Admin />
            </TabPanel>
            <TabPanel value='Location'>
            
            <LocationManager isSuperAdmin={true}/>
            </TabPanel>

        </TabContext>
    </Box>
    </Box>
  )
}

export default SuperAdminSettings