import { Box, Grid, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import React, { useContext, useEffect, useRef, useState } from 'react';
import PaymentFilter from 'src/features/Payments/PaymentFilter';
import Voucher from '../components/Services/Voucher';
import { FinalCalednarasdmnf } from '../components/customTimeSeletor';
import { useGetLocation } from 'src/api/CourtSettings';
import CustomSelectCheckBox from '../components/CustomSelectBox';
import { useGetAllPayments } from 'src/api/Payments';
import MainContext from 'src/context/MainContext';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import { RxHamburgerMenu } from 'react-icons/rx';
import MethodModel from 'src/features/Payments/MethodModel';
import { useGetAllPaymentMethodsByLoc } from 'src/hooks/useGetAllPaymentMethods';
import NotificationToast from 'src/Notification/NotificationToast';
import { addThousandSeparator } from 'src/constants/SportSEparator';
import Coupon from 'src/features/Payments/Coupon';
import { formatMinutes } from 'src/constants/formateMinutes';
import Memberships from 'src/features/Payments/Membserships';
import { useTranslation } from 'react-i18next';
import { useSocket } from 'src/context/SocketProvider';
import { useQueryClient } from 'react-query';
import TopUserInfo from '../components/TopUserInfo';

const paymentsDesgin = {
   background:"white", 
   borderRadius:'7px', 
   padding:"20px 26px 20px 20px", 
   gap:'5px'
}

const justifyContent = {
   display:"grid",
   gridTemplateColumns:"repeat(5, 1fr)",
   fontFamily:"var(--font-regular)",
   color:"#22356DB2",
   gap:'30px'   
}

const justifyContent23 = {
   display:"grid",
   gridTemplateColumns:"repeat(3, 1fr)",
   fontFamily:"var(--font-regular)",
   color:"#22356DB2",
   gap:'30px'   
}
 
//total booking pending price
export  function getTotalBookingPendingPrice(data:any,name:string) {

   let total = 0;
   data.forEach((item:any) => {
      if(item?.service_type===name){
         item.Payment.forEach((payment:any) => {
            if (payment.status === "Pending payment") {
               total += payment.price;
            }
      });
      }
   });
   return total;
}

// export  function getTotalBookingPaidPrice(data:any,name:string) {

//    let total = 0;
//    data.forEach((item:any) => {
//       if(item?.service_type===name){
//          item.Payment.forEach((payment:any) => {
//             if (payment.status === "Paid") {
//                total += payment.price;
//             }
//       });
//       }
//    });
//    return total;
// }



const getTotalBookingPaidPrice = (data: any[], targetServiceType: string): number => {
   let totalPaidPrice = 0;

   data.forEach(item => {
     // Check if the current item's service_type matches the target service type
     if (item.service_type === targetServiceType) {
       item.Payment.forEach((payment:any) => {
         // Only calculate for payments with status 'Paid'
         if (payment?.payment_method && (payment?.status==="Paid"|| payment?.prev_status==="Paid")) {
           const finalPrice = payment.price - payment.discount - payment.refund_price;
           totalPaidPrice += finalPrice;
         }
       });
     }
   });
 
   return totalPaidPrice;
 };
 

// payment?.refund_method==="Wallet"

//&& item?.service_type!=="Voucher" && item?.service_type!=="Membership"

//total refund price
export function getTotalRefundsPrice(data:any,name:string, method?:string) {
   let total = 0;
   let total_wallet = 0;
   data.forEach((item:any) => {
      item.Payment.forEach((payment:any) => {

         if(method && payment?.refund_method===method){
            total += payment.refund_price;
         }
         else if(payment?.refund_method===name && (payment?.status?.includes('Refund')||payment?.prev_status?.includes('Refund')) ){
                  total += payment.refund_price;
         }
      })
     
   });
   return total >0 ? total : total_wallet;
}

// total pending price
export function getTotalPendingPrice(data:any) {
   let total = 0;
   data.forEach((item:any) => {
      item.Payment.forEach((payment:any) => {
         if (payment.status === "Pending payment") {
               total += payment.price;
         }
      });
   });
   return total;
}

export function getTotalPaidPrice(data:any) {
   let total = 0;
   data.forEach((item:any) => {
      item.Payment.forEach((payment:any) => {
         if (payment.status === "Paid" || payment.prev_status==="Paid") {
               total += payment.price;
               total-= payment.refund_price
               total-= payment.discount
         }
      });
   });
   return total;
}


const selectedTab = {
   textDecoration: "underline",
   textDecorationColor: "#22356D",
   padding: "0px",
   textUnderlineOffset: "6px",
};

const headingStyle: any = {
   color: "#22356D",
   fontFamily: "var(--font-semibold)",
   textTransform: "capitalize",
   fontSize:{xs:"1.3rem", sm:'1.5rem'}
};

const unSelected: any = {
   opacity: "0.7",
   paddingLeft: "0", 
   color:'#22356D99 !important',
   fontFamily:"var(--font-regular) !important",
   marginLeft:'15px'
}

//total booking price

// && item.service_type!=="Voucher"

//|| payment?.prev_status==="Paid"
export function getTotalBookingPrice(data:any,name:string) {
   let total = 0;
   data.forEach((item:any) => {
      //   if(item.service_type!=="Membership"){
         item.Payment.forEach((payment:any) => {
            if(payment?.payment_method===name && (payment?.status==="Paid"||payment.prev_status==='Paid' || payment.refund_method!==null)){
               total += payment.price;
         }
      })
      //   }
     
   });
   return total;
}

//&& item?.service_type!=="Voucher"
//&&  item?.service_type!=="Membership"

export function getTotalBookingDiscount(data:any,name:string) {
   let total = 0;
   data.forEach((item:any) => {
         item.Payment.forEach((payment:any) => {
            if(payment?.payment_method===name){
               total += payment.discount;
         }
      })
     
   });
   return total;
}


const Payments = () => {
   const {t} = useTranslation();
   const [AllLocations,setAllLocations] = useState<any>([]);
   const [value, setValue] = React.useState('1');
   const context = useContext(MainContext);
   const SelectedLocation = context?.SelectedLocation;
   const setSelectedLocation = context?.setSelectedLocation;
   const selectedDate = context?.selectedDate;
   const UpdatePayments = context?.UpdatePayments;
   const queryParams = new URLSearchParams(window.location.search);
   const inputValueParam = queryParams.get('inputValue');
   const {MobileSideBar, setMobileSideBar} = React.useContext(MainContext);
   const [AllPaymentMethods,SetAllPayemntMEthods] = useState<any[]>([]);
   const {messages, sendMessage, connectionStatus,listenToEvent  } = useSocket();
   const AllTotalPayment:Array<number> = [];
   const queryClient = useQueryClient();
   const [showModel, setShowModel] = useState(false);
   const [totalMembershipSale,setTotalMembershipSale] = useState<number>(0);
   const {data:PaymentMethods,isSuccess:PaymentMethodsSuccess,isError:PaymentMethodsError,setlocation_id:SetLocationID} = useGetAllPaymentMethodsByLoc();
   const [selectedDay,setSelectedDays]= useState<any>({date_start:moment(new Date()).format('YYYY-MM-DD'),date_end:moment(new Date()).format('YYYY-MM-DD')});
   const {data:locationdata,isError:locationerror,isSuccess:locationSucess} = useGetLocation();
   const {data,isSuccess,isError,isLoading,refetch,setlocation_id,setviewType} = useGetAllPayments();
   const [allPayments,setAllPayments] = useState<any[]>([]);
   const [allVouchers,setallVouchers] = useState<any[]>([]);
   const [totalVoucherSale, setTotalVoucherSale] = useState<number>(0);
   const [AllCoupons,setAllCoupons] = useState<any[]>([]);

   const handleSelectTab = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
   };

   const getTotalPrice = (methodName:string, data:any) => {
      let filteredData = data.filter((item:any) => {
       return item?.method?.method_name === methodName && item?.service_type!=="Voucher" && item?.service_type!=="Membership";
      });
      const totalPrice = filteredData.reduce((total:any, item:any) => total + item?.Payment[0]?.price, 0);
      return totalPrice;
   }

   useEffect(()=>{
      if(UpdatePayments){
         refetch();
      }   
   },[UpdatePayments]);


   useEffect(() => {
      if(locationSucess){
         setAllLocations(locationdata)
         if(SelectedLocation?.id!==0){
            SetLocationID(SelectedLocation?.id);
         }else{
            SetLocationID(locationdata[0]?.id)
            setSelectedLocation(locationdata[0]);
         }
        
      }else{
         setAllLocations([]);
      }
   }, [locationSucess,locationdata,locationerror]);

   useEffect(() => {
      SetLocationID(SelectedLocation?.id);
   }, [SelectedLocation])
   
   useEffect(() => {
      if(PaymentMethodsSuccess){
         SetAllPayemntMEthods(PaymentMethods)
      }else{
         SetAllPayemntMEthods([])
      }
   }, [PaymentMethods,PaymentMethodsSuccess,PaymentMethodsError]);
   
   useEffect(() => {

      if(!!selectedDay.date_start && !!selectedDay.date_end){
         setlocation_id(SelectedLocation?.id);
         setviewType(selectedDay);
      }
   }, [SelectedLocation,selectedDay]);

   useEffect(() => {
      const AllCoupon :any=[];
      if(isSuccess){
            const formattedData = data?.bookings?.map((obj:any) =>{
               var formattedCreatedAt = moment.utc(obj?.date).format("DD/M/YYYY");
               return ({
               id: obj?.id,
               Payment: obj.players.flatMap((player: any) => {
                  const { customer, id, payments } = player;
                 const transaction =customer?.transactions||undefined;
                  const fullName = customer.first_name + " " + customer.last_name;
                  return payments.map((item: any) => {
                    if(!!item?.coupons){
                     AllCoupon.push(item?.coupons.name)
                    }
                    return { fullName,transaction, ...item};
                  })
                }),
               service_type: obj.service.service_type,
               coaches:obj.service.coaches,
               service:obj.service?.event!==null?obj?.service?.event:obj.service?.booking!==null?obj.service?.booking:obj.service?.lesson!==null?obj.service?.lesson:'',
               date:formattedCreatedAt,
               service_id:obj?.id,
               recurring:obj?.service?.recurring,
               booking:obj?.service?.booking !== null ? `Booking ${formatMinutes(obj?.service?.booking?.duration)}` : obj?.service?.additional_service,
               lesson:obj?.service?.lesson !== null ? `${obj?.service?.lesson.lesson_name}` : obj?.service?.additional_service,
               event:obj?.service?.event !== null ?`${obj?.service?.event.event_name}` : obj?.service?.additional_service,
            }) 
         });

         const formattedVoucher = data?.vouchers?.filter((item:any)=>!item.is_cancelled)?.map((obj: any) => {
            const fullName =
              obj?.customer.first_name + " " + obj?.customer.last_name;
            var formattedCreatedAt = moment.utc(obj.created_at).format("DD/M/YYYY");
            return {
              id: obj?.id,
              service_type: "Voucher",
              method: obj.method,
              date: formattedCreatedAt,
              Payment: [
                {
                  discount: 0,
                  fullName: fullName,
                  payment_method: obj.method?.method_name,
                  price: obj?.voucher?.price,
                  refund_price: 0,
                  status: "Paid",
                },
              ],
            };
          });

          const formattedMembership = data?.memberships?.map((obj: any) => {
            const fullName =
              obj?.customer.first_name + " " + obj?.customer.last_name;
            var formattedCreatedAt = moment.utc(obj.start_date).format("DD/M/YYYY");
            return {
              id: obj?.id,
              service_type: "Membership",
              method: obj.method,
              date: formattedCreatedAt,
              membership_name:obj?.membership?.membership_name||'',
              Payment: [
                {
                  discount: 0,
                  fullName: fullName,
                  payment_method: obj.paymentMethod?.method_name,
                  price: obj?.price,
                  refund_price: 0,
                  status: obj?.is_cancelled?"Refunded":"Paid",
                },
              ],
            };
          });

          const formattedManualCredit = data?.manualCredits?.map((obj: any) => {
            const fullName =
              obj?.customer.first_name + " " + obj?.customer.last_name;
            var formattedCreatedAt = moment.utc(obj.created_at).format("DD/M/YYYY");
          
            return {
              id: obj?.id,
              service_type: "Voucher",
              method: obj.method,
              date: formattedCreatedAt,
              Payment: [
                {
                  discount: 0,
                  fullName: fullName,
                  payment_method: obj.method?.method_name,
                  price: obj?.price,
                  refund_price: 0,
                  status: "Paid",
                },
              ],
            };
          });



          const totalPrice = data?.vouchers?.reduce((total: any, payment: any) => {
            return total + payment.voucher.price;
          }, 0)||0;

          const totalManualPrice = data?.manualCredits?.reduce((total: any, payment: any) => {
            return total + payment?.price;
          }, 0)||0;
          setTotalVoucherSale(totalPrice+totalManualPrice);

          const totalMembership = data?.memberships?.reduce((total: any, payment: any) => {
            return total + payment.price;
          }, 0)||0;

          setAllCoupons(AllCoupon);
          setTotalMembershipSale(totalMembership)
          setAllPayments([...formattedData,...formattedVoucher,...formattedManualCredit,...formattedMembership]);
          setallVouchers(formattedVoucher);
      }else{
         setTotalVoucherSale(0);
         setTotalMembershipSale(0); 
         setAllPayments([]);
         setallVouchers([]);
      }
   }, [data,isSuccess,isError]);

   const openMobileSidebar=(event:any)=>{
      event.stopPropagation();
      setMobileSideBar(true)
   }

   useEffect(() => {
      const handleEventOne = (data: any) => {
        queryClient.invalidateQueries(['AllPayments']);
        // Handle event one data
      };
      const handleEventTwo = (data: any) => {
         // console.log('Calendar Daiy:', data);
         queryClient.invalidateQueries(['AllPayments']);
   
         // Handle event one data
       };
  
      listenToEvent('payments', handleEventOne);
      listenToEvent('calendars', handleEventTwo);
  
      // Cleanup
      return () => {
        // Unregister event listeners if necessary
      };
    }, [listenToEvent]);

   return(
      <Box sx={{position:'relative'}}>
          {isLoading && (
          <Box
            position={"absolute"}
            top={-10}
            left={0}
            sx={{
              width: {xs:"90%",sm:"100%"},
              height: {xs:"95%",sm:"99%"},
              zIndex: 9999,
              background: "rgba(0, 0, 0, 0.10)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img style={{borderRadius:'7px',width:'40px',height:'40px'}} src="/images/Loadingic.gif" />
          </Box>
        )}
         <TopUserInfo/>
      <Box sx={{padding:{xs:"20px", sm:"20px", md:"2.3rem"},minHeight:'100vh'}}>
         <NotificationToast/>
         <TabContext value={value}>
            <Box sx={{display:"flex", alignItems:"center", justifyContent:'space-between', gap:'10px'}}>
               <Box sx={{display:"flex", alignItems:"center", gap:'10px'}}>
                  <Box sx={{display:{xs:'block', sm:"block", md:"none"},cursor:"pointer"}}>
                     <RxHamburgerMenu onClick={openMobileSidebar} className='MobileHamburger'/>
                  </Box>
                  <TabList className='CoachSettingsTabs' onChange={handleSelectTab} aria-label="people list" 
                  TabIndicatorProps={{sx: {backgroundColor: "#22356D"}}} 
                     sx={{
                        ".Mui-selected": {
                        color: "#22356D !important",
                        fontFamily: 'var(--font-semibold)'
                     },}}
                  >
                     <Tab label={t(`title.Payments`)} value="1" sx={value == "1"
                     ? { ...selectedTab, ...headingStyle }
                     : { ...headingStyle, ...unSelected }} />
                     <Tab label={t(`title.Club Credits`)} value="2"  sx={value == "2"
                     ? { ...selectedTab, ...headingStyle }
                     : { ...headingStyle, ...unSelected }} />
                     <Tab label={t(`title.Memberships`)} value="3"  sx={value == "3"
                     ? { ...selectedTab, ...headingStyle }
                     : { ...headingStyle, ...unSelected }} />
                     <Tab label={t(`title.Coupon`)} value="4"  sx={value == "4"
                     ? { ...selectedTab, ...headingStyle }
                     : { ...headingStyle, ...unSelected }} />
                      
                  </TabList>
               </Box>
               {value=='1' && <Box sx={{display:{xs:'block', sm:"block", md:"none"}, background:"white", borderRadius:"7px", padding:"5px",width:'30px'}}onClick={()=>setShowModel(true)}>
                  <img style={{width:'30px'}} src='/images/spreadsheet.png' />
               </Box>}
            </Box> 
            {/* -------- Location Owner ------- */}
            <TabPanel value="1" sx={{padding:"0px 0", fontFamily:"var(--font-regular)",color: "#22356D"}}>
               <Box display={'flex'} alignItems={'center'} gap={1} marginTop={2} >
                  <Box width={"170px"}>
                     <CustomSelectCheckBox background={false} text={"Select location"} value={SelectedLocation} setValue={setSelectedLocation} arr={AllLocations} />
                  </Box>
                  <Box width={"201px"} position={'relative'}>
                    <FinalCalednarasdmnf type={"range"} containercss={{width:'201px',borderRadius:'7px',backgroundColor:'white',padding:'0px 4px 0px 0px'}} icon={true} placeholder={"select days"} selectedDay={selectedDay} setSelectedDays={setSelectedDays} style={{width:"90%", height:"33px",color:"#22356D",fontFamily:"var(--font-regular)"}}/>
                  </Box>
               </Box>
               <Box sx={{display:{xs:'none', sm:"none", md:"block"}}}>
                  <Grid container gap={5} marginTop={2}>
                     <Grid item xs={7} sx={{...paymentsDesgin,padding:'20px'}}>
                        <Box sx={{...justifyContent,borderBottom:"1px solid #22356D26", fontFamily:"var(--font-medium)",}}>
                           <p>{t('customers.Payment Method')}</p>
                           <p>{t('payments.Sales')}</p>
                           <p>{t('payments.Refunds')}</p>
                           <p>{t('payments.Discounts')}</p>
                           <p style={{textAlign:'right'}}>{t('payments.Total Sales')}</p>
                        </Box>
                       
                        {AllPaymentMethods?.map((item:any)=> {
                           const SalesPayment = getTotalBookingPrice(allPayments,item?.method_name);
                           const Refunds = getTotalRefundsPrice(allPayments, item?.method_name);
                           const discount = getTotalBookingDiscount(allPayments,item?.method_name)
                           const TotalSales = Number(SalesPayment)-Number(Refunds)-Number(discount); 
                           AllTotalPayment.push(TotalSales);

                           return <Box sx={{...justifyContent, borderBottom:"1px solid #22356D12"}}>
                            <p>{item?.method_name}</p>
                            <p>{addThousandSeparator(SalesPayment,SelectedLocation?.currency)} {SelectedLocation?.currency}</p>
                            <p>{Number(addThousandSeparator(Refunds,SelectedLocation?.currency))> 0 && "-"}{addThousandSeparator(Refunds,SelectedLocation?.currency)} {SelectedLocation?.currency}</p>
                            <p>{Number(addThousandSeparator(discount,SelectedLocation?.currency)) >0 &&"-"}{addThousandSeparator(discount,SelectedLocation?.currency)} {SelectedLocation?.currency}</p>
                            <p style={{textAlign:'right'}}>{addThousandSeparator(Number(getTotalPrice(item?.method_name, allVouchers)) + Number(TotalSales), SelectedLocation?.currency)} {SelectedLocation?.currency}</p>
                         </Box>
                           }
                        )}
                        {/* <Box sx={{...justifyContent, borderBottom:"1px solid #22356D12"}}>
                           <p>{t('payments.Voucher Sales')} {location?.currency}</p>
                           <p>{totalVoucherSale.toLocaleString('id-ID')} {location?.currency}</p>
                           <p>0 {location?.currency}</p>
                           <p>0 {location?.currency}</p>
                           <p style={{textAlign:'right'}}>{totalVoucherSale.toLocaleString('id-ID')} {location?.currency}</p>
                        </Box>
                        <Box sx={{...justifyContent, borderBottom:"1px solid #22356D12"}}>
                           <p>{t('payments.Membership Sales')} {location?.currency}</p>
                           <p>{totalMembershipSale.toLocaleString('id-ID')} {location?.currency}</p>
                           <p>0 {location?.currency}</p>
                           <p>0 {location?.currency}</p>
                           <p style={{textAlign:'right'}}>{totalMembershipSale.toLocaleString('id-ID')} {location?.currency}</p>
                        </Box> */}
                        <Box sx={{...justifyContent, borderBottom:"1px solid #22356D12"}}>
                           <p>{t('payments.Refunded to Wallet')}</p>
                           <p>0 {SelectedLocation?.currency}</p>
                           <p>-{addThousandSeparator(getTotalRefundsPrice(allPayments,"", "Wallet"),SelectedLocation?.currency)} {SelectedLocation?.currency}</p>
                           <p>0 {SelectedLocation?.currency}</p>
                           <p style={{textAlign:'right'}}>-{addThousandSeparator(getTotalRefundsPrice(allPayments,"", "Wallet"),SelectedLocation?.currency)} {SelectedLocation?.currency}</p>
                        </Box>  
                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between', fontFamily:"var(--font-medium)"}}>  
                           <p>{t('common.Total')}</p>
                           <p> {addThousandSeparator(AllTotalPayment?.reduce((item,acc)=>item+acc,0), SelectedLocation?.currency)} {SelectedLocation?.currency} </p>
                        </Box>
                     </Grid>
                     <Grid item xs={4.5} sx={{...paymentsDesgin,padding:'20px'}}>
                        <Box sx={{...justifyContent23,borderBottom:"1px solid #22356D26", fontFamily:"var(--font-medium)"}}>
                           <p>{t('POS.Category')}</p>
                           <p style={{textAlign:'center'}}>{t('customers.Pending Payments')}</p>
                           <Typography sx={{textAlign:'right',fontFamily:"var(--font-medium)", color:"#22356DB2"}}>{t('common.Paid')}</Typography>
                        </Box>
                        <Box sx={{...justifyContent23, borderBottom:"1px solid #22356D12"}}>
                           <p>{t('common.Bookings')}</p>
                           <p style={{textAlign:'center'}}> {addThousandSeparator(getTotalBookingPendingPrice(allPayments,"Booking"), SelectedLocation?.currency)} {SelectedLocation?.currency} </p>
                           <Typography sx={{textAlign:'right',fontFamily:"var(--font-regular)", color:"#22356DB2"}}>{addThousandSeparator(getTotalBookingPaidPrice(allPayments,'Booking'),SelectedLocation?.currency)}</Typography>
                        </Box>
                        <Box sx={{...justifyContent23, borderBottom:"1px solid #22356D12"}}>
                           <p>{t('common.Lessons')}</p>
                           <p style={{textAlign:'center'}}> {addThousandSeparator(getTotalBookingPendingPrice(allPayments,"Lesson"), SelectedLocation?.currency)} {SelectedLocation?.currency} </p>
                           <Typography sx={{textAlign:'right',fontFamily:"var(--font-regular)", color:"#22356DB2"}}>{addThousandSeparator(getTotalBookingPaidPrice(allPayments,'Lesson'),SelectedLocation?.currency)}</Typography>
                        </Box>
                        <Box sx={{...justifyContent23, borderBottom:"1px solid #22356D26"}}>
                           <p>{t('common.Events')}</p>
                           <p style={{textAlign:'center'}}> {addThousandSeparator(getTotalBookingPendingPrice(allPayments,"Event"), SelectedLocation?.currency)} {SelectedLocation?.currency} </p>
                           <Typography sx={{textAlign:'right',fontFamily:"var(--font-regular)", color:"#22356DB2"}}>{addThousandSeparator(getTotalBookingPaidPrice(allPayments,'Event'),SelectedLocation?.currency)}</Typography>
                        </Box>
                        <Box sx={{...justifyContent23, fontFamily:"var(--font-medium)"}}>  
                           <p>{t('common.Total')}</p>
                           <p style={{textAlign:'center'}}>{addThousandSeparator(getTotalPendingPrice(allPayments),SelectedLocation?.currency)} {SelectedLocation?.currency}</p>
                            <Typography sx={{textAlign:'right',fontFamily:"var(--font-medium)", color:"#22356DB2"}}>{addThousandSeparator(getTotalPaidPrice(allPayments),SelectedLocation?.currency)} {SelectedLocation?.currency}</Typography>
                        </Box>
                     </Grid>
                  </Grid>
               </Box>
               <PaymentFilter AllCoupons={AllCoupons} allVouchers={allVouchers} setSelectedDays={setSelectedDays} update={refetch} selectedLocation={SelectedLocation} allPayments={allPayments} />
            </TabPanel>
            {/* ------ staff manager ----- */}
            <TabPanel style={{ color: "#22356D", padding: "0px" }} value="2" sx={{padding:"0px 0"}}>
               <Voucher  />
            </TabPanel>
            <TabPanel style={{ color: "#22356D", padding: "0px" }} value="3" sx={{padding:"0px 0"}}>
               <Memberships />
            </TabPanel>
            <TabPanel style={{ color: "#22356D", padding: "0px" }} value="4" sx={{padding:"0px 0"}}>
               <Coupon />
            </TabPanel>
         </TabContext>
         <MethodModel allVouchers={allVouchers} location={SelectedLocation} AllPayments={allPayments} AllPaymentMethods={AllPaymentMethods} open={showModel} setOpen={setShowModel} data={allPayments} totalVoucherSale={totalVoucherSale} getTotalPrice={getTotalPrice} />
      </Box>
      </Box>
   )
}

export default Payments;