import Box from "@mui/material/Box";
import React from 'react';
import { useTranslation } from "react-i18next";

const LessonsCoachRow = ({Data,index,AllLesson}:{Data:any,index:any,AllLesson:any}) => {
  const {t} = useTranslation();
    const firststyle = {color:'rgba(34, 53, 109,1)',fontFamily:"var(--font-regular)",fontSize:'16px'}
    const secondStyle = {color:'rgba(34, 53, 109,1)',fontFamily:"var(--font-regular)",fontSize:'14px'}

    const NotSpecial = Data?.bookabledays?.filter((item:any)=>item?.is_special===false);

const selectedLessons = AllLesson?.filter((item:any)=>item?.sport?.id===Data?.sport_id)||[]
const selectedCoach = selectedLessons.filter((player:any)=>player?.caochesteachings?.some((coach:any)=>coach?.coach_id===Data?.id));

  return (
    <div style={index%2==0 ? {backgroundColor:'rgba(136, 154, 182, 0.10)',padding:'8px 10px'}:{backgroundColor:'transparent',padding:'8px 10px'}} className=''>
      <Box margin={'5px'}>
        <Box display={'flex'} justifyContent={"space-between"}>
          <p style={firststyle}>{t('common.Coach')} {Data.full_name}</p>
          {Data.profile_visible && <p style={firststyle}>{t('services.coach.app available')}</p>}
        </Box>
        <Box display={'flex'} justifyContent={"space-between"}>
          <p style={secondStyle}>{NotSpecial?.length==7 ?`${t('week.Mon')}-${t('week.Sun')}`:NotSpecial?.length===0?`${t('week.Mon')}-${t('week.Sun')}`:NotSpecial?.map((item:any)=>!!item?.days &&`${t(`week.${item?.days?.slice(0,3)}`)},`)}</p>
          <p style={secondStyle}>{selectedCoach?.length}/{selectedLessons?.length} {t('title.Services')}</p>
        </Box>
      </Box>
    </div> 
  )
}

export default LessonsCoachRow
