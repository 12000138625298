import React, { useContext, useEffect, useState } from "react";
import { getMonthsService } from "src/api/CalendarBooking";
import useMediaQuery from "@mui/material/useMediaQuery";
import useWeekData from "src/customHook/useWeekData";
import MainContext from "src/context/MainContext";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import "./CalendarMonth.css";
import moment from "moment";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { useSocket } from "src/context/SocketProvider";
import { useTranslation } from "react-i18next";

const arrows = {
  position: "absolute",
  display: { xs: "block", sm: "block", md: "none" },
  top: "50%",
  zIndex: 99,
  background: "white",
  borderRadius: "7px",
  border: "1px solid #EEEFEE",
  boxSizing: "border-box",
  padding: "7px 2.2px",
};

const MonthlyCalendar = ({
  selectLocation,
  selectedMonth,
  selectedSport,
}: {
  selectLocation: string;
  selectedMonth: any;
  selectedSport: string;
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const alldays = useWeekData(selectedMonth);
  const [show, setShow] = useState<any>({});
  const [courtShow, setCourtShow] = useState<any>({});
  const [dateShow, setDateShow] = useState<any>("");
  const [dateChart, setDateChart] = useState<any>([]);
  const [viewPort, setViewPort] = useState("Laptop");
  const [allData, setAllData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [FilterData, setFilterData] = useState<any>([]);
  const [visibleData, setVisibleData] = useState([0, 30]);
  const [status, setStatus] = useState<string>("");
  const aggregatedOccupancy: any = {};
  const context = useContext(MainContext);
  const { messages, sendMessage, connectionStatus, listenToEvent } =
    useSocket();
  const { courtMonthlyStats, setCourtMonthlystats } = context;
  const location_id = context?.selectedLocationId;

  const calculateBackgroundColor = (rate: any) => {
    let val = Number(rate);
    if (val === 0) {
      return "#EDF0F4";
    } else if (val <= 25) {
      return "#BEC3D4";
    } else if (val <= 50) {
      return "#A6AEC5";
    } else if (val <= 75) {
      return "#7E89AA";
    } else {
      return "#22356D";
    }
  };

  useEffect(() => {
    const allDataa = allData;
    if (selectedSport === "All Sports") {
      setData(allData);
      setFilterData(allDataa);
    } else {
      const filtered = allDataa?.filter(
        (item: any) => item?.sport.toLowerCase() === selectedSport.toLowerCase()
      );
      setData(filtered);
      setFilterData(filtered);
    }
  }, [selectedSport, allData]);

  useEffect(() => {
    FilterData.forEach((court: any) => {
      court?.occupancy?.forEach((dayOccupancy: any) => {
        const date = moment(dayOccupancy.date)
          .locale(currentLanguage)
          .format("ddd DD MMM");
        if (!aggregatedOccupancy[date]) {
          // Initialize aggregated data for the date
          aggregatedOccupancy[date] = {
            court_created_at: dayOccupancy.court_created_at,
            date: date,
            location: dayOccupancy.location,
            occupancyRate:
              parseFloat(dayOccupancy.occupancyRate) / data?.length,
            occupiedHours: dayOccupancy.occupiedHours,
          };
        } else {
          // Accumulate occupancyRate and occupiedHours
          aggregatedOccupancy[date].occupancyRate +=
            parseFloat(dayOccupancy.occupancyRate) / data?.length;
          aggregatedOccupancy[date].occupiedHours += dayOccupancy.occupiedHours;
        }
      });
    });

    const result = Object.values(aggregatedOccupancy);
    setDateChart(result);
  }, [FilterData, currentLanguage]);

  const monthsData = async (
    view_type: string,
    date: any,
    location_id: number
  ) => {
    setStatus("loading...");
    try {
      let res: any = await getMonthsService(view_type, date, location_id);
      setStatus("");
      setAllData(res);
      setData(res);
    } catch (error: any) {
      setStatus("No data found");
    }
  };

  useEffect(() => {
    const handleEventOne = (data: any) => {
      const { date, location_id: selLocation_id } = data;
      const Selmonth = moment(date).format("MM-YYYY");
      const isSame =
        moment(selectedMonth).format("MM-YYYY") === Selmonth &&
        selLocation_id === location_id;

      isSame &&
        monthsData(
          "courts",
          moment(selectedMonth).format("MM-YYYY"),
          location_id
        );
      // Handle event one data
    };

    listenToEvent("calendars", handleEventOne);

    // Cleanup
    return () => {
      // Unregister event listeners if necessary
    };
  }, [listenToEvent]);

  // getting total occupancy rate and hour as per court_id
  function sumOccupancy(data: any) {
    // sumOccupancyByDateAndCourt(data)
    let totalOccupancyRate = 0;
    let totalOccupiedHours = 0;

    data.forEach((item: any) => {
      totalOccupancyRate += parseFloat(item.occupancyRate);
      totalOccupiedHours += item.occupiedHours;
    });

    let finalData = {
      location: data[0].location,
      court_id: data[0].court_id,
      court_name: data[0].court_name,
      occupancyRate: (totalOccupancyRate / alldays.length)?.toFixed(2),
      occupancyHours: totalOccupiedHours,
    };
    setCourtShow(finalData);
  }

  const handleNext = (event: any) => {
    event.stopPropagation();

    if (viewPort == "Mobile") {
      if (visibleData[1] == 28 && alldays.length == 31) {
        setVisibleData([27, 31]);
      } else if (visibleData[1] == 28 && alldays.length == 30) {
        setVisibleData([26, 30]);
      } else if (visibleData[1] == 28 && alldays.length == 28) {
        setVisibleData([24, 28]);
      } else if (visibleData[1] == 28 && alldays.length == 29) {
        setVisibleData([25, 29]);
      } else {
        const newStart = visibleData[0] + 4;
        const newEnd = visibleData[1] + 4;
        setVisibleData([newStart, newEnd]);
      }
    } else {
      if (visibleData[1] == 24 && alldays.length == 31) {
        setVisibleData([19, 31]);
      } else if (visibleData[1] == 24 && alldays.length == 30) {
        setVisibleData([18, 30]);
      } else if (visibleData[1] == 24 && alldays.length == 28) {
        setVisibleData([16, 28]);
      } else if (visibleData[1] == 24 && alldays.length == 29) {
        setVisibleData([17, 29]);
      } else {
        const newStart = visibleData[0] + 12;
        const newEnd = visibleData[1] + 12;
        setVisibleData([newStart, newEnd]);
      }
    }
  };

  const handlePrev = (event: any) => {
    event.stopPropagation();

    if (viewPort == "Mobile") {
      if (
        (visibleData[0] > 25 && alldays.length == 31) ||
        (visibleData[0] > 25 && alldays.length == 30)
      ) {
        setVisibleData([24, 28]);
      } else if (
        (visibleData[0] === 25 && alldays.length == 29) ||
        (visibleData[0] === 24 && alldays.length == 28)
      ) {
        setVisibleData([20, 24]);
      } else {
        const newStart = visibleData[0] - 4;
        const newEnd = visibleData[1] - 4;
        setVisibleData([newStart, newEnd]);
      }
    } else {
      if (
        (visibleData[0] === 19 && alldays.length == 31) ||
        (visibleData[0] == 18 && alldays.length == 30) ||
        (visibleData[0] === 17 && alldays.length == 29) ||
        (visibleData[0] === 16 && alldays.length == 28)
      ) {
        setVisibleData([12, 24]);
      } else {
        const newStart = visibleData[0] - 12;
        const newEnd = visibleData[1] - 12;
        setVisibleData([newStart, newEnd]);
      }
    }
  };

  useEffect(() => {
    monthsData("courts", moment(selectedMonth).format("MM-YYYY"), location_id);
  }, [selectLocation, selectedMonth]);

  useEffect(() => {
    const monthlyoccupancyRate =
      Math.round(
        dateChart?.reduce(
          (item: any, acc: any) => item + acc?.occupancyRate,
          0
        ) / dateChart?.length
      ) || 0;
    const monthlyoccupiedHours = dateChart?.reduce(
      (item: any, acc: any) => item + acc?.occupiedHours,
      0
    );
    setCourtMonthlystats({
      occupancyRate: monthlyoccupancyRate,
      occupiedHours: monthlyoccupiedHours,
    });

    if (isMobile) {
      setVisibleData([0, 4]);
    } else {
      setVisibleData([0, dateChart.length]);
    }
    const handleResize = () => {
      if (window.matchMedia("(max-width: 600px)").matches) {
        setViewPort("Mobile");
        setVisibleData([0, 4]);
      } else if (window.matchMedia("(max-width: 850px)").matches) {
        setViewPort("Tablet");
        setVisibleData([0, 12]);
      } else {
        setViewPort("Laptop");
        setVisibleData([0, alldays.length]);
      }
    };

    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dateChart, isMobile]);

  return (
    <Box
      sx={{
        minHeight: {
          xs: data.length > 8 ? "auto" : "65vh",
          sm: data.length > 3 ? "100vh" : "100vh",
        },
        marginBottom: { xs: "57px", sm: "24px" },
      }}
    >
      <Box
        position={"relative"}
        display={"flex"}
        borderRadius={"7px"}
        sx={{
          boxShadow: {
            xs: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            sm: "unset",
          },
          background: { xs: "white", sm: "transparent" },
          marginRight: { xs: "15px", sm: "24px" },
          marginLeft: { xs: "15px", sm: "0px" },
          paddingLeft: { xs: "10px", sm: "0px" },
        }}
      >
        {/* left arrow - mobile only*/}
        {dateChart.length > 0 && visibleData[0] > 0 && (
          <Box
            position={"absolute"}
            sx={{
              display: { xs: "block", md: "none" },
              left: "4%",
              top: "7%",
              zIndex: 99,
            }}
            onClick={handlePrev}
          >
            <SlArrowLeft style={{ color: "#22356D" }} />
          </Box>
        )}
        {/* right arrow - mobile only */}
        {dateChart.length > 0 && visibleData[1] < alldays.length && (
          <Box
            position={"absolute"}
            right={"2%"}
            sx={{ display: { xs: "block", md: "none" }, top: "7%", zIndex: 99 }}
            onClick={handleNext}
          >
            <SlArrowRight style={{ color: "#22356D" }} />
          </Box>
        )}

        {/* left arrow - mobile only - in mid*/}
        {FilterData.length > 10 && visibleData[0] > 0 && (
          <Box
            position={"absolute"}
            sx={{ ...arrows, left: { xs: "-4%", sm: "7%" } }}
            onClick={handlePrev}
          >
            <SlArrowLeft style={{ color: "#22356D" }} />
          </Box>
        )}

        {/* right arrow - mobile only - in mid */}
        {FilterData.length > 10 && visibleData[1] < alldays.length && (
          <Box
            sx={{ ...arrows, right: { xs: "-4%", sm: "-2%" } }}
            onClick={handleNext}
          >
            <SlArrowRight style={{ color: "#22356D" }} />
          </Box>
        )}

        <Box
          width={"62px"}
          marginTop={"63px"}
          display={"flex"}
          flexDirection={"column"}
          sx={{
            marginTop: { xs: "70px", md: "63px" },
            paddingRight: { xs: "0px", md: "16px" },
          }}
        >
          {/* court and sport list */}
          {FilterData.map(
            (
              item: {
                court_id: number;
                court_name: string;
                sport: string;
                occupancy: any;
              },
              index: number
            ) => (
              <Box
                position={"relative"}
                onTouchStart={() => sumOccupancy(item.occupancy)}
                onTouchEnd={() => setCourtShow({})}
                onMouseEnter={() => sumOccupancy(item.occupancy)}
                onMouseLeave={() => setCourtShow({})}
              >
                <Box
                  sx={{
                    height: { xs: "55px", md: "120px" },
                    lineHeight: { xs: "15px", md: "normal" },
                    fontFamily: "var(--font-regular)",
                    color: "#22356DB2",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                  key={index}
                >
                  <p style={{ fontFamily: "var(--font-medium)" }}>
                    {item.court_name}
                  </p>
                  <p style={{ fontSize: "15px" }}>{item.sport}</p>
                </Box>
                {/* on hover showing the court details */}
                {courtShow.court_id == item.court_id && (
                  <Box
                    position={"absolute"}
                    top={"-5px"}
                    zIndex={"99"}
                    sx={{
                      background: "white",
                      padding: "2px 10px",
                      borderRadius: "7px",
                      width: "175px",
                      textAlign: "left",
                      fontFamily: "var(--font-medium) !important",
                      color: "#889AB6",
                      boxShadow: {
                        xs: "unset",
                        md: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      },
                    }}
                    left={10}
                  >
                    <p
                      style={{
                        fontFamily: "var(--font-regular) !important",
                        color: "#22356D99",
                      }}
                    >
                      {courtShow.court_name}
                    </p>
                    <p>{courtShow.location}</p>
                    <p>{courtShow.occupancyRate}% Occupancy</p>
                    <p>{Math.round(courtShow.occupancyHours)} Hours booked</p>
                  </Box>
                )}
              </Box>
            )
          )}
        </Box>
        <Box
          flex={1}
          sx={{
            background: "white",
            padding: { xs: "12px 12px 12px 0px", md: "7.5px" },
            borderRadius: "7px",
            boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
          }}
        >
          <Box width={"100%"}>
            <Box display={"flex"} className="borderRight">
              {/* showing all months date */}
              {dateChart
                .slice(visibleData[0], visibleData[1])
                .map((item: any, index: number) => {
                  return (
                    <Box
                      position={"relative"}
                      sx={{
                        width: {
                          xs: "calc(100% / 4)",
                          sm: `calc(100% / 12)`,
                          md: `calc(100% / ${alldays?.length})`,
                        },
                        borderRight: {
                          xs: "none !important",
                          md: "1px solid #22356D26",
                        },
                        borderBottom: "1px solid #22356D26",
                        textAlign: "center",
                      }}
                      onTouchStart={() => setDateShow(item.date)}
                      onTouchEnd={() => setDateShow({})}
                      onMouseEnter={() => setDateShow(item.date)}
                      onMouseLeave={() => setDateShow({})}
                    >
                      <Box>
                        <Box
                          sx={{
                            fontFamily: "var(--font-regular) !important",
                            width: "32px",
                            margin: "auto",
                            color: "#22356DB2",
                            lineHeight: "17px",
                            marginBottom: "5px",
                          }}
                        >
                          <p>{item.date.split(" ")[0]}</p>
                          <p
                            style={{
                              fontFamily: "var(--font-semibold)",
                              fontSize: "1.1rem",
                            }}
                          >
                            {item.date.split(" ")[1]}
                          </p>
                          <p>{item.date.split(" ")[2]}</p>
                        </Box>
                      </Box>
                      {/* on hover showing all months data of court */}
                      {dateShow == item.date && (
                        <Box
                          position={"absolute"}
                          zIndex={"9999"}
                          sx={{
                            background: "white",
                            padding: "2px 10px",
                            borderRadius: "7px",
                            width: "175px",
                            textAlign: "left",
                            fontFamily: "var(--font-medium) !important",
                            color: "#889AB6",
                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            left: {
                              xs:
                                alldays.length - 1 === index ||
                                alldays.length - 2 === index
                                  ? "-77px"
                                  : "-85px",
                              md:
                                alldays.length - 1 === index ||
                                alldays.length - 2 === index
                                  ? "-107px"
                                  : "10px",
                            },
                          }}
                        >
                          <p>{item.date}</p>
                          <p>Average all courts</p>
                          <p>{item.occupancyRate?.toFixed(2)}% Occupancy</p>
                          <p>{item.occupiedHours} Hours booked</p>
                        </Box>
                      )}
                    </Box>
                  );
                })}
            </Box>

            <Box id="month-chart">
              {FilterData.map((row: any) => {
                return (
                  <Box display={"flex"}>
                    {/* showing all courts data */}
                    {row?.occupancy
                      ?.slice(visibleData[0], visibleData[1])
                      .map((item: any, index: number) => {
                        return (
                          <Box
                            sx={{
                              width: {
                                xs: "calc(100% / 4)",
                                sm: `calc(100% / 12)`,
                                md: `calc(100% / ${alldays?.length})`,
                              },
                              borderBottom: "1px solid #22356D26",
                              fontFamily: "var(--font-regular) !important",
                              textAlign: "center",
                              padding: { xs: "4.5px 10px", md: "2.5px 2px" },
                              height: { xs: "55px", md: "120px" },
                            }}
                            position={"relative"}
                            onTouchStart={() =>
                              setShow({ id: index, row: row })
                            }
                            onTouchEnd={() => setShow({})}
                            onMouseEnter={() =>
                              setShow({ id: index, row: row })
                            }
                            onMouseLeave={() => setShow({})}
                          >
                            <Box
                              sx={{
                                width: "90%",
                                height: "98%",
                                margin: "auto",
                                background: calculateBackgroundColor(
                                  item.occupancyRate
                                ),
                                borderRadius: "7px",
                              }}
                            ></Box>

                            {/* showing data when hovering */}
                            {show?.id === index && show?.row === row && (
                              <Box
                                position={"absolute"}
                                top={"-5px"}
                                zIndex={"9999"}
                                sx={{
                                  background: "white",
                                  padding: "2px 10px",
                                  borderRadius: "7px",
                                  width: "175px",
                                  textAlign: "left",
                                  fontFamily: "var(--font-medium) !important",
                                  color: "#889AB6",
                                  boxShadow:
                                    "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                  left: {
                                    xs:
                                      alldays.length - 1 === index ||
                                      alldays.length - 2 === index
                                        ? "-77px"
                                        : "-85px",
                                    md:
                                      alldays.length - 1 === index ||
                                      alldays.length - 2 === index
                                        ? "-107px"
                                        : "-10px",
                                  },
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily:
                                      "var(--font-regular) !important",
                                    color: "#22356D99",
                                  }}
                                >
                                  {item.court_name}
                                </p>
                                <p>{selectLocation}</p>
                                <p>{item.occupancyRate}% Occupancy</p>
                                <p>{item.occupiedHours} Hours booked</p>
                              </Box>
                            )}
                          </Box>
                        );
                      })}
                  </Box>
                );
              })}

              {data.length === 0 && (
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontFamily: "var(--font-family)",
                    color: "#22356D",
                    marginBlock: "1rem",
                  }}
                >
                  {status}
                </p>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MonthlyCalendar;
