import { Box, Typography } from '@mui/material'
import Cleave from 'cleave.js/react'
import React from 'react'
import { locationTypes } from 'src/context/MainContext'

type courtDynamic={
  price: number;
  court_id:number;
  court_name: string;
  open_match_price:number;
}

  
const DynamicPrice = ({data,index,callback,selectedlocation,type,isOpenMatch}:{data:courtDynamic,index:number,callback:any,selectedlocation:locationTypes,type?:string,isOpenMatch?:boolean}) => {

    const handleInput =(value:any)=>{
        callback(value,'price');
    }

    const handleOpenInput =(value:any)=>{
        callback(value,'open_match_price');
    }

  return (
    <Box sx={{display:'flex',flexDirection:{xs:type==="Booking"?"column":"row",md:'row'},alignItems:{xs:'unset',md:'center'},justifyContent:{xs:"space-between",md:'space-between'},gap:{xs:'10px'}}}>
       {type !== "Booking" &&  <Typography sx={{fontFamily:'var(--font-regular)',marginLeft:'15px',fontSize:'14px'}}>{data.court_name}</Typography>}
        
         <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',gap:'5px'}}>
         {type === "Booking" &&  <Typography sx={{fontFamily:'var(--font-regular)',marginLeft:'15px',fontSize:'14px'}}>{data.court_name}</Typography>}
         <Box width={"130px"} sx={{display: "flex",alignItems: "center",justifyContent: "space-between",}}>
              <Cleave
                style={{
                  width: "100px",
                  color: "rgba(34, 53, 109)",
                  fontFamily: "var(--font-regular)",
                  fontSize: "14px",
                  lineHeight: "17px",
                  borderRadius: "7px",
                  padding: "5px 8px",
                  border: "1px solid #EEEFEE",
                }}
                onChange={(event: any) =>
                    handleInput(event.target.rawValue)
                }
                value={data.price}
                placeholder="100"
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  delimiter:selectedlocation.currency === 'IDR' ?'\u2024':','
                }}
              />
                <p style={{color: "#22356D",
  fontFamily: "var(--font-regular)",
  fontSize: "14px"}}>{selectedlocation.currency}</p>
         </Box>
         </Box>
         <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',gap:'5px'}}>
         {type === "Booking" &&  <Typography sx={{fontFamily:'var(--font-regular)',marginLeft:'15px',fontSize:'14px',display:{xs:'block',md:'none'}}}>{data.court_name} Open Match</Typography>}
         { type==="Booking" && <Box width={"130px"} sx={{display: "flex",alignItems: "center",justifyContent: "space-between",opacity:isOpenMatch?1:0.6,pointerEvents:isOpenMatch?'auto':'none'}}>
              <Cleave
                style={{
                  width: "100px",
                  color: "rgba(34, 53, 109)",
                  fontFamily: "var(--font-regular)",
                  fontSize: "14px",
                  lineHeight: "17px",
                  borderRadius: "7px",
                  padding: "5px 8px",
                  border: "1px solid #EEEFEE",
                }}
                onChange={(event: any) =>
                    handleOpenInput(event.target.rawValue)
                }
                value={data.open_match_price}
                placeholder="100"
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  delimiter:selectedlocation.currency === 'IDR' ?'\u2024':','
                }}
              />
                <p style={{color: "#22356D",
                fontFamily: "var(--font-regular)",
                fontSize: "14px"}}>{selectedlocation.currency}</p>
         </Box>}
         </Box>
    </Box>
  )
}

export default DynamicPrice