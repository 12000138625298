import React, { useEffect, useState } from "react";
import { activeColor } from "src/constants/color";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddPlayer from "./Calendar/AddPlayer";
import "./tableStyling.css";
import ScoreEdit from "./ScoreEdit";
import { useFieldArray, useForm } from "react-hook-form";

const scoreStyle={
    // borderRadius: '4px',
    border:'1px solid #939393',
    width:'20px',
    height:'20px',
    textAlign:"center",
    fontSize:{xs:"0.8rem", sm:"0.9rem"},
    // backgroundColor:'white'
}

interface score{
  teamA: {
    score1: number,
    score2: number,
    score3: number
  },
  teamB: {
    score1: number,
    score2: number,
    score3: number
  }
}


const ScoreList = ({ userData,ScoreData}:{ userData: any, ScoreData:any}) => {
    const [isDraw,setIsdraw] = useState<boolean>(false);
    const {setValue,watch,reset} =useForm<score>();
    const {teamA,teamB} = watch();
    const isValidUSerData = userData[0]?.customer!==null || userData[0]?.pairedData?.length!==0 && userData[1]?.customer!==null || userData[1]?.pairedData?.length!==0

    
    return (
        <>
            {/* ----- player list ----- */}
           
            <Box height={userData.length>3?'250px':'auto'} sx={{marginTop:'0px'}} id="table-person">
            {isValidUSerData ?   <Box className="waitingList">
                       <ScoreEdit reset={reset} ScoreData={ScoreData} isDraw={isDraw} setIsdraw={setIsdraw} setValue={setValue} scoredata={{teamA,teamB}} index={0} user={userData[0]}/>
                       <ScoreEdit reset={reset} ScoreData={ScoreData} isDraw={isDraw} setIsdraw={setIsdraw} setValue={setValue} scoredata={{teamA,teamB}} index={1} user={userData[1]}/>
                </Box>
                 :
                 <Typography sx={{color: activeColor, marginBottom:"40px", marginTop:"7px",padding:'10px',fontFamily:'var(--font-regular)'}}>There is no Player in Booking</Typography>}
            </Box>
            
        </>
      )
}

export default ScoreList