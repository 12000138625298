import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useWeekData = (selectedMonth: string) => {
  const {t,i18n} = useTranslation();
  const CurrenLanguage = i18n.language;
  const [allDaysOfMonth, setAllDaysOfMonth] = useState<string[]>([]);

  useEffect(() => {
    const calculateDaysOfMonth = () => {
      const dateObject = new Date(selectedMonth);
      const year = dateObject.getFullYear();
      const month = dateObject.getMonth();
      const firstDayOfMonth =  moment([year, month, 1]);
      const lastDayOfMonth = firstDayOfMonth.clone().endOf('month');
      let currentDay = firstDayOfMonth.clone();
      const daysArray = [];

      while (currentDay.isSameOrBefore(lastDayOfMonth, 'day')) {
        daysArray.push(currentDay.locale(CurrenLanguage).format('ddd DD MMM'));
        currentDay.add(1, 'day');
      }

      setAllDaysOfMonth(daysArray);
    };

    calculateDaysOfMonth();
  }, [selectedMonth,CurrenLanguage]);

  return allDaysOfMonth;
};

export default useWeekData;
