import SelectBox, { SelectBoxPayment } from "../../components/SelectBox";
import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import moment from "moment";
import "./payments.css";
import PaymentPopup from "./PaymentPopup";
import { FiPlus } from "react-icons/fi";
import { IoEyeOutline } from "react-icons/io5";
import { getAllServices } from "src/api/CalendarBooking";
import BookingModel from "../calenderDaily/BookingModel";
import MainContext from "src/context/MainContext";
import Cleave from "cleave.js/react";
import { useGetCourtList } from "src/hooks/Calendars/useGetCourtList";
import { useGetAllPaymentMethodsByLoc } from "src/hooks/useGetAllPaymentMethods";
import { Dialog, DialogContent, useMediaQuery } from "@mui/material";
import theme from "src/theme";
import { addThousandSeparator } from "src/constants/SportSEparator";
import { useTranslation } from "react-i18next";
import { updateMembershipCancel, updatevoucherCancel } from "src/api/Payments";
import { activeColor } from "src/constants/color";

const commonStyle = {
  width: "150px",
  color: "#22356D",
  height: "32px",
  fontFamily: "var(--font-regular)",
};

const style = {
  fontFamily: "var(--font-semibold)",
  background: "white",
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
  padding: "7px 20px",
  borderRadius: "7px",
};

const PaymentFilter = ({
  allPayments,
  selectedLocation,
  allVouchers,
  update,
  setSelectedDays,
  AllCoupons,
}: {
  allPayments: any;
  selectedLocation: any;
  update: any;
  setSelectedDays: any;
  allVouchers: any;
  AllCoupons: any;
}) => {
  const {
    data: PaymentMethods,
    isSuccess,
    isError,
    refetch,
    setlocation_id,
  } = useGetAllPaymentMethodsByLoc();
  const {
    data: CourtList,
    isSuccess: CourtSuccess,
    isError: CourtError,
    refetch: courtRefetch,
    setLocation_id,
  } = useGetCourtList();
  const { t } = useTranslation();
  const services = [
    { name: t("common.Booking"), key: "Booking" },
    { name: t("common.Event"), key: "Event" },
    { name: t("common.Lesson"), key: "Lesson" },
    { name: t("payments.Voucher"), key: "Voucher" },
    { name: t("customers.Membership"), key: "Membership" },
  ];

  const statuses = [
    { name: t("common.Paid"), key: "Paid" },
    { name: t("common.Pending Payment"), key: "Pending payment" },
    { name: t("common.Total Refund"), key: "Total Refund" },
    { name: t("common.Partial Refund"), key: "Partial Refund" },
    { name: t("Calendar.Cancelled"), key: "Cancelled" },
  ];

  interface deleteWarning {
    isOpen: boolean;
    type: string;
    item: any | null;
  }
  const [AllEvent, setAllEvent] = useState<any[]>([]);
  const [deleteWarning, setDeleteWarning] = useState<deleteWarning>({
    type: "",
    isOpen: false,
    item: null,
  });
  const [allCourt, setAllCourt] = useState<any[]>([]);
  const [selectedHead, setselectedHead] = useState<string>("");
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [selectedCourt, setSelectedCourt] = useState<any>("");
  const [AllPaymentMethods, setAllPaymentMethods] = useState<any[]>([]);
  const [index, setindex] = useState<number>();
  const { selectedDate, setNotification } = React.useContext(MainContext);
  const context = useContext(MainContext);
  const setSelectedLocationId = context?.setSelectedLocationId;
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const setSelectedDate = context?.setSelectedDate;
  const queryParams = new URLSearchParams(window.location.search);
  const inputValueParam = queryParams.get("inputValue");
  const [filters, setFilters] = useState({
    customerName: "",
    customerId: "",
    services: "",
    paymentMethod: "",
    status: "",
    discount: "",
    totalPrice: "",
  });
  const [open, setOpen] = useState(false);
  const [showExpand, setshowExpand] = useState<any>(undefined);
  const [data, setData] = useState<any>();
  const [filteredData, setFilteredData] = useState([]);
  const [openBookingModel, setOpenBooking] = useState<boolean>(false);

  useEffect(() => {
    if (!!selectedLocation) {
      setlocation_id(selectedLocation?.id);
      setLocation_id(selectedLocation?.id);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (isSuccess) {
      const backend = PaymentMethods;
      const newupdte = [{ method_name: "Wallet" }, ...backend];
      setAllPaymentMethods(newupdte);
    } else {
    }
  }, [PaymentMethods, isSuccess, isError]);

  // function to convert it into excel format
  const exportToCSV = (data: any) => {
    let isTransmidExist: boolean = data[0]?.Payment[0]?.transaction
      ? true
      : false;
    let csvContent = isTransmidExist
      ? "ID,Date,User,Service,Service Name,Duration,Coach,Payment_Method,Price,transmid_id,Discount,Status,Total_Price\n"
      : "ID,Date,User,Service,Service Name,Duration,Coach,Payment_Method,Price,Discount,Status,Total_Price\n";

    data.forEach((booking: any) => {
      const { date, id, service_type, Payment, service, coaches } = booking;
      const ServiceName =
        service_type === "Event"
          ? service?.event_name
          : service_type === "Lesson"
          ? service?.lesson_name
          : service_type === "Booking"
          ? `Booking ${service?.duration}`
          : "";
      const coachName = coaches?.map((item: any) => item.full_name);
      const finalPrice =
        booking?.Payment?.reduce((acc: any, item: any) => acc + item.price, 0) -
        booking?.Payment?.reduce(
          (acc: any, item: any) => acc + item.discount,
          0
        );
      const price = booking?.Payment.reduce(
        (acc: any, item: any) => acc + item.price,
        0
      );
      const discount = booking.Payment.reduce(
        (acc: any, item: any) => acc + item.discount,
        0
      );
      Payment.forEach((payment: any) => {
        const method =
          payment?.payment_method == null
            ? "Pending Payment"
            : payment?.payment_method;

        const {
          fullName,
          price,
          discount,
          status,
          id: paymentId,
          transaction,
        } = payment;
        const trasactionIDs = transaction
          ? transaction?.map(
              (item: any) => item?.payment_gateway_transaction_id
            )
          : undefined;

        const Price = payment?.payment_method === "Membership" ? 0 : price;
        const TotalPrice =
          payment?.payment_method === "Membership" ? 0 : price - discount;
        const row = isTransmidExist
          ? `${id},${date},${fullName},${service_type},${ServiceName},${service?.duration} min,${coachName},${method},${Price},${trasactionIDs},${discount},${status},${TotalPrice}\n`
          : `${id},${date},${fullName},${service_type},${ServiceName},${service?.duration} min,${coachName},${method},${Price},${discount},${status},${TotalPrice}\n`;

        csvContent += row;
      });
    });

    // allVouchers.map((item: any) => {
    //   const { id, service, method, voucher, full_name, date } = item;
    //   const { method_name } = method;
    //   const { voucher_name, price } = voucher;
    //   const row = `${id},${date},${full_name},${service},${method_name},${price},"0IDR","Paid",${price}\n`;

    //   csvContent += row;
    // });

    const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const csvURL = URL.createObjectURL(csvBlob);

    const link = document.createElement("a");
    link.href = csvURL;
    link.download = "Payment.csv";
    link.click();
  };

  // export to csv
  const handleExportButtonClick = () => {
    exportToCSV(filteredData);
  };

  const handleGetEvents = async (data: any) => {
    setSelectedCourt(data?.court?.id);
    setSelectedLocationId(selectedLocation?.id);
    const selectedDate = new Date(moment(data?.date, "DD/MM/YYYY").toDate());

    setSelectedDate(selectedDate);
    const date = moment(data?.date, "D/M/YYYY")?.format("YYYY-M-D");
    const response = await getAllServices(selectedLocation?.id, date);

    if (!!response) {
      const myArray = response?.serviceBookings;
      const newArray = myArray.map((obj: any) => ({
        ...obj,
        service_id: obj?.id,
        start_time: moment(obj.start_time, "HH:mm:ss").toDate(),
        end_time: moment(obj.end_time, "HH:mm:ss").toDate(),
      }));
      const updated = newArray.filter((item: any) => item?.id === data?.id);

      setAllEvent(updated);
      setOpenBooking(true);
    } else {
      setAllEvent([]);
    }
  };

  useEffect(() => {
    if (CourtSuccess) {
      setAllCourt(CourtList);
    } else {
      setAllCourt([]);
    }
  }, [CourtList, CourtError, CourtSuccess]);

  const handlepopup = (item: any, index: number) => {
    setData(item);
    setindex(index);
    setOpen(true);
  };

  const handleFilterChange = (name: string, value: any) => {
    setFilters((prevFilters: any) => {
      if (prevFilters[name] === value) {
        return { ...prevFilters, [name]: "" };
      } else {
        return {
          ...prevFilters,
          [name]: value,
        };
      }
    });
  };

  // Function to filter data based on filter criteria
  const filterData = () => {
    let filtered = allPayments;

    if (filters.customerName) {
      filtered = filtered.filter((item: any) =>
        item.Payment.some((payment: any) =>
          payment.fullName
            .toString()
            .toLowerCase()
            .includes(filters.customerName.toLowerCase())
        )
      );
    }
    if (filters.customerId) {
      filtered = filtered.filter((item: any) =>
        item.id.toString().startsWith(filters.customerId)
      );
    }
    if (filters.services) {
      filtered = filtered.filter((item: any) =>
        item.service_type.toString().startsWith(filters.services)
      );
    }
    if (filters.paymentMethod) {
      filtered = filtered.filter((item: any) =>
        item.Payment?.some(
          (pay: any) => pay?.payment_method === filters.paymentMethod
        )
      );
    }
    if (filters.status) {
      filtered = filtered.filter((item: any) => {
        return item.Payment?.some((pay: any) => pay?.status === filters.status);
      });
    }
    if (filters.discount) {
      filtered = filtered.filter(
        (item: any) => item.Payment[0]?.coupons?.name === filters.discount
      );
    }
    if (filters.totalPrice) {
      filtered = filtered.filter((item: any) =>
        String(
          item.Payment?.reduce((item: any, acc: any) => item + acc?.price, 0)
        ).includes(filters.totalPrice)
      );
    }
    setFilteredData(filtered);
    // setSortedData(filtered)
  };

  useEffect(() => {
    if (inputValueParam !== null) {
      setSelectedDays({
        date_start: moment(selectedDate).format("MM/DD/YYYY"),
        date_end: moment(selectedDate).format("MM/DD/YYYY"),
      });
      handleFilterChange("customerName", inputValueParam);
    }
  }, [inputValueParam]);

  const handleClick = (id: number, data?: any) => {
    if (showExpand === id) {
      setshowExpand(undefined); // Remove ID if already selected
    } else {
      setshowExpand(id); // Set ID if not selected
    }
    if (isMobile && data) {
      handleGetEvents(data);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect hook to trigger filtering whenever filter criteria change
  useEffect(() => {
    filterData();
  }, [filters, allPayments]);

  const isnextelement =
    showExpand !== undefined &&
    filteredData.findIndex((item: any) => item.id === showExpand) + 1;

  const determineStatus = (data: any) => {
    const statusCounts = data.reduce((acc: any, item: any) => {
      acc[item.status] = (acc[item.status] || 0) + 1;
      return acc;
    }, {});

    const uniqueStatuses = Object.keys(statusCounts);

    if (uniqueStatuses.length === 1) {
      // Only one unique status
      return uniqueStatuses[0];
    } else {
      // More than one unique status
      if (uniqueStatuses.includes("Pending payment")) {
        return `Pending payment +${uniqueStatuses.length - 1}`;
      } else if (uniqueStatuses.includes("Partial Refund")) {
        return `Partial Refund + ${uniqueStatuses.length - 1}`;
      } else if (uniqueStatuses.includes("Total Refund")) {
        return `Total Refund + ${uniqueStatuses.length - 1}`;
      } else if (uniqueStatuses.includes("Paid")) {
        return `Paid + ${uniqueStatuses.length - 1}`;
      }
    }
    return;
  };

  const handleCancelDeleteMembership = (type: string, item: any) => {
    if (type === "Voucher" || type === "Membership") {
      setDeleteWarning({ type, isOpen: true, item });
    } else {
      handleGetEvents(item);
    }
    // item?.service_type==="Voucher" ? handleCancelVoucher(item):  item?.service_type==="Membership" ? handleCancelMembership(item):handleGetEvents(item)
  };

  const handleCancelMembership = async (item: any) => {
    try {
      const response = await updateMembershipCancel(item?.id);
      setNotification({
        message: "Membership cancelled successfully",
        subMessage: "",
        type: "success",
        isShow: true,
      });
      update();
      setDeleteWarning({ isOpen: false, type: "", item: null });
    } catch (error: any) {
      setNotification({
        message: error,
        subMessage: "",
        type: "error",
        isShow: true,
      });
    }
  };

  const handleCancelVoucher = async (item: any) => {
    try {
      const response = await updatevoucherCancel(item?.id);
      setNotification({
        message: "Voucher cancelled successfully",
        subMessage: "",
        type: "success",
        isShow: true,
      });
      update();
      setDeleteWarning({ isOpen: false, type: "", item: null });
    } catch (error: any) {
      setNotification({
        message: error,
        subMessage: "",
        type: "error",
        isShow: true,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    let sorted;
    if (selectedHead) {
      sorted = [...filteredData].sort((a: any, b: any) => {
        // Sorting logic based on the current sorted column
        if (selectedHead === "id") {
          return a[selectedHead] - b[selectedHead];
        } else if (selectedHead === "date") {
          const dateA: any = new Date(
            a[selectedHead].split("/").reverse().join("-")
          );
          const dateB: any = new Date(
            b[selectedHead].split("/").reverse().join("-")
          );
          return dateB - dateA;
        } else if (selectedHead === "user") {
          return a?.Payment[0]?.fullName.localeCompare(b?.Payment[0]?.fullName);
        } else if (selectedHead === "price" || selectedHead === "Tprice") {
          return (
            b?.Payment?.reduce((acc: any, item: any) => acc + item?.price, 0) -
            a?.Payment?.reduce((acc: any, item: any) => acc + item?.price, 0)
          );
        } else if (selectedHead === "discount") {
          return (
            b?.Payment?.reduce(
              (acc: any, item: any) => acc + item?.discount,
              0
            ) -
            a?.Payment?.reduce((acc: any, item: any) => acc + item?.discount, 0)
          );
        } else if (selectedHead === "refund") {
          return (
            b?.Payment?.reduce(
              (acc: any, item: any) => acc + item?.refund_price,
              0
            ) -
            a?.Payment?.reduce(
              (acc: any, item: any) => acc + item?.refund_price,
              0
            )
          );
        }
        return 0;
      });
    } else {
      sorted = filteredData;
    }

    setSortedData(sorted);
  }, [selectedHead, filteredData]);

  const handleSelectedHeadChange = (name: string) => {
    setselectedHead((prev: any) => {
      if (prev === name) {
        return "";
      } else {
        return name;
      }
    });
  };

  const start_time = moment(AllEvent[0]?.start_time).format("HH:mm");
  const end_time = moment(AllEvent[0]?.end_time).format("HH:mm");

  return (
    <Box>
      <Box
        marginBlock={"20px"}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <img
          src="/images/view.png"
          alt="filter"
          style={{ width: "20px", height: "20px" }}
        />
        <input
          name="customerId"
          value={filters.customerId}
          onChange={(e: any) =>
            handleFilterChange("customerId", e.target.value)
          }
          type="number"
          placeholder="ID"
          style={commonStyle}
          className="mobile-res"
        />
        <input
          name="customerName"
          value={filters.customerName}
          onChange={(e: any) =>
            handleFilterChange("customerName", e.target.value)
          }
          type="text"
          placeholder={t("common.Customer")}
          style={commonStyle}
          className="mobile-res"
        />
        <Box sx={{ width: "150px" }} className="mobile-res">
          <SelectBoxPayment
            text={t("common.Service")}
            value={filters.services}
            setValue={(data: any) => handleFilterChange("services", data)}
            arr={services}
          />
        </Box>
        <Box width={"198px"}>
          <SelectBox
            text={t("common.Payment Methods")}
            value={filters.paymentMethod}
            setValue={(data: any) => handleFilterChange("paymentMethod", data)}
            arr={AllPaymentMethods?.map((item: any) => item?.method_name)}
          />
        </Box>
        <Box width={"198px"}>
          <SelectBox
            text={t("common.Discount")}
            value={filters.discount}
            setValue={(data: any) => handleFilterChange("discount", data)}
            arr={AllCoupons}
          />
        </Box>
        {/* <input
          type="text"
          placeholder={t('common.Discount')}
          value={filters.discount}
          onChange={(e: any) => handleFilterChange("discount", e.target.value)}
          style={commonStyle}
          className="mobile-res"
        /> */}
        <Box sx={{ width: "150px" }} className="mobile-res">
          <SelectBoxPayment
            text={t("common.Status")}
            value={filters.status}
            setValue={(data: any) => handleFilterChange("status", data)}
            arr={statuses}
          />
        </Box>
        <Cleave
          placeholder={t("common.Total Price")}
          value={filters.totalPrice}
          onChange={(e: any) =>
            handleFilterChange("totalPrice", e.target.rawValue)
          }
          style={commonStyle}
          className="mobile-res"
          options={{
            numeral: true,
            numeralThousandsGroupStyle: "thousand",
            delimiter: selectedLocation?.currency === "IDR" ? "\u2024" : ",",
          }}
        />
        <Button
          className="mobile-res"
          style={{
            ...commonStyle,
            borderRadius: "7px",
            padding: "0px",
            border: "#C6CBDA 1px solid",
            opacity: "0.7",
            background: "white",
            textTransform: "capitalize",
          }}
          onClick={handleExportButtonClick}
        >
          {t("common.Export To CSV")}
        </Button>
      </Box>

      <Box
        sx={{
          padding: "10px",
          background: "white",
          borderRadius: "7px",
          boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
        }}
      >
        <table style={{ width: "100%" }} id="paymentTable">
          <thead>
            <tr>
              <th
                onClick={() => handleSelectedHeadChange("date")}
                className={
                  selectedHead === "date"
                    ? "static-width underline"
                    : "static-width"
                }
              >
                {t("bookings.Booking Date")}
              </th>
              <th
                onClick={() => handleSelectedHeadChange("user")}
                className={
                  selectedHead === "user"
                    ? "static-width underline"
                    : "static-width"
                }
              >
                {t("customers.User")}
              </th>
              <th className="show-row">{t("title.Services")}</th>
              <th className="show-row">{t("customers.Payment Method")}</th>
              <th
                onClick={() => handleSelectedHeadChange("price")}
                className={
                  selectedHead === "price"
                    ? "static-width underline show-row"
                    : "static-width show-row"
                }
              >
                {t("common.Price")}
              </th>
              <th
                onClick={() => handleSelectedHeadChange("discount")}
                className={
                  selectedHead === "discount"
                    ? "static-width underline show-row"
                    : "static-width show-row"
                }
              >
                {t("payments.Discounts")}
              </th>
              <th
                onClick={() => handleSelectedHeadChange("refund")}
                className={
                  selectedHead === "refund"
                    ? "static-width underline show-row"
                    : "static-width show-row"
                }
              >
                {t("common.Refund")}
              </th>
              <th style={isLargeScreen ? { width: "16%" } : { width: "auto" }}>
                {t("common.Status")}
              </th>
              <th
                onClick={() => handleSelectedHeadChange("Tprice")}
                className={
                  selectedHead === "Tprice"
                    ? "static-width underline show-row"
                    : "static-width show-row"
                }
              >
                {t("common.Total Price")}
              </th>
              <th className="show-row">{t("common.Details")}</th>
            </tr>
          </thead>
          <tbody>
            {sortedData?.length > 0 ? (
              sortedData?.map((item: any, index: number) => {
                const finalPrice =
                  item?.Payment?.filter(
                    (pay: any) => pay?.payment_method !== "Membership"
                  )?.reduce((acc: any, item: any) => acc + item.price, 0) -
                  item?.Payment?.reduce(
                    (acc: any, item: any) => acc + item.discount,
                    0
                  );

                return (
                  <tr
                    className={
                      item.id === showExpand
                        ? "shadowrowcustomer"
                        : "" || isnextelement === index
                        ? "shadowaccordingly"
                        : ""
                    }
                    key={index}
                  >
                    <td
                      onClick={() => handleClick(item.id, item)}
                      className={
                        item.id === showExpand
                          ? "bordertdforcustomer text-center paymenstsassdf"
                          : ""
                      }
                    >
                      {item.date}
                    </td>
                    {item.id === showExpand ? (
                      <>
                        <td
                          className={
                            item.id === showExpand
                              ? "bordertdforcustomer text-center paymenstsassdf "
                              : ""
                          }
                        >
                          {item.Payment.map((expanded: any, index: number) => (
                            <div
                              style={{ width: "100%", margin: "5px 0px" }}
                              className={
                                item.Payment.length === index + 1
                                  ? "text-center"
                                  : " text-center "
                              }
                            >
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                {item?.service_type !== "Voucher" &&
                                  item?.service_type !== "Membership" && (
                                    <IoEyeOutline
                                      cursor={"pointer"}
                                      onClick={() => handlepopup(item, index)}
                                      fontSize={12}
                                    />
                                  )}
                                {expanded.fullName}
                              </span>
                            </div>
                          ))}
                        </td>

                        <td
                          onClick={() => handleClick(item.id)}
                          className={
                            item.id === showExpand
                              ? "bordertdforcustomer text-center paymenstsassdf show-row"
                              : "show-row"
                          }
                        >
                          <div>
                            {item.service_type === "Voucher" ? (
                              t(`payments.Voucher`)
                            ) : item.service_type === "Membership" ? (
                              <div>
                                <p>{t(`common.Membership Sale`)}</p>
                                <p>{item?.membership_name}</p>
                              </div>
                            ) : (
                              t(`common.${item.service_type}`)
                            )}
                          </div>
                          {item.recurring && (
                            <div
                              style={{
                                background: "#889AB626",
                                width: "fit-content",
                                marginInline: "auto",
                                textAlign: "center",
                                color: "#22356DB2",
                                borderRadius: "7px",
                                padding: "3px 8px 0px 8px",
                                fontSize: "13px",
                              }}
                            >
                              {t("Calendar.Recurring")}
                            </div>
                          )}
                          <div style={{ textTransform: "capitalize" }}>
                            {item.service_type === "Booking"
                              ? item.booking
                              : item.service_type == "Event"
                              ? item.event
                              : item.lesson}
                          </div>
                        </td>

                        <td
                          onClick={() => handleClick(item.id)}
                          className={
                            item.id === showExpand
                              ? "bordertdforcustomer text-center paymenstsassdf show-row"
                              : "show-row"
                          }
                        >
                          {item.Payment.map((expanded: any, index: number) => {
                            return (
                              <div
                                style={{ width: "100%", margin: "5px 0px" }}
                                className={
                                  item.Payment.length === index + 1
                                    ? "text-center"
                                    : " text-center "
                                }
                              >
                                {expanded.payment_method === null
                                  ? "Pending Payment"
                                  : expanded.payment_method}
                              </div>
                            );
                          })}
                        </td>
                        <td
                          onClick={() => handleClick(item.id)}
                          className={
                            item.id === showExpand
                              ? "bordertdforcustomer text-center paymenstsassdf show-row"
                              : "show-row"
                          }
                        >
                          {item.Payment.map((expanded: any, index: number) => (
                            <div
                              style={{ width: "100%", margin: "5px 0px" }}
                              className={
                                item.Payment.length === index + 1
                                  ? "text-center"
                                  : " text-center "
                              }
                            >
                              {addThousandSeparator(
                                expanded?.payment_method === "Membership"
                                  ? 0
                                  : expanded?.price,
                                selectedLocation?.currency
                              )}{" "}
                              {selectedLocation?.currency}
                            </div>
                          ))}
                        </td>

                        <td
                          onClick={() => handleClick(item.id)}
                          className={
                            item.id === showExpand
                              ? "bordertdforcustomer text-center paymenstsassdf show-row"
                              : "show-row"
                          }
                        >
                          {item.Payment.map((expanded: any, index: number) => (
                            <div
                              style={{ width: "100%", margin: "5px 0px" }}
                              className={
                                item.Payment.length === index + 1
                                  ? "text-center"
                                  : " text-center "
                              }
                            >
                              {addThousandSeparator(
                                expanded.discount,
                                selectedLocation?.currency
                              )}{" "}
                              {selectedLocation?.currency}
                            </div>
                          ))}
                        </td>
                        <td
                          onClick={() => handleClick(item.id)}
                          className={
                            item.id === showExpand
                              ? "bordertdforcustomer text-center paymenstsassdf show-row"
                              : "show-row"
                          }
                        >
                          {item.Payment.map((expanded: any, index: number) => (
                            <div
                              style={{ width: "100%", margin: "5px 0px" }}
                              className={
                                item.Payment.length === index + 1
                                  ? "text-center"
                                  : " text-center "
                              }
                            >
                              {addThousandSeparator(
                                expanded.refund_price,
                                selectedLocation?.currency
                              )}{" "}
                              {selectedLocation?.currency}
                            </div>
                          ))}
                        </td>

                        <td
                          onClick={() => handleClick(item.id)}
                          className={
                            item.id === showExpand
                              ? "bordertdforcustomer paymenstsassdf text-center "
                              : ""
                          }
                        >
                          {item.Payment.map((expanded: any, index: number) => (
                            <div
                              style={{
                                width: "100%",
                                margin: "5px 0px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                              className={
                                item.Payment.length === index + 1
                                  ? "text-center"
                                  : " text-center "
                              }
                            >
                              {expanded.status === "Cancelled" &&
                                expanded.prev_status === "Paid" && (
                                  <Typography
                                    sx={{
                                      display: { xs: "none", sm: "block" },
                                      background: "#889AB626",
                                      width: "fit-content",
                                      marginInline: "auto",
                                      textAlign: "center",
                                      color: "#22356DB2",
                                      borderRadius: "7px",
                                      padding: "3px 8px 0px 8px",
                                      fontSize: "13px",
                                      fontFamily: "var(--font-regular)",
                                      // ">span:nth-child(1)": {
                                      //   display: { xs: "none", sm: "block" },
                                      //   width: "fit-content",
                                      // },
                                      // ">span:nth-child(2)": {
                                      //   display: { xs: "block", sm: "none" },
                                      //   width: "fit-content",
                                      // },
                                    }}
                                  >
                                    Paid
                                  </Typography>
                                )}
                              <Typography
                                sx={{
                                  background: "#889AB626",
                                  width: "fit-content",
                                  marginInline: "auto",
                                  textAlign: "center",
                                  color: "#22356DB2",
                                  borderRadius: "7px",
                                  padding: "3px 8px 0px 8px",
                                  fontSize: "13px",
                                  fontFamily: "var(--font-regular)",
                                  ">span:nth-child(1)": {
                                    display: { xs: "none", sm: "block" },
                                    width: "fit-content",
                                  },
                                  ">span:nth-child(2)": {
                                    display: { xs: "block", sm: "none" },
                                    width: "fit-content",
                                  },
                                }}
                              >
                                <span>
                                  {expanded.status === "Pending payment"
                                    ? `Pending Payment`
                                    : expanded.status}
                                </span>
                                <span>
                                  {expanded.status === "Pending payment"
                                    ? "Pending"
                                    : "Pending Payment"}
                                </span>
                              </Typography>
                            </div>
                          ))}
                        </td>
                        <td
                          onClick={() => handleClick(item.id)}
                          className={
                            item.id === showExpand
                              ? "bordertdforcustomer text-center paymenstsassdf show-row"
                              : "show-row"
                          }
                        >
                          {item.Payment.map((expanded: any, index: number) => (
                            <div
                              style={{ width: "100%", margin: "5px 0px" }}
                              className={
                                item.Payment.length === index + 1
                                  ? "text-center"
                                  : " text-center "
                              }
                            >
                              {addThousandSeparator(
                                expanded?.payment_method === "Membership"
                                  ? 0
                                  : expanded.price - expanded.discount,
                                selectedLocation?.currency
                              )}{" "}
                              {selectedLocation?.currency}
                            </div>
                          ))}
                        </td>
                      </>
                    ) : (
                      <>
                        <td onClick={() => handleClick(item.id)}>
                          <div
                            style={{ gap: "2px" }}
                            className="flex flex-row items-center justify-center"
                          >
                            {item?.Payment?.length === 1 ? (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                {item?.service_type !== "Voucher" &&
                                  item?.service_type !== "Membership" && (
                                    <IoEyeOutline
                                      cursor={"pointer"}
                                      onClick={() => handlepopup(item, 0)}
                                      fontSize={12}
                                    />
                                  )}
                                {item?.Payment[0]?.fullName}
                              </span>
                            ) : (
                              item.Payment[0]?.fullName
                            )}
                            {item.Payment.length > 1 && (
                              <span style={{ marginBottom: "2px" }}>
                                <FiPlus
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "lighter",
                                    marginRight: "-2px",
                                  }}
                                />
                              </span>
                            )}
                            {item.Payment.length > 1
                              ? `${item.Payment.length - 1}`
                              : ""}
                          </div>
                        </td>

                        <td
                          onClick={() => handleClick(item.id)}
                          className="show-row"
                        >
                          {item.service_type === "Voucher"
                            ? t(`payments.Voucher`)
                            : item.service_type === "Membership"
                            ? t(`common.Membership Sale`)
                            : t(`common.${item.service_type}`)}
                        </td>

                        <td
                          onClick={() => handleClick(item.id)}
                          className="show-row"
                        >
                          {item.Payment[0]?.payment_method == null
                            ? "Pending Payment"
                            : item.Payment[0]?.payment_method}
                        </td>

                        <td
                          onClick={() => handleClick(item.id)}
                          className="show-row"
                        >
                          {addThousandSeparator(
                            item.Payment?.filter(
                              (pay: any) => pay?.payment_method !== "Membership"
                            )?.reduce(
                              (acc: any, item: any) => acc + item.price,
                              0
                            ),
                            selectedLocation?.currency
                          )}{" "}
                          {selectedLocation?.currency}
                        </td>

                        <td
                          onClick={() => handleClick(item.id)}
                          className="show-row"
                        >
                          {addThousandSeparator(
                            item.Payment.reduce(
                              (acc: any, item: any) => acc + item.discount,
                              0
                            ),
                            selectedLocation?.currency
                          )}{" "}
                          {selectedLocation?.currency}
                        </td>
                        <td
                          onClick={() => handleClick(item.id)}
                          className="show-row"
                        >
                          {addThousandSeparator(
                            item.Payment.reduce(
                              (acc: any, item: any) => acc + item.refund_price,
                              0
                            ),
                            selectedLocation?.currency
                          )}{" "}
                          {selectedLocation?.currency}
                        </td>

                        <td
                          onClick={() => handleClick(item.id)}
                          className={
                            item.id === showExpand
                              ? "bordertdforcustomer paymenstsassdf text-center"
                              : ""
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            {item.Payment[0]?.status === "Cancelled" &&
                              item.Payment[0]?.prev_status === "Paid" && (
                                <Typography
                                  sx={{
                                    display: { xs: "none", sm: "block" },
                                    background: "#889AB626",
                                    width: "fit-content",
                                    marginInline: "auto",
                                    textAlign: "center",
                                    color: "#22356DB2",
                                    borderRadius: "7px",
                                    padding: "3px 8px 0px 8px",
                                    fontSize: "13px",
                                    fontFamily: "var(--font-regular)",
                                  }}
                                >
                                  Paid
                                </Typography>
                              )}
                            <Typography
                              sx={{
                                background: "#889AB626",
                                width: "fit-content",
                                marginInline: "auto",
                                textAlign: "center",
                                color: "#22356DB2",
                                borderRadius: "7px",
                                padding: "3px 8px 0px 8px",
                                fontSize: "0.9rem",
                                fontFamily: "var(--font-regular)",
                                ">span:nth-child(1)": {
                                  display: { xs: "none", sm: "block" },
                                  width: "fit-content",
                                },
                                ">span:nth-child(2)": {
                                  display: { xs: "block", sm: "none" },
                                  width: "fit-content",
                                },
                              }}
                            >
                              <span>
                                {determineStatus(item.Payment) ||
                                  item?.Payment[0]?.status}
                              </span>
                              {/* <span>{item.Payment[0].status}</span> */}
                              <span>
                                {item.Payment[0]?.status === "Pending payment"
                                  ? "Pending"
                                  : "Pending Payment"}
                              </span>
                            </Typography>
                          </Box>
                        </td>
                        <td
                          onClick={() => handleClick(item.id)}
                          className={
                            item.id === showExpand
                              ? "bordertdforcustomer paymenstsassdf  text-center show-row"
                              : "show-row"
                          }
                        >
                          {addThousandSeparator(
                            finalPrice,
                            selectedLocation?.currency
                          )}{" "}
                          {selectedLocation?.currency}
                        </td>
                      </>
                    )}
                    <td
                      style={{ width: "auto" }}
                      className={
                        item.id === showExpand
                          ? "bordertdforcustomer paymenstsassdf text-center last show-row"
                          : "show-row"
                      }
                    >
                      <Typography
                        onClick={() =>
                          handleCancelDeleteMembership(item?.service_type, item)
                        }
                        sx={{
                          display:
                            item?.Payment[0]?.status === "Refunded" &&
                            item?.service_type === "Membership"
                              ? "none"
                              : "",
                          margin: "auto",
                          cursor: "pointer",
                          width: "80px",
                          borderRadius: "7px",
                          padding: "5px 15px",
                          border: "1px solid #EEEFEE",
                          opacity: "0.9",
                          background: "var(--Main-Colors-White, #FFF)",
                          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.07)",
                          fontFamily: "var(--font-regular)",
                          "&:hover": {
                            background: "#22356D !important",
                            color: "white !important",
                          },
                        }}
                      >
                        {item?.service_type === "Membership" ||
                        item?.service_type === "Voucher"
                          ? t("common.Cancel")
                          : t("common.Details")}
                      </Typography>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="show-row"></td>
                <td className="show-row"></td>
                <td className="show-row"></td>
                <td className="show-row"></td>
                <td className="show-row"></td>
                <td>{t("common.No Payments found")}</td>
                <td className="show-row"></td>
                <td className="show-row"></td>
                <td className="show-row"></td>
                <td className="show-row"></td>
              </tr>
            )}
          </tbody>
        </table>
        <PaymentPopup
          selectedLocation={selectedLocation}
          index={index}
          open={open}
          setOpen={setOpen}
          bookingData={data}
        />
      </Box>
      <BookingModel
        fromWh={"payments"}
        events={AllEvent?.length > 0 && AllEvent[0]}
        update={update}
        serviceList={(e: any) => console.log("")}
        columnsArray={selectedLocation?.location_name}
        openBookingModel={openBookingModel}
        setOpen={(a: boolean) => {
          setOpenBooking(a);
          setSelectedCourt("");
        }}
        clickStart={start_time}
        clickEnd={end_time}
        clickCourt={[selectedCourt]}
        courtList={allCourt}
        view={"Courts"}
      />
      <Dialog
        onClose={() =>
          setDeleteWarning({ isOpen: false, type: "", item: null })
        }
        open={deleteWarning?.isOpen}
      >
        <DialogContent>
          <Box padding={2}>
            <Box>
              <Typography
                sx={{
                  fontFamily: "var(--font-medium)",
                  color: "#22356D",
                  marginRight: "5rem",
                }}
              >
                {t(
                  `common.Are you sure you want to delete this ${deleteWarning.type.toLowerCase()}?`
                )}
              </Typography>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                marginTop={2}
              >
                <button
                  style={{ ...style, color: activeColor }}
                  onClick={() =>
                    setDeleteWarning({ isOpen: false, type: "", item: null })
                  }
                >
                  {t("customers.No_undo")}
                </button>
                <button
                  onClick={() =>
                    deleteWarning.type === "Voucher"
                      ? handleCancelVoucher(deleteWarning.item)
                      : handleCancelMembership(deleteWarning.item)
                  }
                  style={{
                    ...style,
                    color: "white",
                    background: activeColor,
                  }}
                >
                  {deleteWarning.type === "Voucher" ? (
                    <p>
                      {t("common.Yes")}, {t("common.Delete")}
                    </p>
                  ) : (
                    <p>
                      {t("common.Yes")}, {t("refund")}
                    </p>
                  )}
                </button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PaymentFilter;
