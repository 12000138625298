import { Box, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import BookedSlotPopover from '../calenderDaily/BookedSlotPopover';
import moment from 'moment';
import MainContext from 'src/context/MainContext';
import CoachPopover from '../CoachCalendar/CoachPopover';
import BookingModel from '../calenderDaily/BookingModel';
import { useTranslation } from 'react-i18next';


const WeekCoachPopover = ({serviceList,selectedCoach,selectedDate,columnsArray,refetch}:{serviceList:any,selectedCoach:any,selectedDate:any,columnsArray:any,refetch:any}) => {
    const SelectedLocation = useContext(MainContext);
    const {i18n,t} = useTranslation();
    const CurrentLanguage  = i18n.language;
    const [FilterdServiceList,setFilteredServiceList] = useState<any[]>([]);
    const [clickEvent,setClickEvent] = useState({});
    const [open, setOpen] = useState<boolean>(false);
    const [TotalDuration,setTotalDuration] = useState<any>();
    const service_id:number[] = [];
    const updatedData: any = {};


    const GetallDuration = ()=>{
        const duration = FilterdServiceList?.map((item:any)=>{
            const serviceType = String(item?.service?.service_type)?.toLowerCase();

         return  item?.service[serviceType]?.duration
        })
      const totalDuration = duration?.reduce((item,acc)=>acc+item,0);
     const TotalHour =  (totalDuration/60)?.toFixed(1);
     const FixedTiming = TotalHour.includes('.0')? TotalHour.replace(".0",""):TotalHour
     setTotalDuration(FixedTiming);
    }

        useEffect(() => {
            GetallDuration();
        }, [FilterdServiceList]);


    useEffect(() => {
        const filtered  = serviceList?.filter((item:any)=>moment(item.date).format('DD-MM-YYYY')===selectedDate.format('DD-MM-YYYY'))
        setFilteredServiceList(filtered);
       
    }, [serviceList])


    serviceList?.map((item: any) => {
        if (service_id.includes(item?.service?.id)) {
          updatedData[item?.service?.id] = {
            ...updatedData[item?.service?.id],
            value:
              updatedData[item?.service?.id]?.value +
              item?.service[item?.service?.service_type.toLowerCase()]
                ?.maximum_capacity,
          };
        } else {
          service_id.push(item?.service?.id);
          updatedData[item?.service?.id] = {
            value:
              item?.service[item?.service?.service_type.toLowerCase()]
                ?.maximum_capacity,
            players: item?.players?.length,
          };
        }
      });


     
    const IsUnavailable = selectedCoach?.bookabledays?.filter((item:any)=>item?.Unavailable && item?.is_special )?.some((item:any)=>{
        const startDate = moment(item?.date_start).format('YYYY-MM-DD');
        const endDate = moment(item?.date_end).format('YYYY-MM-DD');
           return selectedDate.isBetween(startDate,endDate,undefined,'[]')
        })

    


    const ShowRecurring = () =>{  
        return (
          <Box sx={{width:'12.5px', height:'12.5px', borderRadius:'17px', background:"#889AB6", fontSize:"9px",lineHeight:"8px", paddingBlock:'2.5px', textAlign:'center', fontWeight:'500',color:'white'}}>R</Box>
        )
      }
    
      const ShowAvailablity = () =>{  
        return (
          <Box sx={{width:'12.5px', height:'12.5px', borderRadius:'17px', background:"#455585", fontSize:"9px",lineHeight:"8px", paddingBlock:'2.5px', textAlign:'center', fontWeight:'500',color:'white'}}>A</Box>
        )
      }


      const CustomEvent = ({ event }: { event: any }) => {
 
        const [showPopHover, setPopHover] = useState(null);
        const context = useContext(MainContext);
        const SelectedLocation = context?.SelectedLocation;
        const [anchorE1, setAnchorE1] = React.useState<HTMLElement | null>(null);
        const handleMouseEnter = (events: React.TouchEvent<HTMLDivElement>) => {
            events.stopPropagation();
          setPopHover(event);
          setAnchorE1(events.currentTarget);
        };

        const HandleClick =()=>{
            setClickEvent(event);
            setOpen(true);
        }
    
        const handlePopClose = () => {
          setPopHover(null);
          setAnchorE1(null);
        };
    
        const ENDTIME = moment(event.end_time).format("HH:mm")==="23:59"?"24:00":moment(event.end_time).format("HH:mm");
        if (event?.service?.service_type === "Event") {
        
          return (
            <Box
            sx={{ backgroundColor: "#FDF0E699",border: "#F07727 solid 0.5px",padding:'5px',borderRadius:'7px'}}
             onClick={HandleClick}
            //  onTouchStart={handleMouseEnter} onTouchEnd={handlePopClose}
              position={"relative"}
              height={"100%"}
            >
              <Typography
                sx={{
                  fontFamily: "var(--font-regular)",
                  fontSize: "0.8rem",
                  color: "#22356D",
                }}
              >
                {moment(event.start_time).format("HH:mm")} -{" "}
                {ENDTIME}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "var(--font-medium)",
                  fontSize: "0.9rem",
                  color: "#F07727",
                  textTransform:"capitalize"
                }}
              >
                {event?.service?.event?.event_name}
              </Typography>
              <Typography sx={{fontFamily:'var(--font-medium)', fontSize:"0.9rem", color:'#F07727', textTransform:"capitalize"}}>{event?.courts?.length > 1 ? `${event?.courts[0]?.court_name} +${event.courts?.length -1} more` : event?.courts[0]?.court_name}</Typography>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
    
                <Typography
                  sx={{
                    fontFamily: "var(--font-regular)",
                    borderRadius: "7px",
                    width: "fit-content",
                    height: "14px",
                    fontSize: "11px",
                    padding: "1px 7px",
                    textAlign: "center",
                    background: `${
                      event?.players.length ==
                      event?.service?.event?.maximum_capacity
                        ? "transparent"
                        : "#F07727"
                    }`,
                    color: `${
                      event?.players?.length ==
                      event?.service?.event?.maximum_capacity
                        ? "#F07727"
                        : "white"
                    }`,
                    position: "absolute",
                    bottom: 5,
                    right: 5,
                  }}
                >
                  {event?.players?.length}
                  {event?.service?.event?.maximum_capacity
                    ? `/${event?.service?.event?.maximum_capacity}`
                    : ""}
                </Typography>
              </Box>
              {/* ------ showing recurring ------ */}
              <Box sx={{position:"absolute", width:"100%", top:"5px",right:'15px', display:"flex", justifyContent:"end", gap:"5px"}}>
                {event?.service?.event?.app_available && ShowAvailablity()}
                {event.service?.recurring && ShowRecurring()}
              </Box>
              <BookedSlotPopover
                SelectedLocation={SelectedLocation}
                viewType="daily"
                players={updatedData[event.service?.id]?.players}
                total={updatedData[event.service?.id]?.value}
                eventType={showPopHover}
                anchorEl={anchorE1}
                handlePopoverClose={handlePopClose}
              />
            </Box>
          );
        } else if (event?.service?.service_type === "Lesson") {
      
          return (
            <Box  onClick={HandleClick} sx={{ backgroundColor: "#FFFDD199",padding:'5px',borderRadius:'7px',
                border: "#A3A434 solid 0.5px",}}
                // onTouchStart={handleMouseEnter} onTouchEnd={handlePopClose}
              height={"100%"}
              position={"relative"}
            >
              <Typography
                sx={{
                  fontFamily: "var(--font-regular)",
                  fontSize: "0.8rem",
                  color: "#22356D",
                }}
              >
                {moment(event.start_time).format("HH:mm")} -{" "}
                {ENDTIME}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "var(--font-medium)",
                  fontSize: "0.9rem",
                  color: "#A3A434",
                }}
              >
                {event?.service?.lesson?.lesson_name}
              </Typography>
              <Typography sx={{fontFamily:'var(--font-medium)', fontSize:"0.9rem", color:'#A3A434', textTransform:"capitalize"}}>{event?.courts.length > 1 ? `${event.courts[0].court_name} +${event.courts.length -1} more` : event.courts[0].court_name}</Typography>
              {/* ------ showing recurring ------ */}
              <Box sx={{position:"absolute", width:"100%", top:"5px", display:"flex",right:'15px', justifyContent:"end", gap:"5px"}}>
                {event?.service?.lesson?.app_available && ShowAvailablity()}
                {event.service?.recurring && ShowRecurring()}
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
    
                <Typography
                  sx={{
                    fontFamily: "var(--font-regular)",
                    borderRadius: "7px",
                    width: "fit-content",
                    height: "14px",
                    fontSize: "11px",
                    padding: "1px 7px",
                    textAlign: "center",
                    background: `${
                      event?.players.length ==
                      event?.service?.lesson?.maximum_capacity
                        ? "transparent"
                        : "#A3A434"
                    }`,
                    color: `${
                      event?.players?.length ==
                      event?.service?.lesson?.maximum_capacity
                        ? "#A3A434"
                        : "white"
                    }`,
                    position: "absolute",
                    bottom: 5,
                    right: 5,
                  }}
                >
                  {event?.players?.length}
                  {event?.service?.lesson?.maximum_capacity
                    ? `/${event?.service?.lesson?.maximum_capacity}`
                    : ""}
                </Typography>
              </Box>
              <BookedSlotPopover
                SelectedLocation={SelectedLocation}
                viewType="daily"
                players={updatedData[event.service?.id]?.players}
                total={updatedData[event.service?.id]?.value}
                eventType={showPopHover}
                anchorEl={anchorE1}
                handlePopoverClose={handlePopClose}
              />
            </Box>
          );
        } else{
            return null;
        }
        // else if (event?.service?.service_type == "Block") {
        //   return (
        //     <>
        //       <Box
        //         onTouchStart={handleMouseEnter} onTouchEnd={handlePopClose}
        //         className="default-hover"
        //         height={"100%"}
        //       >
        //         <Typography
        //           sx={{
        //             fontFamily: "var(--font-regular)",
        //             fontSize: "0.8rem",
        //             color: "#889AB6CC",
        //           }}
        //         >
        //           {moment(event.start_time).format("HH:mm")} -{" "}
        //           {ENDTIME}
        //         </Typography>
        //         <Typography
        //           sx={{
        //             fontFamily: "var(--font-medium)",
        //             fontSize: "0.9rem",
        //             color: "#889AB6CC",
        //           }}
        //         >
        //           Blocked
        //         </Typography>
        //         <BookedSlotPopover
        //           SelectedLocation={SelectedLocation}
        //           viewType="weekly"
        //           players={updatedData[event.service?.id]?.players}
        //           total={updatedData[event.service?.id]?.value}
        //           eventType={showPopHover}
        //           anchorEl={anchorE1}
        //           handlePopoverClose={handlePopClose}
        //         />
        //       </Box>
        //     </>
        //   );
        // } 
        // else if (event?.is_cancelled) {
        //   return (
        //     <Box
        //       onMouseEnter={handleMouseEnter}
        //       onMouseLeave={handlePopClose}
        //       sx={{ pointerEvents: "all", cursor: "pointer" }}
        //       className="default-hover"
        //       width={"10px"}
        //       height={"100%"}
        //     >
        //       <BookedSlotPopover
        //         SelectedLocation={SelectedLocation}
        //         viewType="daily"
        //         players={updatedData[event.service?.id]?.players}
        //         total={updatedData[event.service?.id]?.value}
        //         eventType={showPopHover}
        //         anchorEl={anchorE1}
        //         handlePopoverClose={handlePopClose}
        //       />
        //     </Box>
        //   );
        // }
      };


      return (
    <Box>
         <Box sx={{background:'transparent',borderRadius:'7px',margin:'5px',marginBottom:'8px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between', padding:'5px 8px',opacity:IsUnavailable?'0.6':1,boxShadow: ` ${ IsUnavailable ?'none':
                             "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px"}`,border:IsUnavailable?"":'rgba(0, 0, 0, 0.15) 1px solid'}}>
                <Typography sx={{fontFamily:'var(--font-semibold)',}}>{selectedDate.locale(CurrentLanguage).format('dddd DD MMM')}
                    <br/>
                    <Typography sx={{fontSize:'13px',fontFamily:'var(--font-regular)'}}>{TotalDuration} hours booked</Typography>
                </Typography>
               {IsUnavailable ? <Typography sx={{fontFamily:'var(--font-regular)'}}>[ Day Off ]</Typography>: <Typography sx={{fontFamily:'var(--font-regular)'}}>
                   {selectedCoach?.bookabledays?.filter((bookab:any)=>bookab.days === selectedDate.format('dddd') && !bookab?.is_special && !bookab?.Unavailable)?.map((bookable:any)=><Typography>{moment(bookable.start_time,'HH:mm:ss').format('HH:mm')} - {moment(bookable.end_time,'HH:mm:ss').format('HH:mm')==="00:00"?"24:00":moment(bookable.end_time,'HH:mm:ss').format('HH:mm')}</Typography>)}
                </Typography>}
            </Box>
    {
  FilterdServiceList?.length>0 ?  FilterdServiceList?.sort((a:any,b:any)=>a.start_time-b.start_time)?.map((item:any)=><Box sx={{marginX:'10px',marginY:'5px'}}><CustomEvent event={item}/></Box>):<Typography sx={{fontFamily:'var(--font-regular)',ml:'15px',background:'#FFFFFF80',padding:'5px 7px',borderRadius:'7px',width:'90%'}}>{t('common.No Events')}</Typography>
    }
        <BookingModel
        fromWh={""}
        clickCoach={[]}
        coach={true}
        events={clickEvent}
        update={false}
        serviceList={refetch}
        columnsArray={columnsArray}
        openBookingModel={open}
        setOpen={setOpen}
        clickStart={''}
        clickEnd={''}
        clickCourt={[]}
        courtList={[]}
        view={"Coaches"}
      />
    </Box>
  )
}

export default WeekCoachPopover