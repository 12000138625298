import * as React from "react";
import Box from "@mui/material/Box";
import {styled} from "@mui/material";
import { FiPlus } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import Dialog from "@mui/material/Dialog";
import MainContext from "../context/MainContext";
import Typography from "@mui/material/Typography";
import { IoClose, IoMenu } from "react-icons/io5";
import ToggleButton from "@mui/material/ToggleButton";
import DialogContent from "@mui/material/DialogContent";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AddSportByLocationID, useAddSportByLocationID } from "src/api/CourtSettings";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";
import TabComponentDialog from "./TabComponentDialog";

const StyledBox = styled(Box)`
  box-shadow: 0px 4px 4px 0px #0000000d inset; /* Adjust the values to customize the shadow */
`;



const inputStyle = { border:'1px solid #EEEEFE', padding:"7px 0px 7px 12px", marginTop:"15px", fontSize: '14px', color:" #22356D" };
const style = { fontFamily: 'var(--font-semibold)', boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px", padding: "7px 30px", borderRadius: '7px' };
const commonStyle = {display:'flex', alignItems:"center", gap:"7px"};


export default function CustomTabComponent({
  tabValue,
  setTabValue,
  type,
  tabTextArray,
  setSelectedSport,
  locationid,
  getSportById,
}: {
  tabValue: any;
  type:any;
  setTabValue: any;
  tabTextArray: any[];
  setSelectedSport?: any;
  locationid: any;
  getSportById: any;
}) {
    const context = React.useContext(MainContext);
    const { t } = useTranslation();
   
    const setActiveTab = context?.setActiveTab;
    const [showaddSport, setShowAddSport] = React.useState<boolean>(false);
    const [array, setArray] = React.useState<string[]>([]);
    const {Notification,setNotification} = React.useContext(MainContext);

    const handleChange = (newAlignment: any) => {
      if (newAlignment !== null) {
        setTabValue(newAlignment);
      }
    };

    React.useEffect(() => {
      if (setActiveTab) setActiveTab(tabValue);

      if (tabValue && setSelectedSport) setSelectedSport(tabValue);
    }, [tabValue, setActiveTab]);


  
  return (
    <StyledBox
      sx={{
        padding: "2px",
        borderRadius: "7px", // Set the Box border radius
        display: "inline-block", // Wrap the ToggleButtonGroup
      }}
    >
      <ToggleButtonGroup
        value={tabValue}
        exclusive
        sx={{
            flexWrap:"wrap",
          "& .MuiToggleButtonGroup-grouped": {
            border: "none", // Remove border from buttons
            borderRadius: "7px", // Rounded corners for all buttons
            padding: {xs:"1px 18px",sm:"1px 18px", md:"1px 20px"},
            fontFamily: "var(--font-medium) !important",
            margin: (theme) => theme.spacing(0.5),
            "&.Mui-selected": {
              color: "#22356D",
              backgroundColor: "#fff", // White background for the selected button
              "&:hover": {
                backgroundColor: "#fff", // Keep the background white when hovered
              },
            },
            "&:not(.Mui-selected)": {
              backgroundColor: "transparent", // Transparent background for unselected
              color: "#889AB6",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)", // Slight shade when hovered
              },
            },
          },
        }}
      >
        {tabTextArray?.map((item: any) => (
          <ToggleButton
            key={item?.sport_name}
            value={item}
            onClick={(e: any) => handleChange(item)}
            sx={item?.sport_name =="All Sports" ?{  
               width:'auto',
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: "5px",
              paddingBottom: "5px",
              textTransform: "capitalize",
            }:{
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: "5px",
              paddingBottom: "5px",
              textTransform: "capitalize",
            }}
          >
            {item?.sport_name==="All Sports"?t('common.All Sports'):item?.sport_name}
          </ToggleButton>
        ))}
        <ToggleButton
          value={"add"}
          onClick={() => setShowAddSport(true)}
          sx={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "5px",
            paddingBottom: "5px",
            textTransform: "capitalize",
          }}
        >
          <FiPlus />
        </ToggleButton>
      </ToggleButtonGroup>
   <TabComponentDialog locationid={locationid} tabTextArray={tabTextArray} type={type} getSportById={getSportById} setArray={setArray} setShowAddSport={setShowAddSport} showaddSport={showaddSport} />
    </StyledBox>
  );
}
