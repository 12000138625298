import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { GiPlainCircle } from "react-icons/gi";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import { apiCaller } from "src/api/ApiCaller";
import {
  GetServiceLogs,
  getClubId,
  useGetPlayersNotes,
} from "src/api/CalendarBooking";
import { addThousandSeparator } from "src/constants/SportSEparator";
import { locationTypes } from "src/context/MainContext";
import { useTranslation } from "react-i18next";

interface BookedSlotPopoverProps {
  eventType: any;
  anchorEl: HTMLElement | null;
  handlePopoverClose: () => void;
  players?: number;
  total?: number;
  courtTiming?: any;
  viewType: string;
  SelectedLocation: locationTypes;
}

const commonStyle = {
  fontSize: "13px",
  color: "#22356D",
  fontFamily: "var(--font-regular) !important",
};

const booking = {
  cancelled:
    "repeating-linear-gradient( 131deg, #EBECFD99, #EBECFD99 10px, #EBECFD99 10px, #F3F4FEB2 10px, #F3F4FEB2 10px, #F3F4FEB2 20px ) !important",
  active: "#EBECFD99",
};

const lesson = {
  cancelled:
    "repeating-linear-gradient( 131deg,#FFFDD199,#FFFDD199 10px,#FFFDD199 10px, #FFFEE3B2 10px, #FFFEE3B2 10px, #FFFEE3B2 20px ) !important",
  active: "#FFFDD199",
};

const event = {
  cancelled:
    "repeating-linear-gradient( 131deg, #FEF6F099, #FEF6F099 10px, #FEF6F099 10px, #FEF6F0B2 10px, #FEF6F0B2 10px, #FEF6F0B2 20px ) !important",
  active: "#FDF0E699",
};

const BookedSlotPopover: React.FC<BookedSlotPopoverProps> = ({
  eventType,
  anchorEl,
  viewType,
  handlePopoverClose,
  players,
  total,
  courtTiming,
  SelectedLocation,
}) => {
  const open = Boolean(anchorEl);
  const {t} = useTranslation();
  const type = eventType?.service?.service_type || "";
  const [details, setDetails] = useState<any>({});
  const [lastLog, setLastLog] = useState<any>({});
  const [lastNote, setLastNote] = useState<any>({});
  const { data, isSuccess, isError, refetch, setService_id } =
    useGetPlayersNotes();

  const checkBackground = () => {
    if (type === "Booking") {
      if (eventType?.is_cancelled) {
        return booking.cancelled;
      } else {
        return booking.active;
      }
    } else if (type == "Event") {
      if (eventType?.is_cancelled) {
        return event.cancelled;
      } else {
        return event.active;
      }
    } else if (type == "Lesson") {
      if (eventType?.is_cancelled) {
        return lesson.cancelled;
      } else {
        return lesson.active;
      }
    } else if (eventType?.service?.service_type) {
      return "#e7ebf0";
    } else if (
      eventType?.type === "working_hours" ||
      eventType?.type === "special_days" ||
      eventType?.type === "break_hours"
    ) {
      return "#e7ebf0";
    } else {
      return "";
    }
  };


  const findTotalPrice = (data: any) => {
    let total_price = 0;
    if (data?.length > 0) {
      for (let i = 0; i < data?.length; i++) {
        if (data[i]?.status === "Pending payment" && !data[i]?.is_show && !data[i]?.is_canceled) {
          total_price = total_price + data[i].pending_price;
        }
      }
      return total_price;
    }
    return 0;
  };

  useEffect(() => {
    if (eventType?.service_id) {
      // getEventDetails(eventType?.id);
      getLastNote(eventType?.service_id);
      setDetails(eventType);
      setService_id(eventType?.service_id);
    }
  }, [eventType]);

  const getLastNote = async (service_id: number) => {
    try {
      const response = await GetServiceLogs(service_id);
      setLastLog(response.data?.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      // const logs = data?.serviceDetails?.noteAndLogs?.filter((item:any)=>item?.type==="Logs")?.sort((a:any,b:any)=>new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      const Notes = data?.serviceDetails?.noteAndLogs?.sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      // setLastLog(logs[0]);
      setLastNote(Notes[0]);
    }
  }, [data, isSuccess, isError]);

  const ToPlayer = eventType?.players?.filter((item:any)=>!item?.is_canceled && !item.is_show && !item.is_wating);

  const isUnfilledOpenMatch =
  ToPlayer?.length !==
    eventType?.service?.booking?.maximum_capacity
      ? true && eventType?.service?.booking_type === "Open Match"
      : false;


      const TotalCancelled = details?.players?.filter((player:any)=>player.is_canceled)?.length;



  return (
    <Popover
      sx={{
        pointerEvents: "none",
        zIndex: 1000,
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      {!!eventType && (
        <Paper
          elevation={2}
          sx={{
            background: checkBackground(),
            padding: 1.5,
            borderRadius: "7px",
            maxWidth:
              type === "Booking"
                ? "300px"
                : type === "Lesson"
                ? "280px"
                : type === "Event"
                ? "366px"
                : "250px",
          }}
        >
          {eventType?.type !== "working_hours" &&
            eventType?.type !== "special_days" &&
            eventType?.type !== "break_hours" && (
              <Grid container spacing={2}>
                <Grid item xs={12} position={"relative"}>
                  <Box position={'absolute'} sx={{width:"95%", display:'flex', gap:"7px", justifyContent:"end"}}>
                  { (type==="Lesson" && eventType?.service?.lesson?.app_available) && (
                    <Box
                      sx={{
                        height: "15px",
                        width: "52px",
                        borderRadius: "4px",
                        fontSize: "9px",
                        background: "#455585",
                        color: "white",
                        textAlign: "center",
                        paddingBottom: "3px",
                      }}
                    >
                      APP
                    </Box>
                  )}
                  {
                    (type==="Event" && eventType?.service?.event?.app_available) && <Box
                    sx={{
                      height: "15px",
                      width: "52px",
                      borderRadius: "4px",
                      fontSize: "9px",
                      background: "#455585",
                      color: "white",
                      textAlign: "center",
                      paddingBottom: "3px",
                    }}
                  >
                    APP
                  </Box>
                  }
                   {
                    (type==="Booking" && eventType?.by_app) && <Box
                    sx={{
                      height: "15px",
                      width: "52px",
                      borderRadius: "4px",
                      fontSize: "9px",
                      background: "#455585",
                      color: "white",
                      textAlign: "center",
                      paddingBottom: "3px",
                    }}
                  >
                    APP
                  </Box>
                  }
                  {eventType?.service?.recurring && (
                    <Box
                      sx={{
                        height: "15px",
                        width: "52px",
                        borderRadius: "4px",
                        fontSize: "9px",
                        background: "#889AB6",
                        color: "white",
                        textAlign: "center",
                        paddingBottom: "3px",
                      }}
                    >
                      {t('Calendar.Recurring')}
                    </Box>
                  )}
                  </Box>
                
                  <Typography
                    variant="caption"
                    sx={{
                      color: `#22356D99`,
                      fontSize: "13px",
                      lineHeight: "7px",
                      fontFamily: "var(--font-medium) !important",
                    }}
                  >
                    {moment(eventType?.start_time).format("HH:mm")} -{" "}
                    {moment(eventType?.end_time).format("HH:mm")}
                  </Typography>
                  <br />
                  <Typography
                    variant="caption"
                    sx={{
                      color: `red`,
                      fontSize: "14px",
                      lineHeight: "7px",
                      fontFamily: "var(--font-medium) !important",
                    }}
                  >
                    {eventType?.is_cancelled && t("Calendar.Cancelled")}
                  </Typography>
                  <Typography
                    sx={{
                      color:
                        type == "Booking"
                          ? `#7E93FF`
                          : type == "Lesson"
                          ? `#A3A434`
                          : type == "Event"
                          ? `#F07727`
                          : `#889AB6`,
                      fontSize: "13px",
                      lineHeight: "10px",
                      fontFamily: "var(--font-medium) !important",
                      textTransform: "capitalize",
                    }}
                  >
                    {type == "Event"
                      ? `${
                          eventType?.service?.additional_service ||
                          eventType?.service?.event?.event_name
                        }`
                      : type == "Lesson"
                      ? `${
                          eventType?.service?.additional_service ||
                          eventType?.service?.lesson?.lesson_name
                        }`
                      : type == "Booking"
                      ? `Booking - ${eventType?.court_name}`
                      : eventType?.service?.service_type == "Block"
                      ? `Blocked - ${eventType?.court_name}`
                      : ""}
                  </Typography>
                </Grid>
                {eventType?.service?.service_type !== "Block" && (
                  <Grid item xs={12}>
                    {/* type is equal to lesson */}
                    {type == "Lesson" || type == "Event" ? (
                      <Box>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "14.5px",
                              color: "#22356D",
                              fontFamily: "var(--font-semibold) !important",
                            }}
                          >
                            {t('common.Coach')}
                          </Typography>
                          {details?.service?.coaches?.map(
                            (item: any, index: number) => (
                              <Box key={index} display={"flex"} gap={1}>
                                <Avatar
                                  sx={{ width: "20px", height: "20px" }}
                                  alt="Remy Sharp"
                                  src={
                                    item?.profile_url
                                      ? item?.profile_url
                                      : "/images/Reserved.svg"
                                  }
                                >
                                  J
                                </Avatar>
                                <Typography
                                  sx={{
                                    fontSize: "14.5px",
                                    color: "#22356D",
                                    fontFamily:
                                      "var(--font-regular) !important",
                                  }}
                                >
                                  {item.full_name}
                                </Typography>
                              </Box>
                            )
                          )}
                        </Box>
                        {type == "Lesson" && (
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "14.5px",
                                color: "#22356D",
                                fontFamily: "var(--font-semibold) !important",
                              }}
                            >
                              Booked by
                            </Typography>
                            {details?.players
                              ?.filter((item: any) => !item.is_wating)
                              .map((item: any, index: number) => (
                                <Box
                                  sx={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(2, 1fr)",
                                  }}
                                >
                                    <Box position={'relative'} display={"flex"} gap={1}>
                                      <Avatar
                                        sx={{
                                          width: "20px",
                                          height: "20px",
                                          borderRadius: "50%",
                                        }}
                                        alt="Remy Sharp"
                                        src={
                                          item?.customer?.profile_url
                                            ? item?.customer?.profile_url
                                            : "/images/Reserved.svg"
                                        }
                                      >
                                        J
                                      </Avatar>
                                      <p
                                        style={{
                                          fontSize: "13px",
                                          color: "#22356D",
                                          textTransform: "uppercase",
                                          width:'100px',
                                          wordWrap:'break-word',
                                          fontFamily:
                                            "var(--font-regular) !important",
                                        }}
                                      >
                                        {item?.customer?.first_name}{" "}
                                        {item?.customer?.last_name}
                                      </p>
                                    </Box>
                                  <Box
                                    textAlign={"right"}
                                    position={"relative"}
                                  >
                                    <Box
                                      position={"absolute"}
                                      sx={{
                                        background: "#AD00FFB2",
                                        fontSize: "9px",
                                        padding: "1px 5px",
                                        borderRadius: "7px",
                                        color: "white",
                                        fontFamily:
                                          "var(--font-regular) !important",
                                        display: item?.is_show
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      {t('common.No Show')}
                                    </Box>
                                    <Box
                                      position={"absolute"}
                                      sx={{
                                        background: "#FFE8E8",
                                        fontSize: "9px",
                                        padding: "1px 5px",
                                        borderRadius: "7px",
                                        color: "red",
                                        fontFamily:
                                          "var(--font-regular) !important",
                                        display: item?.is_canceled
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      {t('Calendar.Cancelled')}
                                    </Box>
                                    {item?.refunded_status ? (
                                      <Typography
                                        sx={{
                                          ...commonStyle,
                                          color: `#5B8777`,
                                          marginRight: "12px",
                                        }}
                                      >
                                        {t('Calendar.Refunded')}
                                      </Typography>
                                    ) : (
                                      <Typography
                                        sx={{
                                          ...commonStyle,
                                          color: `${
                                            item.status === "Paid"
                                              ? "#5B8777"
                                              : "red"
                                          }`,
                                          marginRight: "12px",
                                        }}
                                      >
                                       { item.status === "Paid" ? addThousandSeparator(item?.paid_price,SelectedLocation?.currency): addThousandSeparator(!item?.is_show && !item?.is_canceled ? item?.pending_price:0,SelectedLocation?.currency)}{" "}
                                        {SelectedLocation?.currency}
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                              ))}
                          </Box>
                        )}
                        {type == "Event" && (
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "14.5px",
                                color: "#22356D",
                                fontFamily: "var(--font-semibold) !important",
                                display:'flex'
                              }}
                            >
                              {t('common.Players')} {players || ToPlayer?.length}/
                              {total ||
                                eventType?.service?.event?.maximum_capacity} <GiPlainCircle style={{fontSize:'5px',marginTop:'8px',marginLeft:'5px',marginRight:'5px'}}/> Cancelled {TotalCancelled}
                            </Typography>
                            {details?.players
                              ?.filter((item: any) => !item.is_wating && !item.is_canceled)
                              .map((item: any, index: number) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "2.5px",
                                  }}
                                  key={index}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "5px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Avatar
                                      sx={{ width: "20px", height: "20px" }}
                                      alt="Remy Sharp"
                                      src={
                                        item?.customer?.profile_url
                                          ? item?.customer?.profile_url
                                          : "/images/Reserved.svg"
                                      }
                                    >
                                      J
                                    </Avatar>
                                    <Typography
                                      sx={{
                                        fontSize: "13px",
                                        color: "#22356D",
                                        textTransform: "uppercase",
                                        wordWrap:'break-word',
                                        fontFamily:
                                          "var(--font-regular) !important",
                                      }}
                                    >
                                      {item?.customer?.first_name}{" "}
                                      {item?.customer.last_name}
                                    </Typography>
                                    <Box
                                      sx={{
                                        background: "#AD00FFB2",
                                        fontSize: "9px",
                                        padding: "1px 5px",
                                        borderRadius: "7px",
                                        color: "white",
                                        fontFamily:
                                          "var(--font-regular) !important",
                                        display: item?.is_show
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      {t('common.No Show')}
                                    </Box>
                                    {/* <Box
                                      sx={{
                                        background: "#FFE8E8",
                                        fontSize: "9px",
                                        padding: "1px 5px",
                                        borderRadius: "7px",
                                        color: "red",
                                        fontFamily:
                                          "var(--font-regular) !important",
                                        display: item?.is_canceled
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      {t('Calendar.Cancelled')}
                                    </Box> */}
                                  </Box>
                                  {item?.refunded_status ? (
                                    <Typography
                                      sx={{
                                        ...commonStyle,
                                        color: `#5B8777`,
                                        marginRight: "12px",
                                      }}
                                    >
                                      {t('common.Refunded')}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      sx={{
                                        ...commonStyle,
                                        color: `${
                                          item.status === "Paid"
                                            ? "#5B8777"
                                            : "red"
                                        }`,
                                        marginRight: "12px",
                                      }}
                                    >
                                    { item.status === "Paid" ? addThousandSeparator(item?.paid_price,SelectedLocation?.currency): addThousandSeparator(!item?.is_show && !item?.is_canceled ? item?.pending_price:0 ,SelectedLocation?.currency)}{" "}
                                    {SelectedLocation?.currency}
                                    </Typography>
                                  )}
                                </Box>
                              ))}
                          </Box>
                        )}
                      </Box>
                    ) : (
                      // type is equal to booking
                      type == "Booking" &&
                      eventType?.service?.service_type !== "Block" && (
                        <Box>
                          {details?.players?.length > 0 && (
                            <Box>
                              <Box className="flex flex-row items-center">
                                <Typography
                                  sx={{
                                    fontSize: "14.5px",
                                    color: "#22356D",
                                    fontFamily:
                                      "var(--font-semibold) !important",
                                  }}
                                >
                                  {" "}
                                  {t('common.Players')} {ToPlayer?.length}/
                                  {
                                    eventType?.service?.booking
                                      ?.maximum_capacity || ""
                                  }{" "}
                                </Typography>
                                {isUnfilledOpenMatch && (
                                  <Box
                                    sx={{
                                      marginLeft: "5px",
                                      background: "rgba(234, 249, 86, 1)",
                                      fontSize: "9px",
                                      padding: "1px 5px",
                                      borderRadius: "7px",
                                      color: "#889AB6",
                                      fontFamily:
                                        "var(--font-regular) !important",
                                      display: true ? "block" : "none",
                                    }}
                                  >
                                    {t('Calendar.UNFILLED OPEN MATCH')}
                                  </Box>
                                )}
                              </Box>
                              {details?.players
                                ?.filter((item: any) => !item.is_wating)
                                .map((item: any, index: number) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      marginBottom: "2.5px",
                                      flexDirection: "row",
                                    }}
                                    key={index}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: "5px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Avatar
                                        sx={{ width: "20px", height: "20px" }}
                                        alt="Remy Sharp"
                                        src={
                                          item?.customer?.profile_url
                                            ? item?.customer?.profile_url
                                            : "/images/Reserved.svg"
                                        }
                                      >
                                        {" "}
                                        J{" "}
                                      </Avatar>
                                      <Typography
                                        sx={{
                                          fontSize: "13px",
                                          color: "#22356D",
                                          textTransform: "uppercase",
                                          wordWrap:'break-word',
                                          fontFamily:
                                            "var(--font-regular) !important",
                                        }}
                                      >
                                        {item?.customer?.first_name}{" "}
                                        {item?.customer.last_name}
                                      </Typography>
                                      <Box
                                        position={"relative"}
                                        sx={{
                                          background: "#AD00FFB2",
                                          fontSize: "9px",
                                          padding: "1px 5px",
                                          borderRadius: "7px",
                                          color: "white",
                                          fontFamily:
                                            "var(--font-regular) !important",
                                          display: item?.is_show
                                            ? "block"
                                            : "none",
                                        }}
                                      >
                                        {t('common.No Show')}
                                      </Box>
                                      <Box
                                        sx={{
                                          background: "#FFE8E8",
                                          fontSize: "9px",
                                          padding: "1px 5px",
                                          borderRadius: "7px",
                                          color: "red",
                                          fontFamily:
                                            "var(--font-regular) !important",
                                          display: item?.is_canceled
                                            ? "block"
                                            : "none",
                                        }}
                                      >
                                        {t('Calendar.Cancelled')}
                                      </Box>
                                    </Box>
                                    {item?.refunded_status ? (
                                      <Typography
                                        sx={{
                                          ...commonStyle,
                                          color: `#5B8777`,
                                          marginRight: "12px",
                                        }}
                                      >
                                        {t('Calendar.Refunded')}
                                      </Typography>
                                    ) : (
                                      <Typography
                                        sx={{
                                          ...commonStyle,
                                          color: `${
                                            item.status === "Paid"
                                              ? "#5B8777"
                                              : "red"
                                          }`,
                                          marginRight: "12px",
                                        }}
                                      >
                                        { item.status === "Paid" ? addThousandSeparator(item?.paid_price,SelectedLocation?.currency): addThousandSeparator(!item?.is_show && !item?.is_canceled ? item?.pending_price:0,SelectedLocation?.currency)}{" "}
                                        {SelectedLocation?.currency}
                                      </Typography>
                                    )}
                                  </Box>
                                ))}
                            </Box>
                          )}
                        </Box>
                      )
                    )}
                  </Grid>
                )}

                {eventType?.service?.service_type !== "Block" && (
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#22356D",
                        fontFamily: "var(--font-medium) !important",
                      }}
                    >
                      {t('customers.Pending Payment')}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        borderRadius: "7px",
                        paddingInline: "5px",
                        background: `${
                          findTotalPrice(details?.players) !== 0
                            ? "red"
                            : "transparent"
                        }`,
                        color: `${
                          findTotalPrice(details?.players) !== 0
                            ? "white"
                            : "green"
                        }`,
                        fontFamily: "var(--font-light) !important",
                      }}
                    >
                      {addThousandSeparator(
                        findTotalPrice(details?.players),
                        SelectedLocation?.currency
                      )}{" "}
                      {SelectedLocation?.currency}
                    </Typography>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  {type !== "Lesson" && lastNote?.internal_note && (
                    <Box>
                      <Typography sx={{ ...commonStyle, fontWeight: "800" }}>
                        {t('Calendar.Internal notes')}
                      </Typography>
                      <Typography sx={{ ...commonStyle }}>
                        {lastNote?.internal_note}
                      </Typography>
                    </Box>
                  )}
                  {lastLog?.log && (
                    <Typography
                      sx={{
                        ...commonStyle,
                        color: "#8d98b4",
                        marginTop: "7px",
                      }}
                    >
                      {/* Via app 13:52 Thur 12th nov */}
                      {lastLog?.log} by {lastLog.user?.full_name} at{" "}
                      {moment(lastLog.created_at).format("HH:mm")}{" "}
                      {moment(lastLog.created_at).format("DD/MM/YYYY")}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
          {eventType?.type === "working_hours" && (
            <Grid item xs={12}>
                <Typography
                sx={{
                  fontSize: "14px",
                  color: "#22356D",
                  fontFamily: "var(--font-regular) !important",
                }}
              >
                {moment(eventType?.start_time).format("HH:mm")}-
                {moment(eventType?.end_time).format("HH:mm")} | {t('Calendar.Closed')}
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: "14px",
                  color: "#22356D",
                  fontFamily: "var(--font-regular) !important",
                }}
              >
                {viewType === "daily"
                  ? `${moment(courtTiming?.start_time, "HH:mm:ss").format(
                      "HH:mm"
                    )}-${moment(courtTiming?.end_time, "HH:mm:ss").format(
                      "HH:mm"
                    )}
              `
                  : `${moment(eventType?.start_time, "HH:mm:ss").format(
                      "HH:mm"
                    )}-${moment(eventType?.end_time, "HH:mm:ss").format(
                      "HH:mm"
                    )}}`}{" "}
                | {t('Calendar.Open')}
              </Typography> */}
              <Typography
                sx={{
                  fontSize: "14px",
                  marginTop: "10px",
                  color: "#22356D",
                  fontFamily: "var(--font-regular) !important",
                  textTransform:'capitalize'
                }}
              >
                {eventType?.court_name} - {t('Calendar.Working Hours Settings')}
              </Typography>
            </Grid>
          )}
          {eventType?.type === "break_hours" && (
            <Grid item xs={12}>
               <Typography
                sx={{
                  fontSize: "14px",
                  color: "#22356D",
                  fontFamily: "var(--font-regular) !important",
                }}
              >
                {moment(eventType?.start_time).format("HH:mm")}-
                {moment(eventType?.end_time).format("HH:mm")} | {t('Calendar.Closed')}
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: "14px",
                  color: "#22356D",
                  fontFamily: "var(--font-regular) !important",
                }}
              >
                {viewType === "daily"
                  ? `${moment(courtTiming?.start_time, "HH:mm:ss").format(
                      "HH:mm"
                    )}-${moment(courtTiming?.end_time, "HH:mm:ss").format(
                      "HH:mm"
                    )}
              `
                  : `${moment(eventType?.start_time, "HH:mm:ss").format(
                      "HH:mm"
                    )}-${moment(eventType?.end_time, "HH:mm:ss").format(
                      "HH:mm"
                    )}`}{" "}
                | {t('Calendar.Open')}
              </Typography> */}
              <Typography
                sx={{
                  fontSize: "14px",
                  marginTop: "10px",
                  color: "#22356D",
                  fontFamily: "var(--font-regular) !important",
                  textTransform:'capitalize'
                }}
              >
                {eventType?.court_name} - {t('Calendar.break Hours Settings')}
              </Typography>
            </Grid>
          )}
          {eventType?.type === "special_days" && (
            <Grid item xs={12}>
                  <Typography
                sx={{
                  fontSize: "14px",
                  color: "#22356D",
                  fontFamily: "var(--font-regular) !important",
                }}
              >
                {moment(eventType?.start_time).format("HH:mm")}-
                {moment(eventType?.end_time).format("HH:mm")} | {t('Calendar.Closed')}
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: "14px",
                  color: "#22356D",
                  fontFamily: "var(--font-regular) !important",
                }}
              >
                {viewType === "daily"
                  ? `${moment(courtTiming?.start_time, "HH:mm:ss").format(
                      "HH:mm"
                    )}-${moment(courtTiming?.end_time, "HH:mm:ss").format(
                      "HH:mm"
                    )}
              `
                  : `${moment(eventType?.start_time, "HH:mm:ss").format(
                      "HH:mm"
                    )}-${moment(eventType?.end_time, "HH:mm:ss").format(
                      "HH:mm"
                    )}`}{" "}
                | {t('Calendar.Open')}
              </Typography> */}
              <Typography
                sx={{
                  fontSize: "14px",
                  marginTop: "10px",
                  color: "#22356D",
                  fontFamily: "var(--font-regular) !important",
                  textTransform:'capitalize'
                }}
              >
                {eventType?.court_name} - {t('Calendar.Working Hours Settings')}
              </Typography>
            </Grid>
          )}
        </Paper>
      )}
    </Popover>
  );
};

export default BookedSlotPopover;
