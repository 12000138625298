import { Box, Dialog, DialogContent, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoMdAdd } from 'react-icons/io'
import { IoClose, IoMenu } from 'react-icons/io5'
import { AddSportByLocationID } from 'src/api/CourtSettings'
import MainContext from 'src/context/MainContext'





const inputStyle = { border:'1px solid #EEEEFE', padding:"7px 0px 7px 12px", marginTop:"15px", fontSize: '14px', color:" #22356D" };
const style = { fontFamily: 'var(--font-semibold)', boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px", padding: "7px 30px", borderRadius: '7px' };
const commonStyle = {display:'flex', alignItems:"center", gap:"7px"};

const addObj = {
    sport_name:'',
    create_level:false
  }

const TabComponentDialog = ({tabTextArray,showaddSport,setShowAddSport,setArray,type,locationid,getSportById}:{showaddSport:any,setShowAddSport:any,setArray:any,type:string,locationid:number,getSportById:any,tabTextArray:any}) => {
    const {t} = useTranslation();
    const { control, setValue, watch } = useForm();
    const {setNotification} = useContext(MainContext);
    const { fields, append, remove, swap, move, insert } = useFieldArray(
      {
        control,
        name: "Rows",
      }
    );
    const SportsData = watch("Rows");

    const handleAdd = () => {
        append(addObj);
        setArray((prevArray:any) => [...prevArray, ""]);
      };

      const handleClose = () => {
        setShowAddSport(false);
        remove();
      };
  

      const handleRemove = (index: number) => {
        remove(index);
    };
    
    
    
    // updation for particuklar sport
      const handleUpdate = (index:number,name:string)=>{
        setValue(`Rows.${index}.sport_name`,name);
      }

      React.useEffect(()=>{
        if (showaddSport) {
          remove(); // Clear previous data before repopulating
          if (type === "service") {
              const final = tabTextArray?.slice(1, tabTextArray?.length);
              final?.forEach((item: any) => append(item));
          } else {
              tabTextArray?.forEach((item: any) => append(item));
          }
      }
      },[tabTextArray,showaddSport])
  

      const handleSubmit= async(e:any)=>{
        e.preventDefault();
        const APIdata:any= {
          sports:SportsData?.map((item:any,index:number)=>{
          const obj ={
            "sport_name":item.sport_name,
            "sequence":index+1,
            "create_level":item.create_level
          }
        return obj
          })
        }
      
        try {
          const response = await AddSportByLocationID(locationid,APIdata);
          setNotification({isShow:true,message:response.data.message,subMessage:'',type:'success'})
          getSportById(locationid)
          handleClose();
        } catch (error:any) {
          setNotification({isShow:true,message:error,subMessage:'',type:'error'})
        }
  
      }
  

      
  // on drag updatedata
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return; // Dragged outside the list, do nothing
    }
    const { source, destination } = result;
    if (source.index === destination.index) {
      return; // Item dropped in the same position, do nothing
    }
    move(source.index, destination.index); // Use move from useFieldArray
  };


  return (
    <Dialog
    sx={{
      ".MuiDialog-paper": {
        width: "650px",
        height: "auto",
      },
    }}
    onClose={handleClose}
    open={showaddSport}
  >
    <DialogContent>
      <Box>
        <form onSubmit={(e:any)=>handleSubmit(e)}>
            <Typography sx={{fontFamily:"var(--font-medium)"}}>{t('courts.Edit Sports')}</Typography>
            <Typography onClick={handleAdd} sx={{...commonStyle, cursor:'pointer',fontFamily:"var(--font-regular)", padding:"10px 0px 10px 10px"}}>{t('courts.Add new label')}<IoMdAdd style={{cursor:'pointer'}}  /></Typography>
            <Box sx={{paddingLeft:"20px"}}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
              {(provided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef}>
                  {SportsData.map((item:any, index:any) => (
                    <Draggable key={index} draggableId={index.toString()} index={index}>
                      {(provided) => (
                        <Box  {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef} sx={{ ...commonStyle, marginBottom: "10px",flexDirection:{xs:'column',md:'row'}}} key={index}>
                              <Box sx={{display:'flex',flexDirection:'row',gap:'5px',alignItems:'center'}}>
                          <IoMenu style={{ color: "#22356D", fontSize: "1.5rem" }} />
                              <input onChange={(e)=>handleUpdate(index,e.target.value)} value={item.sport_name} type='text' placeholder={t('common.Enter a label')} style={{ ...inputStyle, marginTop: "0px !important" }} />   
                                                       
                          <IoClose style={{ cursor: "pointer",width:'20px' }} onClick={() => handleRemove(index)} />
                          </Box> 
                            <Typography sx={{display:'flex',flexDirection:'row' ,gap:'10px',fontFamily:'var(--font-regular) !important',marginLeft:'15px'}}>{t('common.Level for this sport')} 
                              <label style={{marginLeft:'5px',marginBottom:'-10px'}} className="container relative">
                                  <input onClick={()=>setValue(`Rows.${index}.create_level`,!item.create_level)} type="checkbox" checked={item.create_level} />
                                  <span className="mark" style={{ left: "-9px" }}></span>
                                </label>
                            </Typography>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
                </Droppable>
            </DragDropContext>
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'} marginTop={2} >
                <button style={{ ...style, color: 'white', background: "#22356D" }} type='submit' >{t('common.Save')}</button>
            </Box>
        </form>
      </Box>
    </DialogContent>
  </Dialog>
  )
}

export default TabComponentDialog