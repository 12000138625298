function formatResponse(data: any,t:any) {
  let formattedResponse = "";


  data.forEach((item: any) => {
    if (
      item?.date_start == undefined &&
      item?.date_end == undefined &&
      item?.days == undefined
    ) {
      formattedResponse = "Select day and time";
    } else if (
      item?.days?.length !== 0 &&
      item?.date_start == undefined &&
      item?.date_end == undefined &&
      item?.start_time !== undefined &&
      item?.end_time !== undefined &&
      item?.days?.length !== 0
    ) {
      const daysOfWeek = item?.days?.map((day: any) => {
        return day?.slice(0, 1).toUpperCase() + day?.slice(1);
      });
      const startTime = item .start_time!=="Unavailable"?item.start_time?.slice(0, 5):"Not Working";
      const endTime = item?.end_time?.slice(0, 5);
      formattedResponse += `${daysOfWeek?.join(
        ", "
      )}${"  "} ${startTime==="Not Working"?t("common.Not Working"): `${startTime} - ${endTime}`}\n`;
    } else if (
      item?.date_start !== undefined &&
      item?.date_end !== undefined &&
      item?.start_time !== undefined &&
      item?.end_time !== undefined
    ) {
      const startDate = new Date(item.date_start);
      const endDate = new Date(item.date_end);
      const startMonth = startDate.toLocaleString("default", {
        month: "short",
      });
      const endMonth = endDate.toLocaleString("default", { month: "short" });
      const startTime = item .start_time!=="Unavailable"?item.start_time?.slice(0, 5):"Not Working";
      const endTime = item.end_time?.slice(0, 5);
      formattedResponse += `${startMonth}. ${startDate.getDate()}-${
        startMonth === endMonth ? "" : endMonth + ". "
      }${endDate.getDate()} ${"  "} ${startTime==="Not Working"?t("common.Not Working"): `${startTime} - ${endTime}`}\n`;
    }
  });

  return formattedResponse;
}
export default formatResponse;

export const FormatSpecialResponse = (data: any) => {
  let formattedResponse = "";
  if (
    data?.date_start !== undefined &&
    data?.date_end !== undefined &&
    data?.start_time !== undefined &&
    data?.end_time !== undefined
  ) {
    const startDate = new Date(data.date_start);
    const endDate = new Date(data.date_end);
    const startMonth = startDate.toLocaleString("default", {
      month: "short",
    });
    const endMonth = endDate.toLocaleString("default", { month: "short" });
    const startTime = data.start_time?.slice(0, 5);
    const endTime = data.end_time?.slice(0, 5);
    formattedResponse += `${startMonth}. ${startDate.getDate()}-${
      startMonth === endMonth ? "" : endMonth + ". "
    }${endDate.getDate()} ${"  "} ${startTime}-${endTime}\n`;
  }else{
    formattedResponse += "Select special dates";
  }

  return formattedResponse;
};
