import moment from "moment";
import "../tableStyling.css";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import InputBase from "@mui/material/InputBase";
import { activeColor } from "src/constants/color";
import { addNotes, useGetPlayersNotes } from "src/api/CalendarBooking";
import MainContext from "src/context/MainContext";
import { LuSendHorizonal } from "react-icons/lu";
import { useGetAllInternalNotes } from "src/hooks/Calendars/useGetAllInternalNotes";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

const InternalNote = ({ notes, setNotes }: { notes: any; setNotes: any }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [inputValue, setInputValue] = useState<any>([]);
  const context = React.useContext(MainContext);
  const {
    data: PlayNot,
    isSuccess: PlayNotSuccess,
    isError: PlayNotError,
    refetch: PlayNotRefetch,
    setService_id: SetServiceID,
  } = useGetPlayersNotes();
  // const { data, isSuccess, isError, refetch, setservice_id } = useGetAllInternalNotes();
  const events = context.event;

  const handleKeyPress = async (event: any) => {
    if (event.key === "Enter") {
      let data = {
        notes: event.target.value,
      };
      if (Object.keys(events).length > 0) {
        updateNotes(data);
      }
    }
  };

  const updateNotes = async (data: { notes: string }) => {
    try {
      let res = await addNotes(events?.id, data);
      setNotes("");
      PlayNotRefetch();
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Object.keys(events).length > 0 && events?.service?.id) {
      SetServiceID(events?.service_id);
    }
  }, [events]);

  useEffect(() => {
    if (PlayNotSuccess) {
      const Notes = PlayNot?.serviceDetails?.noteAndLogs?.sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setInputValue(Notes);
    } else {
      setNotes("");
      setInputValue([]);
    }
  }, [PlayNot, PlayNotSuccess, PlayNotError]);

  const handleClick = (event: any) => {
    event.stopPropagation();
    let data = {
      notes: notes,
    };
    if (Object.keys(events).length > 0) {
      updateNotes(data);
    }
  };

  useEffect(() => {}, [notes]);

  return (
    <Box position={"relative"} zIndex={10}>
      <Box>
        <p
          style={{
            fontFamily: "var(--font-semibold)",
            color: activeColor,
            marginBottom: "7px",
          }}
        >
          {t("Calendar.Internal notes")}
        </p>

        {inputValue.length > 0 && (
          <Box>
            <Box id="table-person" className="internal-notes">
              {inputValue?.map((item: any, index: number) => {
                const inputDate = item.created_at;
                const formattedTime = moment(inputDate)
                  .locale(currentLanguage)
                  .format("HH:mm");
                const formattedDate = moment(inputDate)
                  .locale(currentLanguage)
                  .format("ddd, DD MMMM");
                const role = item?.user?.role?.name
                  .replace("_", " ")
                  .toLowerCase();
                return (
                  <Box
                    key={index}
                    padding={1}
                    width={"fit-content"}
                    borderRadius={"7px"}
                    sx={{
                      fontFamily: "var(--font-regular)",
                      background: "#889AB60D",
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;",
                    }}
                    color={activeColor}
                    gap={"5px"}
                    marginBottom={2}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "var(--font-semibold)",
                          fontSize: "15px",
                          minWidth: "35%",
                          textTransform: "capitalize",
                        }}
                      >
                        {item?.user?.full_name} (
                        <span style={{ fontSize: "14px" }}>{role}</span>):
                      </p>
                      <p>{item?.internal_note}</p>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#a5adc3",
                          fontFamily: "var(--font-regular)",
                          justifySelf: "flex-start",
                          placeSelf: "self-start",
                          fontSize: "14px",
                        }}
                      >
                        {formattedDate}
                      </Typography>
                      <Box color={"#22356D80"} sx={{ alignSelf: "flex-end" }}>
                        <p style={{ fontSize: "12px" }}>{formattedTime}</p>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}

        <Box
          sx={{
            border: "1px solid #EEEFEE",
            borderRadius: "7px",
            marginTop: "10px",
          }}
          position={"relative"}
        >
          <InputBase
            sx={{
              flex: 1,
              width: { xs: "90%", sm: "95%" },
              pl: "10px",
              color: activeColor,
              fontFamily: "var(--font-regular)",
            }}
            placeholder={t("Calendar.Enter notes")}
            type="text"
            value={notes}
            onKeyDown={handleKeyPress}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
          />
          <LuSendHorizonal
            style={{
              position: "absolute",
              right: "10px",
              top: "7px",
              cursor: "pointer",
              color: "#22356D",
            }}
            onClick={handleClick}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default InternalNote;
