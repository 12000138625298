import * as React from "react";
import { useMutation } from "react-query";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LoginMe } from "../api/login";
import MainContext from "../context/MainContext";
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LuArrowRight } from "react-icons/lu";
import NotificationToast from "src/Notification/NotificationToast";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { IoEyeOffOutline } from "react-icons/io5";

const defaultTheme = createTheme();

export default function SignIn() {
  const navigate = useNavigate();
  const context = React.useContext(MainContext);
  const [ispasswordvisible,setIspasswordVisible] = React.useState(false);
  const authenticated = context?.authenticated;
  const setAuthenticated = context?.setAuthenticated;
  const {Notification,setNotification} = React.useContext(MainContext);
  const [password, setpassword] = React.useState('');
  const [isMasked, setIsMasked] = React.useState(true);
  const [email,setEmail] = React.useState('');

  React.useEffect(() => {
    if (authenticated) {
      navigate("/calendars", { replace: true });
    }
  }, [authenticated, navigate]);

  const mutation = useMutation({
    mutationFn: LoginMe,
    onSuccess: (data:any) => {
      setNotification({isShow:true,message:"Successfully login ⭐",subMessage:'',type:'success'});
      if (data?.accessToken) {
        localStorage.setItem("accessToken", data?.accessToken);
        localStorage.setItem("user", JSON.stringify(data?.user));
        localStorage.setItem('location_restriction',JSON.stringify(data?.user?.location_restriction))
        if(data?.user?.role?.name==="STAFF"){
          localStorage.setItem("permission", JSON.stringify(data?.permission));
        }
      
        if (setAuthenticated) setAuthenticated(true);
        navigate("/calendars", { replace: true });
      }
    },
    onError:(error:any)=> {
      const message = error?.response?.data.message
        setNotification({isShow:true,message:`${message}`,subMessage:'',type:'error'});
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const data = new FormData(event.currentTarget);
    event.preventDefault();
  
   
    mutation.mutate({
      email: email || "",
      password: password || "",
    });
  };

  const heading = {
    color: "black",
    fontFamily: "var(--font-semibold)",
    fontSize: "31px",
    lineHeight: "30px",
  };
  
  const subheading = {
    color: "black",
    fontFamily: "var(--font-semibold)",
    fontSize: "15px",
    lineHeight: "48px",
  };

  const borderInout = {
    width: "100%",
    height: "40px",
    border: "1px solid #EAEBEC",
    borderRadius: "12px",
    color: "#22356D",
    poadding: "10px 5px",
    fontFamily: "var(--font-regular)",
  };

  const handleChange = (event:any) => {
    const { value } = event.target;
    setpassword(value);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <NotificationToast/>
      <Grid position={'relative'} container>
        <Grid sx={{ overflow: "hidden" }} md={7} xs={12}>
          <div style={{ height: "100vh" }}>
            <img
              style={{
                filter: "",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "bottom",
                overflow: "hidden",
              }}
              src="/images/LoginImg.png"
            />
          </div>
        </Grid>
        <Grid className="mobileloginscreenRight" sx={{ backgroundColor: "white", padding: "15% 5% 5%",maxHeight:'100vh' }} md={5} xs={12}>
        <form autoComplete="off"  onSubmit={handleSubmit}>
          <Grid className="mobileloginscreenRightForm" xs={10}>
            <div style={{paddingLeft:'10px'}}>
            <Typography className="mobileloginscreenRightHeading" style={heading}>Welcome Back</Typography>
            <Typography className="mobileloginscreenRightSubHeading" style={{ ...subheading, opacity: "0.3" }}>
              Let's Login to you account
            </Typography>
            </div>
            <div className="flex flex-col">
              <div>
                <Typography style={subheading}>Your Email</Typography>
                <input
                 id='email'
                 name='email'
                  style={borderInout}
                  type="email"
                  placeholder="Email"
                  onChange={(e:any)=>setEmail(e.target.value)}
                />
              </div>
              <div style={{ marginBottom: "35px" }}>
                <Typography style={subheading}>Password</Typography>
                <div className="flex flex-row items-center justify-between" style={{...borderInout,paddingRight:'10px'}}>
                   <div style={{width:'92%'}} className={'masked-input-wrapper'}>
                <input
                id="password"
                type="text"
                placeholder="Password"
                style={{width:'100%',height:'35px',borderRadius:'9px'}}
                value={password}
                onChange={handleChange}
                className={isMasked && password?.length>0 ? 'masked' : ''}
                />
                {isMasked && password?.length>0 && (
                  <span className="mask">
                    {'•'.repeat(password.length)}
                  </span>
                )}
              </div>
                  <span className="cursor-pointer" onClick={()=>setIsMasked(!isMasked)}>{!isMasked?<IoEyeOffOutline/>:<MdOutlineRemoveRedEye />}</span>
                </div>
             
                {/* <input
                 autoComplete="new-password"
                 name='password'
                id="password"
                onFocus={(e:any) =>  e.target.autocomplete = "new-off"}
               
                  type={"password"}
                  placeholder="Password"
                /> */}
              </div>
              <Button
              type="submit"
                sx={{
                  backgroundColor: "#22356D",
                  color: "white",
                  borderRadius: "12px",
                  textTransform: "unset",
                  fontFamily: "var(--font-regular)",
                  "&:hover": {
                    background: "#22356DB2",
                    color: "#FFFFFF",
                  },
                }}
              >
                Login &nbsp; <LuArrowRight />
              </Button>
            </div>
          </Grid>
          </form>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}