import * as React from "react";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import { AiOutlineClose } from "react-icons/ai";
import { getTotalBookingDiscount, getTotalBookingPrice, getTotalPendingPrice, getTotalRefundsPrice } from "src/pages/Payments";
import { addThousandSeparator } from "src/constants/SportSEparator";

const subContainer = {
    border:"1px solid #EEEEFE",
    borderRadius:"7px",
    padding:'20px',
    marginTop:"20px",
    color:'#22356DB2',
}

const flexStyle = {
    display:"flex",
    gap:'5.7rem',
    alignItems:"center",
    justifyContent:"space-between",
    padding:'5px 10px 5px 10px',
    borderBottom:'1px solid #EEEEFE',
    color:'#22356DB2',
    fontFamily:"var(--font-regular)"
}

const MethodModel = ({ allVouchers, location, open, setOpen, data, AllPaymentMethods,AllPayments, totalVoucherSale, getTotalPrice }:{ allVouchers:any, location:{id:number, location_name:string, currency:string }, open:boolean, setOpen:(open:boolean)=>void, data:any,AllPaymentMethods:any,AllPayments:any, totalVoucherSale:number,getTotalPrice:any }) => {

    const AllTotalPayment:Array<number>=[];
    const AllTotalSales:Array<number>=[];
    const AllTotalDiscount:Array<number>=[];
    const AllTotalRefunds:Array<number>=[];
    const [index, setIndex] = React.useState(0);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body');

    const handleClose = () => {
        setOpen(false);
    };

    function getTotalBookingPendingPrice(data:any,name:string) {
        let total = 0;
        data.forEach((item:any) => {
            if(item?.service_type===name){
                item.Payment.forEach((payment:any) => {
                    // console.log(payment.status)
                    if (payment.status === "Pending payment") {
                        total += payment.price;
                    }
                });
            }
        });
        return total;
    }

    return (
    <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{".MuiDialog-paper":{
          paddingInline:{xs:"10px", sm:'1rem'}
        },
        ".MuiDialogContent-root":{
          padding:{xs:"12px 10px",sm:"20px 24px"}
        }   
        }}
      >
        <DialogContent>
            <Box position={'relative'}><AiOutlineClose style={{position:'absolute', right:0, color:"#22356DB2", cursor:"pointer"}} onClick={handleClose} /></Box>
            <Box sx={{...subContainer, marginTop:"30px"}}>
                <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", padding:"10px", fontFamily:"var(--font-medium)"}}>
                    <Box sx={{display:"flex", justifyContent:"start"}}>{index>0 && <SlArrowLeft onClick={()=>setIndex((prev)=>prev-1)} />}</Box>
                    <p>
                        {index===0 ? "Sales by Payment Method" : index===1 ? "Refunds by Payment Method" :index===2? "Discount":"Total Sales by Payment Method"}
                    </p>
                    <Box sx={{display:"flex", justifyContent:"end"}}>{index<3 && <SlArrowRight onClick={()=>setIndex((prev)=>prev+1)} />}</Box>
                </Box>
                { AllPaymentMethods?.map((item:any)=> {
                    const SalesPayment = getTotalBookingPrice(AllPayments,item?.method_name);
                    const Refunds = getTotalRefundsPrice(AllPayments,item?.method_name,"");
                    const discountt = getTotalBookingDiscount(AllPayments,item?.method_name);
                    const TotalSales = SalesPayment-Refunds-discountt;
                    const TotalSalesPayment = SalesPayment;
                    const TotalRefunds = Refunds;
                    AllTotalDiscount.push(discountt);
                    AllTotalSales.push(TotalSalesPayment);
                    AllTotalRefunds.push(TotalRefunds);                           
                    AllTotalPayment.push(TotalSales);

                    return <Box>
                        {index===0 && 
                        <Box sx={{...flexStyle, padding:"10px"}}>
                            <p>{item?.method_name}</p>
                            <p>{addThousandSeparator(SalesPayment,location?.currency)} {location?.currency}</p>
                        </Box>
                    }
                    {index===1 && 
                        <Box sx={{...flexStyle, padding:"10px"}}>
                            <p>{item?.method_name}</p>
                            <p>-{addThousandSeparator(Refunds,location?.currency)} {location?.currency}</p>
                        </Box>
                    }
                    {index===2 && 
                        <Box sx={{...flexStyle, padding:"10px"}}>
                            <p>{item?.method_name}</p>
                            <p>-{addThousandSeparator(discountt,location?.currency)} {location?.currency}</p>
                        </Box>
                    }
                    {index===3 && 
                        <Box sx={{...flexStyle, padding:"10px"}}>
                            <p>{item?.method_name}</p>
                            <p>{addThousandSeparator(Number(TotalSales)+Number(getTotalPrice(item?.method_name, allVouchers)), location?.currency)} {location?.currency}</p>
                        </Box>
                    }
                    </Box>
                }
                )}
                {/* <Box sx={{...flexStyle, padding:"10px"}}>
                    <p>Refunded To Wallet</p>
                    {index===3 || index===1 ? <p>{addThousandSeparator(getTotalRefundsPrice(AllPayments, "", "Wallet"),location?.currency)} {location?.currency}</p> : <p>0 {location?.currency}</p>}
                </Box>
                 <Box sx={{...flexStyle, padding:"10px"}}>
                    <p>Voucher Sales </p>
                    {index===3 || index===0 ? <p>{totalVoucherSale} {location?.currency}</p> : <p>0 {location?.currency}</p>}
                </Box> */}
                <Box sx={{...flexStyle,padding:"10px", fontFamily:'var(--font-medium)', borderBottom:"none"}}>
                    <p>Total</p>
                    <p>{index===0 ?
                    addThousandSeparator(AllTotalSales?.reduce((item,acc)=>item+acc,0),location?.currency):
                    index===1?-(addThousandSeparator(AllTotalRefunds?.reduce((item,acc)=>item+acc,0) + getTotalRefundsPrice(AllPayments, "", "Wallet"),location?.currency)):
                    index===2?-addThousandSeparator(AllTotalDiscount?.reduce((item,acc)=>item+acc,0),location?.currency): 
                    addThousandSeparator(AllTotalPayment?.reduce((item,acc)=>item+acc,0) + totalVoucherSale - getTotalRefundsPrice(AllPayments, "", "Wallet"),location?.currency)
                    } {" "}
                    {location?.currency}</p>
                </Box>
            </Box>
            <Box sx={subContainer}>
                <Box sx={{...flexStyle, fontFamily:'var(--font-medium)'}}>
                    <p>Pending Payments</p>
                    <p>Total</p>
                </Box>
                <Box sx={{...flexStyle}}>
                    <p>Bookings</p>
                    <p>{addThousandSeparator(getTotalBookingPendingPrice(AllPayments,"Booking"),location?.currency)} {location?.currency}</p>
                </Box>
                <Box sx={{...flexStyle}}>
                    <p>Lessons</p>
                    <p>{addThousandSeparator(getTotalBookingPendingPrice(AllPayments,"Lesson"),location?.currency)} {location?.currency}</p>
                </Box>
                <Box sx={{...flexStyle}}>
                    <p>Events</p>
                    <p>{addThousandSeparator(getTotalBookingPendingPrice(AllPayments,"Event"),location?.currency)} {location?.currency}</p>
                </Box>
                <Box sx={{...flexStyle, fontFamily:'var(--font-medium)', borderBottom:"none"}}>
                    <p>Total</p>
                    <p>{addThousandSeparator(getTotalPendingPrice(AllPayments),location?.currency)} {location?.currency}</p>
                </Box>
            </Box>
        </DialogContent>
      </Dialog>
  )
}

export default MethodModel