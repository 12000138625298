import Box from "@mui/material/Box";
import React from "react";

/* 
  const handleSelection = (event: any, item: any) => {
    console.log(event,item,'item');
    // event.stopPropogation();

    const alreadySelected = selected?.filter((sel:any)=>sel?.service===arr?.label && sel?.id===item?.id);
    const RemovedSel = selected?.filter((sel:any)=>sel?.id!==item?.id)
    const updatedArr = arr?.children?.map((sel:any)=>{
      return {...sel,service:arr?.label}
    })
    const isEmpty = selected?.filter((sel:any)=>sel?.service===arr?.label);
    const RemovedD = selected?.filter((sel:any)=>sel?.service!==arr?.label)
    const updatedObj = {...item,service:arr?.label}
        if(item==="Select All"){
          setSelected(isEmpty?.length===0?[...selected,...updatedArr]:RemovedD);
        }else{
          if(alreadySelected?.length!==0){
            setSelected(RemovedSel);
          }else{
            setSelected([...selected,updatedObj])
          }
        }
  }; */

export const FristCompo = ({
  text,
  arr,
  selected,
  setSelected,
  append,
  remove
}: {
  text: string;
  arr: any;
  selected: any;
  setSelected: any;
  append:any;
  remove:any;
}) => {

  const LABEL = arr?.label.includes('Booking')?"Booking":arr?.label.includes('Lesson')?'Lesson':"Event";
  const handleSelection = (event: any, item: any) => {
  const filtered =  selected?.some((service:any)=>service?.id===item?.id && item?.label.includes(LABEL));
  const IndexNumber =  selected?.findIndex((service:any)=>service?.id===item?.id && item?.label.includes(LABEL));
  const AllSingleService = selected?.filter((sel:any)=>sel.label.includes(LABEL))?.map((sel:any)=>sel?.id);
  console.log(AllSingleService,'allsingleServiciicic')
    event.stopPropagation();
    // check if clicked on parent checkbox
    if (item === "Select All") {
      if(AllSingleService?.length===0){
        setSelected('serviceMembership',[...selected,...arr?.children]);
      }else{
        const filteredData = selected.filter((sel:any)=>!AllSingleService?.includes(sel.id));
        // console.log(filteredData,'filterDATA');
        setSelected('serviceMembership',filteredData)
      }
    } else {
      if(filtered){
         remove(IndexNumber)
      }else{
        append(item);
      }
      
    }
  };


  const preSelect = selected?.filter((item: any) =>item.label.includes(LABEL));
  const isPArentSelected =preSelect?.length === arr.children?.length;
  return (
    <Box width={"-webkit-fill-available"} sx={{ background: "white" }}>
      {/* Add Select All option */}
      <Box
        sx={{
          background: isPArentSelected ? "#22356DB2" : "#889AB61A",
          color: isPArentSelected ? "white" : "#22356D",
          padding: "10px",
          cursor: "pointer",
          "&:hover": {
            background: "#22356DB2",
            color: "#FFFFFF",
          },
        }}
        display={"flex"}
        justifyContent={"space-between"}
        gap={"20px"}
        onClick={(event) => handleSelection(event, "Select All")}
      >
        <p style={{ fontFamily: "var(--font-regular)" }}>All {text}</p>
        <label className="container relative">
          <input readOnly type="checkbox" checked={isPArentSelected} />
          <span className="mark" style={{ left: "-9px" }}></span>
        </label>
      </Box>
      {/* Render other options */}
      {arr.children.map((item: any) => {
        const isSelected = preSelect?.some(
          (okay: any) => okay.id === item.id
        );

        return (
          <Box
            key={item.id}
            sx={{
              background: isSelected ? "#22356DB2" : "#889AB61A",
              color: isSelected ? "white" : "#22356D",
              padding: "10px 10px 10px 20px",
              cursor: "pointer",
              "&:hover": {
                background: "#22356DB2",
                color: "#FFFFFF",
              },
            }}
            display={"flex"}
            justifyContent={"space-between"}
            gap={"20px"}
            onClick={(event) => handleSelection(event, item)}
          >
            <p style={{ fontFamily: "var(--font-regular)" }}>{item.label}</p>
            <label className="container relative">
              <input type="checkbox" readOnly checked={!!isSelected ?isSelected:false} />
              <span className="mark" style={{ left: "-9px" }}></span>
            </label>
          </Box>
        );
      })}
    </Box>
  );
};
