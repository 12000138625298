import CustomSelectCheckBox from '../../components/CustomSelectBox'
import { FinalCalednarasdmnf } from '../../components/customTimeSeletor'
import { Box, Button, Grid } from '@mui/material'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetLocation } from 'src/api/CourtSettings'
import { GetAllProductsSale, getCategory } from 'src/api/POS'
import { addThousandSeparator } from 'src/constants/SportSEparator'
import MainContext from 'src/context/MainContext'
import { useGetAllPaymentMethodsByLoc } from 'src/hooks/useGetAllPaymentMethods'
import PurchaseFilter from './PurchaseFilter'
import { category } from './Products'
import MakePurchase from './MakePurchase'


const paymentsDesgin = {
    background: "white",
    borderRadius: "7px",
    padding: "20px 26px 20px 20px",
    gap: "5px",
  };
  
  const justifyContent = {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    fontFamily: "var(--font-regular)",
    color: "#22356DB2",
    gap: "30px",
  };
  
  const selectedTab = {
    textDecoration: "underline",
    textDecorationColor: "#22356D",
    padding: "0px",
    textUnderlineOffset: "6px",
  };
  
  const headingStyle: any = {
    color: "#22356D",
    fontFamily: "var(--font-semibold)",
    textTransform: "capitalize",
    fontSize: { xs: "1.3rem", sm: "1.5rem" },
  };
  
  const unSelected: any = {
    opacity: "0.7",
    paddingLeft: "0",
    color: "#22356D99 !important",
    fontFamily: "var(--font-regular) !important",
    marginLeft: "15px",
  };
  

const Purchase = () => {
    const {t}  = useTranslation();
  const AllTotalPayment:Array<number> = [];
  const [open,setOpen] = useState(false);
  const [AllRecords,setAllRecords] = useState<any[]>([]);
  const [allCategory,setAllCategory] = useState<category[]>([]);
  const { MobileSideBar, setMobileSideBar } = useContext(MainContext);
  const [selectedDay, setSelectedDays] = useState<any>({
    date_start: moment(new Date()).format("YYYY-MM-DD"),
    date_end: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [showPR,setShowPR] = useState(false);
  const {
    data: PaymentMethods,
    isSuccess: PaymentMethodsSuccess,
    isError: PaymentMethodsError,
    setlocation_id: SetLocationID,
  } = useGetAllPaymentMethodsByLoc();
  const [AllPaymentMethods, SetAllPayemntMEthods] = useState<any[]>([]);
  const {
    data: locationdata,
    isError: locationerror,
    isSuccess: locationSucess,
  } = useGetLocation();
  const [AllLocations, setAllLocations] = useState<any>([]);
  const [location, setLocation] = useState<any>();

  const openMobileSidebar = (event: any) => {
    event.stopPropagation();
    setMobileSideBar(true);
  };

 

  useEffect(() => {
    if (locationSucess) {
      setAllLocations(locationdata);
      setLocation(locationdata[0]);
      SetLocationID(locationdata[0].id);
    } else {
      setAllLocations([]);
    }
  }, [locationSucess, locationdata, locationerror]);

  useEffect(() => {
    SetLocationID(location?.id);
  }, [location])
  

  useEffect(() => {
    if (PaymentMethodsSuccess) {
      SetAllPayemntMEthods(PaymentMethods);
    } else {
      SetAllPayemntMEthods([]);
    }
  }, [PaymentMethods, PaymentMethodsSuccess, PaymentMethodsError]);

  useEffect(() => {
   if(!!location){
    getProductSale(location?.id,selectedDay);
   }
  }, [location,selectedDay])

  const getProductSale = async(location_id:number,selectedDay:any)=>{
  try {
    const response = await GetAllProductsSale(location_id,selectedDay);
    const finaldata = response?.payments?.map((item:any)=>{
      const {customers,...newitem} =item;
      const fullName = !!customers ?`${customers?.first_name} ${customers?.last_name}`:null
      return {...newitem,fullName,customers}
    })
    setAllRecords(finaldata);
  } catch (error) {
    setAllRecords([])
    console.log(error)
  }
  }

  useEffect(() => {
    getAllCategory();
  }, []);

  
  const getAllCategory = async()=>{
    const response = await getCategory();
    setAllCategory(response);
  }


  return (
    <Box>
    <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "none", lg: "space-between" },
              alignItems: { xs: "flex-start", lg: "center" },
              flexDirection: { xs: "column", lg: "row" },
              gap: { xs: 1, lg: "none" },
            }}
            marginTop={2}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              <Box sx={{ width: { xs: "120px", lg: "170px" } }}>
                <CustomSelectCheckBox
                  background={false}
                  text={"Select location"}
                  value={location}
                  setValue={setLocation}
                  arr={AllLocations}
                />
              </Box>
              <Box position={"relative"}>
                <FinalCalednarasdmnf
                  type={"range"}
                  containercss={{
                    width: "201px",
                    borderRadius: "7px",
                    backgroundColor: "white",
                    padding: "0px 4px 0px 0px",
                  }}
                  icon={true}
                  placeholder={"select days"}
                  selectedDay={selectedDay}
                  setSelectedDays={setSelectedDays}
                  style={{
                    width: "90%",
                    height: "33px",
                    color: "#22356D",
                    fontFamily: "var(--font-regular)",
                  }}
                />
              </Box>
            </Box>
            <Button
            onClick={()=>setOpen(true)}
              sx={{
                color: "white",
                backgroundColor: "#22356D",
                textTransform: "unset",
              }}
            >
              {t('POS.Make a Sale')}
            </Button>
          </Box>
            <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            <Grid container gap={5} marginTop={2}>
              <Grid item xs={12} sx={{ ...paymentsDesgin, padding: "20px" }}>
                <Box
                  sx={{
                    ...justifyContent,
                    borderBottom: "1px solid #22356D26",
                    fontFamily: "var(--font-medium)",
                  }}
                >
                  <p>{t('POS.Inventory Purchase')}</p>
                  <p style={{textAlign:'center'}}>{t('POS.Units')}</p>
                  <p style={{ textAlign: "right" }}>{t('POS.Total Purchase')}</p>
                </Box>

                {allCategory?.map((item: any) => {
                  const SalesPayment = 0;
                  const Refunds = 0;
                  const discount = 0;
                  const TotalSales =
                    Number(SalesPayment) - Number(Refunds) - Number(discount);
                    AllTotalPayment.push(TotalSales);
                  return (
                    <Box
                      sx={{
                        ...justifyContent,
                        borderBottom: "1px solid #22356D12",
                      }}
                    >
                      <p>{item?.category_name}</p>
                      <p style={{textAlign:'center'}}>
                        {addThousandSeparator(discount, location?.currency)}
                      </p>
                      <p style={{ textAlign: "right" }}>
                        {addThousandSeparator(
                          Number(0) + Number(TotalSales),
                          location?.currency
                        )}{" "}
                        {location?.currency}
                      </p>
                    </Box>
                  );
                })}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontFamily: "var(--font-medium)",
                  }}
                >
                  <p>Total</p>
                  <p>{addThousandSeparator(AllTotalPayment?.reduce((item,acc)=>item+acc,0), location?.currency)} {location?.currency}</p>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <MakePurchase open={open} handleClose={()=>setOpen(false)} locationdata={AllLocations} update={()=>console.log('')} />
          <PurchaseFilter allCategory={allCategory} allPayments={AllRecords}
            selectedLocation={location}
            setSelectedDays={setSelectedDays}
            update={()=>getProductSale(location?.id,selectedDay)}/>
          </Box>
  )
}

export default Purchase