import CustomCalenderTime from '../../components/CourtSetting/CustomCalenderTime'
import { Dialog, DialogContent, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown } from 'react-icons/io';
import formatResponse from 'src/constants/FormatResponse';

const leftborderInput = {
    width: "190px",
    height: "28px",
    opacity: "0.9",
    padding: "5px 5px 5px 0px",
    fontSize: "14px",
    border: "1px solid #EEEFEE",
    borderRadius: "7px",
    color: "#22356D",
  };

const SelectTimeRestriction = ({idx,index,formUpdate,conditionData}:any) => {
    const {t} = useTranslation();
    const [showCalendar,setShowCalendar] = useState<boolean>(false)

    const handleClose = ()=>{
        setShowCalendar(false)
    }

    const handleUpdateForm = (data:any,index:number)=>{
        const TimeRes = data[0];
        formUpdate(`serviceMembership.${index}.time_restriction.${idx}`,TimeRes)
    }

    
    return (
    <div>
         <Typography
          className="flex items-center justify-between"
          onClick={() => setShowCalendar(true)}
          style={leftborderInput}
        >
          <input
            style={{
              width: "100%",
              fontSize: "14px",
              fontFamily: "var(--font-regular)",
            }}
            placeholder="Select day and time"
            value={formatResponse([conditionData[index]?.time_restriction[idx]],t)==="Select day and time"?t('common.Select day and time'):formatResponse([conditionData[index]?.time_restriction[idx]],t)}
          />
          <IoIosArrowDown fontSize={"16px"} opacity={0.7} />
        </Typography>
         <Dialog hideBackdrop={true} onClose={handleClose} open={showCalendar}>
        <DialogContent>
          <CustomCalenderTime
          handleClose={handleClose}
            index={index}
            preTime={conditionData[index]?.time_restriction[idx]}
            setGetDateTime={(e: any) => handleUpdateForm(e, index)}
            setShowCalendar={setShowCalendar}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default SelectTimeRestriction