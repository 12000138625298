import MyDropdown from "../../components/Payment/LocationSelectCheckBox";
import Box from "@mui/material/Box";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetAllServicesCourt, useGetAllServicesCourt } from "src/api/Voucher";
import MainContext from "src/context/MainContext";

const ServicesRow = ({
  data,
  sport,
  setSerivces,
  court,
  name,
  selected,
  fromaddCourt
}: {
  selected: any;
  sport:any;
  name: string;
  data: any;
  setSerivces: any;
  court: any;
  fromaddCourt:any;
}) => {
  const {t} = useTranslation();
  const [selectedService, setSelectedServices] = useState<any>([]);
  const [allServices, setAllServices] = useState<any>([]);
  const {setSelectedCourtDurations} = useContext(MainContext);
  const { data:ServiceData,isError,isSuccess,setName,setsport_id} = useGetAllServicesCourt();

  useEffect(() => {
    if(sport?.id>0){
      setsport_id(sport?.id);
      setName(data);
    }
  }, [sport,data]);

  useEffect(() => {
    if(isSuccess){
    if(name=="Bookings"){
     ServiceData?.forEach((child: any) => {
       child.name = `Booking ${child.duration} min`;
     });
 
    }else if(name=="Lessons"){
     ServiceData?.forEach((child: any) => {
       child.name = child.lesson_name;
     });
    }else if(name=="Events"){
     ServiceData?.forEach((child: any) => {
       child.name = child.event_name;
     });
    }
    setAllServices(ServiceData);
    }else{
      setAllServices([]);
    }
   
  }, [ServiceData,isError,isSuccess,selected])
  



  useEffect(() => {
   if(court?.court_id!==0 && !fromaddCourt){
    if (name === "Bookings") {
      if (selected?.bookings?.length > 0) {
        selected?.bookings?.forEach((child: any) => {
          child.name = `Booking ${child.duration} min`;
        });
        setSelectedServices(selected?.bookings);
      }else{
        setSelectedServices([]);
      }
     
    } else if (name === "Lessons") {
      if (selected?.lessons?.length > 0) {
        selected?.lessons?.forEach((child: any) => {
          child.name = child.lesson_name;
        });
        setSelectedServices(selected?.lessons);
      }else{
        setSelectedServices([]);
      }
    } else if (name === "Events") {
      // Update names for "events" children
      if (selected?.events?.length > 0) {
        selected?.events?.forEach((child: any) => {
          child.name = child.event_name;
        });
        setSelectedServices(selected?.events);
      } else{
        setSelectedServices([]);
      }
    }
   }
  }, [selected]);


// get services on the basis of services
  // const GetaServices = async () => {
  //  try {
  //   const response = await GetAllServicesCourt(data,sport?.id);
  //   if(name=="Bookings"){
  //    response?.forEach((child: any) => {
  //      child.name = `Booking ${child.duration} min`;
  //    });
 
  //   }else if(name=="Lessons"){
  //    response?.forEach((child: any) => {
  //      child.name = child.lesson_name;
  //    });
  //   }else if(name=="Events"){
  //    response?.forEach((child: any) => {
  //      child.name = child.event_name;
  //    });
  //   }
  //   setAllServices(response);
  //  } catch (error) {
  //   setAllServices([]);
  //  }
 
  // };


  const PAssData = useCallback(() => {
    let apidata: any = {};

    if (name === "Bookings") {
      apidata.bookings = selectedService?.map((item: any) => item.id);
    
      return apidata;
    } else if (name === "Lessons") {
      apidata.lessons = selectedService?.map((item: any) => item.id);
      return apidata;
    } else if (name === "Events") {
      apidata.events = selectedService?.map((item: any) => item.id);
      return apidata;
    }
  }, [selectedService, name]);


  
  useEffect(() => {
   if(fromaddCourt ||court?.court_id===0){
   if(name=="Bookings"){
    setSelectedCourtDurations(allServices);
   }
    setSelectedServices(allServices)
   }
  
  }, [court,allServices,fromaddCourt])
  

  const handleChange = (data:any)=>{
    setSelectedServices(data);
  }

  useEffect(() => {
    setSerivces((prev: any) => {
      const newFormattedData = PAssData();
      if (newFormattedData === undefined) {
        return prev;
      }

      if (prev?.length > 0) {
        const indexToUpdate = prev?.findIndex(
          (item: any) => item?.id === newFormattedData?.id
        );
        // If an object with the same id is found, update its data

        if (indexToUpdate !== -1) {
          const updatedState = [...prev];
          updatedState[indexToUpdate] = newFormattedData;
          return updatedState;
        } else if (prev.length !== 0) {
          return [...prev, newFormattedData];
        }
      } else {
        return [newFormattedData];
      }
    });
  }, [PAssData, setSerivces, selectedService, name]);

  return (
    <Box sx={{ display: "flex", flexDirection:{xs:"column", sm:"row"}, alignItems: "center", gap: {xs:"5px", sm:"20px"}, marginBottom:{xs:"10px", sm:'0px'} }}>
      <p
        style={{
          width: "180px",
          padding:'3px 5px',
          height: "27px",
          border: "1px solid #EEEEFE",
          borderRadius: "7px",
          fontSize: "14px",
          opacity: "1",
          fontFamily: "var(--font-regular)",
          color: "#22356D",
        }}
      >{t(`common.${name}`)}</p>

      <MyDropdown
        text={name}
        border={true}
        arr={allServices}
        selected={selectedService}
        setSelected={(data:any)=>handleChange(data)}
        error={""}
        getOpeon={(e: any) => console.log('')}
      />
    </Box>
  );
};

export default ServicesRow;
