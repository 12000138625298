import MyDropdown from "../../components/Payment/LocationSelectCheckBox";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CreateVoucher, DeleteVoucher, GetAllServicesList, UpdateVoucher } from "src/api/Voucher";
import Cleave from "cleave.js/react";
import MainContext, { locationTypes } from "src/context/MainContext";
import { useTranslation } from "react-i18next";
import { Box, Dialog, DialogContent, Stack } from "@mui/material";
import { activeColor } from "src/constants/color";
import { Permission, useGetPermissions } from "src/api/CalendarBooking";
import StaffModal from "src/Notification/StaffModal";

type FormInputs = {
  price: number;
  VoucherName: number;
  VoucherValue: number;
};

const borderInput = {
  width: "180px",
  opacity: "0.7",
  padding: "5px 8px",
  fontSize: "14px",
  border: "1px solid #EEEFEE",
  borderRadius: "7px",
  color: "#22356D",
};

const style = {
  fontFamily: "var(--font-semibold)",
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
  padding: "7px 20px",
  borderRadius: "7px",
};


export function StructureTheData(data:any){

  const Bfindindex = data.findIndex((item:any)=>item?.label==="Bookings")
  const Lfindindex = data.findIndex((item:any)=>item?.label==="Lessons")
  const Efindindex = data.findIndex((item:any)=>item?.label==="Events")
        
  const structuredData :any= {
    "Bookings": data[Bfindindex]?.children?.map((booking:any) => ({ "id": booking.id, "label": `Booking ${booking.duration} min - ${booking.sport.sport_name}` })),
    "Lessons":data[Lfindindex]?.children?.map((lesson:any) => ({ "id": lesson.id, "label": `Lesson ${lesson.lesson_name} - ${lesson.sport.sport_name}` })),
    "Events": data[Efindindex]?.children.map((event:any) => ({ "id": event.id, "label": `${event.event_name} - ${event.sport.sport_name}` }))
  };  
  
  return structuredData;
    }
  

const VoucherSettingsPopup = ({data,locationdata,getVouchers,handleClose}:{handleClose:any,data:any,locationdata:any,getVouchers:any}) => {
  const { control,register, handleSubmit, watch, setValue } = useForm<FormInputs>();
  const {t} = useTranslation();
  const { price, VoucherName, VoucherValue } = watch();
  const {Notification,setNotification,setIsPermissionOn} = useContext(MainContext);
   const StaffPermission = useGetPermissions();
  const [selectedlocations, setSelectedLocations] = useState<locationTypes[] | any[]>([]);
  const [error, setError] = useState("");       
  const [servicesopen,setservicesopen] = useState(false);
  const [WarningDelete,setWarningDelete] = useState(false);
  const currency = selectedlocations[0]?.currency ||""
  const locations = locationdata?.map((item:any) => {
    const {location_name,id,currency} = item;
    const name=location_name
    return {name,id,currency}
  });


  useEffect(() => {
   if(!!data){
    const selectedloc = locationdata?.filter((loc:locationTypes)=>data?.locations?.some((item:any)=>item?.id===loc?.id))?.map((loc:any)=>{
      const {location_name,id,currency} = loc;
      const name=location_name
      return {name,id,currency}
        })
  


    setValue('price',data.price);
    setValue('VoucherName',data.voucher_name);
    setValue('VoucherValue',data.value)
    setSelectedLocations(selectedloc)
   }
  }, [data])

  const selectedLocations = selectedlocations?.map((item:any)=>item.id);

  const APidata ={
    "voucher_name": VoucherName,
    "value": VoucherValue,
    "price": price,
    "locations": selectedLocations,
    "currency":currency
  }
  
  const updateAPidata :any={
   "voucher_name": VoucherName,
    "value": VoucherValue,
    "price": price,
    "locations": selectedLocations,
    "currency":currency
  }

  useEffect(()=>{
if(VoucherName!== data.voucher_name){
updateAPidata.voucher_name=VoucherName;
}
if(VoucherValue!== data.value){
  updateAPidata.value=VoucherValue;
}
if(price!== data.price){
  updateAPidata.price=price;
}
  },[VoucherName,price,VoucherValue,selectedLocations])

  const handleFormSubmit = (e: any) => {
    e.preventDefault();

    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      handleClose()
      return;
    }


    if (!VoucherName) {
      setError("VoucherName");
      setTimeout(() => setError(""), 2000);
    } else if (!VoucherValue) {
      setError("VoucherValue");
      setTimeout(() => setError(""), 2000); 
    } else if (!price) {
      setError("price");
      setTimeout(() => setError(""), 2000);
    }  else if (selectedlocations.length === 0) {
      setError("location");
      setTimeout(() => setError(""), 2000);
    } else {
      setError("");
      // call api on the basis of conditions
      !!data? updatevoucherById() : createNewvoucher();
     
    }
  };


  const updatevoucherById=async()=>{
    const response = await UpdateVoucher(data.id,updateAPidata);
    if(response){
      setNotification({isShow:true,message:response,subMessage:'',type:'success'})
      getVouchers()
      handleClose()
    }
  }
  
const ShowWarning = ()=>{
  setWarningDelete(true);
}

const handleWarningClose = () =>{
  setWarningDelete(false);
  // handleClose();
}

  const deleteVoucherById =async()=>{
const response = await DeleteVoucher(data.id);
if(response){
  setNotification({isShow:true,message:response,subMessage:'',type:'success'})
  getVouchers()
  handleClose()
}
  }

  const createNewvoucher = async()=>{
    const response = await CreateVoucher(APidata)
    if(response){
      setNotification({isShow:true,message:response,subMessage:'',type:'success'})
      getVouchers()
      handleClose()
    }
  }


  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <Typography
          sx={{ fontFamily: "var(--font-medium)", marginBlock: "20px" }}
        >
          <span
            style={{
              margin: "10px 0",
              color: "#22356D",
              fontFamily: "var(--font-semibold)",
              fontSize: "16px",
              borderBottom: "1px solid #22356D",
            }}
          >
            {t('payments.Voucher Settings')}
          </span>
        </Typography>
        <div
          style={{ margin: "15px 0px",flexWrap:'wrap' }}
          className="flex flex-row items-center justify-between"
        >
          <Typography sx={{ fontFamily: "var(--font-regular)" }}>
            {t('payments.Voucher Name')}<span style={{color:'red'}}> *</span>
          </Typography>
          <Box>
          <input
            id={error == "VoucherName" ? "customerror" : ""}
            {...register("VoucherName", { required: true })}
            style={borderInput}
            type="text"
            placeholder={t("common.Enter Name")}
          />
            {/* {error === "VoucherName" && (
                      <Typography
                        sx={{
                          fontFamily: "var(--font-regular)",
                          fontSize: "12px",
                          color: "red",
                        }}
                      >
                        {t('This is a required field')}
                      </Typography>
                    )} */}
          </Box>
        </div>
        <div
          style={{ margin: "15px 0px" }}
          className="flex flex-row items-center justify-between"
        >
          <Typography sx={{ fontFamily: "var(--font-regular)" }}>
            {t('payments.Value')}<span style={{color:'red'}}> *</span>
          </Typography>
          <div className="flex flex-row items-center gap-2">
            <Box>
          <Controller
                    name="VoucherValue"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Cleave
                      id={error == "VoucherValue" ? "customerror" : ""}
                      style={{ ...borderInput, width: currency?"150px":'180px' }}
                      onChange={(event:any) => onChange(event.target.rawValue)}
                      value={value}
                      className="input-price"
                      placeholder={t("common.Enter Credit Value")}
                      options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'thousand',
                          delimiter:'\u2024'
                      }}
                  />
                    )}
                  />
          </Box>
            <Typography
              sx={{ fontFamily: "var(--font-regular)", fontSize: "14px",display:currency?'block':'none' }}
            >
              {currency}
            </Typography>
          </div>
        </div>
        <div
          style={{ margin: "15px 0px" }}
          className="flex flex-row items-center justify-between"
        >
          <Typography sx={{ fontFamily: "var(--font-regular)" }}>
            {t('common.Price')}<span style={{color:'red'}}> *</span>
          </Typography>
          <div className="flex flex-row items-center gap-2">
            <Box>
          <Controller
            name="price"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Cleave
              id={error == "price" ? "customerror" : ""}
              style={{ ...borderInput, width: currency?"150px":'180px' }}
              onChange={(event:any) => onChange(event.target.rawValue)}
              value={value}
              className="input-price"
              placeholder={t("common.Enter Price")}
              options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand',
                  delimiter:'\u2024'
              }}
          />
            )}
          />
          </Box>
            <Typography
              sx={{ fontFamily: "var(--font-regular)", fontSize: "14px" ,display:currency?'block':'none'}}
            >
              {currency}
            </Typography>
          </div>
        </div>
        <div
          style={{ margin: "15px 0px" }}
          className="flex flex-row items-center justify-between"
        >
          <Typography sx={{ fontFamily: "var(--font-regular)" }}>
            {t('common.Location')}<span style={{color:'red'}}> *</span>
          </Typography>
          <Box>
          <MyDropdown 
            isValidation={true}
            getOpeon={setservicesopen} 
            border={true}
            error={error === "location" && error}
            text="Locations"
            arr={locations}
            selected={selectedlocations}
            setSelected={setSelectedLocations}
          />
          </Box>
        </div>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          marginTop={servicesopen ?"200px":"20px"}
        >
          <Button
            onClick={!!data ? ShowWarning :handleClose}
            style={{
              textTransform: "unset",
              fontFamily: "var(--font-semibold)",
              width: "150px",
              height: "37px",
              color: "#22356D",
              backgroundColor: "white",
              fontSize: "16px",
              padding: "10px 30px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
            }}
          >
          {!!data ? t("common.Delete"):t("common.Cancel")}
          </Button>
          <Button
            type="submit"
            // onClick={handleSave}
            style={{
              zIndex: 1,
              textTransform: "unset",
              fontFamily: "var(--font-semibold)",
              color: "white",
              backgroundColor: "#22356D",
              width: "92px",
              height: "37px",
              fontSize: "16px",
            }}
          >
            {t("common.Save")}
          </Button>
        </Grid>
      </form>
      <Dialog
        onClose={handleWarningClose}
        open={WarningDelete}
      >
        <DialogContent style={{ marginTop: "0px" }} id="table-person">
         {StaffPermission?.role!=="OTHER" ? <Box>
                  <Typography sx={{fontFamily:"var(--font-medium)",color:"#22356D",marginBottom:'5px'}}>{t('common.You are not allowed to')} {t('common.delete this voucher')}</Typography>
                  <Stack direction={'row'} spacing={1} marginBottom={4}>
                      <img src="/images/info.svg" alt="info" />
                      <Typography sx={{color:"#22356DB2",fontFamily:"var(--font-regular)",}}>{t('common.Only staff managers are allowed to')} {t('common.delete vouchers.')}</Typography>
                  </Stack>
                  <button style={{ ...style, color: activeColor }}  onClick={handleWarningClose}>{t('common.Close')}</button>
              </Box>:<Box>
                  <Typography sx={{fontFamily:"var(--font-medium)",color:"#22356D",marginBottom:'5px'}}>{t('common.Are you sure you want to delete this voucher ?')}</Typography>
                  <Stack direction={'row'} spacing={1} marginBottom={4}>
                      <img src="/images/info.svg" alt="info" />
                      <Typography sx={{color:"#22356DB2",fontFamily:"var(--font-regular)",}}>{t('common.This will erase all the voucher information')}</Typography>
                  </Stack>
                  <Box
                display={"flex"}
                justifyContent={"space-between"}
                marginTop={2}
              >
                <button
                  style={{ ...style, color: activeColor }}
                 onClick={handleWarningClose}
                >
                  {t('customers.No_undo')}
                </button>
                <button
                  style={{ ...style, color: "white", background: activeColor }}
                  onClick={deleteVoucherById}
                >
                  {t('customers.Yes, Cancel')}
                </button>
              </Box>
              </Box>}
          </DialogContent>
          </Dialog>
    </div>
  );
};

export default VoucherSettingsPopup;
