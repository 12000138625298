import { Box, Button, Dialog, DialogContent, Grid, Typography } from '@mui/material'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import AddIcon from "@mui/icons-material/Add";
import { addThousandSeparator } from 'src/constants/SportSEparator'
import { useTranslation } from 'react-i18next';
import CustomerAddMembership from './CustomerMembershipModel';
import StaffModal from 'src/Notification/StaffModal';
import { Permission, useGetPermissions } from 'src/api/CalendarBooking';
import { GetAllServicesList } from 'src/api/Voucher';

interface location {
  id:number
}

interface events{
  id:number
}
interface bookings{
  id:number
}
interface lessons{
  id:number
}

interface membership{
  events:events[];
  lessons:lessons[];
  bookings:bookings[];
}

const MembershipTab = ({userData,profile_url,customerSetting,AllMemberShip,refetch,GetAllCustomer}:{userData:any,profile_url:any,customerSetting:any,AllMemberShip:any,refetch:any,GetAllCustomer:any}) => {
  const {t} = useTranslation();
  const [showAddMember,setShowAddMmeber] = useState<boolean>(false);
   const StaffPermission = useGetPermissions();
  const [allServices,setAllServices] = useState<any>([]);

  const getServices = async() =>{
    try {
      const res =  await GetAllServicesList();
      setAllServices(res)
    } catch (error) {
      
    }
  }

  useEffect(() => {
    getServices();
  }, [])
  
 const TotalServices = (Locations:location[])=>{
  return allServices?.reduce((total:any, root:any) => total + root?.children?.filter((rot:any)=> Locations?.some((loc:any)=>loc?.id===rot?.location_id))?.length, 0);
 }

 const MembershipService = (membership:membership) =>{
      return membership?.bookings?.length + membership?.lessons?.length + membership?.events?.length
 }


 return (
    <Box>
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "var(--font-regular)",
          color: "#22356D",
        }}
      >
        {t('customers.Membership Settings')}
      </Typography>
      <Button
        onClick={() => setShowAddMmeber(true)}
        style={{
          color: "rgba(34, 53, 109, 0.70)",
          fontFamily: "var(--font-regular)",
          fontSize: "14px",
          gap: "10px",
          textTransform: "none",
        }}
      >
        {t('customers.Add membership')}
        <AddIcon style={{ opacity: 0.7 }} fontSize="small" />
      </Button>
    </Box>

    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
      <Typography
        sx={{
          fontFamily: "var(--font-semibold)",
          color: "#22356D",
          fontSize: "14px",
          opacity: "0.7",
        }}
      >
        {t('customers.Active Memberships')}
      </Typography>
      <Typography
        sx={{
          fontFamily: "var(--font-semibold)",
          color: "#22356D",
          fontSize: "14px",
          opacity: "0.7",
        }}
      >
        {t('customers.Remaining')}
      </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {/* <Button onClick={()=>setShowEditWallet(true)} sx={{ ...style, color: activeColor,padding:'0px 2px',textTransform:'capitalize',marginBottom:'5px' }}>Edit</Button> */}
        {AllMemberShip?.filter((item:any)=>{
          return moment(item?.finish_date).isAfter(moment(new Date()))||item?.finish_date===null
        })?.map((item: any) => {
         return !!item.membership && <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
          <Typography
          sx={{
            fontFamily: "var(--font-regular)",
            color: "#22356D",
            fontSize: "14px",
            opacity: "0.7",
          }}
        >
         {item.membership?.membership_name}
        </Typography>
        <Typography
          sx={{
            fontFamily: "var(--font-regular)",
            color: "#22356D",
            fontSize: "14px",
            opacity: "0.7",
          }}
        >
         {MembershipService(item.membership)}/{TotalServices(item.membership?.locations)}
        </Typography>
        <Typography
          sx={{
            fontFamily: "var(--font-regular)",
            color: "#22356D",
            fontSize: "14px",
            opacity: "0.7",
          }}
        >
         
          {item?.uses_left!==-1?item?.uses_left:"Unlimited"}
        </Typography>
      </Box>
        }
        )}
      </Box>
    </Box>
    <Box sx={{ marginTop: "20px" }}>
      <Typography
        sx={{
          fontFamily: "var(--font-semibold)",
          color: "#22356D",
          fontSize: "14px",
          opacity: "0.7",
        }}
      >
        {t('customers.Membership History')}
      </Typography>
    </Box>

    <Grid container sx={{display:'grid',gridTemplateColumns:{ xs: "repeat(2, 1fr)", sm: "repeat(4, 1fr)" }}}>
      <Typography
        sx={{
          display: { md: "block", xs: "none" },
          fontFamily: "var(--font-semibold)",
          color: "#22356D",
          fontSize: "14px",
          opacity: "0.7",
        }}
      >
        {t('Calendar.Starting Date')}
      </Typography>
      <Typography
        sx={{
          fontFamily: "var(--font-semibold)",
          color: "#22356D",
          fontSize: "14px",
          opacity: "0.7",
        }}
      >
        {t('payments.Membership Name')}
      </Typography>
      <Typography
        sx={{
          display: { md: "block", xs: "none" },
          fontFamily: "var(--font-semibold)",
          color: "#22356D",
          fontSize: "14px",
          opacity: "0.7",
        }}
      >
        {t('common.Price')}
      </Typography>
      <Typography
        sx={{
          fontFamily: "var(--font-semibold)",
          color: "#22356D",
          fontSize: "14px",
          opacity: "0.7",
        }}
      >
        {t('Calendar.Finishing Date')}
      </Typography>
    </Grid>
     <Box id="table-person" style={{paddingLeft:'10px',height:'auto',maxHeight:'120px',padding:'5px',marginTop:'0px'}}>
      {AllMemberShip?.map((item: any) => (
          <Grid container sx={{display:'grid',gridTemplateColumns:{ xs: "repeat(2, 1fr)", sm: "repeat(4, 1fr)" }}}>
            <Typography
              sx={{
                display: { md: "block", xs: "none" },
                fontFamily: "var(--font-regular)",
                color: "#22356D",
                fontSize: "14px",
              }}
            >
              {moment(item?.start_date).format("DD/MM/YYYY")}
            </Typography>
            <Typography
              sx={{
                fontFamily: "var(--font-regular)",
                color: "#22356D",
                fontSize: "14px",
              }}
            >
              {item?.membership?.membership_name || t("Manual Credits")}
            </Typography>
            <Typography
              sx={{
                display: { md: "block", xs: "none" },
                fontFamily: "var(--font-regular)",
                color: "#22356D",
                fontSize: "14px", 
              }}
            >
              {addThousandSeparator(item?.price ,item?.membership?.currency)} {item?.membership?.currency}
            </Typography>
            <Typography
              sx={{
                fontFamily: "var(--font-regular)",
                color: "#22356D",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {item?.finish_date ?moment(item?.finish_date).format('DD/MM/YYYY') : "No time Expiration"}
            </Typography>
          </Grid>
      ))}

     </Box>
     <Dialog  sx={{
              ".MuiDialog-paper": {
                minWidth: {md:"890px",sm:'auto'},
              },
            }} open={showAddMember} onClose={()=>setShowAddMmeber(false)}>
            <DialogContent>
              <CustomerAddMembership update={()=>{
                refetch();
                GetAllCustomer();
              }} customerSetting={customerSetting} customerdata={userData} profile_url={profile_url} setIsOpen={setShowAddMmeber} /> 
            </DialogContent>
          </Dialog>
         {!StaffPermission.edit_permissions && <StaffModal />}
  </Box>
  )
}

export default MembershipTab