import { Box, Typography } from '@mui/material'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import SearchTeammates from './SearchTeammates';
import { addWaitingTeam, getClubId, useGetAllCustomerList, useGetAllWaitingList } from 'src/api/CalendarBooking';
import { apiCaller } from 'src/api/ApiCaller';
import MainContext from 'src/context/MainContext';
import SinglePairWaitingList from './SinglePairWaitingList';
import { organizePlayers } from './EventTab';

const common = {display:"flex", alignItems:"center", gap:"10px",
    "& p":{
        fontFamily:"var(--font-regular) !important",
        color:"#22356D"
    }
};
  

const WaitingTeam = ({ events, serviceLists,waitPlayers, selectEvent,WaitingAppRefetch,filterCustomerList, teams ,teamMates}:{ events: any,filterCustomerList:any, serviceLists: any, waitPlayers:any,selectEvent:any,WaitingAppRefetch:any, teams:any,teamMates:any}) => {
    const context = useContext(MainContext);
    const maxxxxxx =selectEvent?.maximum_capacity ||
    events?.service?.event?.maximum_capacity ||
    events?.service?.booking?.maximum_capacity;
    const {Notification,setNotification} = useContext(MainContext);
    const SelectedLocation = context?.SelectedLocation;
    const [customerList, setCustomerList] = useState<any>([]);
    const [waitingTeam, setWaitingTeam] = useState<any>([]);
    
   
    const movePlayers = async (id:number,position:number) =>{

        try {
            let club_id = getClubId();
          let res: any = await apiCaller.post(`${club_id}/players/move-player/${events?.service_id}/${id}`,{position});          
          serviceLists();
          setNotification({isShow:true,message:res.data?.message,subMessage:'',type:'success'})
        } catch (error:any) {
          const err = error.response?.data?.message;
          setNotification({isShow:true,message:err,subMessage:'',type:'error'})

          console.log(err);
        }
      }

    const checkTeammate = async (data:any,position:number) => {
        let sendData = {};
        if(data.text==="first"){
            sendData = { "customer_id" : data.newValue,position:position||2 }
        }else{
            sendData = {
                "position":position||2,
                "customer_id":data.newValue
            }
        }
        try{
            let res = await addWaitingTeam(events?.service_id, sendData);
            WaitingAppRefetch();
        }catch(error:any){
            console.log(error);
            setNotification({isShow:true, message:error.response.data?.message,subMessage:'',type:'error'})
        }
    }

    useEffect(()=>{
        setCustomerList(filterCustomerList);
    },[filterCustomerList])

    useEffect(() => {
            let updatedWaiting = waitPlayers?.filter((item:any)=>item.status==="waiting" || item.status==="waiting_approved");
            const updatedPosition = organizePlayers(updatedWaiting,maxxxxxx);
            setWaitingTeam(updatedPosition);
            let arr:number[] = [];

            teams?.forEach((item:any)=>{
                arr.push(item.customer?.id)
            })

            waitPlayers?.forEach((item:any)=>{
                arr.push (item.customer?.id);
                if(item?.pairedData?.length>0){
                  arr.push(item?.pairedData[0]?.customer?.id)
                }
            })
            let filteredData = filterCustomerList.filter((item:any)=> !arr.includes(item.id));
            setCustomerList(filteredData)
        
    }, [waitPlayers,filterCustomerList,maxxxxxx])
 

    return (
       <Box>
            <Box id="table-person" sx={{marginTop:"0px !important", maxHeight:"313px !important",">div:nth-child(odd)":{
                    background:"#889AB626 !important"
                },">div:nth-child(even)":{
                    background:"white"
                },">div:hover":{background:"#889AB626 !important"}}}>
                {/* if added in waiting list */}
                {waitingTeam.map((item:any, index:number) => <Box sx={{...common, justifyContent:"space-between", padding:"10px"}}>
                    <SinglePairWaitingList allWaiting={waitPlayers} teamMates={teamMates} movePlayers={movePlayers} checkTeammate={checkTeammate} customerList={customerList} refetch={()=>console.log('refetch')} data={item} index={index}/>
                 
                </Box>)}
            </Box>
       </Box>
  )
}

export default WaitingTeam