import React, { useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MainContext from "src/context/MainContext";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { useTranslation } from "react-i18next";

const DateCarousel = () => {
  const {t,i18n} = useTranslation();
  const CurrenLanguage = i18n.language;
  const [activeIndex, setActiveIndex] = useState(0);
  const context = React.useContext(MainContext);
  const selectedDate = context?.selectedDate;
  const setSelectedDate = context?.setSelectedDate;
  const selectedMonth = context?.selectedMonth;
  const setSelectedMonth = context?.setSelectedMonth;
  const [viewPort, setView] = useState(false);
  const today = moment(); 
  const innerWidth = Number(window.innerWidth) >= 1200 && Number(window.innerWidth) <= 1360;

  // Generate an array of dates for the current year
  const [yearDates, setYearDates] = useState<moment.Moment[]>([]);
  
  useEffect(() => {
    const generateYearDates = () => {
      const firstDayOfYear = today.clone().startOf("year");
      const lastDayOfYear = today.clone().endOf("year");
      const dates: moment.Moment[] = [];
      let currentDate = firstDayOfYear.clone();
      while (currentDate.isSameOrBefore(lastDayOfYear, "day")) {
        dates.push(currentDate.clone());
        currentDate.add(1, "day");
      }
      return dates;
    };
    setYearDates(generateYearDates());
  }, []);

  // Calculate the index to display the current date in the middle
  useEffect(() => {
    if (selectedDate) {
      const selectedIndex = yearDates.findIndex((date) => date.isSame(selectedDate, "day"));
      if (selectedIndex !== -1) {
        setActiveIndex(Math.max(0, selectedIndex - (viewPort ? 5 : innerWidth?1: 2)));
      }
    }
  }, [selectedDate, yearDates]);

  useEffect(() => {
    if (selectedMonth) {
      const isFirstDateOfMonth = moment(selectedMonth)?.isSame(moment(), 'month');
      if (!isFirstDateOfMonth) {
        const firstDateOfMonthIndex = yearDates.reduceRight((lastIdx, date, index) => {
          const currentDate = moment(date);
          const isSameMonth = currentDate.isSame(selectedMonth, 'month');
          if (isSameMonth && lastIdx === -1) {
              return index; // Found the last index of the selected month
          }
          return lastIdx;
      }, -1);
        setSelectedDate(selectedMonth);
      }else{
        setSelectedDate(selectedMonth)
      }
    }
  }, [selectedMonth, yearDates]);


  const handleClick = (direction: string) => {
    setActiveIndex((prevIndex) => {
      const maxIndex = yearDates.length - 5; // Maximum index to prevent overflow
      if (direction === "next" && prevIndex < maxIndex) {

        const nextDate = moment(selectedDate).add(1, "day").toDate();
        setSelectedMonth(nextDate)
        setSelectedDate(nextDate);
        return prevIndex + 1;
      }

      if (direction === "prev" && prevIndex > 0) {
        
        const nextDate = moment(selectedDate).subtract(1, "day").toDate();
        setSelectedMonth(nextDate)
        setSelectedDate(nextDate);
        return prevIndex - 1;
      }

      return prevIndex;
    });
  }; 

  const handleDayClick = (day: moment.Moment) => {
    if (setSelectedDate){ 
      setSelectedDate(day.toDate());
      setSelectedMonth(day.toDate())
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia('(min-width: 600px) and (max-width: 900px)').matches) {
        setView(true);
      }else{
        setView(false);
      }
    };

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!selectedDate) return null;

  return (
    <Grid container alignItems="center">
      <Grid container sx={{ width: "100%", justifyContent:{xs:"center", sm:"space-between", md:"unset"}, gap:{xs:"10px", sm:"0%", md:"0"},"@media (max-width: 400px)":{
        gap:0,
        marginBottom:"10px"
      } }}>
        <Button
          sx={{ minWidth: "fit-content", width: "23px !important",padding:{xs:"2px 0px", md:"2px"} }}
          onClick={() => handleClick("prev")}
          disabled={activeIndex === 0}
        >
          <ChevronLeftIcon style={{color:"#22356D"}} />
        </Button>
        {yearDates.slice(activeIndex, activeIndex + (viewPort ? 11 :innerWidth?4: 5)).map((date: moment.Moment, i: number) => {
          const isSelected = date.isSame(selectedDate, "day");
          const buttonStyles = {
            color: isSelected ? "#22356D" : "inherit",
            backgroundColor: isSelected ? "#fff" : "inherit",
            border: "none",
            "&:hover": { border: "none" }
          };
          const dayName = date.locale(CurrenLanguage).format("ddd");
          const dayOfMonth = date.format("D");

          return (
            <Button
              key={i}
              onClick={() => handleDayClick(date)}
              sx={{
                fontSize: {xs:"1rem", sm:"0.87rem !important"},
                display: "flex",
                flexDirection: "column",
                minWidth: "50px",
                borderRadius: "10px",
                ...buttonStyles
              }}
              variant={isSelected ? "outlined" : "text"}
            >
              <Typography sx={{ textTransform: "capitalize", fontFamily: "var(--font-regular)", fontSize: {xs:"1rem", sm:"0.87rem !important"}, }}>{dayName}</Typography>
              <Typography variant="h6" sx={{ fontSize: {xs:"1.25rem !important", md:"1rem !important"}, fontFamily: "var(--font-semibold) !important", lineHeight: "1.2" }}>{dayOfMonth}</Typography>
            </Button>
          );
        })}
        <Button
          sx={{ minWidth: "fit-content", width: "23px !important", padding:{xs:"2px 0px", md:"2px"} }}
          onClick={() => handleClick("next")}
          disabled={activeIndex === yearDates.length - 5}
        >
          <ChevronRightIcon style={{color:"#22356D"}} />
        </Button>
      </Grid>
    </Grid>
  );
};

export default DateCarousel;
