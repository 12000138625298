import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";

const CoachCrousel = ({selectedCoach,SetSelectedCoach,CoachList}:{CoachList:any[],selectedCoach:any,SetSelectedCoach:any}) => {
     const [selectedIndex,setSelectedIndex] = useState<number>(0);
     const CoachListLength = CoachList?.length-1;

    const handleClick =(direction:string)=> {
          if(direction==="prev" && selectedIndex>0){
              SetSelectedCoach(CoachList[selectedIndex-1])
          }else if(direction==="next" && selectedIndex < CoachListLength){
            SetSelectedCoach(CoachList[selectedIndex+1])
          }
    }

    useEffect(() => {
    const Index = CoachList?.findIndex((item:any)=>item.id===selectedCoach.id);
    setSelectedIndex(Index)
    }, [selectedCoach,CoachList]);

    
const isValidLeft = selectedIndex>0;
const isValidright = selectedIndex < CoachListLength;

  return (
    <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',background:'white',padding:'5px', borderRadius:'7px'}}>
<FaChevronLeft style={{color:'#22356D',opacity:isValidLeft?1:'0.3'}} onClick={()=>handleClick('prev')}/>
    <Box sx={{display:'flex',flexDirection:'row',alignItems:'cneter',gap:'10px'}}>
        <img style={{width:'35px',height:'35px',borderRadius:'50%'}} src={selectedCoach?.profile_url ? selectedCoach?.profile_url :'images/Reserved.svg'} /> 
        <Typography sx={{fontFamily:'var(--font-medium)'}}>
            {selectedCoach.full_name}<br/>
            <Typography sx={{fontSize:'12px',textAlign:'center'}}>{selectedIndex+1}/{CoachListLength+1}</Typography>
        </Typography>
    </Box>
        <FaChevronRight style={{color:'#22356D',opacity:isValidright?1:'0.3'}} onClick={()=>handleClick('next')}/>
        </Box>
  )
}

export default CoachCrousel